import { 
  DATA,
  DATA_MERGED,
  CLEAR_DATA,
} from '../actions/types';
import * as LocalStorage from '../lib/LocalStorage';
import { merge } from '../lib/Utils';

const initialState = {
  user_new: {},
  user_list: [],
  upload_new: {},
  upload: {},
  upload_analytics: {},
  upload_list: [],
  run_new: {},
  run: {},
  run_analytics: {},
  run_list: [],
  file_new: {},
  file: {},
  file_analytics: {},
  file_list: [],
  shopping_cart: LocalStorage.getItem('shopping_cart'),
  order_preview: {},
  order_new: {},
  order: {},
  order_list: [],
  audience_new: {},
  audience: {},
  audience_analytics: {},
  audience_list: [],
  pixel_new: {},
  pixel: {},
  pixel_visits: [],
  pixel_list: [],
};

export const data = (state = initialState, action) => {
  const { type, payload } = action;
  
  switch(type) {
    case DATA:
      return merge({
        ...state,
        ...payload,
      })
    case DATA_MERGED:
      return merge(
        {},
        state,
        payload,
      )
    case CLEAR_DATA:
      return initialState;      
    default:
      return state;
  }
};
