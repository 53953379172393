import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { Grid, Paper, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { getAudience } from '../../../actions/audience';
import { isEmpty, sortBy } from '../../../lib/Utils';
import { audienceHistorySchema } from '../config';
import { AutoRefresh, DataGridStatusColumn } from '../../Common';

export function AudienceHistory(props) {

  const { embedded } = props;
  
  // sort by date_created desc, i.e., the latest created first
  const defaultSort = sortBy('date_created', 'desc');

  const { audience } = useSelector(state => state.data);

  const [availableRows, setAvailableRows] = useState([]);

  useEffect(() => {
    if (!isEmpty(audience)) {
      const { history = [] } = audience;
      const _availableRows = history.filter(x => x).sort(defaultSort);
      setAvailableRows(_availableRows);
    }
  }, [audience])

  let columns = [];
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    () => audienceHistorySchema.filter(x => x.hide === true).reduce((a, v) => ({ ...a, [v['field']]: false }), {})
  );

  if (!isEmpty(audience)) {
    const { history } = audience;
    if (!isEmpty(history)) {      
      columns = Object.keys(history[0]).map(x => {
        return {
          field: x,
          headerName: x,
        };
      });
  
      // set up from the config
      columns = audienceHistorySchema.filter(x => x.id === history.id);
  
      // enhance 'status' column
      columns[columns.findIndex(x => x.field === 'status')].renderCell = (params) => {        
        return (
          <DataGridStatusColumn params={params} type={"audiences"} />
        );
      }

    }
  }

  const component = (
    <Grid container id="audiences-get-one-audience-history">
      <Grid item xs={6} sx={{ my: 1 }}>
        <Typography><strong>Audience History</strong></Typography>
      </Grid>
      <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, mt: 1 }}>
        <DataGrid
          rows={availableRows}
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(x) => setColumnVisibilityModel(x)}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10,50,100]}
          checkboxSelection={false}
          disableRowSelectionOnClick={true}
          autoHeight={true}
          density={'compact'}
          getRowId={row => row.date_created + row.status}
          sx={{ m: 1 }}
        />
      </Grid>
    </Grid>
  );

  return (
    <>
      { !embedded ?
        <Paper id="upload-history" sx={{ boxShadow: 10, borderRadius: 1, p: 2 }}>
          {component}
        </Paper>
        : component
      }
    </>
  );
}
