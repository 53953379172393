export const feedbackLabels = {
  rate: {
    1: 'Very Dissatisfied',
    2: 'Dissatisfied',
    3: 'Neither Satisfied or Dissatisfied',
    4: 'Satisfied',
    5: 'Very Satisfied',
  },
  recommend: {
    1: 'Mostly Unlikely',
    2: 'Unlikely',
    3: 'Neither Likely or Unlikely',
    4: 'Likely',
    5: 'Mostly Likely',
  }
};
