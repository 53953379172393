import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { Box, Stack, Button, Grid, Tooltip, Typography, Divider } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  Cancel as CancelIcon,
} from '@mui/icons-material';
import { isEmpty, merge, priceDisplay } from '../../lib/Utils';
import { uploadAnalyticsPaidSchema } from '../Analytics/Upload/config/uploadAnalyticsPaidSchema';
import { runAnalyticsPaidSchema } from '../Analytics/Run/config/runAnalyticsPaidSchema';
import OrderPriceChangeNotification from './OrderPriceChangeNotification';

export default function OrderItems(props) {

  const { order = {}, user_key, deleteItemHandler, mode, useLimits } = props;
  const { 
    items,
    amount,
    currency,
    decimals,
    order_price_change_notification,
  } = order;

  const renderUploadColumns = (section) => ({
    [section]: uploadAnalyticsPaidSchema[section]?.fields.map((x, i) => {

      if (x.field === 'field') {
        x.renderCell = (params) => {

          const { description, field, name, included_in } = params.row;
          const _description = included_in ? `${description}. Included in ${included_in.toUpperCase()}` : description;

          const _field = field || name;

          return (
            <Grid container direction="row" sx={{ justifyContent: 'flex-start', alignItems: 'center' }} key={`${x}-${i}`}>
              <Grid item xs={8} zeroMinWidth>
                <Tooltip title={_description} arrow>
                  <Typography variant="inherit" noWrap>{included_in ? `${included_in.toUpperCase()}-${_field}` : _field }</Typography>
                </Tooltip>
              </Grid>
              
              {/* delete item */}
              { deleteItemHandler && field !== 'Total' ?
                <Grid item xs={2} zeroMinWidth>
                  <Stack direction="row" spacing={0} sx={{ justifyContent: 'center' }}>
                    <Tooltip title="Delete item" arrow>
                      <CancelIcon
                        fontSize="small"
                        color="warning"
                        onClick={() => deleteItemHandler({ type: 'upload', section, params })}
                        sx={{ display: 'flex' }}
                      />
                    </Tooltip>
                  </Stack>
                </Grid>
                : null
              }
            </Grid>
          )
        };
      }

      return x;
    }),


  })

  const renderRunColumns = (section) => ({
    [section]: runAnalyticsPaidSchema[section]?.fields.map((x, i) => {

      if (x.field === 'field') {
        x.renderCell = (params) => {

          const { description, field, name, included_in } = params.row;
          const _description = included_in ? `${description}. Included in ${included_in.toUpperCase()}` : description;

          const _field = field || name;

          return (
            <Grid container direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }} key={`${x}-${i}`}>
              <Grid item xs={10} zeroMinWidth>
                <Tooltip title={_description} arrow>
                  <Typography variant="inherit" noWrap>{included_in ? `${included_in.toUpperCase()}-${_field}` : _field }</Typography>
                </Tooltip>
              </Grid>
              
              {/* delete item */}
              { deleteItemHandler && field !== 'Total' ?
                <Grid 
                  item
                  xs={2}
                  zeroMinWidth
                  sx={{
                    display: 'flex',
                  }}
                >
                  <Tooltip title="Delete item" arrow>
                    <CancelIcon
                      fontSize="small"
                      color="warning"
                      onClick={() => deleteItemHandler({ type: 'run', section, params })}
                    />
                  </Tooltip>
                </Grid>
                : null
              }
            </Grid>
          )
        };
      }

      return x;
    }),
  })

  const columns = {
    'upload': {
      ...renderUploadColumns('demographic_data'),
      ...renderUploadColumns('identity_data'),
    },
    'run': {
      ...renderRunColumns('demographic_data'),
      ...renderRunColumns('identity_data'),
    }
  }

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() =>
    Object.fromEntries(
      Object.entries(merge({}, columns)).map(([type, section]) => ([
        type,
        Object.fromEntries(
          Object.entries(section).map(([key, value]) => ([
            key,
            value
              .filter(x => x.hide === true)
              .reduce((a, v) => ({ ...a, [v['field']]: false }), {})
          ]))
        )
      ]))
    )
  );
  
  // filter out columns for 'mini' view
  const columnsMini = Object.fromEntries(
    Object.entries(merge({}, columns)).map(([type, section]) => ([
      type,
      Object.fromEntries(
        Object.entries(section).map(([k, v]) => {
          // delete a custom renderHeader defined in this file, make all cells non editable and use the config for any overwrites
          v?.forEach((x,i) => {
            delete x.renderHeader;
            delete x.editable;
            if (mode === 'preOrder' && x.preOrder) v[i] = { ...x, ...x.preOrder };
            if (mode === 'postOrder' && x.postOrder) v[i] = { ...x, ...x.postOrder };
          });
          return [ k, v?.filter(x => (
            x.mini 
            && (
              (mode === 'preOrder' && !x.preOrder?.hide)
              || (mode === 'postOrder' && !x.postOrder?.hide)
            )
          ))];
        })
      )
    ]))
  );

    // // filter out columns for 'mini' view
    // const columnsMini = Object.fromEntries(
    //   Object.entries(merge({}, columns))
    //     .map(([k, v]) => {
    //       // delete a custom renderHeader defined in this file, make all cells non editable and use the config for any overwrites
    //       v?.forEach((x,i) => { 
    //         delete x.renderHeader;
    //         delete x.editable;
            
    //         if (x.preOrder) v[i] = { ...x, ...x.preOrder };
    //       });
    //       return [k, v?.filter(x => x.mini && !x.preOrder?.hide)];
    //     }
    // ));

  // create columnsTotal by getting a first object from columnMini as a sample
  const columnsTotal = Object.values(columnsMini[Object.keys(columnsMini)[0]])[0].filter(x => ['field', 'price', 'decimals', 'currency', 'display_price'].includes(x.field));
  const [columnTotalVisibilityModel, setColumnTotalVisibilityModel] = useState(() =>
    columnsTotal.filter(x => x.hide === true).reduce((a, v) => ({ ...a, [v['field']]: false }), {})  
  );

  const rowsTotal = [
    {
      id: 0,
      field: 'Total',
      key: 'total',
      price: amount,
      decimals,
      currency,
      description: `Total amount`,
    },
  ];

  // if order_price_change_notification - show it in the cell
  columnsTotal[columnsTotal.findIndex(x => x.field === 'display_price')].renderCell = (params) => {
    const { key } = params.row;
    if (key === 'total') {
      if (order_price_change_notification) {
        const { total_price, order_amount, currency, decimals } = order_price_change_notification;
        return (
          <Stack 
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            <Box sx={{ textDecoration : 'line-through' }}>
              {priceDisplay(total_price, decimals, currency)}
            </Box>
            <Box sx={{ color: 'red' }}>
              {priceDisplay(order_amount, decimals, currency)}
            </Box>
          </Stack>
        );
      }
    }
  }

  return (
    <>
      { !isEmpty(items) ?
        <Grid container>
          <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1 }}>
            <>
              { items.map(item => {
                const { resource_type, resource_key, resource_name, resource_items } = item;
                const resource_type_dispay_name = resource_type === 'upload' ? 'List' : 'XAudience';
                const resource_type_route_piece = `${resource_type_dispay_name.toLowerCase()}s`;

                return (
                  <Box key={`${resource_type}-${resource_key}`}>
                    <Divider variant="middle" sx={{ mt: 2}}>
                      <Grid item xs={12} sx={{ mx: 2, my: 1 }}>
                        <Stack direction="row">
                          <Typography variant="inherit" noWrap><strong>{`${resource_type_dispay_name} name: `}</strong></Typography>
                          <Tooltip title={`See '${resource_name}' ${resource_type_dispay_name} Details`} arrow>
                            <Button
                              component={Link}
                              to={{
                                pathname: `/${resource_type_route_piece}/${resource_key}`,
                                search: user_key && `user_key=${user_key}`,
                              }}
                              target="_blank"
                              sx={{ px: 2, m: -1 }}
                            >
                              <Typography variant="inherit" noWrap>{resource_name}</Typography>
                            </Button>
                          </Tooltip>
                        </Stack>
                      </Grid>
                    </Divider>
                    { Object.entries(resource_items).map(([k, v]) => (
                      <Box key={`${resource_type}-${resource_key}-${k}`}>
                        { !isEmpty(v) ?
                          <>
                            <Grid item xs={12} sx={{ mx: 2, my: 1 }}>
                              <Typography>{uploadAnalyticsPaidSchema[k]?.name}</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ mx: 2, my: 1 }}>
                              <DataGrid
                                rows={v}
                                columns={columnsMini[resource_type][k]}
                                columnVisibilityModel={columnVisibilityModel[resource_type][k]}
                                onColumnVisibilityModelChange={(x) => setColumnVisibilityModel(columnVisibilityModel[resource_type][k] = x)}
                                hideFooter={true}
                                autoHeight={true}
                                density={'compact'}
                                getRowId={row => row.key}
                              />
                            </Grid>
                          </>
                          : null
                        }
                      </Box>
                    ))}
                  </Box>
                )})
              }
            </>
            <Divider variant="middle" sx={{ mt: 4 }}><strong>Total</strong></Divider>
            <Grid key='total-section' item xs={12} sx={{ m: 2 }}>
              <DataGrid
                rows={rowsTotal}
                columns={columnsTotal}
                columnVisibilityModel={columnTotalVisibilityModel}
                onColumnVisibilityModelChange={(x) => setColumnTotalVisibilityModel(x)}
                hideFooter={true}
                autoHeight={true}
                density={'compact'}
                sx={
                  {
                    // hide header
                    "& .MuiDataGrid-columnHeaders": { display: "none" },
                    "& .MuiDataGrid-virtualScroller": { marginTop: "0!important" },
                    // bold it
                    '&.MuiDataGrid-root': {
                      fontWeight: 'bold',
                    },
                  }
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <OrderPriceChangeNotification data={order_price_change_notification} />
          </Grid>
        </Grid>
        : null
      }
    </>
  );
};
