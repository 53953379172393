import { sendHttpRequest } from '../lib/Utils';
import { setDataState } from '../actions/data';
import { store } from '../redux/store';

const preUpload = async (kwargs) => {
  const { user_key, upload } = kwargs;

  const response = await sendHttpRequest.post('/uploads',
    { data: { upload } },
    { params: { user_key } },
  );
  return response;
};

/**
 * Put a new object to S3 using signed URL
 * 'config._signedUrl' was set for not attaching the Authorization Bearer to the request
 * (see axiosClient/interceptors.request)
 * 
 * Fix this: 
 * ------WebKitFormBoundary...
 * Content-Disposition: form-data; name="upload"; filename="..."
 * Content-Type: text/csv
 * 
 * @param {*} url 
 * @param {*} file
 * @returns 
 */
const uploadToS3 = async (url, file) => {

  // const formData = new FormData();
  // formData.append('upload', file);

  const response = await sendHttpRequest.request({
    method: 'put',
    url: url,
    data: file,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    _externalService: true,
    onUploadProgress: (event) => {

      const { data } = store.getState();
      const { upload_new } = data;

      store.dispatch(setDataState('upload_new', {
        ...upload_new,
        load_progress: {
          ...event,
        },
      }));
    },
  });

  return response;
};

export const updateUpload = async (kwargs) => {
  const { upload_key, user_key, upload } = kwargs;

  const response = await sendHttpRequest.put(`/uploads/${upload_key}`, 
    { data: { upload } },
    { params: { user_key } },
  );
  return response;
};

export const createUpload = async(kwargs) => {

  const { user_key, upload } = kwargs;
  const { file: fileObject } = upload;

  // create new Upload, initial Upload History
  let response = await preUpload({
    user_key,
    upload: {
      ...upload,
      description: 'Successfully finished stage: new_upload_s3_signed_url',
    },
  });

  // put object to s3 using s3_signed_url
  const { 
    key: upload_key,
    s3_signed_url,
  } = response.data?.data?.upload_list?.at(0) || {};
  response = await uploadToS3(s3_signed_url, fileObject);
  
  // update Upload by creating new Upload History
  response = await updateUpload({
    user_key,
    upload_key,
    upload: {
      status: 'uploaded',
      description: 'Successfully finished stage: new_upload_s3_copy',
    },
  });

  return response;
};

export const getUploads = async () => {
  const response = await sendHttpRequest.get(`/uploads`);
  return response;
};

export const getUpload = async (kwargs) => {
  const { upload_key, user_key } = kwargs;
  const response = await sendHttpRequest.get(`/uploads/${upload_key}`,
    { params: { user_key, history: true } },
  );
  return response;
};

export const getUploadAnalytics = async (kwargs) => {
  const { upload_key, user_key } = kwargs;
  const response = await sendHttpRequest.get(`/uploads/${upload_key}/analytics`, 
    { params: { user_key, price: true } },
  );
  return response;
};

export const deleteUpload = async (kwargs) => {
  const { upload_key, user_key } = kwargs;
  const response = await sendHttpRequest.delete(`/uploads/${upload_key}`, 
    { params: { user_key } },
  );
  return response;
};

export const refreshUpload = async (kwargs) => {
  const { upload_key, user_key } = kwargs;

  const response = await updateUpload({
    user_key,
    upload_key,
    upload: {
      status: 'uploaded',
      description: 'Successfully finished stage: existing_upload_refresh',
    },
  });
  return response;
};
