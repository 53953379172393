import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Alert,
  AlertTitle,
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import {
  CheckBoxOutlineBlankOutlined as CheckBoxOutlineBlankOutlinedIcon,  
  CheckBoxOutlined as CheckBoxOutlinedIcon,
} from '@mui/icons-material';
import { setDataState } from '../../../actions/data';
import { clearMessage } from '../../../actions/message';
import { uploadFormats, } from '../config';

export default function NewUploadFormat() {

  const dispatch = useDispatch();

  const [data, setData] = useState({
    upload_format: 'email_address',
  });

  const handleUploadDataChange = (event) => {
    const { name, value } = event.target;
    setData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  // initial load of the 1st child component
  useEffect(() => {
    dispatch(clearMessage());
    dispatch(setDataState('upload_new'));
  }, []);

  useEffect(() => {
    dispatch(setDataState('upload_new', data));
  }, [data]);

  return (
    <Grid container id="uploads-create-new-upload-format">
      <Grid item xs={12} sx={{ my: 1 }}>
        <Typography><strong>New List: Format of the Audience</strong></Typography>
      </Grid>
      <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="upload_format"
            // defaultValue={data.upload_format}
            name="upload_format"
            value={data.upload_format}
            onChange={handleUploadDataChange}
          >
            {uploadFormats.map(e => (
              <FormControlLabel 
                key={e.id}
                value={e.id}
                control={ <Radio size="small" /> } 
                label={ <Box component="div" fontSize={14}>{e.label}</Box> }
                sx={{ mx: 1 }}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}>
        <Alert
          severity="info"
          variant="outlined"
        >
          <AlertTitle><strong>The following columns are expected (checked are required):</strong></AlertTitle>           
          <List dense>
            {uploadFormats.find(e => e.id === data.upload_format).children.map(e => {
              return (
                <ListItem key={e.id}>
                  <ListItemIcon>
                  { e.required ?
                    <CheckBoxOutlinedIcon color='primary'/>
                    : <CheckBoxOutlineBlankOutlinedIcon color='primary'/>
                  }
                  </ListItemIcon>
                  <ListItemText
                    primary={e.description}
                    sx={{whiteSpace: 'pre-line'}}
                  />
                </ListItem>
              )
            })}
          </List>
        </Alert>
      </Grid>
    </Grid>
  )
}
