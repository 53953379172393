import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Box, Button, Checkbox, Grid, Paper, Typography } from '@mui/material';
import {
  KeyboardArrowRight as KeyboardArrowRightIcon,
  OpenInNew as OpenInNewIcon,
} from '@mui/icons-material';
import { clearShoppingCart, getShoppingCart, setShoppingCart } from '../../../../actions/shopping_cart';
import { createOrder, createOrderUsingAccountCredit, previewOrder } from '../../../../actions/order';
import StripeCheckout from '../Checkout/Stripe';
import TermsAndConditions from '../../../TermsAndConditions';
import { AutoRefresh, OrderItems } from '../../../Common';
import OrderPaymentNotification from './OrderPaymentNotification';
import { useNavigate } from 'react-router-dom';
import { setMessage } from '../../../../actions/message';

export default function ShoppingCart() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { shopping_cart, order_preview: order } = useSelector(state => state.data);

  const [items, setItems] = useState([]);
  const [openCheckoutDialog, setOpenCheckoutDialog] = useState(false);
  const [openTermsAndConditionsDialog, setOpenTermsAndConditionsDialog] = useState(false);
  const [termsAndConditionsChecked, setTermsAndConditionsChecked] = useState(false);

  const handleGetShoppingCart = () => {
    dispatch(getShoppingCart());
  };

  const handleGetOrderPreview = (items) => {
    dispatch(previewOrder(items));
  };

  const handleDeleteItem = (kwargs) => {

    const { type, section, params } = kwargs;
    const { key, match_rate, found_count } = params.row;

    // logic to find out which resource_index (in case of multiple resources) the item to be deleting is coming from...
    // type, section, key, match_rate and found_count should be unique enough
    const resource_index = items.findIndex(x =>
      x.resource_type === type && x.resource_items[section].find(y =>
        y.key === key 
        && y.match_rate === match_rate 
        && y.found_count === found_count
      )
    );

    items.forEach((item, index) => {
      if (index === resource_index) {
        item.resource_items[section] = item.resource_items[section].filter(x => x.key !== key);

        //delete the whole resource if empty after deletion
        const _length = Object.values(item.resource_items).flat().length;
        if (!_length) {
          delete items[index];
        }
      }
    })

    dispatch(setShoppingCart({
      items: items.filter(x=>x),
    }))
  }

  // initial load
  useEffect(() => {

    // set 'shopping_cart' state
    handleGetShoppingCart();
  }, [])

  useEffect(() => {
    if (shopping_cart?.items) {
      setItems(shopping_cart.items);
      handleGetOrderPreview(shopping_cart);
    }
  }, [shopping_cart?.items])

  const handleTermsAndConditionsToggle = (event) => setTermsAndConditionsChecked(event.target.checked);

  const handleCheckout = () => {

    // user_credit
    if (order?.user_credit?.enterprise_user && order?.user_credit?.has_enough_credit) {
      
      dispatch(createOrderUsingAccountCredit(order));
    
      navigate('/');
  
      // set success message
      dispatch(setMessage({
        info: "New Order has been successfully created and paid with your Account Credit. Our data experts will be in touch with you when it is ready"
      }));
  
      // clear shopping cart
      dispatch(clearShoppingCart());

    }

    // bank card
    else {
      setOpenCheckoutDialog(true);
      dispatch(createOrder(order));
    }
  };

  const handleTermsAndConditionsClick = () => {
    setOpenTermsAndConditionsDialog(true);
  };
  
  return (
    <>

      {/* popup window - terms and conditions */}
      <TermsAndConditions
        openDialog={openTermsAndConditionsDialog}
        setOpenDialog={setOpenTermsAndConditionsDialog}
        setChecked={setTermsAndConditionsChecked}
      />

      {/* popup window - stripe checkout */}
      <StripeCheckout
        openDialog={openCheckoutDialog}
        setOpenDialog={setOpenCheckoutDialog}
      />      
      
      <Paper id="shopping-cart" sx={{ boxShadow: 10, borderRadius: 1, p: 2 }}>
        <Grid container>
          <Grid item xs={6} sx={{ my: 1 }}>
            <Typography><strong>Shopping Cart</strong></Typography>
          </Grid>
          <Grid item xs={6} sx={{ my: 1 }}>
            <AutoRefresh handler={handleGetShoppingCart} />
          </Grid>
          <Grid item xs={12}>
            <OrderItems
              order={order}
              deleteItemHandler={handleDeleteItem}
              mode={'preOrder'}
            />
          </Grid>

          <Grid item xs={12} sx={{ mt: 1 }}>
            <OrderPaymentNotification user_credit={order?.user_credit} />
          </Grid>          

          { order?.amount ?
            <>
              <Grid item xs={12}>
                <Alert
                  sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}
                  severity="info"
                  variant="outlined"
                  icon={false}
                >
                  <Box>
                    <Checkbox
                      checked={termsAndConditionsChecked}
                      onChange={handleTermsAndConditionsToggle}
                    />
                    {`Accept `}
                    <Button
                      variant="text"
                      color="inherit"
                      underline="always"
                      onClick={handleTermsAndConditionsClick}
                      startIcon={<OpenInNewIcon />}
                    >
                      {`Terms And Conditions`}
                    </Button>
                    {`to checkout Shopping Cart`}
                  </Box>
                </Alert>
              </Grid>
              <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, mt: 1 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleCheckout}
                  disabled={!termsAndConditionsChecked}
                  endIcon={<KeyboardArrowRightIcon />}
                >
                  { order?.user_credit?.enterprise_user && order?.user_credit?.has_enough_credit ? 
                    "Checkout using your prepaid Account Credit (no charge)"
                    : "Checkout using a credit/debit card"
                  }
                </Button>
              </Grid>
            </>
            :
            <Grid item xs={12}>
              <Alert
                sx={{ boxShadow: 10, borderRadius: 1, mt: 1 }}
                severity="info"
                variant="outlined"
              >
                <Box>
                  Your shopping cart is empty
                </Box>
              </Alert>
            </Grid>
          }
        </Grid>
      </Paper>
    </>
  )
}
