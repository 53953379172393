/* eslint-disable @typescript-eslint/explicit-module-boundary-types -- all exports are ImporterLocale which is already fully typed */
export const enUS = {
    components: {
        ColumnDragCard: {
            dummyHeaderText: 'Unassigned field',
            getHeaderText: (code) => `Column ${code}`
        },
        ColumnDragSourceArea: {
            ariaLabelText: 'Columns to import',
            getPageIndicatorText: (currentPage, pageCount) => `Page ${currentPage} of ${pageCount}`,
            getAssigningColumnText: (columnCode) => `Assigning column ${columnCode}`,
            nextColumnsText: 'Show next columns',
            previousColumnsText: 'Show previous columns'
        },
        ColumnDragTargetArea: {
            ariaLabelText: 'Target fields'
        },
        ColumnPicker: {
            requiredFieldsErrorText: 'Please assign all required fields',
            subtitleText: 'Select Columns Mapping'
        },
        FileSelector: {
            defaultText: 'Drag-and-drop or select CSV file',
            defaultTextSecondary: '(up to 5 GB per file)',
            dragActiveText: 'Drop CSV file here...'
        },
        FileSelectorOnly: {
            defaultText: 'Drag-and-drop or select a file',
            defaultTextSecondary: '(up to 5 GB per file)',
            dragActiveText: 'Drop a file here...'
        },
        FileStep: {
            importErrorText: 'Import error:',
            rawFileShortSummaryText: 'File Size',
            rawFileContentsText: 'File Content',
            previewImportText: 'File Preview',
            hasHeadersText: 'Header row present',
            loadingPreviewText: 'Loading preview...'
        },
        FormatRawPreview: {
            getWarningText: (warningMessage) => `${warningMessage}: please check data formatting`
        },
        FormatErrorMessage: {
            backText: 'Back'
        },
        ImporterFrame: {
            previousStepText: 'Go to previous step',
            nextStepText: 'Next'
        },
        ProgressDisplay: {
            subtitleText: 'Column Mapping',
            uploadMoreText: 'Restart',
            finishText: 'Upload',
            statusErrorText: 'Column Mapping could not be completed',
            statusCompleteText: 'Column Mapping has been confirmed! CSV File is ready to be Uploaded!',
            statusPendingText: 'Column Mapping processing...',
            processedRowsText: 'Processed rows:'
        },
        SourceBox: {
            clearAssignmentText: 'Clear column assignment',
            selectColumnText: 'Select column for assignment',
            unselectColumnText: 'Unselect column'
        },
        TargetBox: {
            optionalAriaLabelText: 'optional',
            requiredAriaLabelText: 'required',
            boxPlaceholderText: 'Drag column here',
            getBoxValueActionText: (columnCode) => `Assign column ${columnCode}`,
            clearText: 'Clear column assignment'
        }
    }
};
export const deDE = {
    components: {
        ColumnDragCard: {
            dummyHeaderText: 'Nicht zugewiesenes Feld',
            getHeaderText: (code) => `Spalte ${code}`
        },
        ColumnDragSourceArea: {
            ariaLabelText: 'Zu importierende Spalte',
            getPageIndicatorText: (currentPage, pageCount) => `Seite ${currentPage} von ${pageCount}`,
            getAssigningColumnText: (columnCode) => `Spalte ${columnCode} zuweisen`,
            nextColumnsText: 'Nächste Spalten anzeigen',
            previousColumnsText: 'Vorherige Spalten anzeigen'
        },
        ColumnDragTargetArea: {
            ariaLabelText: 'Zielfelder'
        },
        ColumnPicker: {
            requiredFieldsErrorText: 'Bitte weise allen nicht optionalen Spalten einen Wert zu',
            subtitleText: 'Spalten auswählen'
        },
        FileSelector: {
            defaultText: 'CSV-Datei auf dieses Feld ziehen, oder klicken um eine Datei auszuwählen',
            defaultTextSecondary: '(up to 5 GB per file)',
            dragActiveText: 'CSV-Datei auf dieses Feld ziehen...'
        },
        FileSelectorOnly: {
            defaultText: 'Drag-and-drop or select a file',
            defaultTextSecondary: '(up to 5 GB per file)',
            dragActiveText: 'Drop a file here...'
        },
        FileStep: {
            importErrorText: 'Fehler beim Import:',
            rawFileShortSummaryText: 'File Size',
            rawFileContentsText: 'Originaler Datei-Inhalt',
            previewImportText: 'Import-Vorschau',
            hasHeadersText: 'Mit Kopfzeile',
            loadingPreviewText: 'Vorschau wird geladen...'
        },
        FormatRawPreview: {
            getWarningText: (warningMessage) => `${warningMessage}: bitte Datenformat überprüfen`
        },
        FormatErrorMessage: {
            backText: 'Zurück'
        },
        ImporterFrame: {
            previousStepText: 'Zum vorherigen Schritt',
            nextStepText: 'Weiter'
        },
        ProgressDisplay: {
            subtitleText: 'Importieren',
            uploadMoreText: 'Weitere hochladen',
            finishText: 'Abschließen',
            statusErrorText: 'Konnte nicht importiert werden',
            statusCompleteText: 'Fertig',
            statusPendingText: 'Wird importiert...',
            processedRowsText: 'Verarbeitete Zeilen:'
        },
        SourceBox: {
            clearAssignmentText: 'Zugewiesene Spalte entfernen',
            selectColumnText: 'Spalte zum Zuweisen auswählen',
            unselectColumnText: 'Spalte abwählen'
        },
        TargetBox: {
            optionalAriaLabelText: 'optional',
            requiredAriaLabelText: 'erforderlich',
            boxPlaceholderText: 'Spalte hierher ziehen',
            getBoxValueActionText: (columnCode) => `Spalte ${columnCode} zuweisen`,
            clearText: 'Zugewiesene Spalte entfernen'
        }
    }
};
