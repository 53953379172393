import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { Alert, AlertTitle, Box, Button, Dialog, DialogActions, Divider, Grid, Paper, useTheme, useMediaQuery, LinearProgress } from "@mui/material";
import {
  Close as CloseIcon,
  Payment as PaymentIcon,
} from '@mui/icons-material';
import { isEmpty, priceDisplay } from "../../../../../lib/Utils";
import { setDataState } from "../../../../../actions/data";
import { cancelOrder } from "../../../../../actions/order";

const CLIENT_URL = process.env.REACT_APP_CLIENT_URL;

export default function StripePayment(props) {

  const { 
    openDialog,
    setOpenDialog,
    clientSecret,
    order,
  } = props;

  const {
    name,
    comments,
    amount,
    decimals,
    currency,
  } = order;

  const orderAmountFormatted = priceDisplay(amount, decimals, currency);

  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useDispatch();

  const [info, setInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    
    if (!stripe) {
      return;
    }

    setIsLoading(true);

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {

      setIsLoading(false);

      switch (paymentIntent.status) {
        case "succeeded":
          setInfo({
            severity: 'success',
            message: "Payment succeeded!",
          });
          break;
        case "processing":
          setInfo({
            severity: 'info',
            message: "Your payment is processing",
          });
          break;
        case "requires_payment_method":
          setInfo({
            severity: 'info',
            message: "Please provide the payment data",
          });
          break;
        default:
          setInfo({
            severity: 'warning',
            message: "Something went wrong",
          });          
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: CLIENT_URL,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "validation_error") {
      setInfo({
        severity: 'warning',
        message: error.message,
      });
    }
    else if (error.type === "card_error") {
      setInfo({
        severity: 'error',
        message: `Payment error: ${error.message}`,
      });      
    }
    else {
      setInfo({
        severity: 'error',
        message: 'An unexpected error occurred',
      });
    }

    setIsLoading(false);
  };

  const handleCancel = () => {

    const { key: order_key } = order;

    dispatch(cancelOrder({
      order_key,
    }));
    dispatch(setDataState('order_new'));
    setOpenDialog(false);
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={openDialog}
        onClose={handleCancel}
        aria-labelledby="strype-payment"
      >
        <Paper>
          <Grid container>
            <Grid item xs={12}>
              <Alert
                icon={<PaymentIcon fontSize="inherit" />}
                severity="info"
                variant="outlined"
                sx={{ boxShadow: 10, borderRadius: 1, m: 1, p: 2, pb: 0 }}
              >
                <AlertTitle>
                  <strong>Complete your payment</strong>
                </AlertTitle>
                <Box sx={{ pb: 1 }}>
                  <strong>Description: </strong>{comments}
                </Box>
                <Box sx={{ pb: 1 }}>
                  <strong>Amount: </strong>{orderAmountFormatted}
                </Box>
                <Divider />

                { isLoading ? <LinearProgress /> : null }

                <Grid key='paid-analytics-total' item xs={12} sx={{ borderRadius: 1, m: 1, p: 1 }}>
                  <PaymentElement id="payment-element" />                  
                </Grid>
                
                { !isEmpty(info) && !isLoading ?
                  <>
                    <Alert
                      severity={info.severity}
                      // variant="outlined"
                      sx={{ boxShadow: 10, borderRadius: 1, m: 1, p: 1 }}
                    >
                      <Box>
                        {info.message}
                      </Box>
                      </Alert>
                    <Divider />
                  </>
                  : null
                }
                <DialogActions>
                  <Button
                    type="submit"
                    variant="outlined"
                    color="error"
                    onClick={handleCancel}
                    startIcon={<CloseIcon />}
                    sx={{ boxShadow: 10 }}
                  >
                    {"Cancel"}
                  </Button>
                  <Button
                    type="submit"
                    variant="outlined"
                    color="info"
                    onClick={handleSubmit}
                    startIcon={<PaymentIcon />}
                    sx={{ boxShadow: 10 }}
                    disabled={isLoading}
                  >
                    {`Pay ${orderAmountFormatted} now`}
                  </Button>
                </DialogActions>
              </Alert>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
    </>
  );
}
