export const fileListSchema = [
  {
    field: "user_id",
    headerName: "user_id",
    editable: false,
    sortable: true,
    hide: true,
  },
  {
    field: "user_key",
    headerName: "user_key",
    editable: false,
    sortable: false,
    hide: true,
  },
  {
    field: "user_email_address",
    headerName: "user_email*",
    editable: false,
    sortable: true,
    hide: false,
    flex: 90,
    onlyVisibleToAdmin: true,
  },
  {
    field: "id",
    headerName: "id",
    editable: false,
    sortable: false,
    hide: true,
  },
  {
    field: "key",
    headerName: "key",
    editable: false,
    sortable: false,
    hide: true,
  },
  {
    field: "name",
    headerName: "Name",
    editable: false,
    sortable: true,
    hide: false,
    mini: true,
    flex: 240,
  },
  {
    field: "comments",
    headerName: "Comments",
    editable: false,
    sortable: false,
    hide: false,
    flex: 180,
  },
  {
    field: "dt_created",
    headerName: "Created",
    editable: false,
    sortable: true,
    hide: false,
    mini: true,
    flex: 120,
  },
  {
    field: "dt_last_updated",
    headerName: "Updated",
    editable: false,
    sortable: false,
    hide: true,
  },
  {
    field: "description",
    headerName: "Description",
    editable: false,
    sortable: true,
    hide: true,
    flex: 240,
  },
  {
    field: "file_size",
    headerName: "File Size",
    editable: false,
    sortable: true,
    hide: false,
    flex: 80,
  },
  {
    field: "file_type",
    headerName: "File Type",
    editable: false,
    sortable: true,
    hide: false,
    flex: 80,
  },
  {
    field: "status",
    headerName: "Status",
    editable: false,
    sortable: true,
    hide: false,
    flex: 80,
  }
];
