import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from 'react-router-dom';

// https://github.com/dozoisch/react-google-recaptcha/issues/250 - looks like only affection when ran on localhost, using 3.0.0-alpha.1

const {
  NODE_ENV,
  REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY: GOOGLE_RECAPTCHA_SITE_KEY,
 } = process.env;

export default function ReCaptcha(props) {

  const { captcharef } = props;
  const navigate = useNavigate();

  return (
    <ReCAPTCHA
      ref={captcharef}
      {...props}
      sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
      size="invisible"
      onErrored={(err) => { 
        console.log('recaptcha error', err);
        navigate('/');
      }}
      onExpired={() => {
        console.log('recaptcha expired');
        navigate('/');
      }}
      //hide the recaptcha badge completely, but must show the ReCaptchaRequiredText
      // style={{ visibility: 'hidden' }}
    />
  );
};
