import * as React from 'react';
import {
  Link as RouterLink,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Typography, Breadcrumbs, Link } from '@mui/material';
import {
  Home as HomeIcon,
} from '@mui/icons-material';
import { capitalize } from '../../lib/Utils';

const LinkRouter = (props) => (
  <Link
    underline="hover"
    sx={{ display: 'flex', alignItems: 'center' }}
    color="inherit"  
    {...props}
    component={RouterLink}
  />
);

export default function RouterBreadcrumbs() {

  const location = useLocation();
  const params = useParams();
  
  //upload_key, run_key, etc. care only about the value
  const key = Object.values(params)?.[0];

  const [searchParams] = useSearchParams();
  const user_key = searchParams.get('user_key');

  const { pathname } = location;
  const pathnames = pathname.split('/').filter((x) => x);

  return (
    <div role="presentation">
      <Breadcrumbs 
        aria-label="breadcrumb"
        separator="›"
      >
        <LinkRouter
          to="/"
          key="/"
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Home
        </LinkRouter>
        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;

          value = value === key ? value : capitalize(value);
          
          return last ? (
            <Typography color="text.primary" key={to}>
              {value}
            </Typography>
          )
          // add `user_key` url query param to the resource_key link, if needed
          : value === key ? (
            <LinkRouter
              underline="hover"
              color="inherit"
              to={{
                pathname: to,
                search: user_key && `user_key=${user_key}`,
              }}
              key={to}
            >
              {value}
            </LinkRouter>
          ) : (
            <LinkRouter underline="hover" color="inherit" to={to} key={to}>
              {value}
            </LinkRouter>
          );
        })}
      </Breadcrumbs>
    </div>
  );
}
