export const uploadFormats = [
  {
    id: 'email_address',
    label: 'Email Address',
    children: [
      {
        id: 'email_address',
        description: "One column of raw email addresses, e.g., 'john.doe@bdex.com'",
        required: true,
      },
    ],
  },
  {
    id: 'email_md5',
    label: 'Email Address (MD5 Hash)',
    children: [
      {
        id: 'email_md5',
        description: "One column of hashed email addresses using the MD5 algorithm, e.g., md5_hash('john.doe@bdex.com') -> 'de891b94f351081dfe103ec56265a7ca' \nNote! Please be sure to use email in lowercase and remove all whitespace before the hashing",
        required: true,
      },
    ],
  },
  // [vk] disabled 04/04/2022 - waiting for the backend implementation
  // {
  //   id: 'email_sha256',
  //   label: 'Email Address (SHA-256 Hash)',
  //   children: [
  //     {
  //       id: 'email_sha256',
  //       description: "One column of hashed email addresses using the SHA-256 algorithm, e.g., sha256_hash('john.doe@bdex.com') -> 'b1efd352d30ca7660dda988a7605f8f51b79eb338d34fc25065aa9b7507d126c' \nNote! Please be sure to use email in lowercase and remove all whitespace before the hashing",
  //       required: true,
  //     },
  //   ],
  // },
  {
    id: 'maid',
    label: 'Mobile Advertising ID (MAID)',
    children: [
      {
        id: 'maid',
        description: "One column of Mobile Advertising ID (MAID). Could be either Apple's Identifier for Advertising (IDFA) or Android/Google Advertising ID (AAID), e.g., 'cdda802e-fb9c-47ad-9866-0794d394c912'",
        required: true,
      },
    ],
  },
  {
    id: 'pii',
    label: 'Personal Identifiable Information (PII)',
    children: [
      {
        id: 'first_name',
        description: "First Name, e.g., 'John'",
        required: true,
      },
      {
        id: 'last_name',
        description: "Last Name, e.g., 'Doe'",
        required: true,
      },
      // {
      //   id: 'full_address',
      //   description: 'Full Address (Street Number, Street Name, Apartment Number)',
      //   required: false,
      // },        
      {
        id: 'address1',
        description: "Address 1 (Street Number, Street Name, e.g., '100 SE 1ST AVE') or Full Street Address (Street Number, Street Name, Apartment Name, e.g., '100 SE 1ST AVE APT 999')",
        required: true,
      },
      {
        id: 'address2',
        description: "Address 2 (Apartment Number, e.g., 'APT 999'). Could be empty if 'Full Street Address' is provided in the previous column",
        required: false,
      },
      {
        id: 'city',
        description: "City, e.g., 'MIAMI'",
        required: true,
      },
      {
        id: 'state',
        description: "State, e.g., 'FL'",
        required: true,
      },
      {
        id: 'postal_code',
        description: "Postal Code (Zip), e.g., '33131'",
        required: true,
      },
      {
        id: 'country',
        description: "Country, defaults to 'US'",
        required: false,
      },
    ],
  },
];
