import React from 'react';
import { Typography, Link } from '@mui/material';

export default function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {`© ${[...new Set([2021, new Date().getFullYear()])].join('-')} `}
      <Link color="inherit" underline="always" href="https://www.bdex.com">BDEX</Link>
      {'. All rights reserved. '}
      <Link color="inherit" underline="always" href="https://www.bdex.com/privacy/">Privacy Policy</Link>
    </Typography>
  );
};
