import React from 'react';
import { Box } from '@mui/material';
import Copyright from './Copyright';

export default function Footer() {
  return (
    <Box component="footer" sx={{ p: 2, mt: 8 }}>
      <Copyright />
    </Box>
  );
}
