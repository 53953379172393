import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Alert, AlertTitle, Box, Grid, Button, Dialog, DialogActions, Typography, Divider, useTheme, useMediaQuery, List, ListItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  AddShoppingCart as AddShoppingCartIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { addToShoppingCart } from '../../../actions/shopping_cart';
import { uploadAnalyticsPaidSchema } from './config/uploadAnalyticsPaidSchema';
import { isEmpty, merge, priceDisplay } from '../../../lib/Utils';

const {
  REACT_APP_MIN_PII_ITEM_PAYMENT_AMOUNT: MIN_PII_ITEM_PAYMENT_AMOUNT,
  REACT_APP_MIN_NON_PII_ITEM_PAYMENT_AMOUNT: MIN_NON_PII_ITEM_PAYMENT_AMOUNT,
} = process.env;
  
export default function UploadAnalyticsPaidConfirmationDialog(props) {

  const { 
    openDialog,
    setOpenDialog,
    upload_props,
    columns,
    rows,
    clearCheckedRowIDs,
  } = props;

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() =>
    Object.fromEntries(
      Object.entries(merge({}, columns))
        .map(([key, value]) => (
            [
              key,
              value
                .filter(x => x.hide === true)
                .reduce((a, v) => ({ ...a, [v['field']]: false }), {})
            ]
          )
        )
    )
  );

  const {
    id: resource_id,
    key: resource_key,
    name: resource_name,
    raw_table: resource_raw_table,
    s3_key: resource_s3_key,
  } = upload_props;

  const dispatch = useDispatch();

  const columnsTotal = Object.values(columns)[0].filter(x => ['field', 'price', 'decimals', 'currency', 'display_price'].includes(x.field));

  const [columnTotalVisibilityModel, setColumnTotalVisibilityModel] = useState(() =>
    columnsTotal.filter(x => x.hide === true).reduce((a, v) => ({ ...a, [v['field']]: false }), {})
  );

  const priceRowSample = Object.values(rows)?.[0]?.[0] || {};
  const { decimals, currency } = priceRowSample;
  const priceTotal = Object.values(rows).flat().reduce((acc, curr) => acc + curr.price, 0);

  const itemsPiiMinAmountFormatted = priceDisplay(MIN_PII_ITEM_PAYMENT_AMOUNT, decimals, currency);
  const itemsNonPiiMinAmountFormatted = priceDisplay(MIN_NON_PII_ITEM_PAYMENT_AMOUNT, decimals, currency);

  const rowsTotal = [
    {
      id: 0,
      field: 'Total',
      key: 'total',
      price: priceTotal,
      decimals,
      currency,
      description: 'Total amount'
    },
  ];

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleConfirm = () => {
    handleClose();
    clearCheckedRowIDs();
    const items = [
      {
        resource_type: 'upload',
        resource_id,
        resource_key,
        resource_name,
        resource_raw_table,
        resource_s3_key,
        resource_items: rows,
      }
    ];

    dispatch(addToShoppingCart({ items }));
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={openDialog}
      onClose={handleClose}
      aria-labelledby="upload-paid-analytics-confirmation"
    >
      <Grid container id="upload-paid-analytics-confirmation">
        <Grid item xs={12}>
          <Alert
            icon={false}
            // icon={<AddShoppingCartIcon fontSize="inherit" />} 
            severity="info"
            variant="outlined"
            sx={{ boxShadow: 10, borderRadius: 1, m: 1, p: 2, pb: 0 }}
          >
            <AlertTitle>
              <strong>Add to Shopping Cart</strong>
            </AlertTitle>
            <Alert
              sx={{ boxShadow: 10, borderRadius: 1 }}
              severity="info"
              variant="outlined"
              icon={false}
            >
              <Typography variant="body2">
                <>The total price in the Shopping Cart will be adjusted to satisfy the following requirements:</>
              </Typography>
              <List dense>
                <ListItem>
                  <Typography variant="body2">
                    <>- the minimum price for all <strong>'PII & Postal Address'</strong> items in the Shopping Cart is <strong>{itemsPiiMinAmountFormatted}</strong> and</>
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body2">
                    <>- the minimum price for all other (<strong>non 'PII & Postal Address'</strong>) items in the Shopping Cart is <strong>{itemsNonPiiMinAmountFormatted}</strong></>
                  </Typography>
                </ListItem>
              </List>
            </Alert>
            <Box sx={{ m: 1, pt: 1 }}>
              Please confirm adding the following Paid Analytics Items to Shopping Cart:
            </Box>
            <Box sx={{ m: 1 }}>
              List Name: <strong>{resource_name}</strong>
            </Box>
            <>
              { Object.entries(uploadAnalyticsPaidSchema)
                .filter(([k,]) => !isEmpty(rows?.[k]))
                .map(([k, v], i) => (
                  <Grid key={`paid-analytics-${i}`} item xs={12} sx={{ borderRadius: 1, m: 1, p: 1 }}>
                    <Typography><strong>{v?.name}</strong></Typography>
                    <DataGrid
                      rows={rows[k]}
                      columns={columns[k]}
                      columnVisibilityModel={columnVisibilityModel[k]}
                      onColumnVisibilityModelChange={(x) => setColumnVisibilityModel(columnVisibilityModel[k] = x)}
                      hideFooter={true}
                      autoHeight={true}
                      density={'compact'}
                    />
                  </Grid>
                ))
              }
            </>
            <Divider />
            <Grid key='paid-analytics-total' item xs={12} sx={{ borderRadius: 1, m: 1, p: 1 }}>
              <DataGrid
                rows={rowsTotal}
                columns={columnsTotal}
                columnVisibilityModel={columnTotalVisibilityModel}
                onColumnVisibilityModelChange={(x) => setColumnTotalVisibilityModel(x)}
                hideFooter={true}
                autoHeight={true}
                density={'compact'}
                sx={
                  {
                    // hide header
                    "& .MuiDataGrid-columnHeaders": { display: "none" },
                    "& .MuiDataGrid-virtualScroller": { marginTop: "0!important" },
                    // bold it
                    '&.MuiDataGrid-root': {
                      fontWeight: 'bold',
                    },
                  }
                }
              />
            </Grid>
            <DialogActions>
              <Button
                type="submit"
                variant="outlined"
                color="error"
                onClick={handleClose}
                startIcon={<CloseIcon />}
                sx={{ boxShadow: 10 }}
              >
                {"Cancel"}
              </Button>
              <Button
                type="submit"
                variant="outlined"
                color="info"
                onClick={handleConfirm}
                startIcon={<AddShoppingCartIcon />}
                sx={{ boxShadow: 10 }}
              >
                {"Add to Shopping Cart"}
              </Button>
            </DialogActions>
          </Alert>
        </Grid>
      </Grid>
    </Dialog>
  );
}
