import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CssBaseline, Link, Grid, Typography, Container, } from '@mui/material';
import { Formik, Form, } from 'formik';
import { BdexInputField, } from '../Common';
import { validationSchema, useStyles } from './config';
import { resetPassword } from '../../actions/auth';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { ReCaptcha } from './ReCaptcha';

export default function ResetPassword(props) {

  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const captchaRef = useRef();
  const executeCaptchaAsync = async () => {
    return await captchaRef.current?.executeAsync();
  };
  
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const { error, warning, status } = useSelector(state => state.message);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [accountInactive, setAccountInactive] = useState(false);
  
  useEffect(() => {

    //back to login if 200
    if (status === 200) {
      navigate('/auth/login');
    }
    else {

      //display the link to resend the forgot password link
      if (error?.includes('invalid') || warning?.includes('invalid')) {
        setTokenExpired(true);
      }

      //display the link to resend the account activation link
      else if (error?.includes('has not been activated') || warning?.includes('has not been activated')) {
        setAccountInactive(true);
      }
    }
  }, [error, warning, status, navigate]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography sx={{ textAlign: 'center', typography: 'h5'}}>
          {"Enter new password for your account"}
        </Typography>
      </div>
      <div className={classes.form} noValidate>
      <Formik
        initialValues={{
          token,
          email_address: '',
          password: '',
          password_confirm: '',
        }}
        validationSchema={validationSchema([
          'email_address',
          'password',
          'password_confirm'
        ])}
        validateOnChange={true}
        validateOnBlur={false}
        onSubmit={ async (values, actions) => {

          //start submitting
          actions.setSubmitting(true);

          //execute (solve) captcha (front-end)
          const captcha_token = await executeCaptchaAsync();

          if (captcha_token) {

            // verify solved captcha (server-side) and login
            dispatch(resetPassword({...values, captcha_token }));
          }

          //stop submitting
          actions.setSubmitting(false);

          //reset captcha (front-end)
          captchaRef.current?.reset();

          //reset form
          actions.resetForm();
        }}>
          {
            ({ values, errors, isSubmitting }) => (
              <Form>
                <Grid container>
                  <Grid item xs={12}>
                    <BdexInputField 
                      type="email"
                      name="email_address"
                      label="Email Address"
                      autoFocus
                      autoComplete="email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <BdexInputField 
                      type="password"
                      name="password"
                      label="New Password"
                      autoComplete="new-password"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <BdexInputField 
                      type="password"
                      name="password_confirm"
                      label="Confirm New Password"
                      autoComplete="new-password"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={isSubmitting}
                    >
                      {"Change Password"}
                    </Button>
                  </Grid>
                  <Grid item container justifyContent="center">
                    <Link component={RouterLink} to="/auth/login" variant="body2" underline="hover">
                      {"Go back to Login"}
                    </Link>
                  </Grid>
                  { tokenExpired
                    ? <Grid item container justifyContent="center">
                        <Link
                          component={RouterLink}
                          to="/auth/forgot-password"
                          underline="hover"
                          sx={{
                            color: 'error.main',
                            border: 1,
                            fontWeight: 'medium',
                            borderRadius: 1,
                            typography: 'body2'
                          }}
                        >
                          {"Resend the password reset link"}
                        </Link>
                      </Grid>
                    : <div></div>
                  }
                  { accountInactive
                    ? <Grid item container justifyContent="center">
                        <Link
                          component={RouterLink} 
                          to="/auth/resend-activation"
                          underline="hover"
                          sx={{
                            color: 'error.main',
                            border: 1,
                            fontWeight: 'medium',
                            borderRadius: 1,
                            typography: 'body2'
                          }}
                        >
                          {"Resend the account activation link"}
                        </Link>
                      </Grid>
                    : <div></div>
                  }
                </Grid>
              </Form>
            )
          }
        </Formik>
      </div>
      <ReCaptcha captcharef={captchaRef}/>
    </Container>
  );
};
