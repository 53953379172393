import {
  DATA,
  SET_MESSAGE,
} from "./types";

import * as FileService from "../services/file";
import { parseResponseError } from "../lib/Utils";

export const createFile = (kwargs) => async (dispatch) => {
  try {
    const response = await FileService.createFile(kwargs);

    dispatch({
      type: SET_MESSAGE,
      payload: {
        info: 'New File has been successfully uploaded to Data Clean Room',
        status: '200',
      }
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const getFiles = () => async (dispatch) => {
  try {

    const response = await FileService.getFiles();
    const { file_list } = response?.data?.data || {};

    dispatch({
      type: DATA,
      payload: {
        file_list,
      },
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const getFile = (kwargs) => async (dispatch) => {
  try {
    const response = await FileService.getFile(kwargs);
    const { file_list } = response?.data?.data || {};
    const file = file_list?.[0];

    dispatch({
      type: DATA,
      payload: {
        file,
      },
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const deleteFile = (kwargs) => async (dispatch) => {
  try {

    const response = await FileService.deleteFile(kwargs);

    dispatch({
      type: SET_MESSAGE,
      payload: {
        info: 'Requested File has been permanently deleted from Data Clean Room',
        status: '200',
      }
    });

  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};
