import React from 'react';
import { Paper } from '@mui/material';
import { RunsList } from './RunsList';
import RunFilterMessage from './RunFilterMessage';

export function RunsGetAll(props) {
  return (
    <Paper id="runs-get-all" sx={{ boxShadow: 10, borderRadius: 1, p: 2 }}>
      <RunsList {...props} />
      <RunFilterMessage />
    </Paper>
  )
};
