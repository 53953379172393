import React from 'react';
import { Card, CardMedia, Link } from '@mui/material';

export default function BdexLogoColor() {
  return (
    <Link href="/" underline="none">
      <Card variant='text' sx={{ maxWidth: 200, m: 'auto' }}>
        <CardMedia
          alt="BDEX"
          id="logo"
          component="img"
          image="../../../logos/bdex_logo_color.png"
          sx={{
            width: "200",
            height: "120",
          }}
        />
      </Card>
    </Link>
  )
};
