import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardMedia, Grid, Link, Paper, Typography } from '@mui/material';
import { constructURLQueryString } from '../../lib/Utils';

export function Partners() {

  const { user } = useSelector(state => state.auth);
  const [partners, setPartners] = useState([]);
  const [partnersSoon, setPartnersSoon] = useState([]);

  const importAll = (x) => {
    return x.keys().map(x);
  };

  useEffect(() => {
    setPartners(importAll(require.context('./img/Partners/', false, /\.(png|jpg)$/)));
    setPartnersSoon(importAll(require.context('./img/Partners-Coming-Soon/', false, /\.(png|jpg)$/)));
  }, []);

  const queryString = constructURLQueryString(user, {
    how_did_you_hear_about_us_: 'I am interested in upgrading my Omni IQ Account to deliver my audience to one of the activation partners',
  });

  const url = `https://www.bdex.com/pricing?${queryString}`;

  return (
    <Paper id="acttivate-partners" sx={{ boxShadow: 10, borderRadius: 1, p: 2 }}>
      <Grid 
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} sx={{ my: 1 }}>
          <Typography><strong>Activation Partners</strong></Typography>
        </Grid>
        <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}>
          <Grid container>
            {partners.map((image, index) => (
              <Grid item xs={6} sm={4} md={3} key={`activate-partner-img-${index}`} sx={{ p: 5 }}>
                <Link href={url} underline="none" target="_blank">
                  <Card variant='text'>
                    <CardMedia
                      alt="Upgrade your Omni IQ Account"
                      id="logo"
                      component="img"
                      image={image}
                    />
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ my: 1 }}>
          <Typography><strong>Activation Coming Soon</strong></Typography>
        </Grid>
        <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, mt: 1 }}>
          <Grid container>        
            {partnersSoon.map((image, index) => (
              <Grid item xs={6} sm={4} md={3} key={`activate-partner-coming-soon-img-${index}`} sx={{ p: 5 }}>
                <Link href={url} underline="none" target="_blank">
                  <Card variant='text'>
                    <CardMedia
                      alt="Upgrade your Omni IQ Account"
                      id="logo"
                      component="img"
                      image={image}
                    />
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}
