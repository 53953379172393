import axios from 'axios';
import { logout, refreshToken } from '../actions/auth';
import { store } from '../redux/store';
import { merge } from './Utils';

const {
  REACT_APP_API_URL: API_URL,
  REACT_APP_HTTP_CALL_TIMEOUT: HTTP_CALL_TIMEOUT = 10000,
} = process.env;

const { dispatch } = store;

const axiosClient = axios.create({
  baseURL: API_URL,
  timeout: HTTP_CALL_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
  },
});

// keep track of all active requests
let requests = [];

axiosClient.interceptors.request.use(
  (config) => {

    // pull state on each request
    const { auth } = store.getState();
    const { user, context_user } = auth;

    //append user key to url query params (only if internal call and not /user/* endpoint)
    if (context_user) {
      if (!config._externalService && !config.url.startsWith('/users')) {
        config.params = merge(
          config.params,
          { user_key: context_user.key },
        )
      }
    }
    
    //if user_key is null/false/undefined - don't append it to url query params
    if (config.params && !config.params.user_key) delete config.params.user_key;

    // if internal service
    if (!config._externalService) {
      const accessToken = user?.access_token;
      const authorizationString = `Bearer ${accessToken}`;
      if (accessToken && config.headers['Authorization'] !== authorizationString) {
        config.headers['Authorization'] = authorizationString;
      }
    }
    
    // remove timeout for external services
    else {
      delete config.timeout;
    }

    config.headers = config.headers.toJSON();

    // check if current request is in the list of active requests
    // if it is - cancel the request
    // if it is not - add it to the list of active requests
    const existingRequest = requests.find(x => config?.url === x.url && Object.entries(config.params || {}).every(([k, v]) => x.params?.[k] === v));
    if (existingRequest) {

      //cancel any consequent requests
      return new Promise(() => {});
    }
    else {
      requests.push({
        url: config.url,
        params: config.params,
      });
    }

    return config;
  },
  (err) => {
    throw(err);
  }
);

axiosClient.interceptors.response.use(
  (res) => {

    const config = res.config;

    // remove the current request from the list of active requests
    requests = requests.filter(x => config?.url !== x.url && !Object.entries(config.params || {}).every(([k, v]) => x.params?.[k] === v));

    return res;
  },
  async (err) => {

    const config = err.config;
    const data = err.response?.data;
    const status = err.response?.status;
    const error = data?.error || data?.message;

    // remove the current request from the list of active requests
    requests = requests.filter(x => config?.url !== x.url && !Object.entries(config.params || {}).every(([k, v]) => x.params?.[k] === v));

    // - 400
    //     - Missing * in { data: { * } } payload object
    //     - Unknown *. Defined values: *
    //     - Wrong * in { data: { * } } payload object. Should be *
    //     - User does not have a requested Resource
    //     - Registration failed. Please use your business email address. This platform does not accept email addresses from *
    //     - Registration failed. A user with specified email already exists
    // - 401
    //     - Provided token is invalid
    //     - Provided token is invalid. Token has expired
    //     - CAPTCHA verification failed. Please try again later
    //     - Login failed. Provided credentials do not match our records
    //       - Incorrect email address '${email}
    //       - Incorrect password '*'
    //     - Password change failed. Provided credentials do not match our records
    //       - Incorrect email address, body: '${req.body.email_address}', user: '${user.email_address'
    // - 403
    //     - Request is not allowed by the defined CORS policy
    //     - User does not have an access to the requested Resource
    //     - Login failed. Account has not been activated yet. Please check your email for the activation instructions
    //     - Password change failed. Account has not been activated yet. Please check your email for the activation instructions
    //     - Please contact sales@bdex.com to enable this functionality

    // 403 - log out right away
    // or just display it if related to a disabled functionality 
    if (status === 403) {

      if (error?.includes("to enable this functionality")) {
        throw(err);
      }

      if (error?.includes("Account has not been activated yet")) {
        dispatch(logout({ warning: error }));
      }
      else {
        dispatch(logout());
      }

      //cancel any consequent requests
      return new Promise(() => {});
    }

    // 400 (Bad Request) ?
    else if (status === 400) {
    
      // missing a token ? log out right away
      if (error?.includes("Missing a token in 'Authorization: Bearer {token}' header")) {
        dispatch(logout({ warning: 'User session has expired. Please log in again' }));
      }

      // missing stored refresh-token on the token refresh ? log out right away
      else if (error?.includes("Missing expected 'refresh_token' in the request body")) {
        if (['/auth/refresh-token'].includes(config.url)) {
          dispatch(logout({ warning: 'User session is not valid anymore. Please log in again' }));
        }
      }

      // any other 400 error - throw it
      else {
        throw(err);
      }

      //cancel any consequent requests
      return new Promise(() => {});
    }

    // 401 ? 
    else if (status === 401) {

      // invalid token (including expired) ?
      if (error?.includes('token is invalid')) {

        // expired token ? 
        if (error?.includes('Token has expired')) {

          // log out if expired token for '/auth/*' routes 
          if (config.url.includes('/auth/')) {
            dispatch(logout({ warning: 'User session is not valid anymore. Please log in again' }));

            //cancel any consequent requests
            return new Promise(() => {});
          }

          // try to refresh it
          if (!config._retry) {
            try {
              config._retry = true;
              
              // in order to not display temp 401 errors and have only 1 failed request
              // we need to await for this event to finish before returning!
              await dispatch(refreshToken());
              
              // o_O reconstruct headers
              // https://stackoverflow.com/questions/74166648/setrequestheader-fails-to-execute-with-source-code-as-a-header-value-axios-an/74308583#74308583
              return axiosClient({
                ...config,
                headers: { ...config.headers },
              });
            }
            catch(_err) {
              // do nothing
            }
          }
        }

        // invalid token (and not expired) ? log out right away
        else {
          dispatch(logout({ warning: 'User session is not valid anymore. Please log in again' }));
          
          //cancel any consequent requests
          return new Promise(() => {});
        }
      }
    }

    //it is an error, throw it
    throw(err);
  }
);

export default axiosClient;
