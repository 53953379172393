import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Button, CssBaseline, Link, Grid, Typography, Container, } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Formik, Form, } from 'formik';
import { BdexInputField, BdexCheckbox, } from '../Common';
import { validationSchema, useStyles } from './config';
import { register } from '../../actions/auth';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ReCaptcha } from './ReCaptcha';

export default function Register() {

  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const captchaRef = useRef();
  const executeCaptchaAsync = async () => {
    return await captchaRef.current?.executeAsync();
  };

  const { status } = useSelector(state => state.message);
  useEffect(() => {
    
    //back to login if 200
    if (status === 200) {
      navigate('/auth/login');
    }
  }, [status, navigate]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AddOutlinedIcon />
        </Avatar>
        <Typography sx={{ textAlign: 'center', typography: 'h5'}}>
          {"Create a new account"}
        </Typography>
      </div>
      <div className={classes.form} noValidate>
        <Formik
          initialValues={{
            first_name: '',
            last_name: '',
            email_address: '',
            phone_number: '',
            company_name: '',
            password: '',
            password_confirm: '',
            how_did_you_hear_about_us: '',
            email_opt_in: true,
          }}
          validationSchema={validationSchema([
            'first_name',
            'last_name',
            'email_address',
            'phone_number',
            'company_name',
            'password',
            'password_confirm',
            'how_did_you_hear_about_us'
          ])}
          validateOnChange={true}
          validateOnBlur={false}
          onSubmit={ async (values, actions) => {

            //start submitting
            actions.setSubmitting(true);

            //execute (solve) captcha (front-end)
            const captcha_token = await executeCaptchaAsync();

            if (captcha_token) {

              // verify solved captcha (server-side) and login
              dispatch(register({...values, captcha_token }));
            }

            //stop submitting
            actions.setSubmitting(false);

            //reset captcha (front-end)
            captchaRef.current?.reset();

            //reset form
            actions.resetForm();
          }}>
            {
              ({ values, errors, isSubmitting }) => (
                <Form>
                  <Grid container>
                    <Grid item xs={12}>
                      <BdexInputField 
                        type="text"
                        name="first_name"
                        label="First Name"
                        autoFocus
                        autoComplete="given-name"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <BdexInputField 
                        type="text"
                        name="last_name"
                        label="Last Name"
                        autoComplete="family-name"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <BdexInputField 
                        type="email"
                        name="email_address"
                        label="Email Address"
                        autoComplete="email"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <BdexInputField 
                        type="tel"
                        name="phone_number"
                        label="Phone"
                        autoComplete="tel"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <BdexInputField 
                        type="text"
                        name="company_name"
                        label="Company"
                        autoComplete="organization"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <BdexInputField 
                        type="password"
                        name="password"
                        label="Password"
                        autoComplete="new-password"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <BdexInputField 
                        type="password"
                        name="password_confirm"
                        label="Confirm Password"
                        autoComplete="new-password"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <BdexInputField 
                        type="text"
                        name="how_did_you_hear_about_us"
                        label="How did you hear about us?"
                      />
                    </Grid>
                    <Grid item xs={12} container justify="flex-start">
                      <BdexCheckbox
                        type="checkbox"
                        name="email_opt_in"
                        label="I would like to receive updates via email"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={isSubmitting}
                      >
                        {"Register"}
                      </Button>
                    </Grid>
                    <Grid item xs={12} container justifyContent="space-evenly">
                      <Link component={RouterLink} to="/auth/login" variant="body2" underline="hover">
                        {" Already have an account? Login"}
                      </Link>
                    </Grid>
                  </Grid>
                </Form>
              )
            }
        </Formik>
      </div>
      <ReCaptcha captcharef={captchaRef}/>
    </Container>
  );
};
