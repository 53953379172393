import React from 'react';
import { 
  Alert,
  AlertTitle,
  List,
  ListItem,
  ListItemText,
  Box,
  Paper,
  Grid,
  Link,
  Dialog,
  DialogActions,
  Button,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  Close as CloseIcon,
  Check as CheckIcon,
} from '@mui/icons-material';

const BoxJustified = (props) => {
  
  const { sx, ...other } = props;
  
  return (
    <Box
      sx={{
        m: 1,
        textAlign: 'justify',
        ...sx,
      }}
      {...other}
    >
    </Box>
  )
}

export default function TermsAndConditions(props) {

  const { 
    openDialog,
    setOpenDialog,
    setChecked,
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setChecked(false);
    setOpenDialog(false);
  };

  const handleSubmit = () => {
    setChecked(true);
    setOpenDialog(false);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={openDialog}
      onClose={handleClose}
      aria-labelledby="terms-and-conditions"
    >    
      <Paper id="terms-and-conditions">
        <Grid container>
          <Grid item xs={12}>
            <Alert
              severity="info"
              variant="outlined"
              sx={{ boxShadow: 10, borderRadius: 1, m: 1, p: 2 }}
              icon={false}
            >
              <AlertTitle>
                <Box sx={{ textAlign: 'center' }}>
                  <strong>{`TERMS AND CONDITIONS`}</strong>
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                  <strong>{`BDEX, LLC`}</strong>
                </Box>
              </AlertTitle>
              <BoxJustified>
                {`Use of this BDEX LLC and its subsidiaries and affiliates ("BDEX") website (this "Website") is governed by the terms and conditions contained herein as well as the terms and conditions of the Privacy Policy posted on the Website (collectively, "Terms"). These Terms are legally binding on all users of this Website and constitute a legally binding agreement between you ("Client") and BDEX, LLC. By accessing this Website, Client expressly and irrevocably agrees to the Terms and to abide by the Terms. These Terms currently list the BDEX current policies and terms and conditions, but, as noted below, the Terms may be adjusted from time to time at BDEX discretion. If the Client of this Website does not agree with any of these Terms, Client may not, under any circumstances, utilize the BDEX Website.`}
              </BoxJustified>
              <BoxJustified>
                {`All service attachments all statements of work, addenda, schedules, exhibits and other attachments (the "Attachments"), appendant hereto or subsequently signed by the parties and that reference this Agreement (collectively, the Attachments and this Agreement are referred to herein as the "Agreement")`}
              </BoxJustified>
              <BoxJustified>
                <strong>{`NOW, THEREFORE,`}</strong>{` in consideration of the mutual covenants contained herein, the Parties agree as follows:`}
              </BoxJustified>
              <Box>
                <List dense>
                  <ListItem>
                    <ListItemText
                      primary={
                        <>
                          <Box>
                            <strong>{`1. Definitions`}</strong>
                          </Box>
                          <BoxJustified>
                            <strong>{`a. "BDEX Platform"`}</strong>
                            {` The set of technology and Services that enable the selling and buying of data in real-time as well as all supporting analytics and reporting in support of data selling and buying.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`b. "Client"`}</strong>
                            {` Defined as Data Buyer as defined below.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`c. "Data Buyer"`}</strong>
                            {` Any company who through setup of a campaign on the BDEX Platform and/or placement of BDEX Code on their Website or systems purchases data for usage outside of the BDEX Platform.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`d. "BDEX Code"`}</strong>
                            {` means any code or proprietary software code provided by BDEX to Clients under this Agreement.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`e. "Cookie"`}</strong>
                            {` means a text file that is set on a computer when the computer communicates with a server and is recovered by that server.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`f. "Services"`}</strong>
                            {` shall mean BDEX onboarding, enriching, warehousing or distributing Client Data and collecting revenues generated from the above Services via the BDEX Platform as further set forth in this Agreement.`}
                          </BoxJustified>
                        </>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <>
                          <Box>
                            <strong>{`2. License to BDEX Platform`}</strong>
                          </Box>
                          <BoxJustified>
                            {`BDEX has developed and operates a marketplace in which its Clients may buy Data to target advertisements, commerce, website personalization, 1st party data enrichment or other online and offline uses. Clients utilize BDEX technology to exchange data in real-time or near real-time.  Subject to the terms of this Agreement, BDEX hereby grants to Client a limited, nonexclusive, nontransferable, non-sublicensable license to install and use the BDEX Code (if applicable) and BDEX Platform during the term of this Agreement solely to allow (a) Client to be a Data Buyer; (b) Client to connect with, and transmit data to or from the BDEX Platform, and (c) Client to view transactions related to the exchange of data and associated payments.`}
                          </BoxJustified>
                        </>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <>
                          <Box>
                            <strong>{`3. Intellectual Property Rights`}</strong>
                          </Box>
                          <BoxJustified>
                            <strong>{`a. BDEX.`}</strong>
                            {` BDEX shall own and retain all intellectual property rights in (i) the BDEX Platform and Services created or otherwise developed by BDEX in connection with the Services alone or jointly with Client; (ii) all pre-existing materials owned and/or incorporated by BDEX into the Platform or Services; (iii) all materials obtained from third parties other than Client or developed by BDEX in connection with the Platform or Services within or outside the scope of this Agreement; (iv) all back-end components and software code (both in object code and source code form), including but not limited to, html files, java files, graphics files, data files, scripts, tools and programs that are proprietary to BDEX and/or incorporated by BDEX into the Platform or Services; and (v) any enhancements, modifications, or derivatives of any of the foregoing (collectively, the "BDEX Materials").  BDEX hereby grants to Client a limited, revocable non-transferable, or sub-licensable worldwide, non-exclusive right and license to use the BDEX Materials during the Term solely in connection with and as part of Client's use and internal maintenance of the Platform Services.  To the extent that Client provides any feedback or develops any concepts, tools, methods, or improvements or other portion of the Platform Services ("Client Supplied Deliverables"), Client hereby automatically assigns all right, title, and interest in and to such Client Supplied Deliverables to BDEX and shall execute any assignments or evidence of the same.  If Client does not do so, Client hereby irrevocably appoints BDEX as its attorney in fact to execute the same.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`b. No Exploitation.`}</strong>
                            {` Client may not (i) use, copy, modify or distribute the BDEX Materials except as expressly provided in this Agreement; (ii) reverse assemble, reverse compile, otherwise translate or create derivative works of the BDEX Materials except as specifically permitted by law without the possibility of contractual waiver; (iii) sublicense, rent, lease or otherwise transfer the BDEX Materials or (iv) use the BDEX Materials separate or apart from the Platform Services.  Any modifications to the BDEX Materials without BDEX's authorization will void the warranties hereunder.  Client will use commercially reasonable efforts to protect BDEX's proprietary intellectual property rights, notify BDEX of any use of BDEX Materials not authorized under this Agreement, and cooperate with BDEX, at BDEX's expense, in any legal action to prevent or stop unauthorized use, reproduction, or distribution of BDEX Materials.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`c. Third Party Materials.`}</strong>
                            {` To the extent that any materials including without limitation, any application programming interface (each, an "API") which shall be licensed by BDEX to power the Services are licensed from third parties or any other third party rights are included in the Platform or Services ("Third Party Materials"), Client shall not own the Third Party Materials and BDEX shall obtain a license to use such Third Party Materials in the Platform Services.  Client agrees to comply with all license restrictions, limitations, or obligations in connection with such Third-Party Materials.  Without limiting the foregoing, Client acknowledges that BDEX may license each API for use in connection with the Services from a third-party provider.  Accordingly, Client agrees that BDEX shall provide such API in accordance with the terms, conditions and obligations, and any limitations of liability or warranty set forth in the license agreement between BDEX and the API provider ("API License").`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`d. Reservation of Rights.`}</strong>
                            {` BDEX shall be free at all times to use and employ its general skills, know-how, methodologies, algorithms, techniques and expertise relating to the Services, and the other activities undertaken by it in the course of this Agreement.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`e. Information Rights.`}</strong>
                            {` Client agrees to allow BDEX to use certain information about the Client for promotional purposes. This Information may include the Client name, logo and other publicly available information to be referenced on the BDEX website or other promotional materials. Client may request removal of such information at any time with 30 days written notice at which time BDEX must comply to such removal request.`}
                          </BoxJustified>
                        </>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <>
                          <Box>
                            <strong>{`4. Privacy Standards`}</strong>
                          </Box>
                          <BoxJustified>
                            <strong>{`a. Match Data.`}</strong>
                            {` BDEX recognizes that some Data Buyers will use commercially available methods to match data sets associated with cookies, mobile device IDs or hashed emails to individual consumers ("Match Data") and BDEX may provide access to Match Data maintained by another party to Data Buyer. In such instances, BDEX does not own or guarantee the validity of such Match Data and serves only as an intermediary facilitating the transmission of such Match Data between both parties. In the case where Match Data is provided to BDEX for the purpose of enhancing data match rates, BDEX will cleanse the Match Data such that it is not added to the BDEX Platform.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`b. Adherence to Law and Privacy Standards.`}</strong>
                            {` Client represents, warrants and covenants that its execution of and performance under the Agreement including but not limited to use and disclosure of Client Data is and will be in accordance with applicable laws, rules and regulations, including, without limitation, laws governing marketing by telephone, direct mail, e-mail, wireless text messaging, fax and any other mode of communication (collectively, "Privacy Laws"). During the term of this Agreement, Client further represents, warrants and covenants that: (a) Client is and will continue to be in compliance with the Digital Advertising Alliance ("DAA") Code located at `}
                            <Link color="inherit" underline="always" href="http://www.aboutads.info/principles" target="_blank">http://www.aboutads.info/principles</Link>
                            {` (or a successor website thereto); (b) Client will not use, and will use reasonable efforts to ensure that its agents, customers, clients and Affiliates do not use, Client Data for the purposes of making decisions about a consumers's eligibility for credit or insurance; (c) any Client Data does not and will not fall under any of the sensitive data definitions contained in the DAA Code without first obtaining the prior affirmative consent of the applicable consumer: and (d) shall not upload any data to the BDEX exchange to the extent prohibited by the California Consumer Privacy Act. Client shall, at all times, perform its obligations hereunder in such a manner as not to cause BDEX to be in violation of any Privacy Laws.`}
                          </BoxJustified>
                        </>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <>
                          <Box>
                            <strong>{`5. Termination`}</strong>
                          </Box>
                          <BoxJustified>
                            <strong>{`a. Termination for Material Breach or Default.`}</strong>
                            {` If either party commits a material breach or default in the performance of any of its obligations under this Agreement, then the other party may give the defaulting party written notice of the material breach or default (including the provisions of this Agreement that are in material breach or default, facts relating to the material breach or default, and the action required to cure the material breach or default) and the non-defaulting party's intention to terminate the Agreement. If the material breach or default is not cured within 20 days after the defaulting party's receipt of such notice (or such later date as the notice may specify) then the non-defaulting party may terminate this Agreement immediately.`}
                          </BoxJustified>
                        </>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <>
                          <Box>
                            <strong>{`6. BDEX Responsibilities`}</strong>
                          </Box>
                          <BoxJustified>
                            <strong>{`a. Operation of BDEX Platform.`}</strong>
                            {` Subject to the terms of this Agreement, BDEX will manage and operate the BDEX Platform. BDEX will be solely responsible for determining the manner in which such Services are managed and operated. BDEX will cooperate with Client to facilitate Client's provision of Client Data to the BDEX Platform. BDEX will give Client instructions and access credentials, if applicable, to allow Client to deliver the Client Data to BDEX via a file-sharing process.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`b. Data Buyer.`}</strong>
                            {` All payments due under this Agreement are due immediately.`}
                          </BoxJustified>
                        </>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <>
                          <Box>
                            <strong>{`7. Representations and Warranties`}</strong>
                          </Box>
                          <BoxJustified>
                            <strong>{`a. Both Parties.`}</strong>
                            {` Each party represents and warrants that it has the authority to enter into this Agreement and to fully perform its obligations hereunder and during the term of this Agreement it will comply with all applicable laws, rules, and regulations, including those governing privacy.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`b. BDEX.`}</strong>
                            {` BDEX represents and warrants that (a) it is now and will continue throughout the term of this Agreement to be in full compliance with all laws applicable to its business and the rules and regulations including (i) those related to the handling of data, the DAA and IAB including the provisions in the DAA Self-Regulatory Principles restricting the merger of personally identifiable information with online behavioral data and (ii) of any governmental or quasi-governmental regulatory body or agency that has jurisdiction over its business activities or products in any country in which it operates (each, a "Governmental Authority"); (b) it is not presently the subject of any investigation or prosecution by any Governmental Authority in connection with its products, Services, or advertising, and, to the best of its knowledge, no such investigation or prosecution is threatened; (c) the Client Data shall not be used in any manner by BDEX that violates any applicable law, rule or regulation or individual right; (d) the Services provided hereunder by BDEX shall not violate any third party intellectual property, privacy or publicity right; (e) the Services provided hereunder by BDEX will be performed in a professional and workmanlike manner; (f) it has implemented and will maintain an information security program that contains administrative, technical and physical safeguards that are appropriate to its size and complexity, the nature and scope of its activities and the sensitivity of any consumer information at issue and (g) its privacy policy is accessible on its website homepage and that such policy reflects the company's current data use and privacy practices, complies with applicable laws and regulations and instructs users on opt out processes.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`c. Client.`}</strong>
                            {` Client represents and warrants to BDEX that: (i) it owns, or has acquired the express written authority to use (and to grant BDEX the right to use), all of the Client Content for any and all uses in connection with the Services; (ii) the Client Content does not contain any obscene, threatening, abusive, harassing, vulgar, defamatory, libelous, infringing or unlawful content; (iii) there are no claims, or to the best of Client's knowledge, pending or threatened claims, against Client regarding the Client Content or which could impact Client's ability to meet its obligations under this Agreement; (iv) the Client Content shall not infringe, violate or misappropriate the intellectual property, proprietary or any other rights of any third party.`}
                          </BoxJustified>
                        </>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <>
                          <Box>
                            <strong>{`8. Confidential Information`}</strong>
                            {` The parties acknowledge that, during the term of this Agreement, they may disclose certain Confidential Information of a special and unique nature to each other.`}
                          </Box>
                          <BoxJustified>
                            <strong>{`a. Definition.`}</strong>
                            {` As used in this Agreement, "Confidential Information" means and relates to matters such as the parties' (or the parties' clients') trade secrets, files, policies, computer and other databases, data, Client Data and libraries, techniques, plans, strategies, contracts, systems, records, accountings, procedures, forms, manuals, reports, processes, products, publications, services, employees, customer lists, personally identifiable information of either party or either parties' customers, identities, information relating to any aspect of BDEX or Client's operations or any other information (whether in written, verbal, electronic, or other format) that is either (1) marked or identified as being confidential, or (2) that a reasonable person would understand to be confidential from the nature of the information or the circumstances of the disclosure, as well as the nature and extent of the collaboration.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`b. Restrictions on Use or Disclosure.`}</strong>
                            {` Both parties acknowledge that either may receive Confidential Information from the other during the Term of this Agreement. Each party covenants and agrees that neither it nor its employees shall at any time during or following the Term of this Agreement, either directly or indirectly, (1) disclose to any person, organization, or entity not necessary to the performance of its obligations under this agreement, in any manner whatsoever any Confidential Information, or (2) use any Confidential Information for any purpose whatsoever, except as strictly necessary to perform under this Agreement. Each party shall restrict disclosure of the Confidential Information with a need to know such information in order to perform under this Agreement and shall advise any person, organization or entity that receives Confidential Information of their obligations with respect to the Confidential Information.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`c. Duties; Remedies.`}</strong>
                            {` Each party shall protect the other party's Confidential Information using the same standard of care it uses to protect its own confidential and proprietary information, but in any event not less than a reasonable standard of care. Each party (the "Receiving Party") shall immediately notify the other party (the "Disclosing Party") in writing in the event of any unauthorized use or disclosure of the Confidential Information and assist in remedying such unauthorized use or disclosure, as requested by the Disclosing Party (which shall not limit other remedies of the Disclosing Party as provided herein or by applicable law). In the event of a breach or threatened breach of this Confidentiality Section, the Disclosing Party, in addition to and not in limitation of any of the rights, remedies or damages available to it at law or in equity, shall be entitled to a temporary or permanent injunction to prevent or restrain any such breach by the Receiving Party.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`d. Ownership.`}</strong>
                            {` All Confidential Information shall be and remain the property of the Disclosing Party notwithstanding the subsequent termination of this Agreement.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`e. Exceptions.`}</strong>
                            {` Notwithstanding the foregoing, Confidential Information does not include information that: (1) is or becomes public domain information or material through no fault or breach on the part of the Receiving Party; (2) as demonstrated by the written records of the Receiving Party, was already lawfully known (without restriction on disclosure) to the Receiving Party prior to the information being disclosed to the Receiving Party by the Disclosing Party or any representative of the Disclosing Party; (3) has been or is hereafter rightfully furnished to the Receiving Party without restriction on disclosure by a third person lawfully in possession thereof; (4) has been independently developed, by or for the Receiving Party, without reference to the Confidential Information of the Disclosing Party and without any breach or violation of any obligation of this Agreement; (5) is required to be disclosed by court order, provided that the Receiving Party notifies the Disclosing Party so that the Disclosing Party may have a reasonable opportunity to obtain a protective order or other form of protection against disclosure.`}
                          </BoxJustified>
                        </>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <>
                          <Box>
                            <strong>{`9. Indemnification`}</strong>
                          </Box>
                          <BoxJustified>
                            <strong>{`a. Indemnification by BDEX.`}</strong>
                            {` BDEX will indemnify, defend, and hold harmless Client its affiliated companies and its respective managers, shareholders, officers, employees, agents and contractors harmless from and against third-party costs, losses, damages, expenses or claim arising from or in connection with (a) any intentional tortious act of BDEX; and (b) any breach by BDEX of any of its warranties or representations contained in this Agreement; and (c) violation of applicable law, rule or regulation including a data privacy law.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`b. Indemnification by Client.`}</strong>
                            {` Client will indemnify, defend, and hold harmless BDEX its affiliated companies and its respective managers, shareholders, officers, employees, agents and contractors harmless from and against third-party costs, losses, damages, expenses or claim arising from or in connection with (a) any intentional tortious act of Client; and (b) any breach by Client of any of its warranties or representations contained in this Agreement; and (c) violation of applicable law, rule or regulation including a data privacy law.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`c. Indemnification Exception.`}</strong>
                            {` BDEX'S INDEMNIFICATION OBLIGATION DOES NOT APPLY IF THE ALLEGED VIOLATION, INFRINGEMENT OR MISAPPROPRIATION RESULTS FROM CLIENT'S (I) MODIFICATION OR ENHANCEMENT OF THE SERVICES; (II) USE OF THE SERVICES IN COMBINATION WITH OTHER PRODUCTS NOT PROVIDED OR APPROVED BY BDEX; OR (III) FOR INFRINGEMENT CLAIMS TO THE EXTENT SUCH CLAIM IS DIRECTLY ATTRIBUTABLE TO CLIENT'S CONTENT OR SPECIFIC USE OF BDEX'S PLATFORM.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`d. Indemnification Procedures.`}</strong>
                            {` A party's (the "Indemnifying Party") obligations to indemnify the other party (the "Indemnified Party") with respect to any claim, action or proceeding shall be conditioned upon the Indemnified Party: (i) providing the Indemnifying Party with prompt written notice of such claim, action or proceeding, (ii) permitting the Indemnifying Party to assume end solely control the defense of such claim, action or proceeding and all related settlement negotiations, with counsel chosen by the Indemnifying Party, and (iii) cooperating at the Indemnifying Party's request and expense with the defense or settlement of such claim, action or proceeding which cooperation shall include providing reasonable assistance and information. No Indemnified Party shall enter into any settlement agreement for which it will seek indemnification under this Agreement from the Indemnifying Party without the prior written consent of the Indemnifying Party. Nothing herein shall restrict the right of a party to participate in a claim, action or proceeding through its own counsel and at its own expense.`}
                          </BoxJustified>
                        </>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <>
                          <Box>
                            <strong>{`10. Limitation of Liability/Disclaimer`}</strong>
                          </Box>
                          <BoxJustified>
                            <strong>{`a.`}</strong>
                            {` THE TOTAL AGGREGATE LIABILITY OF BDEX FOR ANY AND ALL DAMAGES WHATSOEVER UNDER, IN CONNECTION OR OTHERWISE PERTAINING IN ANY WAY TO THIS AGREEMENT, REGARDLESS OF THE NATURE OR THEORY OF THE CLAIM DURING THE TERM, SHALL NOT, IN ANY EVENT, EXCEED THE LESSER OF (I) THE AGGREGATE AMOUNT OF FEES PAID BY CLIENT TO BDEX OVER THE PREVIOUS TWELVE (12) MONTHS; OR (II) ONE HUNDRED THOUSAND DOLLARS.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`b.`}</strong>
                            {` IN NO EVENT SHALL BDEX BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PROFITS, LOST BUSINESS OR LOST SAVINGS, ANTICIPATED OR OTHERWISE (EVEN IF SUCH DAMAGES ARE FORESEEABLE AND WHETHER OR NOT SUCH PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE).`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`c.`}</strong>
                            {` BDEX MAKES NO REPRESENTATIONS AND DISCLAIMS ALL WARRANTIES REGARDING BDEX SERVICES, INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE AND IMPLIED WARRANTIES ARISING FROM A COURSE OF DEALING OR COURSE OF PERFORMANCE.  BDEX SHALL NOT BE LIABLE FOR LOSS DUE TO ANY ACT OR OMISSION OF ANY THIRD PARTY WITH WHICH BDEX CONDUCTS BUSINESS TO ACCOMPLISH THE PURPOSES HEREIN CONTEMPLATED.  BY USING THE BDEX PLATFORM, CLIENT ACKNOWLEDGES THAT THE PLATFORM IS DELIVERED ON AN "AS IS" "AS AVAILABLE BASIS" AND THAT ITS SERVICE WILL BE UNINTERRUPTED, SECURE OR ERROR FREE.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`d.`}</strong>
                            {` CLIENT ACKNOWLEDGES THAT BDEX HAS NO CONTROL OVER CLIENT DATA S ONCE THEY HAVE BEEN PURCHASED, RELEASED OR POSTED IN THE PUBLIC DOMAIN AS REQUESTED OR APPROVED BY CLIENT, INCLUDING, WITHOUT LIMITATION, VIA SOCIAL NETWORKING, VIDEO SHARING WEBSITES, OR OTHER ADVERTISING PLATFORMS OR ANY USER-GENERATED OR PRIVACY DISCLOSURES ON THIRD PARTY SITES OR THIRD PARTY DISCLOSURES GENERALLY.  AS SUCH, BDEX SHALL NOT BE RESPONSIBLE FOR ENSURING THE CONTENT OR ACCURACY OF WHAT ANY THIRD-PARTY PUBLISHES OR FOR ANY OTHER THIRD-PARTY ACTIONS.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`e.`}</strong>
                            {` THIS SECTION SHALL SURVIVE ANY EXPIRATION OR TERMINATION OF THIS AGREEMENT.`}
                          </BoxJustified>
                        </>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <>
                          <Box>
                            <strong>{`11. General`}</strong>
                          </Box>
                          <BoxJustified>
                            <strong>{`a. Publicity.`}</strong>
                            {` Neither party will make a public announcement regarding the subject matter or existence of this Agreement, or any related discussions between the parties, without the prior written consent of the other party with such consent to be withheld in that party's sole discretion, provided, however, that BDEX may identify Client as a customer of BDEX without prior approval. Neither Party shall use the trademarks or service marks of the other Party in any advertising, promotional or marketing materials without such other Party's prior written consent.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`b. Notices.`}</strong>
                            {` Any notice required or permitted to be given in accordance with this Agreement will be effective upon receipt and shall be if it is in writing and sent by email, certified or registered mail, or insured courier, return receipt requested, to the appropriate party at the addresses set forth above with the appropriate postage affixed. A party may change its address for receipt of notice by notice to the other party in accordance with this Section.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`c. Governing Law.`}</strong>
                            {` This Agreement shall be interpreted and governed by the laws of the State of Florida, without reference to conflict of laws principles and the federal and state courts of Florida shall have exclusive jurisdiction over any claim, suit or proceeding.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`d. Venue.`}</strong>
                            {` In the event that any action is brought relative to the Terms and Conditions contained herein, the venue shall be in Broward County, Florida.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`e. Assignment.`}</strong>
                            {` This Agreement may not be assigned by the Client without the prior written consent of BDEX.  BDEX shall be entitled to assign this Agreement to a successor in interest, successor by merger or other purchaser of all of the assets of BDEX, without any consent by Client.  BDEX shall be entitled to subcontract work to be performed under this Agreement, but in all events, BDEX shall retain responsibility for all such work and Services.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`f. Force Majeure.`}</strong>
                            {` The obligations of any Party to perform under this Agreement may be temporarily suspended during any period during which either Party is unable to carry out its obligations under this Agreement by reason of an act of God or the public enemy, fire, flood, hurricane, tornado, storm, lightning, embargo, war, riot, civil commotions, strike, lock-out or labor difficulty, closing of the public highways or other failure in transportation systems, governmental or administrative interference or action or law, rule, regulation, directive or order, any failure, delay or interruption of any electrical, telecommunication or other services, or any other event or occurrence whether similar or dissimilar to those listed beyond the reasonable control of the affected, and neither Party shall have any liability to the other Party for delay in performance resulting therefrom (except that no such event shall relieve a party of any obligation to pay any sum due under this Agreement).  Notwithstanding the foregoing, the affected Party shall, at such Party's sole expense, use commercially-reasonable efforts to eliminate or mitigate the `}
                            <em>{`force majeure`}</em>
                            {` event and the effects thereof and develop and implement alternative arrangements (subject to the approval of the other Party which shall not be unreasonably withheld) to continue performance hereunder.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`g. Integration and Amendment.`}</strong>
                            {` This Agreement, along with any Attachments, constitutes the entire agreement between the Parties with respect to the subject matter hereof and supersedes all prior and contemporaneous agreements, representations and understandings, verbal and/or written, with respect to the subject matter hereof.  This Agreement and its Attachments may be amended or revoked only by an instrument in writing signed by both Parties.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`h. Headings.`}</strong>
                            {` The headings in this Agreement are for convenience only and shall not be used to interpret, construe, perform or enforce this Agreement.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`i. Severability.`}</strong>
                            {` If any term or provision of this Agreement shall be held invalid or unenforceable to any extent under any applicable law by a court of competent jurisdiction, the remainder of this Agreement shall not be affected thereby, and each remaining term and provision of this Agreement shall be valid and enforceable to the fullest extent permitted by law.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`j. Waiver.`}</strong>
                            {` No waiver by either Party of the breach of any covenant, condition or term of this Agreement shall be construed as a waiver of any preceding or succeeding breach nor shall the acceptance of any fee or other payment during any period in which either Party is in default be deemed to be a waiver of such default.  All waivers must be in writing and signed by the waiving Party.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`k. Attorneys' Fees.`}</strong>
                            {` If legal action is brought to enforce or interpret any provision of this Agreement or the rights or obligations of either Party as they relate to the subject matter of this Agreement, the prevailing Party shall be entitled to recover, as an element of such Party's legal fees and costs, and not as damages, all reasonable costs and expenses incurred or sustained by such prevailing Party in connection with such legal action, including, without limitation, attorneys' fees and expenses and court costs and any incurred in establishing such entitlement or the amount thereof.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`l. Non-Solicitation.`}</strong>
                            {` Without BDEX's express prior written consent, Client shall not, and shall not permit any of its affiliates to, directly or indirectly, at any time during the Term of this Agreement and for a period of three (3) years after its termination or expiration, solicit, hire or engage or attempt to hire or engage any employees, agents, independent contractors or other persons or entities employed or engaged or previously employed or engaged by BDEX or any of their affiliates.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`m. Authority.`}</strong>
                            {` By executing this Agreement, each signatory represents that he or she has the authority to enter into this Agreement and to fully and completely bind the Party whom or which he or she represents.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`n. Independent Contractor.`}</strong>
                            {` BDEX and Client are independent contractors and under no circumstances shall anything in this Agreement be construed to give Client authority to incur any liability or obligation to which BDEX shall be bound.  Nothing in this Agreement shall be construed to give any third party the right to any claim, benefit, right or remedy against BDEX for any reason whatsoever.  Further, BDEX and Client shall not be deemed Clients, co-ventures' or independent contractors in any way, shape or form and shall only be deemed parties bound by the contractual obligations set forth herein and in any Statement of Work, addenda or exhibit attached hereto.  In no event shall BDEX be deemed responsible or liable for any of the liabilities, illegal acts, infringements of any state, federal or local law, or any rule, regulation, guideline or any other promulgation whatsoever and the Client shall remain solely responsible for any and all such violations or infractions.`}
                          </BoxJustified>
                          <BoxJustified>
                            <strong>{`o. Counterparts.`}</strong>
                            {` This Agreement may be executed in multiple counterparts, any one of which will be deemed an original, but all of which shall constitute one and the same instrument.`}
                          </BoxJustified>
                        </>
                      }
                    />
                  </ListItem>
                </List>
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                {`If you have any questions regarding these Terms or this Website, please contact BDEX, LLC at `}              
                <Link color="inherit" underline="always" href="mailto:info@bdex.com">info@bdex.com</Link>
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                {`Copyright © 2022 BDEX, LLC. All Rights Reserved.`}
              </Box>              
              <DialogActions
                sx={{ 
                  mt: 2,
                  alignItems:"center",
                  justifyContent: 'space-around'
                }}>
                  <Button
                    type="submit"
                    variant="outlined"
                    color="error"
                    onClick={handleClose}
                    startIcon={<CloseIcon />}
                    sx={{ boxShadow: 10 }}
                  >
                    {`Decline`}
                  </Button>
                  <Button
                    type="submit"
                    variant="outlined"
                    color="info"
                    onClick={handleSubmit}
                    startIcon={<CheckIcon />}
                    sx={{ boxShadow: 10 }}
                  >
                    {`Accept`}
                  </Button>
              </DialogActions>
            </Alert>
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  )
}
