import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {  Button, ListItemIcon, ListItemText, Menu, MenuItem, Fade, Divider } from '@mui/material';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Info as InfoIcon,
  History as HistoryIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';

export default function FileActions(props) {

  const { file_key, user_key } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = () => {
    setAnchorEl(null);
    setOpenDeleteDialog(true);
  };

  return (
    <>
      <DeleteConfirmationDialog
        openDialog={openDeleteDialog}
        setOpenDialog={setOpenDeleteDialog}
        file_key={file_key}
        user_key={user_key}
      />
      <Button
        id="file-action-button"
        aria-controls={open ? 'file-action-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="outlined"
        disableElevation
        endIcon={<KeyboardArrowDownIcon />}
      >
        Actions
      </Button>
      <Menu
        id="file-action-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'file-action-button',
        }}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={handleClose}
          component={Link}
          to={{
            pathname: `/files/${file_key}`,
            search: user_key && `user_key=${user_key}`,
          }}
        >
          <ListItemIcon>
            <InfoIcon color={"primary"}/>
          </ListItemIcon>
          <ListItemText>
            Details
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={handleClose} 
          component={Link}
          to={{
            pathname: `/files/${file_key}/history`,
            search: user_key && `user_key=${user_key}`,
          }}
        >
          <ListItemIcon>
            <HistoryIcon color={"primary"} />
          </ListItemIcon>
          <ListItemText>
            History
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={handleDelete}
        >
          <ListItemIcon>
            <DeleteIcon color={"error"} />
          </ListItemIcon>
          <ListItemText>
            Delete
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
