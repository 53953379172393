import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CssBaseline, Link, Grid, Typography, Container } from '@mui/material';
import { Formik, Form } from 'formik';
import { BdexInputField } from '../Common';
import { validationSchema, useStyles } from './config';
import { resendActivation } from '../../actions/auth';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ReCaptcha } from './ReCaptcha';

export default function ResendActivation(props) {

  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const captchaRef = useRef();
  const executeCaptchaAsync = async () => {
    return await captchaRef.current?.executeAsync();
  };

  const { status } = useSelector(state => state.message);

  useEffect(() => {
    
    //back to login if 200
    if (status === 200) {
      navigate('/auth/login');
    }
  }, [status, navigate]);
  
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <div className={classes.paper}>
        <Typography justifyContent="center" typography="h5">
          {"Enter your email address to resend the account activation link"}
        </Typography>
      </div>
      <div className={classes.form} noValidate>
        <Formik
          initialValues={{
            email_address: '',
          }}
          validationSchema={validationSchema([
            'email_address',
          ])}
          validateOnChange={true}
          validateOnBlur={false}
          onSubmit={ async (values, actions) => {

            //start submitting
            actions.setSubmitting(true);

            //execute (solve) captcha (front-end)
            const captcha_token = await executeCaptchaAsync();

            if (captcha_token) {

              // verify solved captcha (server-side) and login
              dispatch(resendActivation({...values, captcha_token }));
            }

            //stop submitting
            actions.setSubmitting(false);

            //reset captcha (front-end)
            captchaRef.current?.reset();

            //reset form
            actions.resetForm();
          }}>
            {
              ({ values, errors, isSubmitting }) => (
                <Form>
                  <Grid container>
                    <Grid item xs={12}>
                      <BdexInputField 
                        type="email"
                        name="email_address"
                        label="Email Address"
                        autoFocus
                        autoComplete="email"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={isSubmitting}
                      >
                        {"Send Email"}
                      </Button>
                    </Grid>
                    <Grid item container justifyContent="center">
                      <Link component={RouterLink} to="/auth/login" variant="body2" underline="hover">
                        {"Go back to Login"}
                      </Link>
                    </Grid>
                  </Grid>
                </Form>
              )
            }
        </Formik>
      </div>
      <ReCaptcha captcharef={captchaRef}/>
    </Container>
  );
};
