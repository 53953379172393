import React, { useState } from 'react';
import { IconButton, InputAdornment, TextField, } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useField, } from 'formik';

export default function BdexInputField({ variant = 'standard', label, required, autoFocus, autoComplete, multiline, rows, ...props }) {
  const [ field, meta, ] = useField(props);
  const errorText = meta?.touched ? meta?.error ?? '' : '';
  const type = props.type ?? 'text';

  const [showPassword, setShowPassword] = useState(false);

  const toggleClickShowPassword = () => {
    setShowPassword( prevState => !prevState );
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      {...field}
      variant={variant}
      size='small'
      margin='normal'
      fullWidth
      required={required}
      id={field.name}
      label={label}
      type={type === 'password' && !showPassword ? 'password' : 'text'}
      autoComplete={autoComplete ?? 'no'}
      autoFocus={autoFocus}
      helperText={errorText}
      error={!!errorText}
      multiline={multiline}
      rows={rows}
      InputProps={
        ((type === 'password') ? 
          { 
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={toggleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            )
          } : {}
        )
      }
    />
  )
};
