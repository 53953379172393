import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, ListItemIcon, ListItemText, Menu, MenuItem, Fade, Divider } from '@mui/material';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Info as InfoIcon,
  RecentActors as RecentActorsIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';

export default function PixelActions(props) {

  const { pixel_key, reporting_enabled, active, user_key } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = () => {
    setAnchorEl(null);
    setOpenDialog(true);
  };

  return (
    <>
      <DeleteConfirmationDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        pixel_key={pixel_key}
        user_key={user_key}
      />
      <Button
        id="pixel-action-button"
        aria-controls={open ? 'pixel-action-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="outlined"
        disableElevation
        endIcon={<KeyboardArrowDownIcon />}
      >
        Actions
      </Button>
      <Menu
        id="pixel-action-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'pixel-action-button',
        }}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={handleClose} 
          component={Link}
          to={{
            pathname: `/pixels/${pixel_key}`,
            // search: user_key && `user_key=${user_key}`,
          }}
        >
          <ListItemIcon>
            <InfoIcon color={"primary"}/>
          </ListItemIcon>
          <ListItemText>
            Details
          </ListItemText>
        </MenuItem>
        <Divider />
        { active ?
          <>
            <MenuItem
              onClick={handleClose} 
              component={Link}
              to={{
                pathname: `/pixels/${pixel_key}/visits`,
                // search: user_key && `user_key=${user_key}`,
              }}
            >
              <ListItemIcon>
                <RecentActorsIcon color={"primary"}/>
              </ListItemIcon>
              <ListItemText>
                Visits
              </ListItemText>
            </MenuItem>
            <Divider />
          </>
          : null
        }
        <MenuItem
          onClick={handleDelete}
        >
          <ListItemIcon>
            <DeleteIcon color={"error"} />
          </ListItemIcon>
          <ListItemText>
            Delete
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
