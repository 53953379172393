import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { 
  Alert,
  Box,
  TextField,
  Grid,
  Typography,
  Button,
} from '@mui/material';
import {
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material';
import { createPixel } from '../../../actions/pixel';
import { isEmpty } from '../../../lib/Utils';

export function NewPixelAdditionalData() {

  const dispatch = useDispatch();

  const initialPixelData = {
    site: "",
    email_address: "",
  };
  const [pixelData, setPixelData] = useState(initialPixelData);
  const [isPixelCreateButtonDisabled, setIsPixelCreateButtonDisabled] = useState(true);

  const handleDataChange = (event) => {
    const { name, value } = event.target;

    setPixelData(prev => {
      const data = {
        ...prev,
        [name]: value,
      };
      return data;
    })
  };

  const handleCreatePixel = () => {
    setIsPixelCreateButtonDisabled(true);

    // delete empties
    dispatch(createPixel({ pixel: JSON.parse(JSON.stringify(pixelData)) }));
    setPixelData(initialPixelData);
  }

  useEffect(() => {
    if (!isEmpty(pixelData.email_address) && !isEmpty(pixelData.site)) {
      setIsPixelCreateButtonDisabled(false);
    }
    else setIsPixelCreateButtonDisabled(true);
  }, [pixelData]);

  return (
    <Grid container id="pixels-create-new-pixel-additional-data-pixel-site" sx={{ my: 1 }}>
      <Grid item xs={12} sx={{ my: 1 }}>
        <Typography><strong>Create new Omni Pixel</strong></Typography>
      </Grid>
      <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}>
        <Alert severity="info" variant="outlined">
          <Box>
            You can create a new Omni Pixel which will allow you to track visitors on the website.
          </Box>
          <Box>
            Please specify the website description (domain) where you plan to install the Omni Pixel to, and the email address where you would like to receive the website visitors report generated by Omni Pixel.
          </Box>
          <Box>
            Once a new Omni Pixel is created, you can add any additional optional parameters to the Pixel Script which can be used for customization on your side, e.g., to track a specific page, or a subdomain on your website (see more in the Omni Pixel Details once it is created).
          </Box>
        </Alert>
      </Grid>
      <Grid item xs={12} sx={{ mx: 1 }}>
        <TextField
          type="text"
          name="site"
          label="Website Description"
          value={pixelData.site}
          variant="standard"
          size='small'
          margin='normal'
          fullWidth
          required={true}
          multiline={false}
          rows={1}
          placeholder="e.g., bdex.com"
          onChange={handleDataChange}
          autoFocus
        />
      </Grid>
      <Grid item xs={12} sx={{ mx: 1 }}>
        <TextField
          type="text"
          name="email_address"
          label="Email Address"
          value={pixelData.email_address}
          variant="standard"
          size='small'
          margin='normal'
          fullWidth
          required={true}
          multiline={false}
          rows={1}
          placeholder="e.g., sample@example.com"
          onChange={handleDataChange}
        />
      </Grid>
      <Button
        id="create_pixel"
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleCreatePixel} 
        disabled={isPixelCreateButtonDisabled}
        endIcon={<KeyboardArrowRightIcon />}
        sx={{ boxShadow: 10, borderRadius: 1, mt: 5 }}
      >
        {"Create a New Omni Pixel"}
      </Button>
    </Grid>
  )
}