import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Grid, Box, Tooltip, Typography, Stack, Alert, AlertTitle } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  ThumbUpOffAlt as ThumbUpOffAltIcon,
  ThumbDownOffAlt as ThumbDownOffAltIcon,
  Cancel as CancelIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  BarChart as BarChartIcon,
  History as HistoryIcon,
} from '@mui/icons-material';
import { getUploads } from '../../../actions/upload';
import { createRun } from '../../../actions/run';
import { setDataState } from '../../../actions/data';
import { uploadListSchema } from '../config';
import { isEmpty, sortBy } from '../../../lib/Utils';
import UploadActions from './UploadActions';
import { AutoRefresh, DataGridStatusColumn } from '../../Common';

const {
  REACT_APP_MIN_MATCHED_RECORDS_REQUIRED_FOR_NEW_RUN: MIN_MATCHED_RECORDS_REQUIRED_FOR_NEW_RUN = 1000,
} = process.env;

export function UploadsList(props = {}) {

  const { newRun, checkboxes } = props;
  const [isRunSubmitting, setIsRunSubmitting] = useState(false);
  const [availableRows, setAvailableRows] = useState([]);
  const [checkedRowIDs, setCheckedRowIDs] = useState([]);
  const [positiveRows, setPositiveRows] = useState([]);
  const [negativeRows, setNegativeRows] = useState([]);
  const [checkedUploadKey, setCheckedUploadKey] = useState();
  const [checkedUploadUserKey, setCheckedUploadUserKey] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  
  // sort by dt_last_updated desc, i.e., the latest updated first
  const defaultSort = sortBy('dt_last_updated', 'desc');

  const { upload_list, run_new: run } = useSelector(state => state.data);
  const { user, context_user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // set the schema-required counts
  upload_list?.forEach((item,index) => {
    item.uploaded_row_count = item.counts?.uploaded_row_count;
    item.uploaded_valid_row_count = item.counts?.uploaded_valid_row_count;
    item.matched_row_count = item.counts?.matched_row_count;
    item.matched_consumer_count = item.counts?.matched_consumer_count;

    upload_list[index] = item;
  })
  
  const handleGetUploads = () => {
    dispatch(getUploads());
  };

  // initial load
  useEffect(() => {

    // set 'upload_list' state
    handleGetUploads();

    if (user.permissions?.resources?.includes('others.uploads')) {
      setIsAdmin(true);
    }
  }, [])

  // load when upload_list is updated
  useEffect(() => {

    //exclude anything already present in positiveRows or negativeRows
    const _availableRows = upload_list
      .filter(x => !(positiveRows.concat(negativeRows)).filter(y => x.id === y.id).length)
      .sort(defaultSort);
    setAvailableRows(_availableRows);
  }, [upload_list])

  // upload.key
  useEffect(() => {
    if (checkboxes) {
      const checkedUpload = availableRows.filter(x => x.id === checkedRowIDs[0])?.[0];
      setCheckedUploadKey(checkedUpload?.key);
      setCheckedUploadUserKey(checkedUpload?.user_key);
    } 
  }, [availableRows, checkedRowIDs])

  let columns = [];
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    () => uploadListSchema.filter(x => x.hide === true).reduce((a, v) => ({ ...a, [v['field']]: false }), {})
  );

  const isUserKeyRequired = isAdmin && isEmpty(context_user);

  if (!isEmpty(upload_list)) {
    columns = Object.keys(upload_list[0]).map(x => {
      return {
        field: x,
        headerName: x,
      };
    });

    // set up from the config
    columns = uploadListSchema.filter(x => x.id === upload_list?.id);

    // remove all admin-visible columns if not an admin
    if (!isAdmin) {
      columns = columns.filter(x => !x.onlyVisibleToAdmin);
    }

    // enhance 'status' column
    columns[columns.findIndex(x => x.field === 'status')].renderCell = (params) => {
      return (
        <DataGridStatusColumn params={params} type={"uploads"} />
      );
    }

    // enhance 'name' column
    columns[columns.findIndex(x => x.field === 'name')].renderCell = (params) => {
      const { name, key: upload_key, user_key } = params.row;

      if (newRun) return name;
      
      return (
        <Grid container direction="row" sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
          <Grid item xs={10} zeroMinWidth>
            <Tooltip title={`See '${name}' List Details`} arrow>
              <Button 
                component={Link}
                to={{
                  pathname: `/lists/${upload_key}`,
                  search: isUserKeyRequired && `user_key=${user_key}`,
                }}
                sx={{ width: '100%', justifyContent: 'flex-start' }}
              >
                <Typography variant="inherit" noWrap>{name}</Typography>
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={2} zeroMinWidth sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
            <Stack direction="row" spacing={0} sx={{ justifyContent: 'center' }}>
              <Tooltip title={`See List Analytics`} arrow>
                <Box 
                  component={Link}
                  to={{
                    pathname: `/lists/${upload_key}/analytics`,
                    search: isUserKeyRequired && `user_key=${user_key}`,
                  }}
                >
                  <BarChartIcon
                    fontSize="small"
                    color="primary"
                    sx={{ display: 'flex' }}
                  />
                </Box>
              </Tooltip>
              <Tooltip title={`See List History`} arrow>
                <Box 
                  component={Link}
                  to={{
                    pathname: `/lists/${upload_key}/history`,
                    search: isUserKeyRequired && `user_key=${user_key}`,
                  }}
                >
                  <HistoryIcon
                    fontSize="small"
                    color="primary"
                    sx={{ display: 'flex' }}
                  />
                </Box>
              </Tooltip>
            </Stack>
          </Grid>
        </Grid>
      );
    }
  }

  // filter out columns for 'mini' view
  const columnsMini = columns.filter(x => x.mini);

  const handleMarkChecked = (selection) => {
    if (newRun) {
      const _checkedRowIDs = availableRows.filter(x => selection.includes(x.id)).map(x => x.id);
      setCheckedRowIDs(_checkedRowIDs);
    }
    else if (checkboxes) {
      if (selection.length > 1) {
        const selectionSet = new Set(checkedRowIDs);
        const result = selection.filter(x => !selectionSet.has(x));
        setCheckedRowIDs(result);
      }
      else {
        setCheckedRowIDs(selection);
      }
    }
  };

  const handleRowSelectable = (params) => {
    if (newRun) {
      if (
        ((params.row.user_key === user.key) || (isAdmin && params.row.user_key === context_user?.key))
        && ['available'].includes(params.row.status)
        && params.row.uploaded_valid_row_count
      ) {
        return true;
      }
      else {
        return false;
      }
    }
    return true;
  };

  // o_O https://github.com/mui-org/material-ui-x/issues/2714
  const handleMarkPositive = useCallback(() => {
    setTimeout(() => {
      setPositiveRows(prev => [
          ...prev, 
          ...availableRows.filter(x => checkedRowIDs.includes(x.id))
        ].sort(defaultSort));
      setAvailableRows(prev => prev.filter(x => !checkedRowIDs.includes(x.id)))
      setCheckedRowIDs([]);
    })
  }, [checkedRowIDs]);

  const handleMarkNegative = useCallback(() => {
    setTimeout(() => {
      setNegativeRows(prev => [
        ...prev, 
        ...availableRows.filter(x => checkedRowIDs.includes(x.id))
      ].sort(defaultSort));      
      setAvailableRows(prev => prev.filter(x => !checkedRowIDs.includes(x.id)))
      setCheckedRowIDs([]);
    })
  }, [checkedRowIDs]);

  const handleClearPositive = () => {
    setPositiveRows([]);
    setAvailableRows(prev => [...prev, ...positiveRows].sort(defaultSort));
  }

  const handleClearNegative = () => {
    setNegativeRows([]);
    setAvailableRows(prev => [...prev, ...negativeRows].sort(defaultSort));
  }

  useEffect(() => {
    if (newRun) {

      // clean run, keep run_name, run_comments
      const { run_name, run_comments } = run;
      dispatch(setDataState('run_new'));

      dispatch(setDataState('run_new', {
        run_name,
        run_comments,
        upload_list_positive: positiveRows,
        upload_list_negative: negativeRows,
        },
      ));
    }
  }, [positiveRows, negativeRows]);

  const handleCreateRun = () => {
    setIsRunSubmitting(true);
    dispatch(createRun({ run }));
    dispatch(setDataState('run_new'));
    navigate('/xaudiences');
  }
  
  const matched_upload_records_used_in_run_count = run.upload_list_positive?.reduce((acc, curr) => acc + curr?.matched_row_count || 0, 0) || 0;

  return (
    <>
      <Grid container id="upload-get-all-upload-list">
        <Grid item xs={6} sx={{ my: 1 }}>
          <Typography><strong>Uploaded Lists</strong></Typography>
        </Grid> 
        <Grid item xs={6} sx={{ my: 1 }}>
          <AutoRefresh handler={handleGetUploads} />
        </Grid>
        <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, mt: 1 }}>
          <DataGrid
            rows={availableRows}
            columns={columns}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(x) => setColumnVisibilityModel(x)}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10,50,100]}
            checkboxSelection={newRun || checkboxes ? true : false}
            disableRowSelectionOnClick={false}
            autoHeight={true}
            density={'compact'}
            rowSelectionModel={checkedRowIDs}
            onRowSelectionModelChange={handleMarkChecked}
            localeText={
              checkboxes ? {
                footerRowSelected: (count) => <UploadActions upload_key={checkedUploadKey} user_key={isUserKeyRequired && checkedUploadUserKey} /> }
              : null
            }
            isRowSelectable={handleRowSelectable}
            sx={{
              m: 1,
              // hideSelectAllCheckbox
              '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
                display: 'none',
              },
            }}
          />
        </Grid>
      </Grid>

      { newRun ?
        <>
          <Grid container id="runs-create">
            <Grid item xs={6} textAlign={"center"}>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                onClick={handleMarkPositive}
                startIcon={<ThumbUpOffAltIcon />}
                sx={{ boxShadow: 10, borderRadius: 1, my: 2 }}
              >
                {"Use as Positive"}
              </Button>
            </Grid>
            <Grid item xs={6} textAlign={"center"}>
              <Button
                type="submit"
                variant="outlined"
                color="warning"
                onClick={handleMarkNegative}
                startIcon={<ThumbDownOffAltIcon />}
                sx={{ boxShadow: 10, borderRadius: 1, my: 2 }}
              >
                {"Use as Negative"}
              </Button>
            </Grid>
            { positiveRows.length || negativeRows.length ?
              <>
                <Grid item xs={6} sx={{ mt: 2 }}>
                  <Box sx={{ p: 2, border: '1px dashed grey' }}>
                    <Grid container>
                      <Grid item xs={10}>
                        <Typography><strong>Positive List{positiveRows.length > 1 ? 's' : ''}</strong></Typography>
                      </Grid>
                      { positiveRows.length ?
                        <Grid item xs={2}>
                          <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
                            <Tooltip title="clear selection" arrow>
                              <CancelIcon
                                fontSize="small"
                                color="action"
                                onClick={handleClearPositive}
                              />
                            </Tooltip>
                          </Stack>
                        </Grid>
                        : null
                      }
                    </Grid>
                    <DataGrid
                      rows={positiveRows}
                      columns={columnsMini}
                      columnVisibilityModel={columnVisibilityModel}
                      onColumnVisibilityModelChange={(x) => setColumnVisibilityModel(x)}
                      hideFooter={true}
                      autoHeight={true}
                      density={'compact'}
                      components={{
                        NoRowsOverlay: () => (
                          <Stack height="100%" alignItems="center" justifyContent="center">
                            None
                          </Stack>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} sx={{ mt: 2 }}>
                  <Box sx={{ p: 2, border: '1px dashed grey' }}>
                    <Grid container>
                      <Grid item xs={10}>
                        <Typography><strong>Negative List{negativeRows.length > 1 ? 's' : ''}</strong></Typography>
                      </Grid>
                      { negativeRows.length ?
                        <Grid item xs={2}>
                          <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
                            <Tooltip title="clear selection" arrow>
                              <CancelIcon
                                fontSize="small"
                                color="action"
                                onClick={handleClearNegative}
                              />
                            </Tooltip>
                          </Stack>
                        </Grid>
                        : null
                      }
                    </Grid>
                    <DataGrid
                      rows={negativeRows}
                      columns={columnsMini}
                      columnVisibilityModel={columnVisibilityModel}
                      onColumnVisibilityModelChange={(x) => setColumnVisibilityModel(x)}
                      hideFooter={true}
                      autoHeight={true}
                      density={'compact'}
                      components={{
                        NoRowsOverlay: () => (
                          <Stack height="100%" alignItems="center" justifyContent="center">
                            None
                          </Stack>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
              </>
              : null
            }
          </Grid>
          { !isEmpty(run.upload_list_positive)
            && matched_upload_records_used_in_run_count >= MIN_MATCHED_RECORDS_REQUIRED_FOR_NEW_RUN ?
            <Button
              id="create_run"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleCreateRun} 
              disabled={isRunSubmitting}
              endIcon={<KeyboardArrowRightIcon />}
              sx={{ boxShadow: 10, borderRadius: 1, mt: 5 }}
            >
              {"Create a New XAudience (Expanded Audience)"}
            </Button>
            :
            <Grid container id="runs-create-minimum-size-notification">
              <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, mt: 1 }}>
                <Alert severity="info" variant="outlined">
                  <AlertTitle><strong>Note!</strong></AlertTitle>
                  <Box>
                    In order to qualify for the new Expanded Audience the Matched Records size of all Positive List(s) must be at least <strong>{MIN_MATCHED_RECORDS_REQUIRED_FOR_NEW_RUN - matched_upload_records_used_in_run_count}</strong> more.
                  </Box>
                </Alert>
              </Grid>
            </Grid>
          }
        </>
        : null
      }
    </>
  );
}
