export const uploadTypes = [
  {
    id: "positive",
    label: "Positive List(s)",
    description: "Uploaded List(s) to be used as a look-alike audience (i.e., similar taxonomy, attributes)",
  },
  {
    id: "negative",
    label: "Negative List(s)",
    description: "Uploaded List(s) to be used as an opposite audience to the List(s) that have been marked as Positive",
  }
];
