import { combineReducers } from '@reduxjs/toolkit';
import { auth } from './auth';
import { message } from './message';
import { data } from './data';
import { LOGOUT } from '../actions/types';

const appReducer = combineReducers({
  auth,
  message,
  data,

  // add more top-level reducers, if needed

});

// LOGOUT action type - set the store to the initial state 
// https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
// https://stackoverflow.com/questions/59061161/how-to-reset-state-of-redux-store-when-using-configurestore-from-reduxjs-toolki/61943631#61943631
export const rootReducer = (state, action) => {
  if (action.type === LOGOUT) state = {};
  
  return appReducer(state, action);
};
