import { sendHttpRequest } from '../lib/Utils';
import { setDataState } from '../actions/data';
import { store } from '../redux/store';

const preFile = async (kwargs) => {
  const { user_key, file } = kwargs;

  const response = await sendHttpRequest.post('/files', 
    { data: { file } },
    { params: { user_key } },
  );
  return response;
};

/**
 * Put a new object to S3 using signed URL
 * 'config._signedUrl' was set for not attaching the Authorization Bearer to the request
 * (see axiosClient/interceptors.request)
 * 
 * Fix this: 
 * ------WebKitFormBoundary...
 * Content-Disposition: form-data; name="file"; filename="..."
 * Content-Type: text/csv
 * 
 * @param {*} url 
 * @param {*} file
 * @returns 
 */
const fileToS3 = async (url, file) => {

  // const formData = new FormData();
  // formData.append('file', file);

  const response = await sendHttpRequest.request({
    method: 'put',
    url: url,
    data: file,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    _externalService: true,
    onUploadProgress: (event) => {

      const { data } = store.getState();
      const { file_new } = data;

      store.dispatch(setDataState('file_new', {
        ...file_new,
        load_progress: {
          ...event,
        },
      }));
    },
  });

  return response;
};

export const updateFile = async (kwargs) => {
  const { file_key, user_key, file } = kwargs;

  const response = await sendHttpRequest.put(`/files/${file_key}`, 
    { data: { file } },
    { params: { user_key } },
  )
  return response;
};

export const createFile = async(kwargs) => {

  const { user_key, file } = kwargs;
  const { file: fileObject } = file;

  // create new File, initial File History
  let response = await preFile({
    user_key,
    file: {
      ...file,
      description: 'Successfully finished stage: new_file_s3_signed_url',
    },
  });

  // put object to s3 using s3_signed_url
  const {
    key: file_key,
    s3_signed_url,
  } = response?.data?.data?.file_list?.at(0) || {};
  response = await fileToS3(s3_signed_url, fileObject);
  
  // update File by creating new File History
  response = await updateFile({
    user_key,
    file_key,
    file: {
      status: 'uploaded',
      description: 'Successfully finished stage: new_file_s3_copy',
    },
  });

  return response;
};

export const getFiles = async () => {
  const response = await sendHttpRequest.get(`/files`);
  return response;
};

export const getFile = async (kwargs) => {
  const { file_key, user_key } = kwargs;
  const response = await sendHttpRequest.get(`/files/${file_key}`, 
    { params: { user_key, history: true } },
  );
  return response;
};

export const deleteFile = async (kwargs) => {
  const { file_key, user_key } = kwargs;
  const response = await sendHttpRequest.delete(`/files/${file_key}`, 
    { params: { user_key } },
  );
  return response;
};
