import React from 'react';
import { Typography } from '@mui/material';
import BdexLogoColor from './Logos/BdexLogoColor';

export default function Header() {
  return (
    <Typography component='span' variant="body2" color="textSecondary" align="center">
      <BdexLogoColor />
    </Typography>
  )
};