import React, { useMemo, useRef, useEffect, useState } from 'react';
import { parsePreview } from '../../parser';
import { ImporterFrame } from '../ImporterFrame';
import { FileSelector } from './FileSelector';
import { FormatRawPreview } from './FormatRawPreview';
import { FormatDataRowPreview } from './FormatDataRowPreview';
import { FormatErrorMessage } from './FormatErrorMessage';
import './FileStep.scss';
import { useLocale } from '../../locale/LocaleContext';
export const FileStep = ({ customConfig, assumeNoHeaders, fileSelectionOnly, prevState, onChange, onAccept, onFileSelection }) => {
    // seed from previous state as needed
    const [selectedFile, setSelectedFile] = useState(prevState ? prevState.file : null);
    const [preview, setPreview] = useState(() => prevState && Object.assign({ parseError: undefined }, prevState));
    const [papaParseConfig, setPapaParseConfig] = useState(prevState ? prevState.papaParseConfig : customConfig);
    const [hasHeaders, setHasHeaders] = useState(prevState ? prevState.hasHeaders : false);
    // info object exposed to the progress callbacks
    const importInfo = useMemo(() => {
        return {
            file: selectedFile,
        };
    }, [selectedFile]);
    // notify on start of processing
    // (separate effect in case of errors)
    const onFileSelectionRef = useRef(onFileSelection); // wrap in ref to avoid re-triggering (only first instance is needed)
    useEffect(() => {
        if (onFileSelectionRef.current) {
            onFileSelectionRef.current(importInfo);
        }
    }, [importInfo]);
    // wrap in ref to avoid triggering effect
    const customConfigRef = useRef(customConfig);
    customConfigRef.current = customConfig;
    const assumeNoHeadersRef = useRef(assumeNoHeaders);
    assumeNoHeadersRef.current = assumeNoHeaders;
    const onChangeRef = useRef(onChange);
    onChangeRef.current = onChange;
    // notify of current state
    useEffect(() => {
        onChangeRef.current(preview && !preview.parseError
            ? Object.assign(Object.assign({}, preview), { papaParseConfig, hasHeaders }) : null);
    }, [preview, papaParseConfig, hasHeaders]);
    // perform async preview parse once for the given file
    const asyncLockRef = useRef(0);
    useEffect(() => {
        // clear other state when file selector is reset
        if (!selectedFile) {
            setPreview(null);
            return;
        }
        // preserve existing state when parsing for this file is already complete
        if (preview && preview.file === selectedFile) {
            return;
        }
        const oplock = asyncLockRef.current;
        // lock in the current PapaParse config instance for use in multiple spots
        const config = customConfigRef.current;
        // kick off the preview parse
        parsePreview(selectedFile, config).then((results) => {
            // ignore if stale
            if (oplock !== asyncLockRef.current) {
                return;
            }
            // save the results and the original config
            setPreview(results);
            setPapaParseConfig(config);
            // pre-fill headers flag (only possible with >1 lines)
            setHasHeaders(results.parseError
                ? false
                : !assumeNoHeadersRef.current && !results.isSingleLine);
        });
        return () => {
            // invalidate current oplock on change or unmount
            asyncLockRef.current += 1;
        };
    }, [selectedFile, preview]);
    const { importErrorText, rawFileShortSummaryText, rawFileContentsText, previewImportText, hasHeadersText, loadingPreviewText } = useLocale('FileStep');
    // clear selected file
    // preview result content to display
    const reportBlock = useMemo(() => {
        if (!preview) {
            return null;
        }
        if (preview.parseError) {
            return (React.createElement("div", { className: "CSVImporter_FileStep__mainResultBlock" },
                React.createElement(FormatErrorMessage, { onCancelClick: () => setSelectedFile(null) },
                    importErrorText,
                    ' ',
                    React.createElement("b", null, preview.parseError.message || String(preview.parseError)))));
        }
        return (React.createElement("div", { className: "CSVImporter_FileStep__mainResultBlock" },
            React.createElement("div", { className: "CSVImporter_FileStep__header" },
                rawFileShortSummaryText,
                ':',
                ` ${(((selectedFile === null || selectedFile === void 0 ? void 0 : selectedFile.size) || 0) / 1024 / 1024).toFixed(2)} MB (${selectedFile === null || selectedFile === void 0 ? void 0 : selectedFile.type})`),
            fileSelectionOnly ? null : (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "CSVImporter_FileStep__header" },
                    rawFileContentsText,
                    ':'),
                React.createElement(FormatRawPreview, { chunk: preview.firstChunk, warning: preview.parseWarning, onCancelClick: () => setSelectedFile(null) }),
                preview.parseWarning ? null : (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "CSVImporter_FileStep__header" },
                        previewImportText,
                        ':',
                        !preview.isSingleLine && ( // hide setting if only one line anyway
                        React.createElement("label", { className: "CSVImporter_FileStep__headerToggle" },
                            React.createElement("input", { type: "checkbox", checked: hasHeaders, onChange: () => {
                                    setHasHeaders((prev) => !prev);
                                } }),
                            React.createElement("span", null, hasHeadersText)))),
                    React.createElement(FormatDataRowPreview, { hasHeaders: hasHeaders, rows: preview.firstRows })))))));
    }, [
        preview,
        rawFileContentsText,
        previewImportText,
        hasHeaders,
        hasHeadersText,
        importErrorText
    ]);
    if (!selectedFile) {
        return (React.createElement(FileSelector, { onSelected: (file) => setSelectedFile(file), fileSelectionOnly: fileSelectionOnly }));
    }
    return (React.createElement(ImporterFrame, { fileName: selectedFile.name, fileSelectionOnly: fileSelectionOnly, nextDisabled: !preview || !!preview.parseError || !!preview.parseWarning, onNext: () => {
            if (!preview || preview.parseError) {
                throw new Error('unexpected missing preview info');
            }
            onAccept();
        }, onCancel: () => setSelectedFile(null) }, reportBlock || (React.createElement("div", { className: "CSVImporter_FileStep__mainPendingBlock" }, loadingPreviewText))));
};
