import { store } from '../redux/store';
import { setDataState } from '../actions/data';
import { isEmpty, sendHttpRequest } from '../lib/Utils';

export const previewOrder = async(kwargs) => {

  const { 
    items = [],
  } = kwargs;

  if (!isEmpty(items)) {
    const response = await sendHttpRequest.post('/orders/preview',
    { 
      data: { 
        order: {
          items,
        } 
      }
    },
  );
  return response;
  }
};

export const createOrder = async(kwargs) => {

  const { 
    order_preview_key,
  } = kwargs;

  const response = await sendHttpRequest.post('/orders',
    { 
      data: { 
        order: {
          order_preview_key,
        } 
      }
    },
  );
  return response;
};

export const createOrderUsingAccountCredit = async(kwargs) => {

  const {
    order_preview_key,
  } = kwargs;

  const response = await sendHttpRequest.post('/orders',
    { 
      data: {
        order: {
          order_preview_key,
        },
      },
    },
    { params: { use_credit: true } },
  );

  return response;
};

export const getOrders = async () => {
  const response = await sendHttpRequest.get(`/orders`);
  return response;
};

export const getOrder = async (kwargs) => {
  const { order_key, user_key } = kwargs;
  const response = await sendHttpRequest.get(`/orders/${order_key}`,
    { params: { user_key, history: true } },
  );
  return response;
};

export const updateOrder = async (kwargs) => {
  const { order_key, user_key, order } = kwargs;

  const response = await sendHttpRequest.put(`/orders/${order_key}`,
    { data: { order } },
    { params: { user_key } },
  )
  return response;
};

const fileFromS3 = async (url, file_name) => {

  // in case there are some corner cases, here are the available libraries:
  // https://www.npmjs.com/package/file-saver
  // https://www.npmjs.com/package/js-file-download

  const response = await sendHttpRequest.request({
    method: 'get',
    url: url,
    // headers: {
    //   'Content-Type': 'application/octet-stream',
    //   'Content-Disposition': 'attachment; filename="file_name"'
    // },
    responseType: 'blob',
    _externalService: true,
    onDownloadProgress: (event) => {

      const { data } = store.getState();
      const { order } = data;

      store.dispatch(setDataState('order', {
        ...order,
        load_progress: {
          ...event,
        },
      }));
    },
  });

  const href = window.URL.createObjectURL(response.data);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute(
    'download',
    `${file_name}`,
  );

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);
};

export const getOrderFiles = async (kwargs) => {

  const { user_key , order_key } = kwargs;

  // create Order History with status 'downloaded'
  let response = await updateOrder({
    user_key,
    order_key,
    order: {
      status: 'delivered',
      description: 'Successfully finished stage: order_files_downloaded',
    },
  });

  // download object from s3 using s3_signed_url
  const { 
    s3_signed_url,
    dt_last_updated,
   } = response?.data?.data?.order_list?.at(0) || {};
  const fileName = `bdex_order_${order_key}_${dt_last_updated}_all.zip`;
  response = await fileFromS3(s3_signed_url, fileName);
  
  return response;
};

export const cancelOrder = async(kwargs) => {
  const { order_key, user_key } = kwargs;
  const response = await sendHttpRequest.delete(`/orders/${order_key}`,
  { params: { user_key } },
  );
  return response;
};
