import React, { useMemo, useEffect, useRef } from 'react';
import { ColumnDragCard } from './ColumnDragCard';
import { IconButton } from '../IconButton';
import './ColumnDragTargetArea.scss';
import { useLocale } from '../../locale/LocaleContext';
const TargetBox = ({ hasHeaders, field, touched, assignedColumn, dragState, eventBinder, onHover, onAssign, onUnassign }) => {
    // wrap in ref to avoid re-triggering effect
    const onHoverRef = useRef(onHover);
    onHoverRef.current = onHover;
    // respond to hover events when there is active mouse drag happening
    // (not keyboard-emulated one)
    const containerRef = useRef(null);
    const isHoveredRef = useRef(false); // simple tracking of current hover state to avoid spamming onHover (not for display)
    useEffect(() => {
        const container = containerRef.current;
        if (!dragState || !dragState.pointerStartInfo || !container) {
            return;
        }
        // measure the current scroll-independent position
        const rect = container.getBoundingClientRect();
        const minX = rect.x;
        const maxX = rect.x + rect.width;
        const minY = rect.y;
        const maxY = rect.y + rect.height;
        // listen for pointer movement (mouse or touch) and detect hover
        const listeners = dragState.updateListeners;
        const listenerName = `field:${field.name}`;
        listeners[listenerName] = (xy) => {
            const isInBounds = xy[0] >= minX && xy[0] < maxX && xy[1] >= minY && xy[1] < maxY;
            if (isInBounds !== isHoveredRef.current) {
                // cannot use local var for isHovered state because the effect re-triggers after this
                isHoveredRef.current = isInBounds;
                onHoverRef.current(field.name, isInBounds);
            }
        };
        // cleanup
        return () => {
            delete listeners[listenerName];
        };
    }, [dragState, field.name]);
    // if this field is the current highlighted drop target,
    // get the originating column data for display
    const sourceColumn = dragState && dragState.dropFieldName === field.name
        ? dragState.column
        : null;
    // see if currently assigned column is being dragged again
    const isReDragged = dragState ? dragState.column === assignedColumn : false;
    // drag start handlers for columns that can be re-dragged (i.e. are assigned)
    const dragStartHandlers = useMemo(() => assignedColumn && !isReDragged
        ? eventBinder(assignedColumn, field.name)
        : {}, [eventBinder, assignedColumn, isReDragged, field.name]);
    const valueContents = useMemo(() => {
        if (sourceColumn) {
            return (React.createElement(ColumnDragCard, { rowCount: 3, column: sourceColumn, isDropIndicator: true }));
        }
        if (assignedColumn) {
            return (React.createElement(ColumnDragCard, { rowCount: 3, column: assignedColumn, isShadow: isReDragged, isDraggable: !isReDragged }));
        }
        const hasError = touched && !field.isOptional;
        return (React.createElement(ColumnDragCard, { rowCount: 3, hasHeaders: hasHeaders, hasError: hasError }));
    }, [hasHeaders, field, touched, assignedColumn, sourceColumn, isReDragged]);
    const { optionalAriaLabelText, requiredAriaLabelText, boxPlaceholderText, getBoxValueActionText, clearText } = useLocale('TargetBox');
    // @todo mouse cursor changes to reflect draggable state
    return (React.createElement("section", { className: "CSVImporter_ColumnDragTargetArea__box", "aria-label": `${field.label} (${field.isOptional ? optionalAriaLabelText : requiredAriaLabelText})`, ref: containerRef },
        React.createElement("div", { className: "CSVImporter_ColumnDragTargetArea__boxLabel", "aria-hidden": true },
            field.label,
            field.isOptional ? null : React.createElement("b", null, "*")),
        React.createElement("div", { className: "CSVImporter_ColumnDragTargetArea__boxValue" },
            !sourceColumn && !assignedColumn && (React.createElement("div", { className: "CSVImporter_ColumnDragTargetArea__boxPlaceholderHelp", "aria-hidden": true }, boxPlaceholderText)),
            React.createElement("div", Object.assign({}, dragStartHandlers), valueContents),
            dragState && !dragState.pointerStartInfo ? (React.createElement("div", { className: "CSVImporter_ColumnDragTargetArea__boxValueAction" },
                React.createElement(IconButton, { label: getBoxValueActionText(dragState.column.code), small: true, type: "forward", onClick: () => onAssign(field.name) }))) : (!sourceColumn &&
                assignedColumn && (React.createElement("div", { className: "CSVImporter_ColumnDragTargetArea__boxValueAction" },
                React.createElement(IconButton, { label: clearText, small: true, type: "close", onClick: () => onUnassign(assignedColumn) })))))));
};
export const ColumnDragTargetArea = ({ hasHeaders, fields, columns, fieldTouched, fieldAssignments, dragState, eventBinder, onHover, onAssign, onUnassign }) => {
    const { ariaLabelText } = useLocale('ColumnDragTargetArea');
    return (React.createElement("section", { className: "CSVImporter_ColumnDragTargetArea", "aria-label": ariaLabelText }, fields.map((field) => {
        const assignedColumnIndex = fieldAssignments[field.name];
        return (React.createElement(TargetBox, { key: field.name, field: field, touched: fieldTouched[field.name], hasHeaders: hasHeaders, assignedColumn: assignedColumnIndex !== undefined
                ? columns[assignedColumnIndex]
                : null, dragState: dragState, eventBinder: eventBinder, onHover: onHover, onAssign: onAssign, onUnassign: onUnassign }));
    })));
};
