import React from 'react';
import { TextButton } from '../TextButton';
import './FormatErrorMessage.scss';
import { useLocale } from '../../locale/LocaleContext';
export const FormatErrorMessage = React.memo(({ onCancelClick, children }) => {
    const { backText } = useLocale('FormatErrorMessage');
    return (React.createElement("div", { className: "CSVImporter_FormatErrorMessage" },
        React.createElement("span", null, children),
        React.createElement(TextButton, { onClick: onCancelClick }, backText)));
});
