import React from 'react';
import { Paper } from '@mui/material';
import { UploadHistory } from './UploadHistory';
import { UploadAnalytics } from '../../Analytics/Upload';
import { UploadDetails } from './UploadDetails';

export function UploadsGetOne() {

  return (
    <Paper id="uploads-get-one" sx={{ boxShadow: 10, borderRadius: 1, p: 2 }}>
      <UploadDetails />
      <UploadAnalytics embedded={true} />
      <UploadHistory embedded={true} />
    </Paper>
  )
}
