import { Utils, LocalStorage } from '../lib';
import { store } from '../redux/store';

export const getShoppingCart = async () => {
  const { auth } = store.getState();
  const { user } = auth;

  const response = await Utils.sendHttpRequest.get(`/users/${user.key}/session`);
  
  const { shopping_cart } = response.data?.data?.user_session?.data ?? {};
  if (shopping_cart) {
    LocalStorage.setItem('shopping_cart', shopping_cart);
  }

  return response;
};

export const setShoppingCart = async (kwargs = {}) => {
  const { items = [], merge = false } = kwargs;
  const { auth } = store.getState();
  const { user } = auth;

  const response = await Utils.sendHttpRequest.post(`/users/${user.key}/session`,
    { 
      data: {
        user_session: {
          shopping_cart: {
            ...(items && { items }),
          },
        },
      },
    },
    merge && {
      params: {
        merge,
      }
    },
  );

  const { shopping_cart } = response.data?.data?.user_session?.data ?? {};
  if (shopping_cart) {
    LocalStorage.setItem('shopping_cart', shopping_cart);
  }

  return response;
};
