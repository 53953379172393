import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

const ANALYTICS_SERVER_URL = process.env.REACT_APP_ANALYTICS_SERVER_URL;

export function UploadAnalyticsBase(props) {

  const { randomKey } = props;
  const params = useParams();
  const { upload_key } = params;

  const url1 = `${ANALYTICS_SERVER_URL}/?sourceType=upload&sourceKey=${upload_key}&type=histogram&attr1=gender&style=pie&title=Gender`;
  const url2 = `${ANALYTICS_SERVER_URL}/?sourceType=upload&sourceKey=${upload_key}&type=histogram&attr1=hh_income&style=bar&title=Household+Income`;
  const url3 = `${ANALYTICS_SERVER_URL}/?sourceType=upload&sourceKey=${upload_key}&type=histogram&attr1=birth_year&style=line&title=Birth+Year`;

  return (
    <Grid container sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}>
      <Grid item xs={12} sm={12} md={6}>
        <iframe
          src={url1}
          width="100%"
          height="475px"
          frameBorder="0"
          title="analytics-graph-iframe-1"
          key={`${randomKey}-1`}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <iframe 
          src={url2}
          width="100%"
          height="475px"
          frameBorder="0"
          title="analytics-graph-iframe-2"
          key={`${randomKey}-2`}
        />
      </Grid>
      <Grid item xs={12}>
        <iframe
          src={url3}
          width="100%"
          height="475px"
          frameBorder="0"
          title="analytics-graph-iframe-3"
          key={`${randomKey}-3`}
        />
      </Grid>
    </Grid>
  )
}
