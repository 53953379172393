import {
  DATA,
  SET_MESSAGE,
} from "./types";

import * as UserService from "../services/user";
import { parseResponseError } from "../lib/Utils";

export const getUsers = () => async (dispatch) => {
  try {
    const response = await UserService.getUsers();
    const { user_list } = response.data.data;

    user_list.forEach((user, index) => {

      // destructure user resource_counts
      const resource_count = user?.resource_counts?.resource_count || {};
      user.upload_count = resource_count.uploads;
      user.run_count = resource_count.runs;
      user.file_count = resource_count.files;
      user.order_count = resource_count.orders;
      user.audience_count = resource_count.audiences;
      
      // delete resource_counts
      delete user.resource_counts;

      // save the enhanced user object back
      user_list[index] = user;
    })

    dispatch({
      type: DATA,
      payload: {
        user_list,
      },
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const getUser = (user_id) => async (dispatch) => {
  try {

    let response;
    if (user_id) {
      response = await UserService.getUser(user_id);
    }
    else {
      response = await UserService.getCurrentUser();
    }

    const { user_list } = response?.data?.data || {};
    const user = user_list[0];

    dispatch({
      type: DATA,
      payload: {
        user,
      },
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const getCurrentUser = () => getUser();

export const createUserFeedback = (payload) => async (dispatch) => {
  try {

    const response = await UserService.createUserFeedback(payload);
    const { feedback } = response?.data?.data || {};
    const feedBackStatus = Object.values(feedback);
    const failedFeedBackStatus = feedBackStatus.find(x => x.status != 200);

    if (failedFeedBackStatus) {
      const { status, statusText } = failedFeedBackStatus;
      throw new Error(`Error submitting a feedback. Reason: ${status}-${statusText}`);
    }
   
    dispatch({
      type: SET_MESSAGE,
      payload: {
        info: `Your feedback has been submitted. Thank you for being a part of Omni IQ!`,
        feedBackStatus,
      }
    });
  }
  catch(err) {
    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const updateUser = (kwargs) => async (dispatch) => {
  try {

    const response = await UserService.updateUser(kwargs);

    dispatch({
      type: SET_MESSAGE,
      payload: {
        info: 'User settings have been updated',
        status: '200',
      }
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};
