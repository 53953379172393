import { 
  SET_MESSAGE,
  CLEAR_MESSAGE,
} from '../actions/types';

const initialState = {};

export const message = (state = initialState, action) => {
  const { type, payload } = action;
  
  switch(type) {
    case SET_MESSAGE:
      return {
        ...payload,
      };
    case CLEAR_MESSAGE:
      return initialState;
    default:
      return state;
  }
};
