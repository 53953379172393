import React, { useMemo } from 'react';
import { PREVIEW_ROW_COUNT } from '../../parser';
import './ColumnDragCard.scss';
import { useLocale } from '../../locale/LocaleContext';
// @todo sort out "grabbing" cursor state (does not work with pointer-events:none)
export const ColumnDragCard = ({ hasHeaders, column: optionalColumn, rowCount = PREVIEW_ROW_COUNT, hasError, isAssigned, isShadow, isDraggable, isDragged, isDropIndicator }) => {
    const isDummy = !optionalColumn;
    const column = useMemo(() => optionalColumn || {
        index: -1,
        code: '',
        header: hasHeaders ? '' : undefined,
        values: [...new Array(PREVIEW_ROW_COUNT)].map(() => '')
    }, [optionalColumn, hasHeaders]);
    const headerValue = column.header;
    const dataValues = column.values.slice(0, headerValue === undefined ? rowCount : rowCount - 1);
    const { getHeaderText, dummyHeaderText } = useLocale('ColumnDragCard');
    return (
    // not changing variant dynamically because it causes a height jump
    React.createElement("div", { key: isDummy || isShadow ? 1 : isDropIndicator ? 2 : 0, className: "CSVImporter_ColumnDragCard", "data-dummy": !!isDummy, "data-error": !!hasError, "data-shadow": !!isShadow, "data-draggable": !!isDraggable, "data-dragged": !!isDragged, "data-drop-indicator": !!isDropIndicator },
        React.createElement("div", { className: "CSVImporter_ColumnDragCard__cardHeader" },
            isDummy ? (React.createElement("var", { role: "text" }, dummyHeaderText)) : (React.createElement("var", { role: "text" }, getHeaderText(column.code))),
            isDummy || isAssigned ? '\u00a0' : React.createElement("b", { "aria-hidden": true }, column.code)),
        headerValue !== undefined ? (React.createElement("div", { className: "CSVImporter_ColumnDragCard__cardValue", "data-header": true }, headerValue || '\u00a0')) : null,
        React.createElement("div", { role: "text" }, dataValues.map((value, valueIndex) => (React.createElement("div", { key: valueIndex, className: "CSVImporter_ColumnDragCard__cardValue" }, value || '\u00a0'))))));
};
