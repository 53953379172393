import React from 'react';
import { Paper } from '@mui/material';
import { FilesList } from './FilesList';

export function FilesGetAll(props) {
  return (
    <Paper id="files-get-all" sx={{ boxShadow: 10, borderRadius: 1, p: 2 }}>
      <FilesList {...props} />
    </Paper>
  )
};
