import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppBar, Badge, Button, Divider, Grid, IconButton, Stack, Tab, Tabs, Toolbar, Tooltip, Typography } from '@mui/material';
import { 
  Menu as MenuIcon,
  Notifications as NotificationsIcon,
  AccountCircle as AccountCircleIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
  Logout as LogoutIcon,
  ShoppingCart as ShoppingCartIcon,
} from '@mui/icons-material';
import { logout, logoutContextUser } from '../../actions/auth';

export default function Header(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, context_user } = useSelector(state => state.auth);
  const { shopping_cart } = useSelector(state => state.data);

  const shopping_cart_item_count = shopping_cart?.items?.map(x => Object.values(x.resource_items).flat().length)?.reduce((acc, curr) => acc + curr, 0);
  const { onDrawerToggle } = props;

  return (
    <>
      <AppBar color="primary" position="sticky" elevation={20}>
        <Toolbar>
          <Grid 
            container 
            spacing={1} 
            alignItems="center"
            >
            <Grid sx={{ display: { sm: 'none', xs: 'block' } }} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs />
            <Grid item>
              <Badge
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                badgeContent={shopping_cart_item_count}
                onClick={() => navigate('/cart')}
                sx={{ color: 'inherit' }}
                overlap="circular"
                variant="standard"
              >  
                <IconButton color="inherit" sx={{ p: 0.5 }}>
                  <ShoppingCartIcon />
                </IconButton>
              </Badge>
            </Grid>
            <Grid item>
              <Tooltip
                title={
                  <Typography variant="body2">Alerts • No alerts</Typography>
                }
                arrow
              >
                <IconButton color="inherit" sx={{ p: 0.5 }}>
                  <NotificationsIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip
                title={
                  <Stack 
                    spacing={1} 
                    sx={{
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="body2">{`user: ${user.email_address}`}</Typography>
                    <Divider />
                    <Button
                      onClick={() => dispatch(logout())}
                      sx={{ color: 'inherit' }}
                    >
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography variant="body2">{'log out'}</Typography>
                        <LogoutIcon sx={{ fontSize: 16 }} />
                      </Stack>
                    </Button>
                  </Stack>
                }
                leaveDelay={1000}
                arrow
              >
                <IconButton color="inherit" sx={{ p: 0.5 }}>
                  <AccountCircleIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            {
              context_user ? (
                <Grid item>
                  <Tooltip
                    title={
                      <Stack 
                        spacing={1}
                        sx={{
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography variant="body2">{`logged in as: ${context_user.email_address}`}</Typography>
                        <Divider />
                        <Button
                          onClick={() => dispatch(logoutContextUser())}
                          sx={{ color: 'inherit' }}
                        >
                          <Stack
                            direction="row"
                            spacing={1}
                            sx={{
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Typography variant="body2">{'log out'}</Typography>
                            <LogoutIcon sx={{ fontSize: 16 }} />
                          </Stack>
                        </Button>
                      </Stack>
                    }
                    leaveDelay={1000}
                    arrow
                  >
                    <IconButton color="inherit" sx={{ p: 0.5 }}>
                      <Badge 
                        badgeContent={'!'} 
                        color="warning"
                      >
                        <SupervisedUserCircleIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </Grid>              
              ) : null
            }
          </Grid>
        </Toolbar>
      </AppBar>
      {/* <AppBar component="div" position="static" elevation={10} sx={{ zIndex: 0 }}>
        <Tabs value={0} textColor="inherit">
          <Tab label="Create" />
          <Tab label="Templates" />
          <Tab label="Preview" />
          <Tab label="Status" />
        </Tabs>
      </AppBar> */}
    </>
  );
};
