import React from 'react';
import { Paper } from '@mui/material';
import { OrderHistory } from './OrderHistory';
import { OrderDetails } from './OrderDetails';

export function OrdersGetOne() {

  return (
    <Paper id="orders-get-one" sx={{ boxShadow: 10, borderRadius: 1, p: 2 }}>
      <OrderDetails />
      <OrderHistory embedded={true} />
    </Paper>
  )
}
