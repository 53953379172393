import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography, Button, Alert, Grid } from '@mui/material';
import {
  CloudUpload as CloudUploadIcon,
}
from '@mui/icons-material';
import { isEmpty } from '../../../lib/Utils';
import { createUpload } from '../../../actions/upload';
import { setDataState } from '../../../actions/data';
import { ProgressBar } from '../../Common';

export default function NewUploadSubmit() {

  const { upload_new: upload } = useSelector(state => state.data);
  const { upload_name, load_progress } = upload;

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(() => {
    if (isEmpty(load_progress)) return false;
    return true;
  });

  const progressRef = useRef(null);

  const handleCreateUpload = () => {
    setIsSubmitting(true);
    dispatch(createUpload({ upload }));
    progressRef.current.scrollIntoView();
  }

  useEffect(() => {
    if (load_progress?.progress === 1) {
      setIsSubmitting(false);
      dispatch(setDataState('upload_new'));
      if (location.pathname === '/lists/new') {
        navigate('/lists');
      }
    }
  }, [load_progress]);

  return (
    <>
      { upload_name ?
        <>
          <Button
            id="uploads-create-new-upload-submit"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleCreateUpload}
            disabled={isSubmitting}
            endIcon={<CloudUploadIcon />}
            sx={{ boxShadow: 10, borderRadius: 1, mt: 5 }}
          >
            {"Confirm Upload"}
          </Button>
          { load_progress ?
            <Grid container id="uploads-create-new-upload-submit-progress">
              <Grid item xs={12} sx={{ my: 1 }}>
                <Typography><strong>New List: Upload Progress</strong></Typography>
              </Grid>
              <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}>
                { load_progress.uploadedPercent !== 1 ?
                  <Alert severity="warning" variant="outlined">
                    {"Uploading the List... Feel free to navigate from this page, but please do not close or refresh it"}
                  </Alert>
                  : null
                }
              </Grid>
              <Grid item xs={12} sx={{ m: 1 }}>
                <ProgressBar {...load_progress} />
              </Grid>
            </Grid>
            : null
          }
          <div ref={progressRef}></div>
        </>
        : null
      }
    </>
  );
}
