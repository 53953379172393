import { Accessibility } from 'accessibility';
import './Accessibility.css';

export const enableAcessibilityWidget = () => new Accessibility({
  icon: {
    img: 'accessible',
  },
  animations: {
    buttons: true,
  },
  session: {
    persistent: process.env.NODE_ENV === 'production',
  },
});
