import { dataGridColumns, getPrice } from "../../../../lib/Utils";

export const runAnalyticsPaidSchema = {
  demographic_data: {
    name: 'Demographic Data',
    fields: [
      {
        field: "field",
        headerName: "Field",
        editable: false,
        sortable: false,
        flex: 240,
        mini: true,
        mappings: {
          gender: {
            // display_name: 'Gender - overwritten',
            // description: 'Gender description - overwritten',
          },
          hh_income: {},
          net_asset_value: {},
          birth_year: {},
          occupation_category: {},
          education_level: {},
          employment_status: {},
          pii: {},
          city: {
            included_in: 'pii',
          },
          state: {
            included_in: 'pii',
          },
          postal_code: {
            included_in: 'pii',
          },
        }
      },
      {
        field: "matched_count",
        headerName: "Matched Rows",
        editable: false,
        sortable: false,
        width: 120,
        hide: true,
        type: 'number',
      },
      {
        field: "match_rate",
        headerName: "Match Rate",
        editable: false,
        sortable: false,
        width: 120,
        ...dataGridColumns.display_percent,
      },
      {
        field: "found_count",
        headerName: "Records",
        editable: false,
        sortable: false,
        width: 120,
        mini: true,
        type: 'number',
      },
      {
        field: "price",
        headerName: "price*",
        editable: false,
        sortable: false,
        width: 0,
        mini: true,
        hide: true,
      },
      {
        field: "decimals",
        headerName: "decimals*",
        editable: false,
        sortable: false,
        width: 0,
        mini: true,
        hide: true,
      },
      {
        field: "currency",
        headerName: "currency*",
        editable: false,
        sortable: false,
        width: 0,
        mini: true,
        hide: true,
      },
      {
        field: "display_price",
        headerName: "Price",
        editable: false,
        sortable: false,
        width: 160,
        mini: true,
        ...dataGridColumns.display_price,
      },
    ],
  },
  identity_data: {
    name: 'Identity Data',
    fields: [
      {
        field: "field",
        headerName: "Field",
        editable: false,
        sortable: false,
        flex: 240,
        mini: true,
        mappings: {
          // email_address: {
          //   // display_name: 'can overwrite the display_name like so',
          //   // description: 'can overwrite the description like so',
          // },
          email_md5: {},
          maid_aaid: {},
          maid_idfa: {},
          // ip_address: {},
        },
      },
      {
        field: "matched_count",
        headerName: "Matched Rows",
        editable: false,
        sortable: false,
        width: 120,
        hide: true,
        type: 'number',
      },
      {
        field: "match_rate",
        headerName: "Match Rate",
        editable: false,
        sortable: false,
        width: 120,
        hide: true,
        ...dataGridColumns.display_percent,
      },
      {
        field: "found_count",
        headerName: "Available Records",
        description: "The number of available Records",
        editable: false,
        sortable: false,
        width: 220,
        mini: true,
        type: 'number',
        preOrder: {
          hide: true,
        }
      },
      // default state in the analytics datagrid
      {
        headerName: "Records to purchase",
        description: "The number of Records to purchase",        
        field: "purchased_count",
        editable: true,
        sortable: false,
        width: 220,
        mini: true,
        type: 'number',

        //pre-order state in the pre-shopping cart and shopping cart
        preOrder: {
          headerName: "Records to purchase",
          description: "The number of Records to purchase",
          editable: false,          
          renderCell: (params) => params.formattedValue,
          renderColumn: () => null,
        },

        //post-order in order details
        postOrder: {
          field: "purchased_count",
          headerName: "Purchased Records",
          description: "The number of purchased Records",          
          editable: false,
          renderCell: (params) => params.formattedValue,
          renderColumn: () => null,
        },
      },
      {
        field: "price",
        headerName: "price*",
        editable: false,
        sortable: false,
        width: 0,
        mini: true,
        hide: true,
        // valueGetter: (value, row, column, apiRef) => getPrice(row.pricing_schedule, row.purchased_count),
      },
      {
        field: "decimals",
        headerName: "decimals*",
        editable: false,
        sortable: false,
        width: 0,
        mini: true,
        hide: true,
      },
      {
        field: "currency",
        headerName: "currency*",
        editable: false,
        sortable: false,
        width: 0,
        mini: true,
        hide: true,
      },
      {
        field: "display_price",
        headerName: "Price",
        editable: false,
        sortable: false,
        width: 160,
        mini: true,
        ...dataGridColumns.display_price,
      },
    ],
  },
};
