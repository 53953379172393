import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid, List, ListItem, ListItemText, Divider, ListItemIcon } from '@mui/material';
import {
  UploadFile as UploadListIcon,
  OpenWith as ExpandeAudienceIcon,
  CrisisAlert as ActivateAudienceIcon,
} from '@mui/icons-material';

export function HomePageLinks() {

  const { user, context_user } = useSelector(state => state.auth);
  const [isAdmin, setIsAdmin] = useState();

  // initial load
  useEffect(() => {

    // admin?
    if (user.permissions?.resources?.includes('others.users')) {
      setIsAdmin(true);
    }

  }, [])

  return (
    <>
      { !isAdmin ?
        <Grid container id="home-page-links">
          <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, mt: 1 }}>
            <List>
              <ListItem
                component={Link}
                to="/lists/new"
                sx={{
                  color: 'info.main'
                }}
              >
                <ListItemIcon>
                  <UploadListIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Step 1. Upload a List"
                  secondary={
                    <>
                      <strong>{'Analyze'}</strong>
                      {` your audience and purchase audience data`}
                    </>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem
                component={Link}
                to="/audiences/new"
                sx={{
                  color: 'info.main'
                }}
              >
                <ListItemIcon>
                  <ExpandeAudienceIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Step 2. Expand Your Audience"
                  secondary={
                    <>
                      <strong>{'Target'}</strong>
                      {` with audiences built with Machine Learning and your first party data`}
                    </>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem
                component={Link}
                to="/activate/partners"
                sx={{
                  color: 'info.main'
                }}
              >
                <ListItemIcon>
                  <ActivateAudienceIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Step 3. Activate Your Expanded Audience"
                  secondary={
                    <>
                      <strong>{'Activate'}</strong>
                      {` your new expended audience on ad platforms and get new customers`}
                    </>
                  }
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
        : null
      }
    </>
  )
};
