import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { getFile } from '../../../actions/file';
import { clearMessage } from '../../../actions/message';
import { isEmpty, bytesDisplay, constructS3Uri } from '../../../lib/Utils';
import { AutoRefresh } from '../../Common';

export function FileDetails() {
    
  const { file } = useSelector(state => state.data);
  const { user } = useSelector(state => state.auth);

  const dispatch = useDispatch();
  
  const params = useParams();
  const { file_key } = params;

  const [searchParams] = useSearchParams();
  const user_key = searchParams.get('user_key');

  const [availableRows, setAvailableRows] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  const handleGetFile = () => {
    dispatch(getFile({
      file_key,
      user_key,
    }));
  };

  // navigate to '/files' if error 400 or 404 received
  const navigate = useNavigate();
  const { error, status } = useSelector(state => state.message);
  
  useEffect(() => {

    if (status) {
      if (status === 404 || (status === 400 && error?.includes('User does not have a requested'))) {
        dispatch(clearMessage());
        navigate('/files');
      }
    }
  }, [error, status, dispatch, navigate]);
  
  // initial load
  useEffect(() => {

    // set 'file' state
    handleGetFile();

    // isAdmin?
    if (user.permissions?.resources?.includes('others.files')) {
     setIsAdmin(true);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(file)) {

      const rows = [
        {
          id: 1,
          key: 'Name',
          value: file.name,
        },
        {
          id: 2,
          key: 'Comments',
          value: file.comments,
        },
        {
          id: 3,
          key: 'Created',
          value: file.dt_created,
        },
        {
          id: 4,
          key: 'Last Updated',
          value: file.dt_last_updated,
        },    
        {
          id: 5,
          key: 'Last Stage Description',
          value: file.description,
        },
        {
          id: 6,
          key: 'Status',
          value: file.status,
        },
        {
          id: 7,
          key: 'File Size',
          value: bytesDisplay(file.file_size),
        },
        {
          id: 8,
          key: 'File Type',
          value: file.file_type,
        },
      ];

      if (isAdmin) {

        rows.push({
          id: rows.length + 1,
          key: 'file_id*',
          value: file.id,
        })

        rows.push({
          id: rows.length + 1,
          key: 'file_key*',
          value: file.key,
        })

        rows.push({
          id: rows.length + 1,
          key: 'user_email*',
          value: file.user_email_address,
        })

        rows.push({
          id: rows.length + 1,
          key: 'user_key*',
          value: file.user_key,
        })

        rows.push({
          id: rows.length + 1,
          key: 'user_id*',
          value: file.user_id,
        })

        rows.push({
          id: rows.length + 1,
          key: 's3_location*',
          value: file.s3_location || constructS3Uri(file.s3_bucket, file.s3_key),
        })

      }

      setAvailableRows(rows);
  
    }
  }, [file])

  let columns = [];
  if (!isEmpty(file)) {
    columns = [
      {
        field: 'key',
        minWidth: '120px',
        flex: 1,
        display: 'flex',
      },
      {
        field: 'value',
        minWidth: '240 px',
        flex: 1,
      },
    ];
  }

  return (
    <Grid container id="files-get-one-filedetails">
      <Grid item xs={6} sx={{ my: 1 }}>
        <Typography><strong>File Details</strong></Typography>
      </Grid>
      <Grid item xs={6} sx={{ my: 1 }}>
        <AutoRefresh handler={handleGetFile} off={true} />
      </Grid>
      <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}>
        <DataGrid
          rows={availableRows}
          columns={columns}
          hideFooter={true}
          autoHeight={true}
          density={'compact'}
          customHeadRender={() => null }
          sx={
            {
              m: 1,
              // hide header
              "& .MuiDataGrid-columnHeaders": { display: "none" },
              "& .MuiDataGrid-virtualScroller": { marginTop: "0!important" },
              // highlight admin-visible rows
              "& .MuiDataGrid-row--admin-visible": {
                bgcolor: '#e0e0e0',
                '&:hover': {
                  bgcolor: '#d0d0d0',
                }
              }
            }
          }
          getRowClassName={(params) => {
            if (params.row.key.endsWith('*')) {
              return `MuiDataGrid-row--admin-visible`;
            }
          }}
        />
      </Grid>
    </Grid>
  );
}
