import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  AddShoppingCart as AddShoppingCartIcon,  
} from '@mui/icons-material';
import { isEmpty, merge } from '../../../lib/Utils';
import { uploadAnalyticsPaidSchema } from './config/uploadAnalyticsPaidSchema';
import UploadAnalyticsPaidConfirmationDialog from './UploadAnalyticsPaidConfirmationDialog';

export function UploadAnalyticsPaid(props) {

  const { 
    randomKey,
    upload_props,
    user_key,
  } = props;

  const {
    user_key: upload_user_key,
  } = upload_props;

  const initialState = {
    demographic_data: [],
    identity_data: [],
  };

  const [availableRows, setAvailableRows] = useState(initialState);
  const [checkedRowIDs, setCheckedRowIDs] = useState(initialState);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const { upload_analytics } = useSelector(state => state.data);
  const confirmRef = useRef(null);

  useEffect(() => {
    if (!isEmpty(upload_analytics)) {

      let demographic_data = [];
      if (!isEmpty(upload_analytics.demographic_data)) {

        // demographic_data - get mapping from the 'field' field config
        const demographic_data_mapping = uploadAnalyticsPaidSchema.demographic_data?.fields.find(x => x.field === 'field').mappings;

        demographic_data = Object.entries(demographic_data_mapping)
          .map(([key, value], id) => {

            const item = upload_analytics.demographic_data?.find(x => x.key === key) || {};
            const included_in = value.included_in;
            const display_name = value.display_name || item.name;
            const description = value.description || item.description;
            //const field = included_in ? ` > ${display_name}` : display_name;            
            const field = display_name;            

            return {
              ...item,
              id,
              field,
              key,
              description,
              included_in,
            }
          })
          .filter(x => x.field);
      }

      let identity_data = [];
      if (!isEmpty(upload_analytics.identity_data)) {

        // identity_data - get mapping from the 'field' field config
        const identity_data_mapping = uploadAnalyticsPaidSchema.identity_data?.fields.find(x => x.field === 'field').mappings;
      
        identity_data = identity_data_mapping && Object.entries(identity_data_mapping)
          .map(([key,value], id) => {
            const item = upload_analytics.identity_data?.find(x => x.key === key) || {};
            const included_in = value.included_in;
            const display_name = value.display_name || item.name;
            const description = value.description || item.description;
            const field = display_name;
              
            return {
              ...item,
              id,
              field,
              key,
              description,
              included_in,
            }
          })
          .filter(x => x?.field)
      }

      setAvailableRows({
        demographic_data,
        identity_data,
      });
    }
  }, [upload_analytics])

  const checkedRowsCount = Object.values(checkedRowIDs).reduce((acc, curr) => acc.concat(curr), []).length;

  const renderColumns = (section) => ({
    [section]: uploadAnalyticsPaidSchema[section]?.fields.map((x, i) => {
      if (x.field === 'field') {
        x.renderCell = (params) => {
          const { id, description, field, included_in } = params.row;
          const _description = included_in ? `${description}. Included in ${included_in.toUpperCase()}` : description;
          if (checkedRowIDs[section]?.includes(id)) {
            return (
              <Stack key={i} direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                <Tooltip title={_description} placement="right" arrow>
                  <Typography variant="inherit" noWrap sx={ included_in ? { pl: 3 } : {}}>{field}</Typography>
                </Tooltip>
                <Tooltip title={`Add all checked (${checkedRowsCount}) Analytics segments to Shopping Cart`} arrow>
                  <Button
                    onClick={handleConfirm}
                  >
                    <AddShoppingCartIcon
                      fontSize="small"
                      color="primary"
                    />
                  </Button>
                </Tooltip>
              </Stack>
            )
          }
          else {
            return (
              <Stack key={i} direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                <Tooltip title={_description} placement="right" arrow>
                  <Typography variant="inherit" noWrap sx={ included_in ? { pl: 3 } : {}}>{field}</Typography>
                </Tooltip>
              </Stack>
            )
          }
        };
      }
      return x;
    }),
  })

  const columns = {
    ...renderColumns('demographic_data'),
    ...renderColumns('identity_data'),
  }
 
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => 
    Object.fromEntries(
      Object.entries(merge({}, columns))
        .map(([key, value]) => (
          [
            key,
            value?.filter(x => x.hide === true)?.reduce((a, v) => ({ ...a, [v['field']]: false }), {})
          ]
        )
      )
    )
  );

  // filter out columns for 'mini' view
  const columnsMini = Object.fromEntries(
    Object.entries(merge({}, columns))
      .map(([k, v]) => {
        //delete button on the 'field' column
        v?.forEach(x => x.field === 'field' && delete x.renderCell);
        return [k, v?.filter(x => x.mini)];
      }
  ));

  const rowsMini = Object.fromEntries(
    Object.entries(merge({}, checkedRowIDs))
      .map(([k, v]) => [k, availableRows[k]?.filter(x => v.includes(x.id))])
  );

  const handleConfirm = () => {
    setOpenConfirmDialog(true);
  };

  const clearCheckedRowIDs = () => {
    setCheckedRowIDs(initialState);
  }

  return (
    <>
      <UploadAnalyticsPaidConfirmationDialog
        openDialog={openConfirmDialog}
        setOpenDialog={setOpenConfirmDialog}
        upload_props={upload_props}
        columns={columnsMini}
        rows={rowsMini}
        clearCheckedRowIDs={clearCheckedRowIDs}
      /> 
      <Grid container id={`upload-analytics-paid-${randomKey}`}>
        <Grid item xs={6} sx={{ my: 1 }}>
          <Typography><strong>Available Paid Analytics:</strong></Typography>
        </Grid>
        <>
          { Object.entries(uploadAnalyticsPaidSchema)
            .filter(([k,]) => !isEmpty(availableRows?.[k]))
            .map(([k, v], i) => (
              <Grid key={`upload-analytics-paid-${i}`} container>
                <Grid item xs={12} sx={{ m: 1 }}>
                  <Typography><strong>{v.name}</strong></Typography>
                </Grid>
                <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, mb: 1 }}>
                  <DataGrid
                    rows={availableRows[k]}
                    columns={columns[k]}
                    columnVisibilityModel={columnVisibilityModel[k]}
                    onColumnVisibilityModelChange={(x) => setColumnVisibilityModel(columnVisibilityModel[k] = x)}
                    hideFooter={true}
                    checkboxSelection={true}
                    disableRowSelectionOnClick={true}
                    autoHeight={true}
                    density={'compact'}
                    rowSelectionModel={checkedRowIDs[k]}
                    onRowSelectionModelChange={(selection) => {
                      const _selection = selection.filter(x => {
                        const section = availableRows[k];
                        const included_in = section.find(y => y.id === x)?.included_in;                     
                        if (included_in) {
                          const included_in_field_checked = selection.includes(section.find(x => x.key === included_in)?.id)
                          if (included_in_field_checked) return null;
                        }
                        return true;
                      })

                      setCheckedRowIDs(prev => {
                        // scroll to the button only on 1st checked item
                        if (checkedRowsCount === 0) confirmRef.current.scrollIntoView();

                        return {
                          ...prev,
                          [k]: _selection,
                        }
                      })
                    }}
                    isRowSelectable={(params) => {
                      const included_in = params.row.included_in;
                      if (upload_user_key && upload_user_key !== user_key) return false;
                      if (included_in) {
                        const included_in_field_checked = checkedRowIDs[k].includes(availableRows[k].find(x => x.key === included_in)?.id);
                        if (included_in_field_checked) return false;
                      }
                      return true;
                    }}
                    sx={
                      {
                        m: 1,
                        // hideSelectAllCheckbox only if viewed by admin
                        ...(upload_user_key && upload_user_key !== user_key && {
                          '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
                            display: 'none',
                          }
                        })
                      }
                    }
                  />
                </Grid>
              </Grid>
            ))
          }
          { checkedRowsCount !== 0 ?
            <Grid item xs={12}>
              <Tooltip title={`Add all checked (${checkedRowsCount}) Analytics segments to Shopping Cart`} arrow>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleConfirm}
                  endIcon={<AddShoppingCartIcon />}
                  sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}
                >
                  {"Add to Shopping Cart"}
                </Button>
              </Tooltip>
            </Grid>
            : null
          }
          <div ref={confirmRef}></div>
        </>
      </Grid>
    </>
  )
}
