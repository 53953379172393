import React from 'react';
import {
  Alert,
  Box,
  Paper,
  Grid,
  Link,
} from '@mui/material';
import {
  Email as EmailIcon,
} from '@mui/icons-material';

export default function RunFilterMessage() {

  return (
    <Grid container id="runs-get-all-run-filter-message">
      <Grid item xs={12}>
        <Alert
          sx={{ boxShadow: 10, borderRadius: 1, mt: 1 }}
          severity="info"
          variant="outlined"
          icon={<EmailIcon />}
        >
          <Box>
            <Link color="inherit" underline="always" href="https://www.bdex.com/talk-to-an-expert/?ref=filter.xaudience.omni.iq." target="_blank">Contact sales</Link>
            {` to filter your XAudience (Expanded Audience) by location or other criteria.`}
          </Box>
        </Alert>
      </Grid>
    </Grid>
  )
}
