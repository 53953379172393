import React from 'react';
import { Paper } from '@mui/material';
import NewFileAdditionalData from './NewFileAdditionalData';
import NewFilePreview from './NewFilePreview';
import NewFileSubmit from './NewFileSubmit';

export function FilesCreate() {
  return (
    <Paper id="files-create" sx={{ boxShadow: 10, borderRadius: 1, p: 2 }}>
      <NewFilePreview />
      <NewFileAdditionalData />
      <NewFileSubmit />
    </Paper>
  )
};
