import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Typography, Button, Alert, Grid } from '@mui/material';
import {
  CloudUpload as CloudUploadIcon,
}
from '@mui/icons-material';
import { isEmpty } from '../../../lib/Utils';
import { createFile } from '../../../actions/file';
import { setDataState } from '../../../actions/data';
import { ProgressBar } from '../../Common';

export default function NewFileSubmit() {

  const { file_new: file } = useSelector(state => state.data);
  const { load_progress } = file;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(() => {
    if (isEmpty(load_progress)) return false;
    return true;
  });

  const progressRef = useRef(null);

  const handleCreateFile = () => {
    setIsSubmitting(true);
    dispatch(createFile({ file }));
    progressRef.current.scrollIntoView();
  }

  useEffect(() => {
    if (load_progress?.progress === 1) {
      setIsSubmitting(false);
      dispatch(setDataState('file_new'));
      navigate('/files');
    }
  }, [load_progress]);

  return (
    <>
      { !isEmpty(file) ?
        <>
          <Button
            id="files-create-new-file-submit"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleCreateFile}
            disabled={isSubmitting}
            endIcon={<CloudUploadIcon />}
            sx={{ boxShadow: 10, borderRadius: 1, mt: 5 }}
          >
            {"Confirm Upload"}
          </Button>
          { load_progress ?
            <Grid container id="files-create-new-file-submit-progress">
              <Grid item xs={12} sx={{ my: 1 }}>
                <Typography><strong>New List: File Progress</strong></Typography>
              </Grid>
              <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}>
                { load_progress?.progress !== 1 ?
                  <Alert severity="warning" variant="outlined">
                    {"Uploading the File... Feel free to navigate from this page, but please do not close or refresh it"}
                  </Alert>
                  : null
                }
              </Grid>
              <Grid item xs={12} sx={{ m: 1 }}>
                <ProgressBar {...load_progress} />
              </Grid>
            </Grid>
            : null
          }
          <div ref={progressRef}></div>
        </>
        : null
      }
    </>
  );
}
