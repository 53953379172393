import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(2),
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    //color: theme.palette.primary.main,
  },
  submit: {
    margin: theme.spacing(4, 0, 1),
    // color: theme.palette.getContrastText(theme.palette.primary.main),
    // backgroundColor: theme.palette.primary.main,
    // '&:hover': {
    //   color: theme.palette.getContrastText(theme.palette.primary.light),
    //   backgroundColor: theme.palette.primary.light,
    // },
  },
  
}));

export default useStyles;
