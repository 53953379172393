import React from 'react'
import { Box, LinearProgress, Typography } from '@mui/material';
import { bytesDisplay, secondsDisplay, percentDisplay } from '../../lib/Utils';

export default function ProgressBar(props) {
  
  const { total, loaded, progress, rate, estimated } = props;

  const percentString = percentDisplay(progress);
  const loadedVsTotalString = `${bytesDisplay(loaded)}/${bytesDisplay(total)}`;
  const rateString = `${bytesDisplay(rate)}/s`;
  const estimatedString = secondsDisplay(estimated);
  const progressString = `${percentString} (${loadedVsTotalString}) at ${rateString}. Estimated time left: ${estimatedString}`;

  return (
    <Box alignItems="center">
      <Box>
        <LinearProgress variant="determinate" value={progress * 100} />
      </Box>
      <Box>
        <Typography variant="body2" color="textSecondary">{progressString}</Typography>
      </Box>
    </Box>
  );
};
