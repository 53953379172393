import React from 'react';
import { Paper } from '@mui/material';
import { PixelDetails } from './PixelDetails';
import { PixelVisits } from './PixelVisits';

export function PixelsGetOne() {

  return (
    <Paper id="pixels-get-one" sx={{ boxShadow: 10, borderRadius: 1, p: 2 }}>
      <PixelDetails />
      {/* <PixelVisits /> */}
    </Paper>
  )
}
