import React from 'react';
import Grid from '@mui/material/Grid';
import { Outlet } from 'react-router-dom';

export default function Content() {

  return (
    <Grid container>
      <Grid item xs={12}>
        <Outlet />
      </Grid>
    </Grid>
  );
}
