import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { getUpload } from '../../../actions/upload';
import { clearMessage } from '../../../actions/message';
import { isEmpty, formatNumber, bytesDisplay, constructS3Uri } from '../../../lib/Utils';
import { AutoRefresh } from '../../Common';

export function UploadDetails() {
    
  const { upload } = useSelector(state => state.data);
  const { user } = useSelector(state => state.auth);

  const dispatch = useDispatch();
  
  const params = useParams();
  const { upload_key } = params;

  const [searchParams] = useSearchParams();
  const user_key = searchParams.get('user_key');

  const [availableRows, setAvailableRows] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  const handleGetUpload = () => {
    dispatch(getUpload({
      upload_key,
      user_key,
    }));
  };

  // navigate to '/lists' if error 400 or 404 received
  const navigate = useNavigate();
  const { error, status } = useSelector(state => state.message);
  useEffect(() => {

    if (status === 404 || (status === 400 && error?.includes('User does not have a requested'))) {
      dispatch(clearMessage());
      navigate('/lists');
    }
  }, [error, status, dispatch, navigate]);
  
  // initial load
  useEffect(() => {

    // set 'upload' state
    handleGetUpload();

    // isAdmin?
    if (user.permissions?.resources?.includes('others.uploads')) {
      setIsAdmin(true);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(upload)) {
      const {
        counts = {},
        athena_tables = [],
      } = upload;

      const {
        uploaded_row_count,
        uploaded_valid_row_count,
        matched_row_count,
        matched_consumer_count,
        unique_uploaded_count,
        unactionable_counts,
        matched_row_identifiers_stats,
        matched_consumer_identifiers_stats,
      } = counts;

      const rows = [
        {
          id: 1,
          key: 'Name',
          value: upload.name,
        },
        {
          id: 2,
          key: 'Comments',
          value: upload.comments,
        },
        {
          id: 3,
          key: 'Created',
          value: upload.dt_created,
        },
        {
          id: 4,
          key: 'Last Updated',
          value: upload.dt_last_updated,
        },    
        {
          id: 5,
          key: 'Last Stage Description',
          value: upload.description,
        },
        {
          id: 6,
          key: 'Status',
          value: upload.status,
        },
        {
          id: 7,
          key: 'Format',
          value: upload.format,
        },
        {
          id: 8,
          key: 'File Size',
          value: bytesDisplay(upload.file_size),
        },
      ];

      if (isAdmin) {

        rows.push({
          id: rows.length + 1,
          key: 'upload_id*',
          value: upload.id,
        })

        rows.push({
          id: rows.length + 1,
          key: 'upload_key*',
          value: upload.key,
        })

        rows.push({
          id: rows.length + 1,
          key: 'user_email*',
          value: upload.user_email_address,
        })

        rows.push({
          id: rows.length + 1,
          key: 'user_key*',
          value: upload.user_key,
        })

        rows.push({
          id: rows.length + 1,
          key: 'user_id*',
          value: upload.user_id,
        })
        
        rows.push({
          id: rows.length + 1,
          key: 'delimiter*',
          value: upload.delimiter,
        })

        rows.push({
          id: rows.length + 1,
          key: 'headers_mapped_string*',
          value: upload.headers_mapped_string,
        })

        rows.push({
          id: rows.length + 1,
          key: 'header_present*',
          value: upload.header_index === 0 ? true : false,
        })

        rows.push({
          id: rows.length + 1,
          key: 's3_location*',
          value: upload.s3_location || constructS3Uri(upload.s3_bucket, upload.s3_key),
        })

        upload.preview && rows.push({
          id: rows.length + 1,
          key: 's3_preview*',
          value: upload.preview,
        })
      }

      if (uploaded_row_count) {

        uploaded_row_count >= 0 && rows.push({
          id: rows.length + 1,
          key: 'Uploaded Records',
          value: formatNumber(uploaded_row_count),
        })

        unique_uploaded_count >= 0 && rows.push({
          id: rows.length + 1,
          key: '> Uploaded Records (unique)',
          value: formatNumber(unique_uploaded_count),
        })

        if (!isEmpty(unactionable_counts)) {

          if (isAdmin) {
            rows.push({
              id: rows.length + 1,
              key: '> Uploaded Records (unactionable)*',
              value: formatNumber(Object.values(unactionable_counts).reduce((acc, curr) => acc + curr)),
            })

            rows.push({
              id: rows.length + 1,
              key: `> > Uploaded Records (invalid format)*`,
              value: formatNumber(unactionable_counts.invalid_format) || 0,
            })

            rows.push({
              id: rows.length + 1,
              key: `> > Uploaded Records (overlinked)*`,
              value: formatNumber(unactionable_counts.overlinked) || 0,
            })

            rows.push({
              id: rows.length + 1,
              key: `> > Uploaded Records (blacklisted)*`,
              value: formatNumber(unactionable_counts.blacklisted) || 0,
            })

          }
        }

        uploaded_valid_row_count >= 0 && rows.push({
          id: rows.length + 1,
          key: '> Uploaded Records (unique, valid format)',
          value: formatNumber(uploaded_valid_row_count),
        })

        matched_row_count >= 0 && rows.push({
          id: rows.length + 1,
          key: '> Matched Records',
          value: formatNumber(matched_row_count),
        })
      }

      if (isAdmin) {

        if (matched_row_identifiers_stats) {
          for (const [k, v] of Object.entries(matched_row_identifiers_stats)) {
            v.found_identifier_count >= 0 && rows.push({
              id: rows.length + 1,
              key: `> > Identifiers Linked to Found BDEX Consumers (${k})*`,
              value: formatNumber(v.found_identifier_count),
            })
            v.matched_consumer_count >= 0 && rows.push({
              id: rows.length + 1,
              key: `> > > Found BDEX Consumers*`,
              value: formatNumber(v.matched_consumer_count),
            })              
            v.matched_source_count >= 0 && rows.push({
              id: rows.length + 1,
              key: `> > > Matched Records*`,
              value: formatNumber(v.matched_source_count),
            })
          }
        }

        matched_consumer_count >= 0 && rows.push({
          id: rows.length + 1,
          key: '> Matched Consumers (1+ per Record)*',
          value: formatNumber(matched_consumer_count),
        })

        if (matched_consumer_identifiers_stats) {
          for (const [k, v] of Object.entries(matched_consumer_identifiers_stats)) {
            v.found_identifier_count >= 0 && rows.push({
              id: rows.length + 1,
              key: `> > Identifiers Linked to Found BDEX Consumers (${k})*`,
              value: formatNumber(v.found_identifier_count),
            })
            v.matched_consumer_count >= 0 && rows.push({
              id: rows.length + 1,
              key: `> > > Found BDEX Consumers*`,
              value: formatNumber(v.matched_consumer_count),
            })
            v.matched_source_count >= 0 && rows.push({
              id: rows.length + 1,
              key: `> > > Matched Records*`,
              value: formatNumber(v.matched_source_count),
            })
          }
        }

        for (const [k, v] of Object.entries(athena_tables)) {
          if (v) {
            rows.push({
              id: rows.length + 1,
              key: `athena_${k}*`,
              value: v,
            })
          }
        }
      }

      setAvailableRows(rows);

    }
  }, [upload])
  
  let columns = [];
  if (!isEmpty(upload)) {
    columns = [
      {
        field: 'key',
        minWidth: '120px',
        flex: 1,
        display: 'flex',
      },
      {
        field: 'value',
        minWidth: '240 px',
        flex: 1,
      },
    ];

    columns[columns.findIndex(x => x.field === 'key')].renderCell = (params) => {
      const { key } = params.row;
      const indentation_level = key.split('>').length - 1;
      return (
        <Box sx={{ pl: 3 * indentation_level }}>
          {key.replaceAll('>', '')}
        </Box>
      );
    }

    columns[columns.findIndex(x => x.field === 'value')].renderCell = (params) => {
      const { key, value } = params.row;
      if (key.includes('preview')) {
        return (
          <Box>
            <pre 
              style={{ 
                fontFamily: 'inherit',
                fontSize: 'inherit',
              }}
            >
              {value}
            </pre>
          </Box>
        );
      }
    }
  }
  
  return (
    <Grid container id="uploads-get-one-upload-details">
      <Grid item xs={6} sx={{ my: 1 }}>
        <Typography><strong>List Details</strong></Typography>
      </Grid>
      <Grid item xs={6} sx={{ my: 1 }}>
        <AutoRefresh handler={handleGetUpload} off={true} />
      </Grid>
      <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}>
        <DataGrid
          rows={availableRows}
          columns={columns}
          hideFooter={true}
          autoHeight={true}
          density={'compact'}
          customHeadRender={() => null }
          sx={
            {
              m: 1,
              // hide header
              "& .MuiDataGrid-columnHeaders": { display: "none" },
              "& .MuiDataGrid-virtualScroller": { marginTop: "0!important" },
              // highlight admin-visible rows
              "& .MuiDataGrid-row--admin-visible": {
                bgcolor: '#e0e0e0',
                '&:hover': {
                  bgcolor: '#d0d0d0',
                }
              },
            }
          }
          getRowClassName={(params) => {
            if (params.row.key.endsWith('*')) {
              return `MuiDataGrid-row--admin-visible`;
            }
          }}
          getRowHeight={(params) => {
            if (params.model.key.includes('preview')) {
              return 'auto';
            }
            return null;
          }}
        />
      </Grid>
    </Grid>
  );
}
