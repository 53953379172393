import React from 'react';
import { Paper } from '@mui/material';
import { FileHistory } from './FileHistory';
import { FileDetails } from './FileDetails';

export function FilesGetOne() {

  return (
    <Paper id="filed-get-one" sx={{ boxShadow: 10, borderRadius: 1, p: 2 }}>
      <FileDetails />
      <FileHistory embedded={true} />
    </Paper>
  )
}
