export const getItem = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

export const setItem = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const updateItem = (key, data) => {

  if (!getItem(key)) {
    setItem(key, data);
  }
  else {
    setItem(
      key,
      {
        ...getItem(key),
        ...data,
      }
    );
  }
};

export const removeItem = (key) => {
  localStorage.removeItem(key);
};
