import React from 'react';
import { Paper } from '@mui/material';
import { AudienceHistory } from './AudienceHistory';
import { AudienceAnalytics } from '../../Analytics';
import { AudienceDetails } from './AudienceDetails';

export function AudiencesGetOne() {

  return (
    <Paper id="audiences-get-one" sx={{ boxShadow: 10, borderRadius: 1, p: 2 }}>
      <AudienceDetails />
      {/* <AudienceAnalytics embedded={true} /> */}
      <AudienceHistory embedded={true} />
    </Paper>
  )
}
