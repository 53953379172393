import { sendHttpRequest } from '../lib/Utils';

export const createRun = async(kwargs) => {

  const { user_key, run } = kwargs;

  const response = await sendHttpRequest.post('/runs',
    { 
      data: {
        run: {
          ...run,
          description: 'Successfully finished stage: new_run_created',
        },
      },
    },
    { params: { user_key } },
  );
  return response;
};

export const getRuns = async () => {
  const response = await sendHttpRequest.get(`/runs`);
  return response;
};

export const getRun = async (kwargs) => {
  const { run_key, user_key } = kwargs;
  const response = await sendHttpRequest.get(`/runs/${run_key}`,
    { params: { user_key, history: true } },
  );
  return response;
};

export const getRunAnalytics = async (kwargs) => {
  const { run_key, user_key } = kwargs;
  const response = await sendHttpRequest.get(`/runs/${run_key}/analytics`, 
    { params: { user_key, price: true, price_schedule: true } },
  );
  return response;
};

export const deleteRun = async (kwargs) => {
  const { run_key, user_key } = kwargs;
  const response = await sendHttpRequest.delete(`/runs/${run_key}`,
    { params: { user_key } },
  );
  return response;
};
