import React from 'react'
import { Alert, Link, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { constructURLQueryString } from '../../../../lib/Utils';

export default function OrderPaymentNotification(props) {

  const { user_credit = {} } = props;

  const { user } = useSelector(state => state.auth);
  const queryString = constructURLQueryString(user, {
    utm_campaign: 'Omni%20IQ%20Pick%20a%20Plan%20and%20Upgrade',
    utm_source: 'website',
    utm_medium: 'pricing%20page',
    utm_content: 'pricing'
  });
  const url = `https://go.bdex.com/upgrade?${queryString}`;

  return (
    <>
    { user_credit.enterprise_user && user_credit.has_enough_credit ?
      <Alert
        sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}
        severity="info"
        variant="outlined"
      >
        <Typography variant="body2">
          <strong>You will not be charged for this Order as your Account has a sufficient prepaid Credit amount</strong>
        </Typography>
      </Alert>
    : user_credit.enterprise_user && !user_credit.has_enough_credit ?
      <Alert
        sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}
        severity="error"
        variant="outlined"
      >
        <Typography variant="body2">
          You have an unsufficient prepaid Credit amount on your Account.
        </Typography>
        <Typography variant="body2">
          {`Please `}
          <Link color="inherit" underline="always" href={url} target="_blank">contact your account manager</Link>
          {` for additional support.`}
        </Typography>
        <Typography variant="body2">
          As an alternative, you may continue and pay with a credit/debit card.
        </Typography>
      </Alert>
      : null
    }
    </>
  )
};
