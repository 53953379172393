import React, { useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useLocale } from '../../locale/LocaleContext';
import './FileSelector.scss';
export const FileSelector = ({ onSelected, fileSelectionOnly, }) => {
    const onSelectedRef = useRef(onSelected);
    onSelectedRef.current = onSelected;
    const dropHandler = useCallback((acceptedFiles) => {
        // silently ignore if nothing to do
        if (acceptedFiles.length < 1) {
            return;
        }
        const file = acceptedFiles[0];
        onSelectedRef.current(file);
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: dropHandler
    });
    const { defaultText, defaultTextSecondary, dragActiveText } = fileSelectionOnly ? useLocale('FileSelectorOnly') : useLocale('FileSelector');
    return (React.createElement("div", Object.assign({ className: "CSVImporter_FileSelector", "data-active": !!isDragActive }, getRootProps()),
        React.createElement("input", Object.assign({}, getInputProps())),
        isDragActive ? (React.createElement("span", null, dragActiveText)) : (React.createElement("span", null,
            React.createElement("div", null, defaultText),
            React.createElement("div", null, defaultTextSecondary)))));
};
