import React from 'react';
import { Paper } from '@mui/material';
import { NewPixelAdditionalData } from './NewPixelAdditionalData';

export function PixelsCreate() {
  return (
    <Paper id="pixel-create" sx={{ boxShadow: 10, borderRadius: 1, p: 2, mt: 1 }}>
      <NewPixelAdditionalData />
    </Paper>
  )
}
