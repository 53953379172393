import {
  DATA,
  SET_MESSAGE,
} from "./types";
import * as OrderService from "../services/order";
import { parseResponseError } from "../lib/Utils";

export const previewOrder = (kwargs) => async (dispatch) => {
  try {

    const response = await OrderService.previewOrder(kwargs);
    const { order_list } = response?.data?.data || {};
    const order = order_list?.[0];

    dispatch({
      type: DATA,
      payload: {
        order_preview: order,
      },
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const createOrder = (kwargs) => async (dispatch) => {
  try {

    const response = await OrderService.createOrder(kwargs);
    const { order_list } = response?.data?.data || {};
    const order = order_list?.[0];

    dispatch({
      type: DATA,
      payload: {
        order_new: order,
      },
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const createOrderUsingAccountCredit = (kwargs) => async (dispatch) => {
  try {

    const response = await OrderService.createOrderUsingAccountCredit(kwargs);
    const { order_list } = response?.data?.data || {};
    const order = order_list?.[0];

    dispatch({
      type: DATA,
      payload: {
        order_new: order,
      },
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const getOrders = () => async (dispatch) => {
  try {
    const response = await OrderService.getOrders();
    const { order_list } = response?.data?.data || {};

    dispatch({
      type: DATA,
      payload: {
        order_list,
      },
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const getOrder = (kwargs) => async (dispatch) => {
  try {
    const response = await OrderService.getOrder(kwargs);
    const { order_list } = response?.data?.data || {};
    const order = order_list?.[0];

    dispatch({
      type: DATA,
      payload: {
        order,
      },
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const getOrderFiles = (kwargs) => async (dispatch) => {
  try {
    const response = await OrderService.getOrderFiles(kwargs);

    // do not do anything if the file has been successfully downloaded
    
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const cancelOrder = (kwargs) => async (dispatch) => {
  try {

    const response = await OrderService.cancelOrder(kwargs);

    dispatch({
      type: DATA,
      payload: {
        order_new: {},
      },
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};
