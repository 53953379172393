import React, { useState, useEffect } from 'react';
import { Snackbar, Alert, Paper, Link, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { constructURLQueryString, isEmpty } from '../../lib/Utils';

export default function BdexSnackbar({ ...props }) {

  const initialSnackBarState = {
    open: false,
    type: null,
    data: null,
  };
  const [snackBar, setSnackBar] = useState(() => initialSnackBarState);
  const message = useSelector(state => state.message);
  const { user } = useSelector(state => state.auth);

  useEffect(() => {
    if (!isEmpty(message)) {
      
      // do not need the message.status anymore
      //if (message.status) delete message.status;
      
      const status = message.status;
      const type = Object.keys(message)[0];
      let data = Object.values(message)[0];
      
      // 429 - too many request, show a link to upgrade 
      if (status === 429) {
        const queryString = constructURLQueryString(user, {
          how_did_you_hear_about_us_: 'I am interested in upgrading my Omni IQ Account to increase a request limit',
        });

        const url = `https://www.bdex.com/pricing?${queryString}`;

        data = (
          <>
            {data}
            <hr />
            <Link href={url} color="inherit" underline="none" target="_blank">
              <Box>Request an increase to this limit</Box>
            </Link>
          </>
        )
      }

      setSnackBar({
        open: isEmpty(message) ? false : true,
        type,
        data,
      })
    }
    else {
      //hide the snackbar
      setSnackBar({ open: false })
    }
  }, [message]);

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackBar({
      ...snackBar,
      open: false,
    });
  };

  return (
    <Snackbar
      {...props}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={snackBar.open}
      autoHideDuration={5000}
      onClose={handleSnackBarClose}
      // TransitionComponent={<Slide direction="up" />}
    >
      <Paper 
        elevation={12}
        square={true}
        variant={'elevation'}
      >
        <Alert
          variant="filled"
          onClose={handleSnackBarClose}
          severity={snackBar.type}
        >
          {snackBar.data}
        </Alert>
      </Paper>
    </Snackbar>
  )
};
