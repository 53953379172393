import {
  DATA,
  SET_MESSAGE,
} from "./types";
import * as AudienceService from "../services/audience";
import { parseResponseError } from "../lib/Utils";

export const createAudience = (kwargs) => async (dispatch) => {
  try {

    const response = await AudienceService.createAudience(kwargs);

    dispatch({
      type: SET_MESSAGE,
      payload: {
        info: 'New Audience has been successfully created. Our data experts will be in touch with you when it is ready',
        status: '200',
      }
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const getAudiences = () => async (dispatch) => {
  try {
    const response = await AudienceService.getAudiences();
    const { audiences: audience_list } = response?.data?.data || {};

    dispatch({
      type: DATA,
      payload: {
        audience_list,
      },
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const getAudience = (kwargs) => async (dispatch) => {
  try {
    const response = await AudienceService.getAudience(kwargs);
    const { audiences: audience_list } = response?.data?.data || {};
    const audience = audience_list?.[0];

    dispatch({
      type: DATA,
      payload: {
        audience,
      },
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const getAudienceAnalytics = (kwargs) => async (dispatch) => {
  try {
    const response = await AudienceService.getAudienceAnalytics(kwargs);
    const { audience_list } = response?.data?.data || {};
    const { analytics_stats } = audience_list?.[0] || {};

    dispatch({
      type: DATA,
      payload: {
        audience_analytics: analytics_stats,
      },
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const deleteAudience = (kwargs) => async (dispatch) => {
  try {
    const response = await AudienceService.deleteAudience(kwargs);

    dispatch({
      type: SET_MESSAGE,
      payload: {
        info: 'Requested Audience has been permanently deleted',
        status: '200',
      }
    });

  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};
