import { LocalStorage } from '../lib';
import { sendHttpRequest } from '../lib/Utils';
import { store } from '../redux/store';

export const getUser = async (user_key) => {
  const response = await sendHttpRequest.get(`/users/${user_key}`);
  return response;
};

export const getCurrentUser = async () => {
  const user = LocalStorage.getItem('user');
  const response = await sendHttpRequest.get(`/users/${user?.key}`);
  return response;
};

export const getUsers = async () => {
  const response = await sendHttpRequest.get(`/users`);
  return response;
};

export const createUserFeedback = async(data) => {

  const { auth } = store.getState();
  const { user } = auth;

  const response = await sendHttpRequest.post(`/users/${user?.key}/feedback`, {
    data: {
      feedback: {
        ...data,
      },
    }
  });
  return response;
};

export const updateUser = async (kwargs) => {
  const { user_key, config } = kwargs;

  const response = await sendHttpRequest.put(`/users/${user_key}`, {
    data: {
      user: {
        config: config,
      },
    }
  });
  return response;
};
