import React from 'react';
import { Paper } from '@mui/material';
import { NewAudienceAdditionalData } from './NewAudienceAdditionalData';

export function AudiencesCreate() {
  return (
    <Paper id="audience-create" sx={{ boxShadow: 10, borderRadius: 1, p: 2 }}>
      <NewAudienceAdditionalData />
    </Paper>
  )
}
