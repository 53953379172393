import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid, Tooltip, Typography, Button, Stack, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  Close as CloseIcon,
  Check as CheckIcon,
  RecentActors as RecentActorsIcon,
} from '@mui/icons-material';
import { getPixels } from '../../../actions/pixel';
import { pixelListSchema } from '../config';
import { isEmpty, sortBy } from '../../../lib/Utils';
import PixelActions from './PixelActions';
import { AutoRefresh } from '../../Common';

export function PixelsList(props = {}) {

  const { checkboxes = true } = props;
  const [availableRows, setAvailableRows] = useState([]);
  const [checkedRowIDs, setCheckedRowIDs] = useState([]);
  const [checkedPixelKey, setCheckedPixelKey] = useState();
  const [checkedPixelUserKey, setCheckedPixelUserKey] = useState();
  const [checkedPixelKeyActive, setCheckedPixelKeyActive] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  // sort by date created desc, i.e., the latest created first
  const defaultSort = sortBy('dt_created', 'desc');

  const { pixel_list } = useSelector(state => state.data);
  const { user, context_user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  
  const handleGetPixels = () => {
    dispatch(getPixels());
  };

  // initial load
  useEffect(() => {

    // set 'pixel_list' state
    handleGetPixels();

    if (user.permissions?.resources?.includes('others.pixels')) {
      setIsAdmin(true);
    }
  }, []);
  
  // load when pixel_list is updated
  useEffect(() => {
    const _availableRows = pixel_list?.filter(x => x)?.sort(defaultSort);
    setAvailableRows(_availableRows);
  }, [pixel_list])

  // pixel.site_key - no id returned, so use site_key instaed
  useEffect(() => {
    const checkedPixel = availableRows?.filter(x => x.site_key === checkedRowIDs[0])?.[0];
    setCheckedPixelKey(checkedPixel?.site_key);
    setCheckedPixelUserKey(checkedPixel?.user_key);
    setCheckedPixelKeyActive(checkedPixel?.active);
  }, [availableRows, checkedRowIDs])
  
  let columns = [];
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    () => pixelListSchema.filter(x => x.hide === true).reduce((a, v) => ({ ...a, [v['field']]: false }), {})
  );

  const isUserKeyRequired = isAdmin && isEmpty(context_user);

  if (!isEmpty(pixel_list)) {
    columns = Object.keys(pixel_list[0]).map(x => {
      return {
        field: x,
        headerName: x,
      };
    });

    // set up from the config
    columns = pixelListSchema.filter(x => x.site_key === pixel_list?.site_key);

    // remove all admin-visible columns if not an admin
    if (!isAdmin) {
      columns = columns.filter(x => !x.onlyVisibleToAdmin);
    }
    
    // enhance 'site' column
    columns[columns.findIndex(x => x.field === 'site')].renderCell = (params) => {
      const { site, site_key, reporting_enabled, active, user_key } = params.row;

      return (
        // 'space-between'
        <Grid container direction="row" sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
          <Grid item xs={10} zeroMinWidth>
          {/* <Grid item xs={12} sm={12} md={10} zeroMinWidth> */}
            <Tooltip title={`See '${site}' Pixel Details`} arrow>
              <Button
                component={Link}
                to={{
                  pathname: `/pixels/${site_key}`,
                //   search: isUserKeyRequired && `user_key=${user_key}`,
                }}
                sx={{ width: '100%', justifyContent: 'flex-start' }}
                // sx={{ width: '100%', p: 1, m: -1, justifyContent: 'flex-start' }}
              >
                <Typography variant="inherit" noWrap>{site}</Typography>
              </Button>
            </Tooltip>
          </Grid>
          { active ?
            <Grid item xs={2} zeroMinWidth sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
              <Stack direction="row" spacing={0} sx={{ justifyContent: 'center' }}>
               <Tooltip title={`See Pixel Visits`} arrow>
                  <Button
                    component={Link}
                    to={{
                      pathname: `/pixels/${site_key}/visits`,
                      // search: isUserKeyRequired && `user_key=${user_key}`,
                    }}
                    sx={{ width: '100%', justifyContent: 'flex-end' }}
                  >
                    <Typography
                      variant="inherit"
                      noWrap
                      sx={{ mr: 1, display: 'flex' }}
                    >
                      See Pixel Visits
                    </Typography>
                    <RecentActorsIcon
                      fontSize="small"
                      color="primary"
                      sx={{ display: 'flex' }}
                    />
                  </Button>
                </Tooltip>
              </Stack>
            </Grid>
            : null
          }
        </Grid>
      );
    }

    // enhance 'active' column
    columns[columns.findIndex(x => x.field === 'active')].renderCell = (params) => {
      const { active } = params.row;

      return (
        <>
          { active ? 
            <CheckIcon color="primary" sx={{ fontSize: "small" }}/>
            : <CloseIcon color="error" sx={{ fontSize: "small" }} />
          }
        </>
      );
    }

  }

  return (
    <Grid container id="pixels-get-all-pixels-list">
      <Grid item xs={6} sx={{ my: 1 }}>
        <Typography><strong>Created Omni Pixels</strong></Typography>
      </Grid>
      <Grid item xs={6} sx={{ my: 1 }}>
        <AutoRefresh handler={handleGetPixels} />
      </Grid>
      <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}>
        <DataGrid
          rows={availableRows}
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(x) => setColumnVisibilityModel(x)}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10,50,100]}
          checkboxSelection={checkboxes ? true : false}
          disableRowSelectionOnClick={false}
          autoHeight={true}
          density={'compact'}
          getRowId={row => row.site_key}
          rowSelectionModel={checkedRowIDs}
          onRowSelectionModelChange={(selection) => {
            if (selection.length > 1) {
              const selectionSet = new Set(checkedRowIDs);
              const result = selection.filter(x => !selectionSet.has(x));
              setCheckedRowIDs(result);
            }
            else {
              setCheckedRowIDs(selection);
            }
          }}
          localeText={{
            footerRowSelected: (count) => <PixelActions pixel_key={checkedPixelKey} user_key={isUserKeyRequired && checkedPixelUserKey} active={checkedPixelKeyActive} />
          }}
          sx={{
            m: 1,
            // hideSelectAllCheckbox
            '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
              display: 'none',
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
