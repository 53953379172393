var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useMemo, useState, useEffect, useContext } from 'react';
import { FileStep } from './file-step/FileStep';
import { generatePreviewColumns } from './fields-step/ColumnPreview';
import { FieldsStep } from './fields-step/FieldsStep';
import { ProgressDisplay } from './ProgressDisplay';
import './Importer.scss';
import { LocaleContext } from '../locale/LocaleContext';
import { enUS } from '../locale';
const FieldDefinitionContext = React.createContext(null);
let fieldIdCount = 0;
// defines a field to be filled from file column during import
export const ImporterField = ({ name, label, optional }) => {
    // @todo this is not SSR-compatible
    const fieldId = useMemo(() => (fieldIdCount += 1), []);
    const fieldSetter = useContext(FieldDefinitionContext);
    // update central list as needed
    useEffect(() => {
        if (!fieldSetter) {
            console.error('importer field must be a child of importer'); // @todo
            return;
        }
        fieldSetter((prev) => {
            const newField = { id: fieldId, name, label, isOptional: !!optional };
            const copy = [...prev];
            const existingIndex = copy.findIndex((item) => item.name === name);
            // preserve existing array position if possible
            // @todo keep both copies in a map to deal with dynamic fields better
            if (existingIndex === -1) {
                copy.push(newField);
            }
            else {
                copy[existingIndex] = newField;
            }
            return copy;
        });
    }, [fieldId, fieldSetter, name, label, optional]);
    // on component unmount, remove this field from list by ID
    useEffect(() => {
        if (!fieldSetter) {
            console.error('importer field must be a child of importer'); // @todo
            return;
        }
        return () => {
            fieldSetter((prev) => {
                return prev.filter((field) => field.id !== fieldId);
            });
        };
    }, [fieldId, fieldSetter]);
    return null;
};
export function Importer(_a) {
    var { assumeNoHeaders, restartable, fileSelectionOnly, processChunk, onStart, onFileSelection, onComplete, onClose, children: content, locale } = _a, customPapaParseConfig = __rest(_a, ["assumeNoHeaders", "restartable", "fileSelectionOnly", "processChunk", "onStart", "onFileSelection", "onComplete", "onClose", "children", "locale"]);
    // helper to combine our displayed content and the user code that provides field definitions
    const [fields, setFields] = useState([]);
    const [fileState, setFileState] = useState(null);
    const [fileAccepted, setFileAccepted] = useState(false);
    const [fieldsState, setFieldsState] = useState(null);
    const [fieldsAccepted, setFieldsAccepted] = useState(false);
    // reset field assignments when file changes
    const activeFile = fileState && fileState.file;
    useEffect(() => {
        if (activeFile) {
            setFieldsState(null);
        }
    }, [activeFile]);
    const externalPreview = useMemo(() => {
        // generate stable externally-visible data objects
        const externalColumns = fileState &&
            generatePreviewColumns(fileState.firstRows, fileState.hasHeaders);
        return (fileState &&
            externalColumns && {
            rawData: fileState.firstChunk,
            columns: externalColumns,
            skipHeaders: !fileState.hasHeaders,
            parseWarning: fileState.parseWarning,
            meta: fileState.metadata,
        });
    }, [fileState]);
    // render provided child content that defines the fields
    const contentNodes = useMemo(() => {
        return typeof content === 'function'
            ? content({
                file: fileState && fileState.file,
                preview: externalPreview
            })
            : content;
    }, [fileState, externalPreview, content]);
    const contentWrap = (React.createElement(FieldDefinitionContext.Provider, { value: setFields }, contentNodes));
    // fall back to enUS if no default locale provided
    locale = locale !== null && locale !== void 0 ? locale : enUS;
    if (!fileAccepted || fileState === null || externalPreview === null) {
        return (React.createElement(LocaleContext.Provider, { value: locale },
            React.createElement("div", { className: "CSVImporter_Importer" },
                React.createElement(FileStep, { customConfig: customPapaParseConfig, assumeNoHeaders: assumeNoHeaders, fileSelectionOnly: fileSelectionOnly, prevState: fileState, onChange: (parsedPreview) => {
                        setFileState(parsedPreview);
                    }, onAccept: () => {
                        setFileAccepted(true);
                    }, onFileSelection: onFileSelection }),
                contentWrap)));
    }
    if (!fieldsAccepted || fieldsState === null) {
        return (React.createElement(LocaleContext.Provider, { value: locale },
            React.createElement("div", { className: "CSVImporter_Importer" },
                React.createElement(FieldsStep, { fileState: fileState, fields: fields, prevState: fieldsState, onChange: (state) => {
                        setFieldsState(state);
                    }, onAccept: () => {
                        setFieldsAccepted(true);
                    }, onCancel: () => {
                        // keep existing preview data and assignments
                        setFileAccepted(false);
                    } }),
                contentWrap)));
    }
    return (React.createElement(LocaleContext.Provider, { value: locale },
        React.createElement("div", { className: "CSVImporter_Importer" },
            React.createElement(ProgressDisplay, { fileState: fileState, fieldsState: fieldsState, externalPreview: externalPreview, processChunk: processChunk, onStart: onStart, onRestart: restartable
                    ? () => {
                        // reset all state
                        setFileState(null);
                        setFileAccepted(false);
                        setFieldsState(null);
                        setFieldsAccepted(false);
                    }
                    : undefined, onComplete: onComplete, onClose: onClose }),
            contentWrap)));
}
