import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { Grid, Paper, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { getRun } from '../../../actions/run';
import { isEmpty, sortBy } from '../../../lib/Utils';
import { runHistorySchema } from '../config';
import { AutoRefresh, DataGridStatusColumn } from '../../Common';

export function RunHistory(props) {

  const { embedded } = props;
  
  // descending by id desc, i.e. latest first
  const defaultSort = sortBy('id', 'desc');
  
  const { run } = useSelector(state => state.data);
  
  const dispatch = useDispatch();

  const params = useParams();
  const { run_key } = params;

  const [searchParams] = useSearchParams();
  const user_key = searchParams.get('user_key');

  const [availableRows, setAvailableRows] = useState([]);

  const handleGetRun = () => {
    dispatch(getRun({
      run_key,
      user_key,
    }));
  };

  // initial load
  useEffect(() => {

    // set 'run' state
    handleGetRun();
  }, []);

  useEffect(() => {
    if (!isEmpty(run)) {
      const { history = [] } = run;
      const _availableRows = history.filter(x => x).sort(defaultSort);
      setAvailableRows(_availableRows);
    }
  }, [run])

  let columns = [];
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    () => runHistorySchema.filter(x => x.hide === true).reduce((a, v) => ({ ...a, [v['field']]: false }), {})
  );

  if (!isEmpty(run)) {
    const { history } = run;
    if (!isEmpty(history)) {      
      columns = Object.keys(history[0]).map(x => {
        return {
          field: x,
          headerName: x,
        };
      });
  
      // set up from the config
      columns = runHistorySchema.filter(x => x.id === history.id);
  
      // enhance 'status' column
      columns[columns.findIndex(x => x.field === 'status')].renderCell = (params) => {        
        return (
          <DataGridStatusColumn params={params} type={"runs"} />
        );
      }

    }
  }

  const component = (
    <Grid container id="runs-get-one-run-history">
      <Grid item xs={6} sx={{ my: 1 }}>
        <Typography><strong>XAudience History</strong></Typography>
      </Grid>
      <Grid item xs={6} sx={{ my: 1 }}>
        <AutoRefresh handler={handleGetRun} off={true} />
      </Grid>
      <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, mt: 1 }}>
        <DataGrid
          rows={availableRows}
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(x) => setColumnVisibilityModel(x)}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10,50,100]}
          checkboxSelection={false}
          disableRowSelectionOnClick={true}
          autoHeight={true}
          density={'compact'}
          sx={{ m: 1 }}
        />
      </Grid>
    </Grid>
  );

  return (
    <>
      { !embedded ?
        <Paper id="upload-history" sx={{ boxShadow: 10, borderRadius: 1, p: 2 }}>
          {component}
        </Paper>
        : component
      }
    </>
  );
}
