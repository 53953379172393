import React from 'react';
import { Paper } from '@mui/material';
import { PixelsList } from './PixelsList';
import { PixelsCreate } from '../Create';

export function PixelsGetAll(props) {
  return (
    <>
      <Paper id="pixels-get-all" sx={{ boxShadow: 10, borderRadius: 1, p: 2 }}>
        <PixelsList {...props} />
      </Paper>
      <PixelsCreate />
    </>
  )
};
