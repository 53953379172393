import React, { useState } from 'react';
import { Alert, AlertTitle, Box, Grid, TextField, Button, Dialog, DialogActions, useTheme, useMediaQuery } from '@mui/material';

import {
  Delete as DeleteIcon,
  Refresh as RefreshIcon,
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { deleteAudience } from '../../../actions/audience';

export default function DeleteConfirmationDialog(props) {

  const { openDialog, setOpenDialog, audience_key, user_key } = props;
  const dispatch = useDispatch();

  const [ data, setData ] = useState('');

  const deleteCode = `delete ${audience_key?.substring(0,6)}`;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleDataChange = (event) => {
    const { value } = event.target;
    setData(value);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setData('');
  };

  const handleDelete = () => {
    handleClose();
    dispatch(deleteAudience({
      audience_key,
      user_key,
    }));
  }

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="audience-delete-confirmation"        
      >
        <Grid container id="audience-delete-confirmation">
          <Grid item xs={12}>
            <Alert
              icon={<DeleteIcon fontSize="inherit" />} 
              severity="error"
              variant="outlined"
              sx={{ boxShadow: 10, borderRadius: 1, m: 1, p: 2, pb: 0 }}
            >
              <AlertTitle>
                <strong>Permanently delete this Audience?</strong>
              </AlertTitle>
              <Box sx={{ mt: 1 }}>
                You will no longer have access to a permanently deleted Audience. There is no option to undo this operation.
              </Box>
              <Box sx={{ mt: 1 }}>
                Please confirm the Audience deletion by typing: <strong>{deleteCode}</strong>
              </Box>
              <TextField
                focused={true}
                type="text"
                name="delete_code"
                placeholder={deleteCode}
                variant="standard"
                size='small'
                margin='normal'
                fullWidth
                required={false}
                multiline={false}
                rows={1}
                value={data}
                onChange={handleDataChange}
                color="error"
                sx={{ pr: 1 }}
              />
              <DialogActions>
                <Button
                  type="submit"
                  variant="outlined"
                  color="error"
                  onClick={handleClose}
                  startIcon={<RefreshIcon />}
                  sx={{ boxShadow: 10 }}
                >
                  {"Cancel"}
                </Button>
                <Button
                  type="submit"
                  variant="outlined"
                  color="error"
                  onClick={handleDelete}
                  startIcon={<DeleteIcon />}
                  sx={{ boxShadow: 10 }}
                  disabled={data === deleteCode ? false : true}
                >
                  {"Delete Audience"}
                </Button>
              </DialogActions>
            </Alert>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
