import { dataGridColumns } from "../../../lib/Utils";

export const pixelVisitsSchema = [
  {
    field: "last_date_of_visit",
    headerName: "Date",
    editable: false,
    sortable: true,
    hide: false,
    flex: 120,
  },
  {
    field: "first_name",
    headerName: "First Name",
    editable: false,
    sortable: true,
    hide: false,
    flex: 80,
  },
  {
    field: "last_name",
    headerName: "Last Name",
    editable: false,
    sortable: true,
    hide: false,
    flex: 80,
  },
  {
    field: "address1",
    headerName: "Address1",
    editable: false,
    sortable: true,
    hide: false,
    flex: 120,
  },
  {
    field: "address2",
    headerName: "Address2",
    editable: false,
    sortable: true,
    hide: false,
    flex: 60,
  },
  {
    field: "city",
    headerName: "City",
    editable: false,
    sortable: true,
    hide: false,
    flex: 80,
  },
  {
    field: "state",
    headerName: "State",
    editable: false,
    sortable: true,
    hide: false,
    flex: 20,
  },
  {
    field: "postal_code",
    headerName: "Postal Code",
    editable: false,
    sortable: true,
    hide: false,
    flex: 50,
  },
  {
    field: "email_address",
    headerName: "email_address",
    editable: false,
    sortable: true,
    hide: false,
    flex: 80,
  },
  {
    field: "ip_address",
    headerName: "IP Address",
    editable: false,
    sortable: true,
    hide: false,
    flex: 70,
  },
  {
    field: "phone_1",
    headerName: "Phone1",
    editable: false,
    sortable: true,
    hide: true,
    flex: 80,
    ...dataGridColumns.display_phone,
  },
  {
    field: "phone_2",
    headerName: "Phone2",
    editable: false,
    sortable: true,
    hide: true,
    flex: 80,
    ...dataGridColumns.display_phone,
  },
  {
    field: "phone_3",
    headerName: "Phone3",
    editable: false,
    sortable: true,
    hide: true,
    flex: 80,
    ...dataGridColumns.display_phone,
  },
];
