import React from 'react';

export default function BdexLogoWhite() {
  return (
    <img
      width="100%"
      alt="bdex"
      src="../../../../logos/bdex_logo_white.png"
    />
  )
};
