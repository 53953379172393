import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box, Tooltip, Typography, Stack } from '@mui/material';
import { getUsers } from '../../../actions/user';
import { DataGrid } from '@mui/x-data-grid';
import { userListSchema } from '../config';
import {
  Login as LoginIcon,
} from '@mui/icons-material';
import { isEmpty, sortBy } from '../../../lib/Utils';
import { loginContextUser } from '../../../actions/auth';
import UserActions from './UserActions';

export function UsersGetAll(props = {}) {

  const { checkboxes = true } = props;

  // descending by id, i.e. latest first
  const defaultSort = sortBy('id', 'asc');

  const { user_list } = useSelector(state => state.data);
  const { user, context_user } = useSelector(state => state.auth);

  const dispatch = useDispatch();

  const handleGetUsers = () => {
    dispatch(getUsers());
  };

  const [availableRows, setAvailableRows] = useState([]);
  const [checkedRowIDs, setCheckedRowIDs] = useState([]);
  const [checkedUserObject, setCheckedUserObject] = useState();
  const [isAdmin, setIsAdmin] = useState();

  // initial load
  useEffect(() => {

    // admin?
    if (user.permissions?.resources?.includes('others.users')) {

      setIsAdmin(true);

      if (!context_user) {

        // set 'user_list' state
        handleGetUsers();
      }
    }

  }, [])

  // user_list was updated
  useEffect(() => {
    const _availableRows = user_list.filter(x=>x).sort(defaultSort);
    setAvailableRows(_availableRows);
  }, [user_list])

  // context_user
  useEffect(() => {
    const context_user = availableRows.filter(x => x.id === checkedRowIDs[0])?.[0] || {};
    const { id, key, email_address } = context_user;
    setCheckedUserObject({ id, key, email_address });
  }, [checkedRowIDs])

  let columns = [];
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    () => userListSchema.filter(x => x.hide === true).reduce((a, v) => ({ ...a, [v['field']]: false }), {})
  );

  if (!isEmpty(user_list)) {
    columns = Object.keys(user_list[0]).map(x => {
      return {
        field: x,
        headerName: x,
      };
    });

    // set up from the config
    columns = userListSchema.filter(x => x.id === user_list?.id);

    // show admin visible columns
    if (isAdmin) {
      columns = columns.filter(x => x.onlyVisibleToAdmin);
    }

    // enhance 'id' column
    columns[columns.findIndex(x => x.field === 'id')].renderCell = (params) => {
      const { id, key, email_address } = params.row;
      
      return (
        <Grid container direction="row" sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
          <Grid item xs={10} zeroMinWidth>
            <Typography variant="inherit" noWrap>{id}</Typography>
          </Grid>

          {/* login as */}
          { user.id !== id ?
            <Grid item xs={2} zeroMinWidth sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
              <Stack direction="row" spacing={0} sx={{ justifyContent: 'center' }}>
                <Tooltip title={`Login as ${email_address}`} arrow>
                  <LoginIcon
                    fontSize="small"
                    color="primary"
                    onClick={() => dispatch(loginContextUser({ id, key, email_address }))}
                    sx={{ '&:hover': { cursor: 'pointer'}, display: 'flex' }}
                  />
                </Tooltip>
              </Stack>
            </Grid>
            : null
          }
        </Grid>
      )
    }
    
  }

  const handleRowSelectable = (params) => {
    return true
    // 'disable' self
    // return user.id === params.row.id ? false : true;
  };

  return (
    <>
      { isAdmin && !context_user ?
        <Grid container id="users-get-all">
          <Grid item xs={6} sx={{ my: 1 }}>
            <Typography><strong>Registered Users</strong></Typography>
          </Grid>
          <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, mt: 1 }}>
            <DataGrid
              rows={availableRows}
              columns={columns}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(x) => setColumnVisibilityModel(x)}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10,50,100]}
              checkboxSelection={checkboxes ? true : false}
              disableRowSelectionOnClick={false}
              autoHeight={true}
              density={'compact'}
              rowSelectionModel={checkedRowIDs}
              onRowSelectionModelChange={(selection) => {
                if (selection.length > 1) {
                  const selectionSet = new Set(checkedRowIDs);
                  const result = selection.filter(x => !selectionSet.has(x));
                  setCheckedRowIDs(result);
                }
                else {
                  setCheckedRowIDs(selection);
                }
              }}
              localeText={
                checkboxes ? { footerRowSelected: (count) => <UserActions context_user={checkedUserObject} /> }
                : null
              }
              isRowSelectable={handleRowSelectable}
              sx={{
                m: 1,
                // hideSelectAllCheckbox
                '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
                  display: 'none',
                },
              }}
            />
          </Grid>
        </Grid>
        : null
      }
    </>
  );
}
