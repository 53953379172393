import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Grid, Typography, List, ListItem, ListItemText, IconButton, Box, Tooltip, Stack, DialogActions, Button, Alert, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  ContentCopy as ContentCopyIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  ChevronRight as ChevronRightIcon,
  Close as CloseIcon,
  Check as CheckIcon,
  RecentActors as RecentActorsIcon,  
} from '@mui/icons-material';
import { getPixel, updatePixel } from '../../../actions/pixel';
import { clearMessage, setMessage } from '../../../actions/message';
import { isEmpty, mergeDeep } from '../../../lib/Utils';
import { AutoRefresh } from '../../Common';

export function PixelDetails() {

  const pixelOptionalParametersInitial = {
    quality_filter: "",
    customer: "",
    page: "",
    session: "",
    subdomain: "",
  };

  const { pixel } = useSelector(state => state.data);
  const { user } = useSelector(state => state.auth);
  const { error, status } = useSelector(state => state.message);

  const dispatch = useDispatch();
  
  const params = useParams();
  const { pixel_key } = params;

  const [searchParams] = useSearchParams();
  const user_key = searchParams.get('user_key');

  const [availableRows, setAvailableRows] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [pixelSettings, setPixelSettings] = useState({});
  const [pixelOptionalParameters, setPixelOptionalParameters] = useState({});

  const handleSaveSettings = () => {

    dispatch(updatePixel({
      pixel_key,
      pixel: pixelSettings,
    }));

    // reset pixelSettings
    setPixelSettings({});
  };

  const handleCopy = (content) => {
    navigator.clipboard.writeText(content);
    dispatch(setMessage({
      info: "Copied to clipboard! Paste this inside the <head> html tag on your website"
    }));
  };

  const handleGetPixel = () => {
    dispatch(getPixel({
      pixel_key,
      user_key,
    }));
  };

  const handleDataChange = (event) => {
    const { name, value } = event.target;

    setPixelOptionalParameters(prev => {
      const data = {
        ...prev,
        [name]: value,
      };
      return data;
    })
  };
  
  // navigate to '/pixels' if error 400 or 404 received
  const navigate = useNavigate();

  useEffect(() => {
    if (error && [400,404].includes(status)) {
      if (error.includes('User does not have a requested')) {
        navigate('/pixels');
        dispatch(clearMessage());
      }
      else {
        handleGetPixel();
      }
    }
  }, [error, status, dispatch, navigate]);

  // initial load
  useEffect(() => {

    // set 'pixel' state
    handleGetPixel();

    // isAdmin?
    if (user.permissions?.resources?.includes('others.pixels')) {
      setIsAdmin(true);
    }
  }, []);
  
  useEffect(() => {

    if (!isEmpty(pixel)) {

      const rows = [
        {
          id: 1,
          key: 'Website description',
          field: 'site',
          value: pixel.site,
          description: `Description of the website (domain) where the pixel will be installed to. You may update the value by double clicking on it`,
          type: 'string',
          editable: true,
        },
        {
          id: 2,
          key: 'site_key*',
          value: pixel.site_key,
        },
        {
          id: 3,
          key: 'client_key*',
          value: pixel.client_key,
        },
        {
          id: 4,
          key: 'email_address',
          field: 'email_address',
          value: pixel.email_address,
          description: `Email address where the website visitor report will be sent. You may update the value by double clicking on it`,
          type: 'string',
          editable: true,
        },        
        {
          id: 5,
          key: 'Created',
          value: pixel.date_created,
        },
      ];

      if (pixel.active) {

        // do not return pixel urls
        // rows.push({
        //   id: rows.length + 1,
        //   key: 'Available Pixels',
        //   value: pixel.pixel_urls,
        // });

        rows.push({
          id: rows.length + 1,
          key: 'Pixel Script',
          value: pixel.pixel_script_text,
        });
      }

      rows.push({
        id: rows.length + 1,
        key: 'Active',
        value: pixel.active,
      });

      if (pixel.active) {
        rows.push({
          id: rows.length + 1,
          key: 'Pixel Visits',
        });
      }

      setAvailableRows(rows);
    }
  }, [pixel])
  
  const queryParams = Object.entries(pixelOptionalParameters)
    ?.filter(x => x.at(1) && x.at(1) !== '')
    ?.map(x => `, pixel_kwargs.${x.at(0)}='${x.at(1)}'`)
    ?.join('');

  const pixel_script_text_updated = pixel.pixel_script_text
    ?.replace(/^(.*)\,( function\(\)\{var e=document\.createElement\('script'\).*)$/, `$1${queryParams},$2`);

  let columns = [];
  if (!isEmpty(pixel)) {

    columns = [
      {
        field: 'key',
        minWidth: '120px',
        flex: 1,
        display: 'flex',
      },
      {
        field: 'value',
        minWidth: '240 px',
        flex: 1,
        editable: true,
        display: 'flex',
      },
    ];

    columns[columns.findIndex(x => x.field === 'key')].renderCell = (params) => {
      const { key, description, editable, editable_icon } = params.row;
      if (editable || editable_icon) {
        return (
          <Tooltip
            title={
              <Stack spacing={1} justifyContent="space-between" alignItems="center">
                <Box>{description}</Box>
              </Stack>
            }
            arrow
          >
            <Box>
              {key}
              <EditIcon color="primary" sx={{ fontSize: "small", ml: 1 }} />
            </Box>
          </Tooltip>
        )
      }
    }

    columns[columns.findIndex(x => x.field === 'value')].renderCell = (params) => {
      
      const { key, value } = params.row;

      if (key.includes('Pixel Script')) {
        return (
          <Grid container>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Alert severity="info" variant="outlined">
                <Box>
                    <strong>Note!</strong> The script will not work as designed if modified manually
                  </Box>
                <Box>
                  {"Copy the code snippet and paste it inside the <head> html tag on your website:"}
                </Box>
              </Alert>
            </Grid>
            <Grid item xs={12} sx={{ mr:2, mt: 2 }}>
              <List>
                <ListItem
                  disableGutters
                  secondaryAction={
                    <IconButton aria-label="copy" onClick={e => handleCopy(pixel_script_text_updated)}>
                      <ContentCopyIcon color="primary" sx={{ fontSize: "medium" }} />
                    </IconButton>
                  }
                >
                  <ListItemText primary={pixel_script_text_updated} />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        );
      }

      if (key.includes('Active')) {
        return (
          <>
            { value ? 
              <CheckIcon color="primary" sx={{ fontSize: "small" }}/>
              : <CloseIcon color="error" sx={{ fontSize: "small" }} />
            }
          </>
        );
      }

      if (key.includes('Pixel Visits')) {
        return (
          <Stack direction="row" spacing={0} sx={{ justifyContent: 'center' }}>
            <Tooltip title={`See Pixel Visits`} arrow>
              <Box
                component={Link}
                to={{
                  pathname: `/pixels/${pixel.site_key}/visits`,
                  // search: isUserKeyRequired && `user_key=${user_key}`,
                }}
              >
                <RecentActorsIcon
                  // fontSize="medium"
                  color="primary"
                  sx={{ display: 'flex' }}
                />
              </Box>
            </Tooltip>
          </Stack>
        )
      }

    }
  }

  return (
    <>
      <Grid container id="pixels-get-one-pixel-details">
        <Grid item xs={6} sx={{ my: 1 }}>
          <Typography><strong>Pixel Details</strong></Typography>
        </Grid>
        <Grid item xs={6} sx={{ my: 1 }}>
          <AutoRefresh handler={handleGetPixel} off={true} />
        </Grid>
        <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}>
          <DataGrid
            rows={availableRows}
            columns={columns}
            hideFooter={true}
            autoHeight={true}
            density={'compact'}
            customHeadRender={() => null }
            processRowUpdate={(updatedRow, originalRow) => {

              // (updatedRow.key === originalRow.key)
              if (updatedRow.value !== originalRow.value) {
                setPixelSettings((prev) => mergeDeep(
                  prev,
                  {
                    [updatedRow.field]: updatedRow.value,
                  },
                ));
              }

              // update the available rows for datagrid ui
              const updatedRows = availableRows?.map(x => x.id === originalRow.id ? updatedRow : x);
              setAvailableRows(updatedRows);

              return updatedRow;
            }}
            onProcessRowUpdateError={(err) => {
              console.log(err);
            }}
            isCellEditable={(params) => {
              if (params.row.editable) return true;
            }}
            sx={
              {
                m: 1,
                // hide header
                "& .MuiDataGrid-columnHeaders": { display: "none" },
                "& .MuiDataGrid-virtualScroller": { marginTop: "0!important" },
                // highlight admin-visible rows
                "& .MuiDataGrid-row--admin-visible": {
                  bgcolor: '#e0e0e0',
                  '&:hover': {
                    bgcolor: '#d0d0d0',
                  }
                }
              }
            }
            getRowClassName={(params) => {
              if (params.row.key.endsWith('*')) {
                return `MuiDataGrid-row--admin-visible`;
              }
            }}
            getRowHeight={(params) => {
              if (params.model.key.includes('Pixel')) {
                return 'auto';
              }
              return 'null';
            }}
          />
        </Grid>
        <DialogActions
          sx={{ 
            mt: 2,
            alignItems: "end",
            justifyContent: "flex-end"
          }}
        >
          <Button
            type="submit"
            variant="outlined"
            color="info"
            onClick={handleSaveSettings}
            startIcon={<SaveIcon />}
            sx={{ boxShadow: 10 }}
            disabled={isEmpty(pixelSettings)}
          >
            {"Update settings"}
          </Button>
        </DialogActions>
      </Grid>

      { pixel.active ?
        <Grid container>
          <Grid item xs={12} sx={{ m: 1, pt: 3 }}>
            <Typography>(optional) Update Pixel Scipt with additional parameters:</Typography>
          </Grid>
          <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}>
            <Alert severity="info" variant="outlined">
              <Box>
                You may add any additional parameters to your Pixel script which can be used for customization on your side, e.g., to track a specific page, or a subdomain on your website.
              </Box>
              <Box>
                The <strong>Pixel Script</strong> field above will be updated according to your entries.
              </Box>
              <List dense>
                The <strong>quality_filter</strong> parameter allows you to control the quality of the data during the identity resolution. Supported values are:
                <ListItem>
                  <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                    <Typography variant='inherit'>{"0.7"}</Typography>
                    <ChevronRightIcon />
                    <Typography variant='inherit'>{"Precision - Supports use cases that require the highest levels of accuracy and precision. For example, personalized offers"}</Typography>
                  </Stack>
                </ListItem>
                <ListItem>
                  <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                    <Typography variant='inherit'>{"0.3"}</Typography>
                    <ChevronRightIcon />
                    <Typography variant='inherit'>{"(default value) - Balanced - Supports a range of targeting and measurement use cases. For example, campaign attribution or retargeting"}</Typography>
                  </Stack>
                </ListItem>
                <ListItem>
                  <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                    <Typography variant='inherit'>{"0.1"}</Typography>
                    <ChevronRightIcon />
                    <Typography variant='inherit'>{"Maximum reach - Supports use cases that favor reach. For example, targeting mid-funnel customer segments. Some users and devices within the same household may also be resolved to the same identity"}</Typography>
                  </Stack>
                </ListItem>
              </List>
              <Box>
                Use the predefined parameters below, or create your own key-value pair(s). If you have any questions, please reach out to us and we will gladly help you.
              </Box>
            </Alert>
          </Grid>
          { Object.keys(pixelOptionalParametersInitial)?.map(key => (
            <Grid item xs={12} sx={{ mx: 1 }} key={key}>
              <TextField
                type="text"
                name={key}
                value={pixelOptionalParameters[key] || pixelOptionalParametersInitial[key]}
                variant="standard"
                size='small'
                margin='normal'
                label={key}
                required={false}
                multiline={false}
                rows={1}
                onChange={handleDataChange}
              />
            </Grid>
          ))}
        </Grid>
        : null
      }

      {/* [TODO] ? last 20 results preview  */}

    </>
  );
}
