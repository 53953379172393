import {
  DATA,
  CLEAR_DATA,
} from "./types";

export const setDataState = (key, data = {}) => (dispatch) => {
  dispatch({
    type: DATA,
    payload: {
      [key]: data,
    },
  });
};

export const clearData = () => ({
  type: CLEAR_DATA,
});
