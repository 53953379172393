import React from 'react';
import { Paper } from '@mui/material';
import UploadType from '../../Uploads/Create/UploadType';
import { UploadsList } from '../../Uploads';
import { NewRunAdditionalData } from './NewRunAdditionalData';

export function RunsCreate() {
  return (
    <Paper id="runs-create" sx={{ boxShadow: 10, borderRadius: 1, p: 2 }}>
      <UploadType />
      <NewRunAdditionalData />
      <UploadsList newRun={true} />
    </Paper>
  )
}
