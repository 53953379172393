import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { uploadFormats } from '../config/uploadFormats';
import { Importer, ImporterField } from 'react-csv-importer';
import './NewUploadPreview.css';
import { isEmpty, prepareString } from '../../../lib/Utils';
import { setDataState } from '../../../actions/data';
import { setMessage } from '../../../actions/message';

export default function NewUploadPreview() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  // upload format (schema)
  const { upload_new: upload } = useSelector(state => state.data);
  const { upload_format } = upload;

  const [importerFields, setImportedFields] = useState(null);
  useEffect(() => {
    if (upload_format) {
      const fields = uploadFormats.find(e => e.id === upload_format)?.children.map(e => {
        return (
          <ImporterField key={e.id} name={e.id} label={e.id} optional={!e.required}/>
        )
      })

      setImportedFields(fields)
    }
  }, [upload_format])

  return (
    <Grid container id="uploads-create-new-upload-preview">
      <Grid item xs={12} sx={{ my: 1 }}>
        <Typography><strong>New List: Preview and Column Mapping</strong></Typography>
      </Grid>
      <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, m: 1 }}>
        <Importer
          preview={-1}  // do not parse the csv at all
          assumeNoHeaders={true} // optional, keeps "data has headers" checkbox off by default
          restartable={true} // optional, lets user choose to upload another file when import is complete
          onStart={({ file, preview, fields, columnFields }) => {      
            const rawColumnFields = preview.columns?.map(e => e.header)
            let mappedColumnFields = rawColumnFields.map((e, i) => columnFields[i] || prepareString(e) || `column_${i+1}`)
            
            // rename duplicated columns
            mappedColumnFields = mappedColumnFields.map((e,i) => {
              return mappedColumnFields.filter(x => x === e).length > 1 ? `${e}_${i}` : e;
            })
            
            const { meta, rawData, skipHeaders } = preview;
            const { delimiter, linebreak } = meta;

            if (!isEmpty(file)) {

              let { size, type, name } = file;

              if (size > 5 * 1024 * 1024 * 1024) {
                dispatch(setMessage({
                  error: `Error! File size can not exceed 5 GB. Please upload a smaller file, or do it in parts`,
                  status: 400,
                }));
                dispatch(setDataState('upload_new'));
                navigate('/lists');
              }

              // check type, that it is a csv?
              // if (type === '') {
              //   ...
              // }

              const rawDataPreview = `${rawData.substring(0,400)}...`;

              dispatch(setDataState('upload_new', {
                ...upload,
                // File object reference
                file,
                file_name: name,
                file_size: size,
                file_type: type,
                number_of_rows: null,
                header_index: skipHeaders ? null : 0, //only support 1 header row, for now
                headers_raw_string: rawColumnFields.join(delimiter),
                headers_mapped_string: mappedColumnFields.join(delimiter),
                delimiter,
                linebreak: linebreak?.replace('\r', '').replace('\n', '\\n'),  //remove '\r' as it's not supported by athena, escape '\n'
                preview: rawDataPreview,
              }));
            }
          }}
          // processChunk={(rows, { startIndex }) => {
          //   // required, receives a list of parsed objects based on defined fields and user column mapping;
          //   // may be called several times if file is large
          //   // (if this callback returns a promise, the widget will wait for it before parsing more data)

          //   // optional, invoked when import is done and user clicked "Finish"
          //   // (if this is not specified, the widget lets the user upload another file)
          //   // goToMyAppNextPage();
          // }}
          // onComplete={({ file, preview, fields, columnFields }) => {
          //   // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
          //   // showMyAppToastNotification();
          // }}
          // onClose={({ file, preview, fields, columnFields }) => {

          //   dispatch(createUpload(upload, file));

          //   // optional, invoked when import is done and user clicked "Finish"
          //   // (if this is not specified, the widget lets the user upload another file)
          //   // goToMyAppNextPage();
          // }}
        >
          {importerFields}
        </Importer>
      </Grid>
    </Grid>
  )
}
