export const uploadHistorySchema = [
  {
    field: "id",
    headerName: "id",
    editable: false,
    sortable: false,
    hide: true,
  },
  {
    field: "key",
    headerName: "key",
    editable: false,
    sortable: false,
    hide: true,
  },
  {
    field: "dt_created",
    headerName: "Created",
    editable: false,
    sortable: true,
    hide: false,
    flex: 120,
  },
  {
    field: "description",
    headerName: "Description",
    editable: false,
    sortable: true,
    hide: false,
    flex: 240,
  },
  {
    field: "status",
    headerName: "Status",
    editable: false,
    sortable: true,
    hide: false,
    flex: 80,
  },
];
