import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Box, Button, Grid, Paper, Stack, Typography } from '@mui/material';
import {
  Settings as SettingsIcon,
  ExpandMore as ExpandMoreIcon,
  Login as LoginIcon,
} from '@mui/icons-material';
import { getDuration } from '../../../lib/Utils';
import { getShoppingCart } from '../../../actions/shopping_cart';
import { UserSettings } from './UserSettings';

export function UserSummary() {

  const dispatch = useDispatch();
  const { user, context_user } = useSelector(state => state.auth);
  const { user_list } = useSelector(state => state.data);
  const [isAdmin, setIsAdmin] = useState();

  useEffect(()=> {
    if (user) {
      dispatch(getShoppingCart({
        user_key: user.key,
      }));
    }

    // admin?
    if (user.permissions?.resources?.includes('others.users')) {
      setIsAdmin(true);
    }
  }, [user])

  return (
    <Grid container id="users-get-one-user-summary">
      { !context_user ?
        <>
          <Grid item xs={12}>
            <Alert
              severity="info"
              variant="outlined"
              sx={{ boxShadow: 10, borderRadius: 1, mb: 1 }}              
            >
              { user?.dt_last_login ?
                <>
                  <AlertTitle>
                    <Typography>
                      {`Welcome back `}
                      <strong>{user.first_name} {user.last_name}</strong>
                    </Typography>
                  </AlertTitle>
                  <Box>
                    <Typography>
                      {`You have been our member for `}
                      <strong>{getDuration(user.dt_created).longestDuration}</strong>
                    </Typography>
                    <Typography>
                      {`Last time you visited us `}
                      <strong>{getDuration(user.dt_last_login).longestDuration}</strong>
                      {` ago`}
                    </Typography>
                    { user.resource_counts?.resource_count ?
                      <>
                        <Typography>
                          {`Number of `} <strong>Lists</strong> {`uploaded: `}
                          <strong>{user.resource_counts.resource_count?.uploads}</strong>
                        </Typography>
                        <Typography>
                          {`Number of `} <strong>XAudiences</strong> {`created: `}
                          <strong>{user.resource_counts.resource_count?.runs}</strong>
                        </Typography>
                        <Typography>
                          {`Number of `} <strong>Files </strong> {`uploaded to Data Clean Room: `}
                          <strong>{user.resource_counts.resource_count?.files}</strong>
                        </Typography>
                        <Typography>
                          {`Number of `} <strong>Orders </strong> {`created: `}
                          <strong>{user.resource_counts.resource_count?.orders}</strong>
                        </Typography>
                        <Typography>
                          {`Number of `} <strong>Audiences </strong> {`created: `}
                          <strong>{user.resource_counts.resource_count?.audiences}</strong>
                        </Typography>                                                
                      </> : null
                    }
                  </Box>
                </> : 
                <>
                  <AlertTitle>
                    <Typography>
                      {`Welcome `}
                      <strong>{user.first_name} {user.last_name}</strong>
                      {`. Thank you for registering for the BDEX Omni IQ product!`}
                    </Typography>
                  </AlertTitle>
                </>
              }
            </Alert>
          </Grid>
        </>
        : isAdmin && context_user ?
        <>
          <Grid item xs={12}>
            <Alert
              severity="info"
              variant="outlined"
              icon={<LoginIcon />}
              sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}
            >
              <Typography>
                {`Logged in as user `}
                <strong>{context_user?.email_address}</strong>
              </Typography>
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <Accordion sx={{ boxShadow: 10, my: 1 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center" sx={{ color: "warning.main" }}>
                  <SettingsIcon />
                  <Typography>Change user settings</Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <UserSettings admin={user} user={user_list.find(x => x.id === context_user.id)} />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </>
        : null
      }
    </Grid>
  )
};
