import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Importer } from 'react-csv-importer';
import { Grid, Typography } from '@mui/material';
import { setDataState } from '../../../actions/data';
import { clearMessage, setMessage } from '../../../actions/message';
import './NewFilePreview.css';
import { getFileExtension, isEmpty } from '../../../lib/Utils';
import { useNavigate } from 'react-router-dom';

export default function NewFilePreview() {

  // initial load of the 1st child component
  useEffect(() => {
    dispatch(clearMessage());
    dispatch(setDataState('file_new'));
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Grid container id="riles-create-new-file-preview" >
      <Grid item xs={12} sx={{ my: 1 }}>
        <Typography><strong>New File: Selection</strong></Typography>
      </Grid>
      <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, m: 1 }}>
        <Importer
          fileSelectionOnly={true}  // file selection only, no need for preview 
          onFileSelection={({ file }) => {

            if (!isEmpty(file)) {

              let { size, type, name } = file;

              if (size > 5 * 1024 * 1024 * 1024) {
                dispatch(setMessage({
                  error: `Error! File size can not exceed 5 GB. Please upload a smaller file, or do it in parts`,
                  status: 400,
                }));
                dispatch(setDataState('file_new'));
                navigate('/files');
              }

              if (type === '') {
                const ext = getFileExtension(name);
                type = `unknown/${ext}`;
              }
              
              dispatch(setDataState('file_new', {
                ...file,
                // File object reference
                file,
                file_name: name,
                file_size: size,
                file_type: type,
              }));
            }
          }}
        >
        </Importer>
      </Grid>
    </Grid>
  )
}
