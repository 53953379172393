import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { getAudience } from '../../../actions/audience';
import { clearMessage } from '../../../actions/message';
import { isEmpty } from '../../../lib/Utils';
import { AutoRefresh } from '../../Common';

export function AudienceDetails() {
    
  const { audience } = useSelector(state => state.data);
  const { user } = useSelector(state => state.auth);

  const dispatch = useDispatch();
  
  const params = useParams();
  const { audience_key } = params;

  const [searchParams] = useSearchParams();
  const user_key = searchParams.get('user_key');

  const [availableRows, setAvailableRows] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  const handleGetAudience = () => {
    dispatch(getAudience({
      audience_key,
      user_key,
    }));
  };
  
  // navigate to '/audiences' if error 400 or 404 received
  const navigate = useNavigate();
  const { error, status } = useSelector(state => state.message);
  useEffect(() => {

    if (status === 404 || (status === 400 && error?.includes('User does not have a requested'))) {
      dispatch(clearMessage());
      navigate('/audiences');
    }
  }, [error, status, dispatch, navigate]);

  // initial load
  useEffect(() => {

    // set 'audience' state
    handleGetAudience();

    // isAdmin?
    if (user.permissions?.resources?.includes('others.audiences')) {
      setIsAdmin(true);
    }
  }, []);
  
  useEffect(() => {
    if (!isEmpty(audience)) {

      const latestDate = Math.max(...(audience.history?.map(x => new Date(x.date_created)) || []));
      const latestStatus = audience.history.find(x => x.date_created === new Date(latestDate).toISOString())

      const rows = [
        {
          id: 1,
          key: 'Name',
          value: audience.name,
        },
        {
          id: 2,
          key: 'Key',
          value: audience.key,
        },
        {
          id: 3,
          key: 'Created',
          value: audience.date_created,
        },
        {
          id: 4,
          key: 'Last Updated',
          value: latestStatus?.date_created,
        },
        {
          id: 5,
          key: 'Last Stage Description',
          value: latestStatus?.description,
        },
        {
          id: 6,
          key: 'Status',
          value: audience.status,
        },
        {
          id: 7,
          key: 'is_available_for_download',
          value: audience.is_available_for_download,
        },
        {
          id: 8,
          key: 'search_criteria',
          value: audience.search_criteria,
        },
      ];

      setAvailableRows(rows);
    }
  }, [audience])
  
  let columns = [];
  if (!isEmpty(audience)) {
    columns = [
      {
        field: 'key',
        minWidth: '120px',
        flex: 1,
        display: 'flex',
      },
      {
        field: 'value',
        minWidth: '240 px',
        flex: 1,
      },
    ];
  }

  return (
    <Grid container id="audiences-get-one-audience-details">
      <Grid item xs={6} sx={{ my: 1 }}>
        <Typography><strong>Audience Details</strong></Typography>
      </Grid>
      <Grid item xs={6} sx={{ my: 1 }}>
        <AutoRefresh handler={handleGetAudience} off={true} />
      </Grid>
      <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}>
        <DataGrid
          rows={availableRows}
          columns={columns}
          hideFooter={true}
          autoHeight={true}
          density={'compact'}
          customHeadRender={() => null }
          sx={
            {
              m: 1,
              // hide header
              "& .MuiDataGrid-columnHeaders": { display: "none" },
              "& .MuiDataGrid-virtualScroller": { marginTop: "0!important" },
              // highlight admin-visible rows
              "& .MuiDataGrid-row--admin-visible": {
                bgcolor: '#e0e0e0',
                '&:hover': {
                  bgcolor: '#d0d0d0',
                }
              }
            }
          }
          getRowClassName={(params) => {
            if (params.row.key.endsWith('*')) {
              return `MuiDataGrid-row--admin-visible`;
            }
          }}      
        />
      </Grid>
    </Grid>
  );
}
