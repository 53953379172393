import * as yup from 'yup';

/**
 * Yup Schema
 * https://github.com/jquense/yup#api
 * 
 * Formik has a special config option / prop for Yup object schemas called 
 * validationSchema which will automatically transform Yup's validation errors 
 * into a pretty object whose keys match values and touched. 
 * This symmetry makes it easy to manage business logic around error messages. 
 */


// defining all possible fields that will be validated
const fields = {

  email_address: yup
    .string()
    .trim()
    // ref: https://github.com/jquense/yup/issues/507
    //.email('Enter a valid email address')
    .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Enter a valid email address')
    .required('Email address is required'),

  password: yup
    .string()
    .matches(/[A-Z]+/, 'Password must contain at least one upper case character')
    .matches(/[a-z]+/, 'Password must contain at least one lower case character')
    .matches(/[0-9]+/, 'Password must contain at least one digit')
    .matches(/[!@#$%^&*()_+\-=[\]{}.,/<>?]+/g, 'Password must contain at least one special character (!@#$%^&*()_+-=[]{},./<>?)')
    .min(8, 'Password must be at least 8 characters length')
    .required('Password is required'),
  
  password_confirm: yup
    .string()
    .min(8, 'Password must be at least 8 characters length')
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required('Confirm Password is required'),

  first_name: yup
    .string()
    .trim()
    .min(2, 'Please use a real first name, this is too short')
    .max(32, 'Please use a real first name, this is too long')
    .required('First name is required'),

  last_name: yup
    .string()
    .trim()
    .min(2, 'Please use a real last name, this is too short')
    .max(32, 'Please use a real last name, this is too long')
    .required('Last name is required'),

  phone_number: yup
    .string()
    .trim()
    .min(10, 'Please use a real phone number, this is too short')
    .max(32, 'Please use a real phone number, this is too long')
    .required('Phone number is required'),

  company_name: yup
    .string()
    .trim()
    .min(2, 'Please use a real company name, this is too short')
    .max(32, 'Please use a real company name, this is too long')
    .required('Company name is required'),
    
  how_did_you_hear_about_us: yup
    .string()
    .trim()
    .min(2, 'Please use a real value, this is too short')
    .max(30, 'Please use a value shorter than 30 characters')
    .required('This information is required to better serve our customers. Thank you for providing it!'),

  // add more fields to validate

}

// creating a yup object with only fields required for the requesting component
// ps: if you include all of them, the validation of that component fails
export const validationSchema = (props) => {
  return yup.object().shape(props.reduce((acc, curr) => ({ ...acc, [curr]: fields[curr] }), {}));
};
