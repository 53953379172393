import React from "react";
import { useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import stripeClient from "../../../../../lib/stripeClient";
import StripePayment from "./StripePayment";
import "./StripeStyle.css";

export default function StripeCheckout(props) {

  const { order_new: order } = useSelector(state => state.data);
  const { client_secret } = order?.payment_intent || {};

  return (
    <>
      { client_secret ?
        <Elements 
          options={{
            clientSecret: client_secret,
            // https://stripe.com/docs/elements/appearance-api?platform=web#others
            appearance: {
              theme: 'stripe',
            }
          }}
          stripe={stripeClient}
        >
          <StripePayment 
            clientSecret={client_secret}
            order={order}
            {...props}
          />
        </Elements>
        : null
      }
    </>
  );
}