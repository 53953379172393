import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CssBaseline, Link, Grid, Typography, Container, } from '@mui/material';
import { Formik, Form, } from 'formik';
import { useStyles } from './config';
import { activateAccount } from '../../actions/auth';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { ReCaptcha } from './ReCaptcha';

export default function ActivateAccount(props) {

  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const captchaRef = useRef();
  const executeCaptchaAsync = async () => {
    return await captchaRef.current?.executeAsync();
  };

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const { error, warning, status } = useSelector(state => state.message);
  const [tokenExpired, setTokenExpired] = useState(false);
  
  useEffect(() => {

    //back to login if 200
    if (status === 200) {
      navigate('/auth/login');
    }
    else {
           
      //display the link to resend the account activation link
      if (error?.includes('invalid') || warning?.includes('invalid')) {
        setTokenExpired(true);
      }
    }
  }, [error, warning, status, navigate]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography sx={{ textAlign: 'center', typography: 'h5'}}>
          {"Activate your account"}
        </Typography>
      </div>
      <div className={classes.form} noValidate>
        <Formik
          initialValues={{
            token,
          }}
          onSubmit={ async (values, actions) => {

            //start submitting
            actions.setSubmitting(true);

            //execute (solve) captcha (front-end)
            const captcha_token = await executeCaptchaAsync();

            if (captcha_token) {

              // verify solved captcha (server-side) and login
              dispatch(activateAccount({...values, captcha_token }));
            }

            //stop submitting
            actions.setSubmitting(false);

            //reset captcha (front-end)
            captchaRef.current?.reset();

            //reset form
            actions.resetForm();
          }}>
            {
              ({ values, errors, isSubmitting }) => (
                <Form>
                  <Grid container>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={isSubmitting}
                      >
                        {"Activate Account"}
                      </Button>
                    </Grid>
                    { tokenExpired
                      ? <Grid item container justifyContent="center">
                          <Link
                            component={RouterLink}
                            to="/auth/resend-activation"
                            underline="hover"
                            sx={{
                              color: 'error.main',
                              border: 1,
                              fontWeight: 'medium',
                              borderRadius: 1,
                              typography: 'body2'
                            }}
                          >
                            {"Resend the account activation link"}
                          </Link>
                        </Grid>
                      : <div></div>
                    }
                    <Grid item container justifyContent="center">
                      <Link component={RouterLink} to="/auth/login" variant="body2" underline="hover">
                        {"Go back to Login"}
                      </Link>
                    </Grid>
                  </Grid>
                </Form>
              )
            }
        </Formik>
      </div>
      <ReCaptcha captcharef={captchaRef}/>
    </Container>
  );
};
