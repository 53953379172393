import {
  DATA,
  SET_MESSAGE,
} from "./types";
import * as RunService from "../services/run";
import { parseResponseError } from "../lib/Utils";

export const createRun = (kwargs) => async (dispatch) => {
  try {

    const response = await RunService.createRun(kwargs);

    dispatch({
      type: SET_MESSAGE,
      payload: {
        info: 'New XAudience (Expanded Audience) has been successfully created. Our data experts will be in touch with you when it is ready',
        status: '200',
      }
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const getRuns = () => async (dispatch) => {
  try {
    const response = await RunService.getRuns();
    const { run_list } = response?.data?.data || {};

    dispatch({
      type: DATA,
      payload: {
        run_list,
      },
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const getRun = (kwargs) => async (dispatch) => {
  try {
    const response = await RunService.getRun(kwargs);
    const { run_list } = response?.data?.data || {};
    const run = run_list?.[0];

    dispatch({
      type: DATA,
      payload: {
        run,
      },
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const getRunAnalytics = (kwargs) => async (dispatch) => {
  try {
    const response = await RunService.getRunAnalytics(kwargs);
    const { run_list } = response?.data?.data || {};
    const { analytics_stats } = run_list?.[0] || {};

    dispatch({
      type: DATA,
      payload: {
        run_analytics: analytics_stats,
      },
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const deleteRun = (kwargs) => async (dispatch) => {
  try {
    const response = await RunService.deleteRun(kwargs);

    dispatch({
      type: SET_MESSAGE,
      payload: {
        info: 'Requested XAudience (Expanded Audience) has been permanently deleted',
        status: '200',
      }
    });

  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};
