import React, { forwardRef, useState } from 'react';
import { Grid, Button, Menu, MenuItem, Divider } from '@mui/material';
import {
  CalendarMonth as CalendarMonthIcon,
  Refresh as RefreshIcon,
  ExpandMore as ExpandMoreIcon,
 } from '@mui/icons-material';
import DatePicker from 'react-datepicker';
import './react-datepicker.css';
import { DateTime } from '../../../lib';

export default function BdexDatePicker(kwargs) {

  const { dateRange, setDateRange } = kwargs;
  
  const minDate = DateTime.minDate;
  const maxDate = DateTime.maxDate;
  // const initialStartDate = DateTime.initialStartDate;
  const initialEndDate = DateTime.initialEndDate;

  const [isDateUpdated, setIsDateUpdated] = useState(false);
  const [localDateRange, setLocalDateRange] = useState(dateRange);
  const [startDate, endDate] = localDateRange;

  const handleDateUpdate = () => {
    setDateRange([startDate, endDate]);
    setIsDateUpdated(false);
  };

  const DateInput = forwardRef(
    ({ value, onClick }, ref) => {
      
      const [anchorEl, setAnchorEl] = useState();
      const open = Boolean(anchorEl);

      const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      
      const handleMenuClose = () => {
        setAnchorEl(null);
      };

      const handleDatePickerMenu = () => {
        handleMenuClose();
        onClick();
      };

      return (
        <div>
          <Button
            id="date-picker-button"
            aria-controls={open ? 'date-picker-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleMenuClick}
            startIcon={<CalendarMonthIcon />}
            endIcon={<ExpandMoreIcon />}
            size="small"
          >
            {value}
          </Button>
          <Menu
            id="date-picker-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            MenuListProps={{
              'aria-labelledby': 'date-picker-button',
            }}
          >
            {/* pre defined intervals */}
            { Object.entries(DateTime.intervals).map(([k,v]) => (
              <MenuItem
                key={k}
                onClick={() => {
                  setIsDateUpdated(true);
                  setLocalDateRange([v.startDate, v.endDate]);
                }}
                size="small"
              >
                {v.title}
              </MenuItem>
            ))}
            <Divider />
            <MenuItem
              key='custom-date'
              onClick={handleDatePickerMenu}
              size="small"
              ref={ref}
            >
              {"Custom Date"}
            </MenuItem>
          </Menu>
      </div>
      )
    }
  );

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>
        <DatePicker
          utcOffset={0}
          selectsRange={true}
          minDate={minDate}
          maxDate={maxDate}
          startDate={DateTime.getCorrectDisplayDate(startDate)}
          // startDate={startDate}
          endDate={DateTime.getCorrectDisplayDate(endDate, 'to')}
          // dateFormat="yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
          onChange={(dateRange) => {
            let [startDate, endDate] = dateRange;
            startDate = DateTime.getDayStart(startDate);
            endDate = DateTime.getNextDayStart(endDate);
            setIsDateUpdated(true);
            setLocalDateRange([startDate,endDate]);
          }}      
          customInput={<DateInput />}
          // isClearable={true}
          onCalendarClose={() => {
            if (startDate && !endDate) {
              setIsDateUpdated(true);
              setLocalDateRange([
                DateTime.getDayStart(startDate),
                DateTime.getDayStart(initialEndDate),
              ]);
            }  
          }}
          // showMonthYearPicker={true}
          highlightDates={[minDate, maxDate]}
          monthsShown={2}
          // inline={true}
          showPopperArrow={false}
          fixedHeight={true}
          withPortal={true}
          // todayButton='today'
          // showTimeSelect
        />
      </Grid>
      { isDateUpdated ?
        <Grid item>
          <Button
            type="submit"
            onClick={handleDateUpdate}
            color="warning"
            startIcon={<RefreshIcon />}
            size="small"
          >
            Refresh results
          </Button>
        </Grid>
        : null
      }
    </Grid>
  );
};
