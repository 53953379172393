import {
  DATA,
  SET_MESSAGE,
} from "./types";

import * as ShoppingCartService from "../services/shopping_cart";
import { parseResponseError } from "../lib/Utils";

export const getShoppingCart = () => async (dispatch) => {
  try {
    const response = await ShoppingCartService.getShoppingCart();
    const { shopping_cart } = response.data?.data?.user_session?.data || {};

    dispatch({
      type: DATA,
      payload: {
        shopping_cart,
      },
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

/**
 * merges items in the shopping cart
 * 
 * @param {*} kwargs 
 * @returns 
 */
export const addToShoppingCart = (kwargs) => async (dispatch) => {
  try { 
    const response = await ShoppingCartService.setShoppingCart({
      ...kwargs,
      merge: true,
    });
    const { shopping_cart } = response.data?.data?.user_session?.data || {};

    dispatch({
      type: DATA,
      payload: {
        shopping_cart,
      },
    });
    
    dispatch({
      type: SET_MESSAGE,
      payload: {
        info: 'Requested Items have been added to Shopping Cart',
        status: '200',
      }
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

/**
 * sets items in the shopping cart
 * 
 * @param {*} kwargs 
 * @returns 
 */
export const setShoppingCart = (kwargs) => async (dispatch) => {
  try { 
    const response = await ShoppingCartService.setShoppingCart(kwargs);
    const { shopping_cart } = response.data?.data?.user_session?.data || {};

    dispatch({
      type: DATA,
      payload: {
        shopping_cart,
      },
    });    
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const clearShoppingCart = () => async (dispatch) => {
  try {
    const response = await ShoppingCartService.setShoppingCart();
    const { shopping_cart } = response.data?.data?.user_session?.data || {};
  
    dispatch({
      type: DATA,
      payload: {
        shopping_cart,
      },
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};
