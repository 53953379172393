import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid, Tooltip, Typography, Stack, Button, Box, } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  BarChart as BarChartIcon,
  History as HistoryIcon,
} from '@mui/icons-material';
import { getAudiences } from '../../../actions/audience';
import { audienceListSchema } from '../config';
import { isEmpty, sortBy } from '../../../lib/Utils';
import AudienceActions from './AudienceActions';
import { AutoRefresh, DataGridStatusColumn } from '../../Common';

export function AudiencesList(props = {}) {

  const { checkboxes } = props;
  const [availableRows, setAvailableRows] = useState([]);
  const [checkedRowIDs, setCheckedRowIDs] = useState([]);
  const [checkedAudienceKey, setCheckedAudienceKey] = useState();
  const [checkedAudienceUserKey, setCheckedAudienceUserKey] = useState();
  const [isAdmin, setIsAdmin] = useState(false);

  // sort by date_created desc, i.e., the latest created first
  const defaultSort = sortBy('date_created', 'desc');

  const { audience_list } = useSelector(state => state.data);
  const { user, context_user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  
  const handleGetAudiences = () => {
    dispatch(getAudiences());
  };

  // initial load
  useEffect(() => {

    // set 'audience_list' state
    handleGetAudiences();

    if (user.permissions?.resources?.includes('others.audiences')) {
      setIsAdmin(true);
    }
  }, []);
  
  // load when audience_list is updated
  useEffect(() => {
    const _availableRows = audience_list?.filter(x => x)?.sort(defaultSort);
    setAvailableRows(_availableRows);
  }, [audience_list])

  // audience.key - no id returned, so use key instaed
  useEffect(() => {
    const checkedAudience = availableRows?.filter(x => x.key === checkedRowIDs[0])?.[0];
    setCheckedAudienceKey(checkedAudience?.key);
    setCheckedAudienceUserKey(checkedAudience?.user_key);
  }, [availableRows, checkedRowIDs])
  
  let columns = [];
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    () => audienceListSchema.filter(x => x.hide === true).reduce((a, v) => ({ ...a, [v['field']]: false }), {})
  );

  const isUserKeyRequired = isAdmin && isEmpty(context_user);

  if (!isEmpty(audience_list)) {
    columns = Object.keys(audience_list[0]).map(x => {
      return {
        field: x,
        headerName: x,
      };
    });

    // set up from the config
    columns = audienceListSchema.filter(x => x.key === audience_list?.key);

    // remove all admin-visible columns if not an admin
    if (!isAdmin) {
      columns = columns.filter(x => !x.onlyVisibleToAdmin);
    }

    // enhance 'status' column
    columns[columns.findIndex(x => x.field === 'status')].renderCell = (params) => {      
      return (
        <DataGridStatusColumn params={params} type={"audiences"} />
      );
    }

    // enhance 'name' column
    columns[columns.findIndex(x => x.field === 'name')].renderCell = (params) => {
      const { name, key: audience_key, user_key } = params.row;

      return (
        <Grid
          container
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Grid item xs={12} sm={12} md={10} zeroMinWidth>
            <Tooltip title={`See '${name}' Audience Details`} arrow>
              <Button
                component={Link}
                to={{
                  pathname: `/audiences/${audience_key}`,
                //   search: isUserKeyRequired && `user_key=${user_key}`,
                }}
                sx={{ width: '100%', p: 1, m: -1, justifyContent: 'flex-start' }}
              >
                <Typography variant="inherit" noWrap>{name}</Typography>
              </Button>
            </Tooltip>
          </Grid>
          <Grid item md={2} zeroMinWidth sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
            <Stack direction="row" spacing={0} justifyContent="space-around">
              {/* <Tooltip title={`See Audience Analytics`} arrow>
                <Box 
                  component={Link}
                  to={{
                    pathname: `/audiences/${audience_key}/analytics`,
                    search: isUserKeyRequired && `user_key=${user_key}`,
                  }}
                >
                  <BarChartIcon
                    id={`bar-chart-icon-${audience_key}`}
                    key={`bar-chart-icon-${audience_key}`}
                    fontSize="small"
                    color="primary"
                  />
                </Box>
              </Tooltip> */}
              <Tooltip title={`See Audience History`} arrow>
                <Box 
                  component={Link}
                  to={{
                    pathname: `/audiences/${audience_key}/history`,
                    search: isUserKeyRequired && `user_key=${user_key}`,
                  }}
                >
                  <HistoryIcon
                    id={`history-icon-${audience_key}`}
                    key={`history-icon-${audience_key}`}
                    fontSize="small"
                    color="primary"
                  />
                </Box>
              </Tooltip>
            </Stack>
          </Grid>
        </Grid>
      );
    }
  }

  return (
    <Grid container id="audiences-get-all-audiences-list">
      <Grid item xs={6} sx={{ my: 1 }}>
        <Typography><strong>Created Audiences</strong></Typography>
      </Grid>
      <Grid item xs={6} sx={{ my: 1 }}>
        <AutoRefresh handler={handleGetAudiences} />
      </Grid>
      <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}>
        <DataGrid
          rows={availableRows}
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(x) => setColumnVisibilityModel(x)}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10,50,100]}
          checkboxSelection={checkboxes ? true : false}
          disableRowSelectionOnClick={false}
          autoHeight={true}
          density={'compact'}
          getRowId={row => row.key}          
          rowSelectionModel={checkedRowIDs}
          onRowSelectionModelChange={(selection) => {
            if (selection.length > 1) {
              const selectionSet = new Set(checkedRowIDs);
              const result = selection.filter(x => !selectionSet.has(x));
              setCheckedRowIDs(result);
            }
            else {
              setCheckedRowIDs(selection);
            }
          }}
          localeText={{
            footerRowSelected: (count) => <AudienceActions audience_key={checkedAudienceKey} user_key={isUserKeyRequired && checkedAudienceUserKey}/>
          }}
          sx={{
            m: 1,
            // hideSelectAllCheckbox
            '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
              display: 'none',
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
