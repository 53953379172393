import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  Alert,
  AlertTitle,
  Box,
  TextField,
  Grid,
  Typography,
} from '@mui/material';
import { setDataState } from '../../../actions/data';
import { isEmpty, getFormattedDt } from '../../../lib/Utils';

const MIN_MATCHED_RECORDS_REQUIRED_FOR_NEW_RUN = process.env.REACT_APP_MIN_MATCHED_RECORDS_REQUIRED_FOR_NEW_RUN || 1000;

export function NewAudienceAdditionalData() {

  const { audience_new: audience } = useSelector(state => state.data);

  // [TODO] - when doing refactoring - should it be set as 'data'?
  const { 
    upload_list_positive,
    audience_name = `audience_${getFormattedDt()}`,
    audience_comments = ''
  } = audience;
  const dispatch = useDispatch();

  const [data, setData] = useState();

  const handleDataChange = (event) => {
    const { name, value } = event.target;
    setData(prev => ({
      ...prev,
      [name]: value,
    }));
  };
  
  // update local useState values
  useEffect(() => {
    if (!isEmpty(upload_list_positive)) {
      setData({
        audience_name,
        audience_comments,
      })
    }
  }, [setData, upload_list_positive]);

  // update values in redux
  useEffect(() => {
    dispatch(setDataState('audience_new', {
      ...audience,
      ...data,
    }));
  }, [data]);
  
  return (
    <>
      { !isEmpty(upload_list_positive) && upload_list_positive.reduce((acc, curr) => acc + curr.matched_row_count || 0, 0) >= MIN_MATCHED_RECORDS_REQUIRED_FOR_NEW_RUN ?
        <>
          <Grid container id="audiences-create-new-audience-additional-data-audience-name" sx={{ my: 1 }}>
            <Grid item xs={12} sx={{ my: 1 }}>
              <Typography><strong>New Audience: Name</strong></Typography>
            </Grid>
            <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}>
              <Alert severity="info" variant="outlined">
                <AlertTitle><strong>Note!</strong></AlertTitle>
                <Box>
                  Your List{upload_list_positive?.length === 1 ? ' is ' : '(s) are '} eligible to be used in the new Audience creation. 
                </Box>
                <Box>
                  You may overwrite the default name of this New Audience so you can easy identify it later.
                </Box>
                <Box>
                  It might be a good idea to include a type of the List(s) used, date, project name, etc.
                  Be as descriptive as possible, as any format is accepted here
                </Box>
              </Alert>
            </Grid>
            <Grid item xs={12} sx={{ m: 1 }}>
              <TextField
                type="text"
                name="audience_name"
                variant="standard"
                size='small'
                margin='normal'
                fullWidth
                required={true}
                multiline={false}
                rows={1}
                defaultValue={audience_name}
                onChange={handleDataChange}
                autoFocus
              />
            </Grid>
          </Grid>
          <Grid container id="audiences-create-new-audience-additional-data-audience-comments">
            <Grid item xs={12} sx={{ my: 1 }}>
              <Typography><strong>New Audience: Comments</strong></Typography>
            </Grid>
            <Grid item xs={12} sx={{ m: 1 }}>
              <TextField
                type="text"
                name="audience_comments"
                placeholder="Any additional information related to this Audience."
                variant="standard"
                size='small'
                margin='normal'
                fullWidth
                required={false}
                multiline={true}
                rows={5}
                defaultValue={audience_comments}
                onChange={handleDataChange}
              />
            </Grid>
          </Grid>
        </>
        : null
      }
    </>
  )
}