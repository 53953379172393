import {
  DATA,
  SET_MESSAGE,
} from "./types";
import * as PixelService from "../services/pixel";
import { parseResponseError } from "../lib/Utils";

export const createPixel = (kwargs) => async (dispatch) => {
  try {
    const response = await PixelService.createPixel(kwargs);

    dispatch({
      type: SET_MESSAGE,
      payload: {
        info: 'New Pixel has been successfully created',
        status: '200',
      }
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const getPixels = () => async (dispatch) => {
  try {
    const response = await PixelService.getPixels();
    const { sites: pixel_list } = response?.data?.data?.analytics || {};

    dispatch({
      type: DATA,
      payload: {
        pixel_list,
      },
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const getPixel = (kwargs) => async (dispatch) => {
  try {
    const response = await PixelService.getPixel(kwargs);
    const { sites: pixel_list } = response?.data?.data?.analytics || {};
    const pixel = pixel_list?.[0];

    dispatch({
      type: DATA,
      payload: {
        pixel,
      },
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const getPixelVisits = (kwargs) => async (dispatch) => {
  try {
    const response = await PixelService.getPixelVisits(kwargs);
    const { sites: pixel_list } = response?.data?.data?.analytics || {};
    const pixel = pixel_list?.[0];
    const pixel_visits = pixel?.visits;

    dispatch({
      type: DATA,
      payload: {
        pixel_visits,
      },
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const updatePixel = (kwargs) => async (dispatch) => {
  try {

    const response = await PixelService.updatePixel(kwargs);

    dispatch({
      type: SET_MESSAGE,
      payload: {
        info: 'Pixel has been updated',
        status: '200',
      }
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const deletePixel = (kwargs) => async (dispatch) => {
  try {
    const response = await PixelService.deletePixel(kwargs);

    dispatch({
      type: SET_MESSAGE,
      payload: {
        info: 'Requested Pixel has been permanently deleted',
        status: '200',
      }
    });

  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};
