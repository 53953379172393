import React, { useState } from 'react';
import { Alert, AlertTitle, Box, Grid, TextField, Button, Dialog, DialogActions, useTheme, useMediaQuery } from '@mui/material';
import {
  Refresh as RefreshIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { refreshUpload } from '../../../actions/upload';

export default function RefreshConfirmationDialog(props) {

  const { openDialog, setOpenDialog, upload_key, user_key } = props;
  const dispatch = useDispatch();

  const [ data, setData ] = useState('');

  const refreshCode = `refresh ${upload_key?.substring(0,6)}`;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleDataChange = (event) => {
    const { value } = event.target;
    setData(value);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setData('');
  };

  const handleRefresh = () => {
    handleClose();
    dispatch(refreshUpload({
      upload_key,
      user_key,
    }));
  }

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="upload-refresh-confirmation"        
      >
        <Grid container id="upload-refresh-confirmation">
          <Grid item xs={12}>
            <Alert
              icon={<RefreshIcon fontSize="inherit" />} 
              severity="warning"
              variant="outlined"
              sx={{ boxShadow: 10, borderRadius: 1, m: 1, p: 2, pb: 0 }}
            >
              <AlertTitle>
                <strong>Refresh this List?</strong>
              </AlertTitle>
              <Box sx={{ mt: 1 }}>
                This will overwrite the previous results of this List, inlcuding the Analytics part. Keep in mind that new results may be different than before.
              </Box>
              <Box sx={{ mt: 1 }}>
                Please confirm the List Refresh by typing: <strong>{refreshCode}</strong>
              </Box>
              <TextField
                focused
                type="text"
                name="refresh_code"
                placeholder={refreshCode}
                variant="standard"
                size='small'
                margin='normal'
                fullWidth
                required={false}
                multiline={false}
                rows={1}
                value={data}
                onChange={handleDataChange}
                color="warning"
                sx={{ pr: 1 }}                
              />
              <DialogActions>
                <Button
                  type="submit"
                  variant="outlined"
                  color="warning"
                  onClick={handleClose}
                  startIcon={<CloseIcon />}
                  sx={{ boxShadow: 10 }}
                >
                  {"Cancel"}
                </Button>
                <Button
                  type="submit"
                  variant="outlined"
                  color="warning"
                  onClick={handleRefresh}
                  startIcon={<RefreshIcon />}
                  sx={{ boxShadow: 10 }}
                  disabled={data === refreshCode ? false : true}
                >
                  {"Refresh List"}
                </Button>
              </DialogActions>
            </Alert>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
