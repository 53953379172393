import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  ACTIVATE_ACCOUNT_SUCCESS,
  ACTIVATE_ACCOUNT_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  LOGOUT,
  LOGIN_CONTEXT_USER,
  LOGOUT_CONTEXT_USER,
  SET_MESSAGE,
  REFRESH_TOKEN,
  CLEAR_MESSAGE,
} from "./types";
import * as AuthService from "../services/auth";
import { parseResponseInfo, parseAuthResponseError, isEmpty } from "../lib/Utils";
import { store } from "../redux/store";

export const register = (data) => async (dispatch) => {
  try {
    const captcha = await AuthService.verifyCaptcha(data.captcha_token);
    const response = await AuthService.register(data);

    dispatch({
      type: REGISTER_SUCCESS,
    });

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseInfo(response),
    });
  }
  catch(err) {

    dispatch({
      type: REGISTER_ERROR,
    });

    dispatch({
      type: SET_MESSAGE,
      payload: parseAuthResponseError(err),
    });
  }
};

export const login = (data) => async (dispatch) => {
  try {
    const captcha = await AuthService.verifyCaptcha(data.captcha_token);
    const response = await AuthService.login(data);
    const { user } = response.data?.data || {};

    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        user,
      },
    });

    dispatch({
      type: CLEAR_MESSAGE,
    });
  }
  catch(err) {

    dispatch({
      type: LOGIN_ERROR,
    });

    dispatch({
      type: SET_MESSAGE,
      payload: parseAuthResponseError(err),
    });
  }
};

export const logout = ( reason = {} ) => (dispatch) => {

  //clean up local storage
  AuthService.logout();

  //clean up redux store
  dispatch({
    type: LOGOUT,
  });

  // overwrite the message
  if (!isEmpty(reason)) {
    dispatch({
      type: SET_MESSAGE,
      payload: { ...reason },
    });
  }
}

export const loginContextUser = (data = {}) => (dispatch) => {

  const { email_address } = data;

  //local storage
  AuthService.loginContextUser(data);

  //redux store
  dispatch({
    type: LOGIN_CONTEXT_USER,
    payload: data
  });

  dispatch({
    type: SET_MESSAGE,
    payload: {
      info: `Successfully logged in as user ${email_address}`,
      status: 200,
    },
  });
}

export const logoutContextUser = () => (dispatch) => {

  //get auth.context_user from redux store
  const { auth } = store.getState();
  const { context_user } = auth;

  //clean up local storage
  AuthService.logoutContextUser();

  //clean up redux store
  dispatch({
    type: LOGOUT_CONTEXT_USER,
  });

  dispatch({
    type: SET_MESSAGE,
    payload: {
      info: `Successfully logged out as user ${context_user?.email_address}`,
      status: 200,
    },
  });
}

export const refreshToken = () => async (dispatch) => {
  try {

    const response = await AuthService.refreshToken();
    const { user } = response.data?.data || {};

    dispatch({
      type: REFRESH_TOKEN,
      payload: {
        user,
      },      
    });

    dispatch({
      type: CLEAR_MESSAGE,
    });    

    // dispatch({
    //   type: DATA,
    //   payload: {
    //     user,
    //   },
    // });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseAuthResponseError(err),
    });
  }
};

export const activateAccount = (data) => async (dispatch) => {
  try {
    const captcha = await AuthService.verifyCaptcha(data.captcha_token);
    const response = await AuthService.activateAccount(data);

    dispatch({
      type: ACTIVATE_ACCOUNT_SUCCESS,
    });

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseInfo(response),
    });
  }
  catch(err) {
    
    if (err.response?.data?.error?.includes('token is invalid')) {
      err.response.data.error = 'The account activation link is invalid or expired. Please request a new one, and try again'
    }

    dispatch({
      type: ACTIVATE_ACCOUNT_ERROR,
    });

    dispatch({
      type: SET_MESSAGE,
      payload: parseAuthResponseError(err),
    });
  }
};

export const resendActivation = (data) => async (dispatch) => {
  try {
    const captcha = await AuthService.verifyCaptcha(data.captcha_token);
    const response = await AuthService.resendActivation(data);

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseInfo(response),
    });
  }
  catch(err) {
    dispatch({
      type: SET_MESSAGE,
      payload: parseAuthResponseError(err),
    });
  }
};

export const forgotPassword = (data) => async (dispatch) => {
  try {
    const captcha = await AuthService.verifyCaptcha(data.captcha_token);
    const response = await AuthService.forgotPassword(data);

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseInfo(response),
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseAuthResponseError(err),
    });
  }
};

export const resetPassword = (data) => async (dispatch) => {
  try {
    const captcha = await AuthService.verifyCaptcha(data.captcha_token);
    const response = await AuthService.resetPassword(data);

    dispatch({
      type: RESET_PASSWORD_SUCCESS,
    });

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseInfo(response),
    });
  }
  catch(err) {

    if (err.response?.data?.error?.includes('token is invalid')) {
      err.response.data.error = 'The password reset link is invalid or expired. Please request a new one, and try again'
    }

    dispatch({
      type: RESET_PASSWORD_ERROR,
    });

    dispatch({
      type: SET_MESSAGE,
      payload: parseAuthResponseError(err),
    });
  }
};
