import { dataGridColumns } from "../../../../lib/Utils";

export const uploadAnalyticsPaidSchema = {
  demographic_data: {
    name: 'Demographic Data',
    fields: [
      {
        field: "field",
        headerName: "Field",
        editable: false,
        sortable: false,
        flex: 240,
        mini: true,
        mappings: {
          gender: {
            // display_name: 'Gender - overwritten',
            // description: 'Gender description - overwritten',
          },
          hh_income: {},
          net_asset_value: {},
          birth_year: {},
          occupation_category: {},
          education_level: {},
          employment_status: {},
          pii: {},
          city: {
            included_in: 'pii',
          },
          state: {
            included_in: 'pii',
          },
          postal_code: {
            included_in: 'pii',
          },
        }
      },
      {
        field: "matched_count",
        headerName: "Matched Rows",
        editable: false,
        sortable: false,
        width: 120,
        hide: true,
        type: 'number',
      },
      {
        field: "match_rate",
        headerName: "Match Rate",
        editable: false,
        sortable: false,
        width: 120,
        ...dataGridColumns.display_percent,
      },
      {
        field: "found_count",
        headerName: "Records",
        editable: false,
        sortable: false,
        width: 120,
        mini: true,
        type: 'number',
      },
      {
        field: "price",
        headerName: "price*",
        editable: false,
        sortable: false,
        width: 0,
        mini: true,
        hide: true,
      },
      {
        field: "decimals",
        headerName: "decimals*",
        editable: false,
        sortable: false,
        width: 0,
        mini: true,
        hide: true,
      },
      {
        field: "currency",
        headerName: "currency*",
        editable: false,
        sortable: false,
        width: 0,
        mini: true,
        hide: true,
      },
      {
        field: "display_price",
        headerName: "Price",
        editable: false,
        sortable: false,
        width: 160,
        mini: true,
        ...dataGridColumns.display_price,
      },
    ],
  },
  identity_data: {
    name: 'Identity Data',
    fields: [
      {
        field: "field",
        headerName: "Field",
        editable: false,
        sortable: false,
        flex: 240,
        mini: true,
        mappings: {
          email_address: {
            // display_name: 'can overwrite the display_name like so',
            // description: 'can overwrite the description like so',
          },
          email_md5: {},
          maid_aaid: {},
          maid_idfa: {},
          ip_address: {},
        },
      },
      {
        field: "matched_count",
        headerName: "Matched Rows",
        editable: false,
        sortable: false,
        width: 120,
        hide: true,
        type: 'number',
      },
      {
        field: "match_rate",
        headerName: "Match Rate",
        editable: false,
        sortable: false,
        width: 120,
        hide: true,
        ...dataGridColumns.display_percent,
      },
      {
        field: "found_count",
        headerName: "Records",
        editable: false,
        sortable: false,
        width: 120,
        mini: true,
        type: 'number',
      },
      {
        field: "price",
        headerName: "price*",
        editable: false,
        sortable: false,
        width: 0,
        mini: true,
        hide: true,
      },
      {
        field: "decimals",
        headerName: "decimals*",
        editable: false,
        sortable: false,
        width: 0,
        mini: true,
        hide: true,
      },
      {
        field: "currency",
        headerName: "currency*",
        editable: false,
        sortable: false,
        width: 0,
        mini: true,
        hide: true,
      },
      {
        field: "display_price",
        headerName: "Price",
        editable: false,
        sortable: false,
        width: 160,
        mini: true,
        ...dataGridColumns.display_price,
      },
    ],
  },
};
