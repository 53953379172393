import { dataGridColumns } from "../../../lib/Utils";

export const orderListSchema = [
  {
    field: "user_id",
    headerName: "user_id",
    editable: false,
    sortable: true,
    hide: true,
  },
  {
    field: "user_key",
    headerName: "user_key",
    editable: false,
    sortable: false,
    hide: true,
  },
  {
    field: "user_email_address",
    headerName: "user_email*",
    editable: false,
    sortable: true,
    hide: false,
    flex: 90,
    onlyVisibleToAdmin: true,
  },
  {
    field: "id",
    headerName: "id",
    editable: false,
    sortable: false,
    hide: true,
  },
  {
    field: "key",
    headerName: "key",
    editable: false,
    sortable: false,
    hide: true,
  },
  {
    field: "name",
    headerName: "Name",
    editable: false,
    sortable: true,
    hide: false,
    flex: 240,
  },
  {
    field: "comments",
    headerName: "Comments",
    editable: false,
    sortable: false,
    hide: false,
    flex: 180,
  },
  {
    field: "dt_created",
    headerName: "Created",
    editable: false,
    sortable: true,
    hide: false,
    flex: 120,
  },
  {
    field: "dt_last_updated",
    headerName: "Updated",
    editable: false,
    sortable: false,
    hide: true,
    flex: 120,
  },
  {
    field: "description",
    headerName: "Description",
    editable: false,
    sortable: true,
    hide: true,
    flex: 240,
  },
  {
    field: "display_price",
    headerName: "Price",
    editable: false,
    sortable: false,
    width: 160,
    ...dataGridColumns.display_price,
  },
  {
    field: "status",
    headerName: "Status",
    editable: false,
    sortable: true,
    hide: false,
    flex: 80,
  }  
];
