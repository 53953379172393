import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import { rootReducer } from '../reducers';

// more details: https://redux-toolkit.js.org/api/configureStore
const preloadedState = {};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return [thunk];
  },
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState,
});
