import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Grid, Box, Tooltip, Typography, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  History as HistoryIcon,
  CreditScore as CreditScoreIcon,
  Receipt as ReceiptIcon,
} from '@mui/icons-material';
import { getOrders } from '../../../actions/order';
import { orderListSchema } from '../config';
import { isEmpty, sortBy } from '../../../lib/Utils';
import { AutoRefresh, DataGridStatusColumn } from '../../Common';

export function OrdersList(props = {}) {

  const { checkboxes } = props;
  const [availableRows, setAvailableRows] = useState([]);
  const [checkedRowIDs, setCheckedRowIDs] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  
  // sort by dt_last_updated desc, i.e., the latest updated first
  const defaultSort = sortBy('dt_last_updated', 'desc');

  const { order_list } = useSelector(state => state.data);
  const { user, context_user } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const handleGetOrders = () => {
    dispatch(getOrders());
  };

  // initial load
  useEffect(() => {

    // set 'order_list'
    handleGetOrders();

    if (user.permissions?.resources?.includes('others.orders')) {
      setIsAdmin(true);
    }
  }, [])

  // load when order_list is updated
  useEffect(() => {
    if (order_list) {
      const _availableRows = [...order_list].sort(defaultSort);
      setAvailableRows(_availableRows);
    }
  }, [order_list])

  let columns = [];
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    () => orderListSchema.filter(x => x.hide === true).reduce((a, v) => ({ ...a, [v['field']]: false }), {})
  );

  const isUserKeyRequired = isAdmin && isEmpty(context_user);

  if (!isEmpty(order_list)) {
    columns = Object.keys(order_list[0]).map(x => {
      return {
        field: x,
        headerName: x,
      };
    });

    // set up from the config
    columns = orderListSchema.filter(x => x.id === order_list?.id);

    // remove all admin-visible columns if not an admin 
    if (!isAdmin) {
      columns = columns.filter(x => !x.onlyVisibleToAdmin);
    }

    // enhance 'status' column
    columns[columns.findIndex(x => x.field === 'status')].renderCell = (params) => {
      return (
        <DataGridStatusColumn params={params} type={"orders"} />
      );
    }

    // enhance 'name' column
    columns[columns.findIndex(x => x.field === 'name')].renderCell = (params) => {
      const { name, key: order_key, user_key } = params.row;
      
      return (
        <Grid container direction="row" sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
          <Grid item xs={10} zeroMinWidth>
            <Tooltip title={`See '${name}' Order Details`} arrow>
              <Button 
                component={Link}
                to={{
                  pathname: `/orders/${order_key}`,
                  search: isUserKeyRequired && `user_key=${user_key}`,
                }}
                sx={{ width: '100%', justifyContent: 'flex-start' }}
              >
                <Typography variant="inherit" noWrap>{name}</Typography>
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={2} zeroMinWidth sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
            <Stack direction="row" spacing={0} sx={{ justifyContent: 'center' }}>
              <Tooltip title={`See Order History`} arrow>
                <Box
                  component={Link}
                  to={{
                    pathname: `/orders/${order_key}/history`,
                    search: isUserKeyRequired && `user_key=${user_key}`,
                  }}
                >
                  <HistoryIcon
                    fontSize="small"
                    color="primary"
                    sx={{ display: 'flex' }}
                  />
                </Box>
              </Tooltip>
            </Stack>
          </Grid>
        </Grid>
      );
    }

    // enhance 'price' column
    columns[columns.findIndex(x => x.field === 'display_price')].renderCell = (params) => {
      const { payment_method } = params.row;
      return (
        <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
          <Box>
            {params.formattedValue}
          </Box>
          { payment_method === 'payment_card' ?
            <Tooltip title={`Paid with a credit/debit card`} arrow>
              <CreditScoreIcon
                fontSize="small"
                color="action"
                sx={{ display: 'flex' }}
              />
            </Tooltip>
          : payment_method === 'user_credit' ?
            <Tooltip title={`Used a prepaid Account Credit`} arrow>
              <ReceiptIcon
                fontSize="small"
                color="action"
                sx={{ display: 'flex' }}
              />
            </Tooltip>
          : null
          }
        </Stack>
      );
    }

  }

  const handleMarkChecked = (selection) => {
    if (checkboxes) {
      if (selection.length > 1) {
        const selectionSet = new Set(checkedRowIDs);
        const result = selection.filter(x => !selectionSet.has(x));
        setCheckedRowIDs(result);
      }
      else {
        setCheckedRowIDs(selection);
      }
    }
  };

  const handleRowSelectable = (params) => true;

  return (
    <Grid container id="orders-get-all-orders-list">
      <Grid item xs={6} sx={{ my: 1 }}>
        <Typography><strong>Created Orders</strong></Typography>
      </Grid>
      <Grid item xs={6} sx={{ my: 1 }}>
        <AutoRefresh handler={handleGetOrders} />
      </Grid>
      <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, mt: 1 }}>
        <DataGrid
          rows={availableRows}
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(x) => setColumnVisibilityModel(x)}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10,50,100]}
          checkboxSelection={checkboxes ? true : false}
          disableRowSelectionOnClick={false}
          autoHeight={true}
          density={'compact'}
          rowSelectionModel={checkedRowIDs}
          onRowSelectionModelChange={handleMarkChecked}
          isRowSelectable={handleRowSelectable}
          sx={{
            m: 1,
            // hideSelectAllCheckbox
            '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
              display: 'none',
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
