import React, { useEffect, useState } from 'react';
import { Link, useMatch, useParams, useResolvedPath } from 'react-router-dom';
import { Divider, Drawer, List, Box, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Home as HomeIcon } from '@mui/icons-material';
import BdexLogoWhite from '../Logos/BdexLogoWhite';
import { categories } from './categories';
import { LocalStorage, Utils } from '../../lib';

const item = {
  py: 0,
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.7) inset',
  pb: 1,
  px: 3,
};

function CustomListItemButton({ children, ...props}) {

  const params = useParams();
  props.to = props.to || ''
  
  const resolved = useResolvedPath(props.to);
  const match = useMatch({
    path: resolved?.pathname,
    end: Utils.isEmpty(params),
  });

  return (
    <ListItemButton
      component={Link}
      sx={{ ...item, color: 'rgba(255, 255, 255, 0.7)' }}
      selected={!Utils.isEmpty(match)}
      {...props}
    >
      {children}
    </ListItemButton>
  )
}

export default function Navigator(props) {
  const { ...other } = props;

  const [appVersion, setAppVersion] = useState();
  useEffect(() => {
    const _appVersion = LocalStorage.getItem('app_version');
    setAppVersion(_appVersion);
  }, []);

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding dense>
        <ListItem>
          <BdexLogoWhite />
        </ListItem>
        <ListItem
          component={Link}
          to={'/'}
          sx={{ ...item, ...itemCategory }}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText>BDEX Omni IQ</ListItemText>
        </ListItem>  
        {categories.map(({ id, description, children }) => (
          <Box key={id} sx={{ bgcolor: '#101F33' }}>
            <ListItem sx={{ py: 0.25, px: 2.5 }}>
              <ListItemText 
                sx={{ color: '#ffffff' }}
                primary={id}
              />
              {description ?
                <ListItemText 
                  sx={{  color: 'rgba(255, 255, 255, 0.3)', textAlign: 'right' }}
                  primary={description}
                />
                : null
              }
            </ListItem>
            {children.filter(x => !x.hidden).map(({ id: childId, icon, link_to, disabled }) => (
              <ListItem disablePadding key={childId}>
                <CustomListItemButton
                  to={link_to}
                  disabled={disabled}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{childId}</ListItemText>
                </CustomListItemButton>
              </ListItem>
            ))}
            <Divider variant="middle" />
          </Box>
        ))}
      </List>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Box component="footer" sx={{ p: 0,  mr: 'auto', ml: 1, mt: 'auto', mb: 0, textAlign: 'auto'}}>
          <Typography variant="body2" color="rgba(255, 255, 255, 0.3)" >
            { appVersion ? `version ${appVersion.client}/${appVersion.server}` : null }
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
}
