import { 
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  REFRESH_TOKEN,
  LOGOUT,
  LOGIN_CONTEXT_USER,
  LOGOUT_CONTEXT_USER,
} from '../actions/types';
import * as LocalStorage from '../lib/LocalStorage';

// read initial state from the local storage
const user = LocalStorage.getItem('user');
const context_user = LocalStorage.getItem('context_user');
const initialState = {
  user: user,
  userAuthenticated: user ? true : false,
  context_user: context_user,
};

export const auth = (state = initialState, action) => {

  const { type, payload } = action;

  switch(type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...payload,
        userAuthenticated: true,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        userAuthenticated: false,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        userAuthenticated: false,
      };
    case REGISTER_ERROR:
      return {
        ...state,
        userAuthenticated: false,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        userAuthenticated: false,
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        userAuthenticated: false,
      };      
    case LOGOUT:
      return {
        userAuthenticated: false,
      };
    case LOGIN_CONTEXT_USER:
        return {
          ...state,
          context_user: payload,
        };      
    case LOGOUT_CONTEXT_USER:
      return {
        ...state,
        context_user: null,
      };
    case REFRESH_TOKEN:
      return {
        ...state,
        ...payload,
        userAuthenticated: true,
      };
    default:
      return state;
  }
};
