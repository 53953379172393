import {
  DATA,
  SET_MESSAGE,
} from "./types";

import * as UploadService from "../services/upload";
import { parseResponseError } from "../lib/Utils";

export const createUpload = (kwargs) => async (dispatch) => {
  try {
    const response = await UploadService.createUpload(kwargs);

    dispatch({
      type: SET_MESSAGE,
      payload: {
        info: 'New List has been successfully uploaded. Our data experts will be in touch with you when it is ready',
        status: '200',
      }
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const getUploads = () => async (dispatch) => {
  try {

    const response = await UploadService.getUploads();
    const { upload_list } = response?.data?.data || {};

    dispatch({
      type: DATA,
      payload: {
        upload_list,
      },
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const getUpload = (kwargs) => async (dispatch) => {
  try {
    const response = await UploadService.getUpload(kwargs);
    const { upload_list} = response?.data?.data || {};
    const upload = upload_list?.[0];
    
    dispatch({
      type: DATA,
      payload: {
        upload,
      },
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const getUploadAnalytics = (kwargs) => async (dispatch) => {
  try {
    const response = await UploadService.getUploadAnalytics(kwargs);
    const { upload_list } = response?.data?.data || {};
    const { analytics_stats } = upload_list?.[0] || {};

    dispatch({
      type: DATA,
      payload: {
        upload_analytics: analytics_stats,
      },
    });
  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const deleteUpload = (kwargs) => async (dispatch) => {
  try {

    const response = await UploadService.deleteUpload(kwargs);

    dispatch({
      type: SET_MESSAGE,
      payload: {
        info: 'Requested List has been permanently deleted',
        status: '200',
      }
    });

  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};

export const refreshUpload = (kwargs) => async (dispatch) => {
  try {

    const response = await UploadService.refreshUpload(kwargs);

    dispatch({
      type: SET_MESSAGE,
      payload: {
        info: 'The refresh of the requested List has been successfully started',
        status: '200',
      }
    });

  }
  catch(err) {

    dispatch({
      type: SET_MESSAGE,
      payload: parseResponseError(err),
    });
  }
};
