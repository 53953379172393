import React from 'react';
import { Paper } from '@mui/material';
import { OrdersList } from './OrdersList';

export function OrdersGetAll(props) {
  return (
    <Paper id="orders-get-all" sx={{ boxShadow: 10, borderRadius: 1, p: 2 }}>
      <OrdersList {...props} />
    </Paper>
  )
};
