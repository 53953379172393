import { 
  isEmpty as _isEmpty,
  merge as _merge,
  mergeWith as _mergeWith,
} from 'lodash';
import { v4 } from 'uuid';
import axiosClient from './axiosClient';
import parsePhoneNumber from 'libphonenumber-js'

export const isEmpty = _isEmpty;
export const merge = _merge;

const _mergeArrayOfObjectsByKey = (a, b, ...keys) => {
  const key = a
    ?.map(x => Object.keys(x))
    .flat()
    .find(x => keys.includes(x));

    if (key) {
      const reduced = a.filter(_a => !b.find(_b => _a[key] === _b[key]));
      return reduced.concat(b).sort((x,y) => x[key].toString().localeCompare(y[key].toString())); 
    }
};

export const mergeDeep = (prev, curr) => _mergeWith(prev, curr, (oldValue, newValue, key) => {
  if (key === 'third_party_service_limits') {

    if (Array.isArray(oldValue) && Array.isArray(newValue)) {
      newValue.forEach(requestedService => {
        const existingService = oldValue.find(x => x.service === requestedService?.service);
        
        // push to the limits array of the existing service
        if (existingService) {
          existingService.limits = _mergeArrayOfObjectsByKey(existingService.limits, requestedService.limits, 'domain');
        }
        // create new service element in the third_party_service_limits array
        else {
          oldValue.push(requestedService);
        }
      })
      return oldValue;
    }
  }
  else if (key === 'limits') {
    oldValue = _mergeArrayOfObjectsByKey(oldValue, newValue, 'resource');
    return oldValue;
  }
});

export const parseResponseError = (err) => {

  const error = err.response?.data?.message || err.response?.data?.error || err.response?.statusText;
  const status = err.response?.data?.status || err.response?.status;
  const event_id = err.response?.data?.event_id || err.response?.data?.transaction_id; 

  return {
    error: error
      ? `Server Error ID: ${event_id}: ${error}` 
      : `Client Error ID: ${Date.now()}: ${err.message}. Please report this error to helpdesk`,
    status,
  }
};

export const parseAuthResponseError = (err) => ({
  warning: err.response?.data?.error,
  status: err.response?.status || err.response?.data?.status,
});

export const parseResponseInfo = (response) => ({
  info: response.data?.info || response.data?.statusText,
  status: response.data?.status,
});

/**
 * '# OF ROWS in The File' -> 'of_rows_in_the_file'
 */
export const prepareString = (string = '') => {
  return string?.toLowerCase().replace(/[\W\-_.]+/ig, '_').replace(/^_*/, '');
};

export const sortBy = (field, type = 'asc') => (a,b) => {  
  const x = a[field] || a['dt_created'];
  const y = b[field] || b['dt_created'];
  return x > y ? (type === 'desc' ? -1 : 1) : x < y ? (type === 'desc' ? 1 : -1) : 0;
};

/** '2022-04-20T03:33:23.702Z' -> '20220420_033323' */
export const getFormattedDt = () => {
  return new Date()
    .toISOString()
    .replace(/.\d{3}Z/, '')
    .replaceAll('-', '')
    .replaceAll(':', '')
    .replace('T', '_');
}

export const uuidv4 = () => v4();

export const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const isObject = (string) => typeof string === 'object' && !Array.isArray(string) && !isEmpty(string);

/**
 * 
 * @param {*} user 
 * @param {*} additional_data 
 * @returns 
 */
export const constructURLQueryString = (user, additional_data) => {
  const query = {
    firstname: user?.first_name,
    lastname: user?.last_name,
    phone: user?.phone_number,
    email: user?.email_address,
    ...additional_data,
  };

  const queryEntries = [];
  for (const [key, value] of Object.entries(query)) {
    if (value) queryEntries.push(`${key}=${value}`)
  }

  return queryEntries.join('&');
}

export const sendHttpRequest = axiosClient;

export const getDuration = (date) => {

  const duration = {};
  duration.ms = Date.now() - new Date(date);
  duration.second = parseInt(duration.ms / 1000);
  duration.minute = parseInt(duration.second / 60);
  duration.hour = parseInt(duration.minute / 60);
  duration.day = parseInt(duration.hour / 24);
  duration.month = parseInt(duration.day / (365.25 / 12));
  duration.year = parseInt(duration.month / 365.25);

  let longestDuration = '';
  for (const [key, value] of Object.entries(duration)) {
    if (value > 0) {
      longestDuration = `${value} ${key}`;
      if (value > 1) {
        longestDuration += 's';
      }
    }
  }

  return { duration, longestDuration };
};

export const getFileExtension = (filename) => {
  return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
};

/**
 * 
 * @param {*} obj 
 * @param {...any} keys 
 * @returns 
 */
export const objectPick = (obj, ...keys) => Object.fromEntries(
  keys
  .filter(key => key in obj)
  .map(key => [key, obj[key]])
);

/**
 * 
 * @param {*} data 
 * @param {*} key (default = null) 
 * @returns 
 */
export const getAthenaTable = (data, key = null) => {
  if (!data) return null;
  const table_name = data[key]?.identifiers_table || data[key]?.name || data.table;  
  const athena_table = `${data.database}.${table_name}`;
  const athena_table_partitions = data.partitions?.map(x => `${x.key} in (${x.values?.map(x=>`'${x}'`)?.join(',') || `'${x.value}'`})`)?.join(' and ');
  return athena_table + ( athena_table_partitions ? ` where ${athena_table_partitions}` : '');
};

export const round = (number, decimals = 2) => parseFloat(parseFloat(number).toFixed(decimals));

/**
 * 
 * @param {*} string 
 * @param {*} decimals (default = 0)
 * @returns 
 */
export const formatNumber = (string, decimals = 2) => !isNaN(string) ? round(string, decimals).toLocaleString() : string;

/**
 * 
 * @param {*} bytes 
 * @param {*} decimals (default = 1)
 * @returns 
 */
export const bytesDisplay = (bytes, decimals = 2) => {
  const bytesAsNumber = parseInt(bytes) || 0;

  let number = bytesAsNumber;
  let suffix = 'B';
  if (bytesAsNumber >= 1024 * 1024 * 1024) {
    number = decimalDisplay(bytesAsNumber/1024/1024/1024, decimals);
    suffix = 'GB';
  }
  else if (bytesAsNumber >= 1024 * 1024 ) {
    number = decimalDisplay(bytesAsNumber/1024/1024, decimals);
    suffix = 'MB';
  }
  else if (bytesAsNumber >= 1024) {
    number = decimalDisplay(bytesAsNumber/1024, decimals);
    suffix = 'KB';
  }
  
  return `${number} ${suffix}`;
};

export const secondsDisplay = (seconds, decimals = 0) => {
  const secondsAsNumber = parseInt(seconds) || 0;
  let number = secondsAsNumber;

  let suffix = 'seconds';
  if (secondsAsNumber >= 60*60) {
    number = decimalDisplay(secondsAsNumber/60/60, decimals);
    suffix = 'hour(s)';
  }
  if (secondsAsNumber >= 60) {
    number = decimalDisplay(secondsAsNumber/60, decimals);
    suffix = 'minute(s)';
  }

  return `${number} ${suffix}`;
}
export const decimalDisplay = (value, decimals = 2) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(value);
}

export const priceDisplay = (price, decimals = 2, currency = 'USD') => {
  const display_price = round(price / Math.pow(10, decimals));
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  }).format(display_price);
};

export const phoneNumberDisplay = (phone) => {
  return parsePhoneNumber(phone || '', 'US')?.formatInternational();
};

export const percentDisplay = (value, decimals = 0) => {
  if (!value || value === Infinity) return '0%';
  let percent = value;
  if (percent > 0 && percent < 0.01) percent = '<1%'
  else {
    percent = new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).format(percent);
  }
  return percent;
}

export const dataGridColumns = {
  display_price: {
    type: 'number',
    valueFormatter: (value, row, column, apiRef) => {
      const { amount, price, decimals, currency } = row;
      return priceDisplay(amount || price, decimals, currency);
    },
  },
  display_percent: {
    type: 'number',
    valueFormatter: (value, row, column, apiRef) => percentDisplay(value),
  },
  display_phone: {
    type: 'string',
    valueFormatter: (value, row, column, apiRef) => phoneNumberDisplay(value),
  },
};

export const getPrice = (pricing_schedule, count) => {
  if (!pricing_schedule || count < 0) return null;
  const price = pricing_schedule
    ?.filter(x => (
      x.threshold <= count
    ))
    ?.reduce((acc, curr) => (
      curr.threshold >= acc.threshold ? curr : acc
    ));

  return round(price.cpm * count / 1000, 0);
};

export const constructS3Uri = (bucket, prefix) => bucket && prefix && `s3://${bucket}/${prefix}`;
