import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';

//mui styling
import { 
  ThemeProvider,
  createTheme,
} from '@mui/material/styles';

import { enableAcessibilityWidget } from './components/Accessibility';
import { Footer, Header, Dashboard, Home } from './components';
import { BdexSnackbar } from './components/Common';
import { Login, Register, ActivateAccount, ResendActivation, ForgotPassword, ResetPassword } from './components/Auth';
//uploads/lists
import {
  UploadsCreate,
  UploadsGetAll,
  UploadsGetOne,
  UploadHistory as UploadsGetOneHistory,
} from './components/Uploads';
//files
import {
  FilesCreate,
  FilesGetAll,
  FilesGetOne,
  FileHistory as FilesGetOneHistory,
} from './components/Files';
//runs/xaudiences
import {
  RunsCreate,
  RunsGetAll,
  RunsGetOne,
  RunHistory as RunsGetOneHistory,
} from './components/Runs';
//audiences
import {
    AudiencesCreate,
    AudiencesGetAll,
    AudiencesGetOne,
    AudienceHistory as AudiencesGetOneHistory,
  } from './components/Audiences';
//pixels
import {
  PixelsCreate,
  PixelsGetAll,
  PixelsGetOne,
  PixelVisits,
} from './components/Pixels';
//analytics
import {
  UploadAnalytics as UploadsGetOneAnalytics,
  RunAnalytics as RunsGetOneAnalytics,
  AudienceAnalytics as AudiencesGetOneAnalytics,
} from './components/Analytics';
//activate
import { 
  Partners,
} from './components/Activate';
//feedback
import Feedback from './components/Feedback';
//shopping cart
import ShoppingCart from './components/Orders/Create/ShoppingCart';
//orders
import {
  OrdersGetAll,
  OrdersGetOne,
  OrderHistory as OrdersGetOneHistory,
} from './components/Orders';

export default function App() {

  // mui styling theme
  //https://mui.com/material-ui/customization/theme-components/

  const theme = createTheme({
    // palette: {
    //   primary: {
    //     main: '#0052cc',
    //   },
    //   secondary: {
    //     main: '#edf2ff',
    //   },
    //},
    components: {
      // //works
      // MuiInput: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: "green"
      //     },
      //   },
      // },
      // //works
      // MuiButton: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: "green"
      //     },
      //   },
      // },
      //doesn't work
      // MuiDataGrid: {
      //   styleOverrides: {
      //     // root: {
      //     //   backgroundColor: 'red',
      //     // },
      //     // cell: {
      //     //   backgroundColor: "blue",
      //     // cellContent: {
      //     //   backgroundColor: "green",
      //     // },
      //     columnHeader: {
      //       backgroundColor: "yellow",
      //       fontWeight: 'bold',
      //     },
      //     columnHeaderTitle: {
      //       backgroundColor: "yellow",
      //       fontWeight: 'bold',
      //     },
      //     // '.MuiDataGrid-columnSeparator': {
      //     //   display: 'none',
      //     // },
      //     // '&.MuiDataGrid-root': {
      //     //   border: 'none',
      //     // },
      //     // '& .MuiDataGrid-columnHeaderTitle': {
      //     //   backgroundColor: "red",
      //     //   fontWeight: 'bold',
      //     // },
      //     // '& .MuiDataGrid-columnHeader': {
      //     //   backgroundColor: "yellow",
      //     // },
      //   }
      // }
    }
  });

  const { userAuthenticated } = useSelector(state => state.auth);

  // enable accessibility widget on initial load
  useEffect(() => {
    enableAcessibilityWidget();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {/* <Router history={history}> */}
      <div className='App'>

        <BdexSnackbar />

        { !userAuthenticated ? <Header /> : '' }

        { userAuthenticated ?
        /*
        ******************************
        * User is authenticated
        ******************************
        */
        <Routes>
          <Route path="/" element={ <Dashboard /> }>
            <Route index element={ <Home /> } />
            {/* uploads (lists, understand) */}
            <Route path="lists">
              <Route index element={ <UploadsGetAll checkboxes={true} /> } />
              <Route path="new" element={ <UploadsCreate /> } />
              <Route path=":upload_key">
                <Route index element={ <UploadsGetOne /> } />
                <Route path="analytics" element={ <UploadsGetOneAnalytics /> } />
                <Route path="history" element={ <UploadsGetOneHistory/> } />
              </Route>
            </Route>
            {/* files (files, data clean room) */}
            <Route path="files">
              <Route index element={ <FilesGetAll checkboxes={true} /> } />
              <Route path="new" element={ <FilesCreate /> } />
              <Route path=":file_key">
                <Route index element={ <FilesGetOne /> } />
                <Route path="history" element={ <FilesGetOneHistory/> } />
              </Route>
            </Route>
            {/* runs (xaudiences, target) */}
            <Route path="xaudiences">
              <Route index element={ <RunsGetAll checkboxes={true}/> } />
              <Route path="new" element={ <RunsCreate /> } />
              <Route path=":run_key">
                <Route index element={ <RunsGetOne /> } />
                <Route path="analytics" element={ <RunsGetOneAnalytics /> } />
                <Route path="history" element={ <RunsGetOneHistory/> } />
              </Route>
            </Route>
            {/* audiences */}
            <Route path="audiences">
              <Route index element={ <AudiencesGetAll checkboxes={true}/> } />
              <Route path="new" element={ <AudiencesCreate /> } />
              <Route path=":audience_key">
                <Route index element={ <AudiencesGetOne /> } />
                {/* <Route path="analytics" element={ <AudiencesGetOneAnalytics /> } /> */}
                {/* <Route path="history" element={ <AudiencesGetOneHistory/> } /> */}
              </Route>
            </Route>
            {/* pixels */}
            <Route path="pixels">
              <Route index element={ <PixelsGetAll /> } />
              <Route path="new" element={ <PixelsCreate /> } />
              <Route path=":pixel_key">
                <Route index element={ <PixelsGetOne /> } />
                <Route path="visits" element={ <PixelVisits /> } />
              </Route>
            </Route>
            {/* activate */}
            <Route path="activate">
              <Route index element={ <Partners />} />
              <Route path="partners" element={ <Partners />} />
            </Route>
            {/* general */}
            <Route path="account" element={ <Home />} />
            <Route path="cart" element={ <ShoppingCart />} />
            {/* general - orders */}
            <Route path="orders">
              <Route index element={ <OrdersGetAll /> } />
              <Route path=":order_key">
                <Route index element={ <OrdersGetOne /> } />
                <Route path="history" element={ <OrdersGetOneHistory/> } />
              </Route>
            </Route>
            <Route path="feedback" element={ <Feedback />} />            
            {/* default (anything else) - redirect to '/' */}
            <Route path="*" element={ <Navigate replace to={"/"} /> } />
          </Route>
        </Routes>
        :
        /*
        ******************************
        * User is NOT authenticated
        ******************************
        */  
        <Routes>
          {/* login */}
          <Route path="/auth/login" element={ <Login /> } />

          {/* register */}
          <Route path="/auth/register" element={ <Register /> } />
          
          {/* activate-account */}
          <Route path="/auth/activate-account" element={ <ActivateAccount /> } />

          {/* resend-activation */}
          <Route path="/auth/resend-activation" element={ <ResendActivation /> } />

          {/* forgot-password */}
          <Route path="/auth/forgot-password" element={ <ForgotPassword /> } />

          {/* reset-password */}
          <Route path="/auth/reset-password" element={ <ResetPassword /> } />
          
          {/* any other path - navigate to '/auth/login' */}
          <Route path="*" element={ <Navigate replace to={"/auth/login"} /> } />

        </Routes>
        }

        { !userAuthenticated ? <Footer /> : '' }

      </div>
    </ThemeProvider>
  );
};
