import React from 'react'
import { CircularProgress, Stack, Tooltip, Typography } from '@mui/material';

export default function DataGridStatusColumn(props) {

  const { params, type } = props;
  
  const { percent_completed = 0 } = params.row;
  
  let status = 'unknown';
  for (const [key, value] of Object.entries(params.row)) {
    if (key.endsWith('status') && value) {
      status = value.status || value;
    }
  };

  const colors = {
    uploads: {
      success: ['available'],
      error: ['deleted'],
    },
    runs: {
      success: ['processed'],
      error: ['deleted'],
    },
    files: {
      success: ['uploaded'],
      error: ['deleted'],
    },
    orders: {
      success: ['available', 'delivered'],
      error: ['refunded', 'cancelled'],
    },
    audiences: {
        success: ['available', 'delivered'],
        error: ['deleted'],
      },    
  };

  let color = 'warning';
  for (const [k, v] of Object.entries(colors[type])) {
    if (v.includes(status)) {
      color = k;
    }
  };

  return (
    <Tooltip title={`${status}, ${percent_completed}% completed`} arrow>
      <Stack direction="row" spacing={0.5} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
          <CircularProgress
            variant="determinate"
            thickness={3}
            value={percent_completed}
            size={20}
            sx={{ display: 'flex', color: `${color}.light` }}
            {...props}
          />
        <Typography variant="inherit" noWrap sx={{ color: `${color}.light` }}>{status}</Typography>
      </Stack>
    </Tooltip>
  )
};
