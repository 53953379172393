import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Grid, Stack, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { getRun } from '../../../actions/run';
import { clearMessage } from '../../../actions/message';
import { isEmpty, formatNumber, percentDisplay } from '../../../lib/Utils';
import { uploadListSchema } from '../../Uploads/config';
import { AutoRefresh } from '../../Common';

export function RunDetails() {
    
  const { run } = useSelector(state => state.data);
  const { user } = useSelector(state => state.auth);

  const dispatch = useDispatch();
  
  const params = useParams();
  const { run_key } = params;

  const [searchParams] = useSearchParams();
  const user_key = searchParams.get('user_key');

  const [availableRows, setAvailableRows] = useState([]);
  const [positiveRows, setPositiveRows] = useState([]);
  const [negativeRows, setNegativeRows] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  const handleGetRun = () => {
    dispatch(getRun({
      run_key,
      user_key,
    }));
  };
  
  // navigate to '/xaudiences' if error 400 or 404 received
  const navigate = useNavigate();
  const { error, status } = useSelector(state => state.message);
  useEffect(() => {

    if (status === 404 || (status === 400 && error?.includes('User does not have a requested'))) {
      dispatch(clearMessage());
      navigate('/xaudiences');
    }
  }, [error, status, dispatch, navigate]);

  // initial load
  useEffect(() => {

    // set 'run' state
    handleGetRun();

    // isAdmin?
    if (user.permissions?.resources?.includes('others.runs')) {
      setIsAdmin(true);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(run)) {
      const { 
        counts = {},
        athena_tables = [],
        upload_list_positive,
        upload_list_negative,
      } = run;

      const {
        xaudience_row_count,
        xidentifiers_row_counts = {},
        modeling_stats = {},
      } = counts;

      const rows = [
        {
          id: 1,
          key: 'Name',
          value: run.name,
        },
        {
          id: 2,
          key: 'Comments',
          value: run.comments,
        },
        {
          id: 3,
          key: 'Created',
          value: run.dt_created,
        },
        {
          id: 4,
          key: 'Last Updated',
          value: run.dt_last_updated,
        },    
        {
          id: 5,
          key: 'Last Stage Description',
          value: run.description,
        },
        {
          id: 6,
          key: 'Status',
          value: run.status,
        },
      ];

      if (isAdmin) {

        rows.push({
          id: rows.length + 1,
          key: 'run_id*',
          value: run.id,
        })

        rows.push({
          id: rows.length + 1,
          key: 'run_key*',
          value: run.key,
        })

        rows.push({
          id: rows.length + 1,
          key: 'user_email*',
          value: run.user_email_address,
        })

        rows.push({
          id: rows.length + 1,
          key: 'user_key*',
          value: run.user_key,
        })

        rows.push({
          id: rows.length + 1,
          key: 'user_id*',
          value: run.user_id,
        })

      }

      // xaudience
      xaudience_row_count >= 0 && rows.push({
        id: rows.length + 1,
        key: 'XAudience Size (consumers)',
        value: formatNumber(xaudience_row_count),
      })
      
      if (isAdmin) {

        // xidentifiers
        for (const [k, v] of Object.entries(xidentifiers_row_counts)) {
          rows.push({
            id: rows.length + 1,
            key: `> Linked identifiers (${k})*`,
            value: formatNumber(v),
          })
        }

        // modeling stats
        for (const [k, v] of Object.entries(modeling_stats)) {
          rows.push({
            id: rows.length + 1,
            key: `Modeling ${k}*`,
            value: percentDisplay(v),
          })
        }

        // athena tables
        for (const [k, v] of Object.entries(athena_tables)) {
          if (v) {
            rows.push({
              id: rows.length + 1,
              key: `athena_${k}*`,
              value: v,
            })
          }
        }

      }

      setAvailableRows(rows);
      setPositiveRows(upload_list_positive);
      setNegativeRows(upload_list_negative);

    }
  }, [run])
  
  let columns = [];
  let uploadListColumns = [];
  if (!isEmpty(run)) {
    columns = [
      {
        field: 'key',
        minWidth: '120px',
        flex: 1,
        display: 'flex',
      },
      {
        field: 'value',
        minWidth: '240 px',
        flex: 1,
      },
    ];

    if (!isEmpty(positiveRows)) {
      uploadListColumns = Object.keys(positiveRows[0]).map(x => {
        return {
          field: x,
          headerName: x,
        };
      });
  
      // set up from the config
      uploadListColumns = uploadListSchema.filter(x => x.id === positiveRows?.id);

      // remove all admin-visible columns if not an admin 
      if (!isAdmin) {
        uploadListColumns = uploadListColumns.filter((x) => !x.onlyVisibleToAdmin);
      }

      // filter out columns for 'mini' view
      uploadListColumns = uploadListColumns.filter(x => x.mini);
      
    }
  }

  return (
    <Grid container id="runs-get-one-run-details">
      <Grid item xs={6} sx={{ my: 1 }}>
        <Typography><strong>XAudience (Expanded Audience) Details</strong></Typography>
      </Grid>
      <Grid item xs={6} sx={{ my: 1 }}>
        <AutoRefresh handler={handleGetRun} off={true} />
      </Grid>
      <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}>
        <DataGrid
          rows={availableRows}
          columns={columns}
          hideFooter={true}
          autoHeight={true}
          density={'compact'}
          customHeadRender={() => null }
          sx={
            {
              m: 1,
              // hide header
              "& .MuiDataGrid-columnHeaders": { display: "none" },
              "& .MuiDataGrid-virtualScroller": { marginTop: "0!important" },
              // highlight admin-visible rows
              "& .MuiDataGrid-row--admin-visible": {
                bgcolor: '#e0e0e0',
                '&:hover': {
                  bgcolor: '#d0d0d0',
                }
              }
            }
          }
          getRowClassName={(params) => {
            if (params.row.key.endsWith('*')) {
              return `MuiDataGrid-row--admin-visible`;
            }
          }}      
        />
      </Grid>
      <Grid item xs={12} sx={{ my: 1 }}>
        <Typography><strong>Positive List{positiveRows.length > 1 ? 's' : ''} used to create this XAudience</strong></Typography>
      </Grid>
      <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}>
        <DataGrid
          rows={positiveRows}
          columns={uploadListColumns}
          hideFooter={true}
          autoHeight={true}
          density={'compact'}
          sx={{ m: 1 }}
        />
      </Grid>
      <Grid item xs={12} sx={{ my: 1 }}>
        <Typography><strong>Negative List{negativeRows.length > 1 ? 's' : ''} used to create this XAudience</strong></Typography>
      </Grid>          
      <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}>
        <DataGrid
          rows={negativeRows}
          columns={uploadListColumns}
          hideFooter={true}
          autoHeight={true}
          density={'compact'}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                None
              </Stack>
            ),
          }}
          sx={{ m: 1 }}
        />
      </Grid>
    </Grid>
  );
}
