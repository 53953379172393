import React from 'react';
import { Paper } from '@mui/material';
import { AudiencesList } from './AudiencesList';

export function AudiencesGetAll(props) {
  return (
    <Paper id="audiences-get-all" sx={{ boxShadow: 10, borderRadius: 1, p: 2 }}>
      <AudiencesList {...props} />
    </Paper>
  )
};
