import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  Alert,
  AlertTitle,
  Box,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { setDataState } from '../../../actions/data';

export default function NewUploadAdditionalData() {

  const { upload_new: upload } = useSelector(state => state.data);
  const { file_name } = upload;

  const dispatch = useDispatch();

  const [data, setData] = useState({
    upload_name: file_name || '',
    upload_comments: '',
  });

  const handleDataChange = (event) => {
    const { name, value } = event.target;
    setData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  // update local useState values on new file/file change
  useEffect(() => {
    if (file_name) {
      setData({
        ...upload,
        upload_name: file_name,
        upload_comments: '',
      })
    }
  }, [setData, file_name]);

  // update values in redux
  useEffect(() => {
    if (data?.upload_name) {
      dispatch(setDataState('upload_new', data));
    }
  }, [data]);

  return (
    <>
      { file_name ?
        <>
          <Grid container id="uploads-create-new-upload-additional-data-upload-name" sx={{ my: 1 }}>
            <Grid item xs={12} sx={{ my: 1 }}>
              <Typography><strong>New List: Name</strong></Typography>
            </Grid>
            <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}>
              <Alert severity="info" variant="outlined">
                <AlertTitle><strong>Optional!</strong></AlertTitle>
                <Box>
                  Overwrite the uploaded file name so you can easy identify it at a later stage.
                  It might be a good idea to include a type of the List, date, project name, etc.
                  Be as descriptive as possible, as any format is accepted here
                </Box>
                <Box>
                </Box>
              </Alert>
            </Grid>
            <Grid item xs={12} sx={{ m: 1 }}>
              <TextField
                type="text"
                name="upload_name"
                variant="standard"
                size='small'
                margin='normal'
                fullWidth
                required={true}
                multiline={false}
                rows={1}
                value={data.upload_name}
                onChange={handleDataChange}
                autoFocus
              />
            </Grid>
          </Grid>
          <Grid container id="uploads-create-new-upload-additional-data-upload-comments">
            <Grid item xs={12} sx={{ my: 1 }}>
              <Typography><strong>New List: Comments</strong></Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ m: 1 }}>
            <TextField
              type="text"
              name="upload_comments"
              placeholder="Any additional information related to this List"
              variant="standard"
              size='small'
              margin='normal'
              fullWidth
              required={false}
              multiline={true}
              rows={5}
              value={data.upload_comments}
              onChange={handleDataChange}
            />
          </Grid>
        </>
        : null
      }
    </>
  )
}
