import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Divider, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  AddShoppingCart as AddShoppingCartIcon,
  Edit as EditIcon,
  KeyboardDoubleArrowUp as KeyboardDoubleArrowUpIcon,
} from '@mui/icons-material';
import { getPrice, isEmpty, merge } from '../../../lib/Utils';
import { runAnalyticsPaidSchema } from './config/runAnalyticsPaidSchema';
import RunAnalyticsPaidConfirmationDialog from './RunAnalyticsPaidConfirmationDialog';

export function RunAnalyticsPaid(props) {

  const { 
    run_props,
  } = props;

  const initialState = {
    demographic_data: [],
    identity_data: [],
  };

  const [availableRows, setAvailableRows] = useState(initialState);
  const [checkedRowIDs, setCheckedRowIDs] = useState(initialState);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  // const [updateLimitEvent, setUpdateLimitEvent] = useState(0);

  const { run_analytics } = useSelector(state => state.data);
  const confirmRef = useRef(null);

  useEffect(() => {
    if (!isEmpty(run_analytics)) {

      let demographic_data = [];
      if (!isEmpty(run_analytics.demographic_data)) {

        // demographic_data - get mapping from the 'field' field config
        const demographic_data_mapping = runAnalyticsPaidSchema.demographic_data?.fields.find(x => x.field === 'field').mappings;

        demographic_data = Object.entries(demographic_data_mapping)
          .map(([key, value], id) => {

            const item = run_analytics.demographic_data?.find(x => x.key === key) || {};
            const included_in = value.included_in;
            const display_name = value.display_name || item.name;
            const description = value.description || item.description;
            //const field = included_in ? ` > ${display_name}` : display_name;            
            const field = display_name;            

            return {
              ...item,
              id,
              field,
              key,
              description,
              included_in,
            }
          })
          .filter(x => x.field);
      }

      let identity_data = [];
      if (!isEmpty(run_analytics.identity_data)) {

        // identity_data - get mapping from the 'field' field config
        const identity_data_mapping = runAnalyticsPaidSchema.identity_data?.fields.find(x => x.field === 'field').mappings;
      
        identity_data = identity_data_mapping && Object.entries(identity_data_mapping)
          .map(([key,value], id) => {
            const item = run_analytics.identity_data?.find(x => x.key === key) || {};

            const included_in = value.included_in;
            const display_name = value.display_name || item.name;
            const description = value.description || item.description;
            const field = display_name;

            // always start at 0 records
            const purchased_count = item.purchased_count || 0;
            const pricing_schedule = item.pricing_schedule;
            const price = getPrice(pricing_schedule, purchased_count);
   
            return {
              ...item,
              id,
              field,
              key,
              description,
              included_in,
              purchased_count,
              price,
            }
          })
          .filter(x => x?.field)
      }

      setAvailableRows({
        demographic_data,
        identity_data,
      });
    }
  }, [run_analytics]);

  const checkedRowsCount = Object.values(checkedRowIDs).reduce((acc, curr) => acc.concat(curr), []).length;

  const renderColumns = (section) => ({
    [section]: runAnalyticsPaidSchema[section]?.fields.map((x, i) => {

      if (x.field === 'field') {
        x.renderCell = (params) => {
          const { id, description, field, included_in } = params.row;
          const _description = included_in ? `${description}. Included in ${included_in.toUpperCase()}` : description;
          return (
            <Stack key={i} direction="row" spacing={0} justifyContent="space-between" alignItems="center">
              <Tooltip title={_description} placement="right" arrow>
                <Typography variant="inherit" noWrap sx={ included_in ? { pl: 3 } : {}}>{field}</Typography>
              </Tooltip>
              { checkedRowIDs[section]?.includes(id) ?
                <Tooltip title={`Add all checked (${checkedRowsCount}) Analytics segments to Shopping Cart`} arrow>
                  <Button
                    onClick={handleConfirm}
                  >
                    <AddShoppingCartIcon
                      fontSize="small"
                      color="primary"
                    />
                  </Button>
                </Tooltip>
                : null
              }
            </Stack>
          )
        };
      }

      if (x.field === 'purchased_count') {
        x.renderHeader = () => (
          <Tooltip title={`Set the number of Records to be purchased`} arrow>
            <Box>
              {`Records to purchase`}
              <EditIcon
                fontSize="sm"
                color="action"
                sx={{ ml: 1 }}
              />
            </Box>
          </Tooltip>
        );

        x.renderCell = (params) => {
          const { id, field, row } = params || {};

          const handleSetToMax = () => {
            params.row.purchased_count = params.row.found_count;
            params.row.price = getPrice(params.row.pricing_schedule, params.row.purchased_count);
            // setUpdateLimitEvent(uuidv4());

            const updatedRows = availableRows[section].map(x => {
              if (x.id === params.row.id) return params.row;
              else return x;
            })

            setAvailableRows(prev => {
              return {
                ...prev,
                [section]: updatedRows,
              }
            })            
          }
          
          return (
            <Box>
              <Tooltip title={
                <Box>
                  <Typography variant="body2">{`Change the value`}</Typography>
                  <Divider />
                  <Button
                    variant="body2"
                    onClick={handleSetToMax}
                    startIcon={<KeyboardDoubleArrowUpIcon />}
                  >
                    {"set to max"}
                  </Button>
                </Box>
              } arrow>
                <Box>
                  {params.formattedValue}
                </Box>
              </Tooltip>
            </Box>
          )
        };

      }

      return x;
    }),
  })

  const columns = {
    ...renderColumns('demographic_data'),
    ...renderColumns('identity_data'),
  }

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => 
    Object.fromEntries(
      Object.entries(merge({}, columns))
        .map(([key, value]) => (
          [
            key,
            value?.filter(x => x.hide === true)?.reduce((a, v) => ({ ...a, [v['field']]: false }), {})
          ]
        )
      )
    )
  );

  // filter out columns for 'mini' view
  const columnsMini = Object.fromEntries(
    Object.entries(merge({}, columns))
      .map(([k, v]) => {
        // delete a custom renderHeader defined in this file, make all cells non editable and use the config for any overwrites
        v?.forEach((x,i) => { 
          delete x.renderHeader;
          delete x.editable;
          
          if (x.preOrder) v[i] = { ...x, ...x.preOrder };
        });
        return [k, v?.filter(x => x.mini && !x.preOrder?.hide)];
      }
  ));

  const rowsMini = Object.fromEntries(
    Object.entries(merge({}, checkedRowIDs))
      .map(([k, v]) => [k, availableRows[k]?.filter(x => v.includes(x.id)).filter(x => x.purchased_count > 0)])
  );
  const rowsMiniCount = Object.values(rowsMini).reduce((acc, curr) => acc.concat(curr), []).length;

  const handleConfirm = () => {
    setOpenConfirmDialog(true);
  };

  const clearCheckedRowIDs = () => {
    setCheckedRowIDs(initialState);
  }

  return (
    <>
      { openConfirmDialog ?
        <RunAnalyticsPaidConfirmationDialog
          openDialog={openConfirmDialog}
          setOpenDialog={setOpenConfirmDialog}
          run_props={run_props}
          columns={columnsMini}
          rows={rowsMini}
          clearCheckedRowIDs={clearCheckedRowIDs}
        />
        : null
      }
      <Grid container id={'run-analytics-paid'}>
        <>
          { Object.entries(runAnalyticsPaidSchema)
            .filter(([k,]) => !isEmpty(availableRows?.[k]))
            .map(([k, v], i) => (
              <Grid key={`run-analytics-paid-${i}`} container>
                <Grid item xs={12} sx={{ m: 1 }}>
                  <Typography><strong>{v.name}</strong></Typography>
                </Grid>
                <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, mb: 1 }}>
                  <DataGrid
                    rows={availableRows[k]}
                    columns={columns[k]}
                    columnVisibilityModel={columnVisibilityModel[k]}
                    onColumnVisibilityModelChange={(x) => setColumnVisibilityModel(columnVisibilityModel[k] = x)}
                    hideFooter={true}
                    checkboxSelection={true}
                    disableRowSelectionOnClick={true}
                    autoHeight={true}
                    density={'compact'}
                    rowSelectionModel={checkedRowIDs[k]}
                    processRowUpdate={(updatedRow, originalRow) => {
                      const { purchased_count, found_count } = updatedRow;

                      // validation 
                      if (!purchased_count || purchased_count <0 || purchased_count > found_count) {
                        updatedRow.purchased_count = 0;
                      }

                      // ensure the purchased_count is interger, and update the price
                      updatedRow.purchased_count = parseInt(updatedRow.purchased_count);
                      updatedRow.price = getPrice(updatedRow.pricing_schedule, updatedRow.purchased_count);

                      const updatedSectionRows = availableRows[k].map(x => {
                        if (x.id === originalRow.id) return updatedRow;
                        else return x;
                      })

                      setAvailableRows(prev => {
                        return {
                          ...prev,
                          [k]: updatedSectionRows,
                        }
                      })

                      return updatedRow;
                    }}
                    onProcessRowUpdateError={(err) => {
                      console.log(err);
                    }}
                    onRowSelectionModelChange={(selection) => {
                      const _selection = selection.filter(x => {
                        const item = availableRows[k].find(y => y.id === x);
                        return item.purchased_count !== 0
                      })

                      setCheckedRowIDs(prev => {
                        // scroll to the button only on 1st checked item
                        if (checkedRowsCount === 0) confirmRef.current.scrollIntoView();

                        return {
                          ...prev,
                          [k]: _selection,
                        }
                      })
                    }}
                    isRowSelectable={(params) => {
                      if (params.row.purchased_count === 0) return false;
                      return true;
                    }}
                    sx={
                      {
                        m: 1,
                        // hideSelectAllCheckbox
                        '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
                          display: 'none',
                        }
                      }
                    }
                  />
                </Grid>
              </Grid>
            ))
          }
          { rowsMiniCount !== 0 ?
            <Grid item xs={12}>
              <Tooltip title={`Add all checked (${rowsMiniCount}) Analytics segments to Shopping Cart`} arrow>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleConfirm}
                  endIcon={<AddShoppingCartIcon />}
                  sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}
                >
                  {"Add to Shopping Cart"}
                </Button>
              </Tooltip>
            </Grid>
            : null
          }
          <div ref={confirmRef}></div>
        </>
      </Grid>
    </>
  )
}
