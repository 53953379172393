import React, { useEffect, useState } from 'react';
import { Rating, Box, Paper, Grid, Typography, TextField, Button } from '@mui/material';
import { 
  Star as StarIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material';
import { feedbackLabels } from './config';
import { clearMessage } from '../../actions/message';
import { createUserFeedback } from '../../actions/user';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const { rate, recommend } = feedbackLabels;

const getLabelText = (type) => (value) => {
  return `${value} Star${value !== 1 ? 's' : ''}, ${type[value]}`;
}

export default function Feedback() {

  const [comments, setComments] = useState();
  const [value, setValue] = useState({
    rate: null,
    rate_description: '',
    rate_max: Object.keys(rate).length,
    recommend: null,
    recommend_max: Object.keys(recommend).length,
    recommend_description: '',
  });
  const [hover, setHover] = useState({
    rate: null,
    recommend: null,
  });

  // initial load
  useEffect(() => {
    dispatch(clearMessage());
  }, [])

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCommentsChange = (event) => {
    const { name, value } = event.target;
    setComments(value);
  };

  const handleSubmitFeedback = () => {
    // setIsRunSubmitting(true);
    dispatch(createUserFeedback({ ...value, comments }));
    navigate('/');
  }

  return (
    <Paper id="feedback" sx={{ boxShadow: 10, borderRadius: 1, m: 1, p: 2 }}>
      <Grid container>
        <Grid item xs={12} sx={{ my: 1 }}>
          <Typography><strong>Feedback</strong></Typography>
        </Grid>
        <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}>
          <Grid container sx={{ m: 3 }}>
            <Grid item xs={12} sx={{ my: 1 }}>
              <Typography><strong>How would you rate your experience with our Omni IQ?</strong></Typography>
            </Grid>
            <Grid item xs={12} sx={{ my: 1 }}>
              <Box
                sx={{
                  width: 400,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Rating
                  name="feedback-rate"
                  value={value.rate}
                  getLabelText={getLabelText('rate')}
                  onChange={(event, value) => {
                    setValue(prev => ({
                      ...prev,
                      rate: value,
                      rate_description: rate[value],
                    }));
                  }}
                  onChangeActive={(event, hover) => {
                    setHover(prev => ({
                      ...prev,
                      rate: hover,
                    }));
                  }}
                  emptyIcon={<StarIcon style={{ opacity: 0.95 }} fontSize="inherit" />}
                />
                {value.rate || (hover.rate && hover.rate !== -1)
                  ? <Box sx={{ ml: 2 }}>{rate[hover.rate !== -1 ? hover.rate : value.rate]}</Box>
                  : <Box sx={{ ml: 2 }}>Please rate us</Box>
                }
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}>
          <Grid container sx={{ m: 3 }}>
            <Grid item xs={12}>
              <Typography><strong>How likely would you be to recommend Omni IQ to a friend or colleague?</strong></Typography>
            </Grid>
            <Grid item xs={12} sx={{ my: 1 }}>
              <Box
                sx={{
                  width: 400,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Rating
                  name="feedback-recommend"
                  value={value.recommend}
                  getLabelText={getLabelText('recommend')}
                  onChange={(event, value) => {
                    setValue(prev => ({
                      ...prev,
                      recommend: value,
                      recommend_description: recommend[value],
                    }));
                  }}
                  onChangeActive={(event, hover) => {
                    setHover(prev => ({
                      ...prev,
                      recommend: hover,
                    }));
                  }}
                  emptyIcon={<StarIcon style={{ opacity: 0.95 }} fontSize="inherit" />}
                />
                {value.recommend || (hover.recommend && hover.recommend !== -1)
                  ? <Box sx={{ ml: 2 }}>{recommend[hover.recommend !== -1 ? hover.recommend : value.recommend]}</Box>
                  : <Box sx={{ ml: 2 }}>Please rate us</Box>
                }
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ my: 1 }}>
          <Typography><strong>Comments</strong></Typography>
        </Grid>
        <Grid item xs={12} sx={{ m: 1 }}>
          <TextField
            id="feedback-comments"
            type="text"
            name="comments"
            placeholder="Please share feature requests, experiences and any other feedback about Omni IQ with us."
            variant="standard"
            size='small'
            margin='normal'
            fullWidth
            required={true}
            multiline={true}
            rows={5}
            defaultValue={comments}
            onChange={handleCommentsChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            id="feedback-submit"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSubmitFeedback}
            disabled={value.rate && value.recommend && comments ? false : true}
            endIcon={<KeyboardArrowRightIcon />}
            sx={{ boxShadow: 10, borderRadius: 1, mt: 5 }}
          >
            {"Submit your feedback"}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
