import React from 'react';
import { Checkbox, FormControlLabel, } from '@mui/material';
import { Field, useField, } from 'formik';

export default function BdexCheckbox({ label, ...props }) {
  const [ field, ] = useField(props);

  return (
    <FormControlLabel
      control={
        <Field 
          {...field}
          color={"primary"}
          as={Checkbox} 
        />
      }
      label={label}
  />
  )
};
