export const pixelListSchema = [
  {
    field: "client_key",
    headerName: "client_key",
    editable: false,
    sortable: false,
    hide: true,
  },
  {
    field: "site_key",
    headerName: "site_key",
    editable: false,
    sortable: false,
    hide: true,
  },
  {
    field: "site",
    headerName: "Website description",
    editable: false,
    sortable: true,
    hide: false,
    flex: 240,
  },
  {
    field: "email_address",
    headerName: "email_address",
    editable: false,
    sortable: false,
    hide: true,
    flex: 120,
  },  
  {
    field: "date_created",
    headerName: "Created",
    editable: false,
    sortable: true,
    hide: false,
    flex: 120,
  },
  {
    field: "active",
    headerName: "Active",
    editable: false,
    sortable: true,
    hide: false,
    flex: 40,
  },
  // {
  //   field: "status",
  //   headerName: "Status",
  //   editable: false,
  //   sortable: true,
  //   hide: false,
  //   flex: 80,
  // },
];
