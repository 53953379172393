import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Link, Button, Grid, Typography, Tooltip, Alert } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  OpenInNew as OpenInNewIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';import { getOrder, getOrderFiles } from '../../../actions/order';
import { clearMessage } from '../../../actions/message';
import { constructS3Uri, isEmpty, priceDisplay } from '../../../lib/Utils';
import { AutoRefresh, OrderItems, ProgressBar } from '../../Common';

export function OrderDetails() {
    
  const { order } = useSelector(state => state.data);
  const { load_progress } = order;

  const { user } = useSelector(state => state.auth);

  const dispatch = useDispatch();
  
  const params = useParams();
  const { order_key } = params;

  const [searchParams] = useSearchParams();
  const user_key = searchParams.get('user_key');

  const [isSubmitting, setIsSubmitting] = useState(() => {
    if (isEmpty(load_progress)) return false;
    return true;
  });

  const [availableRows, setAvailableRows] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [availableForDownload, setAvailableForDownload] = useState(false);

  const handleGetOrder = () => {
    dispatch(getOrder({
      order_key,
      user_key,
    }));
  };

  const handleGetOrderFiles = () => {
    setIsSubmitting(true);
    dispatch(getOrderFiles({
      order_key,
      user_key,
    }));
  };
  
  // navigate to '/orders' if error 400 or 404 received
  const navigate = useNavigate();
  const { error, status } = useSelector(state => state.message);

  useEffect(() => {

    if (status === 404 || (status === 400 && error?.includes('User does not have a requested'))) {
      dispatch(clearMessage());
      navigate('/orders');
    }
  }, [error, status, dispatch, navigate]);

  // initial load
  useEffect(() => {

    // set 'order' state
    handleGetOrder();

    // isAdmin?
    if (user.permissions?.resources?.includes('others.orders')) {
      setIsAdmin(true);
    }
  }, []);

  useEffect(() => {
    if (load_progress?.progress === 1) {
      setIsSubmitting(false);
    }
  }, [load_progress]);

  useEffect(() => {
    if (!isEmpty(order)) {

      const isAvailableForDownload = order.history?.some(x => x.status === 'available');
      setAvailableForDownload(isAvailableForDownload);

      const rows = [
        {
          id: 1,
          key: 'Name',
          value: order.name,
        },
        {
          id: 2,
          key: 'Comments',
          value: order.comments,
        },
        {
          id: 3,
          key: 'Created',
          value: order.dt_created,
        },
        {
          id: 4,
          key: 'Last Updated',
          value: order.dt_last_updated,
        },
        {
          id: 5,
          key: 'Last Stage Description',
          value: order.description,
        },
        {
          id: 6,
          key: 'Status',
          value: order.status,
        },
        {
          id: 7,
          key: 'Amount',
          value: priceDisplay(order.amount, order.decimals, order.currency),
        },
      ];

      order.charge?.receipt_url && rows.push({
        id: rows.length + 1,
        key: 'Payment Receipt',
        value: order.charge.receipt_url,
      })
      
      if (isAdmin) {

        rows.push({
          id: rows.length + 1,
          key: 'order_id*',
          value: order.id,
        })

        rows.push({
          id: rows.length + 1,
          key: 'order_key*',
          value: order.key,
        })
        
        rows.push({
          id: rows.length + 1,
          key: 'user_email*',
          value: order.user_email_address,
        })

        rows.push({
          id: rows.length + 1,
          key: 'user_key*',
          value: order.user_key,
        })

        rows.push({
          id: rows.length + 1,
          key: 'user_id*',
          value: order.user_id,
        })

        rows.push({
          id: rows.length + 1,
          key: 's3_location*',
          value: order.s3_location || constructS3Uri(order.s3_bucket, order.s3_key),
        })
      }

      setAvailableRows(rows);
    }
  }, [order])

  let columns = [];
  if (!isEmpty(order)) {
    columns = [
      {
        field: 'key',
        minWidth: '120px',
        flex: 1,
        display: 'flex',
      },
      {
        field: 'value',
        minWidth: '240 px',
        flex: 1,
      },
    ];

    columns[columns.findIndex(x => x.field === 'value')].renderCell = (params) => {
      const { key, value } = params.row;
      if (key.includes('Payment Receipt')) {
        return (
          <Link href={value} target="_blank">
            <Button
              variant="text"
              color="inherit"
              underline="none"
              startIcon={<OpenInNewIcon />}
            >
              {`View Receipt`}
            </Button>
          </Link>
        );
      }
    }
  }

  return (
    <>
      <Grid container id="orders-get-one-order-details">
        <Grid item xs={6} sx={{ my: 1 }}>
          <Typography><strong>Order Details</strong></Typography>
        </Grid>
        <Grid item xs={6} sx={{ my: 1 }}>
          <AutoRefresh handler={handleGetOrder} off={true} />
        </Grid>
        <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}>
          <DataGrid
            rows={availableRows}
            columns={columns}
            hideFooter={true}
            autoHeight={true}
            density={'compact'}
            customHeadRender={() => null }
            sx={
              {
                m: 1,
                // hide header
                "& .MuiDataGrid-columnHeaders": { display: "none" },
                "& .MuiDataGrid-virtualScroller": { marginTop: "0!important" },
                // highlight admin-visible rows
                "& .MuiDataGrid-row--admin-visible": {
                  bgcolor: '#e0e0e0',
                  '&:hover': {
                    bgcolor: '#d0d0d0',
                  }
                }
              }
            }
            getRowClassName={(params) => {
              if (params.row.key.endsWith('*')) {
                return `MuiDataGrid-row--admin-visible`;
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ my: 1 }}>
          <Typography><strong>Order Items</strong></Typography>
        </Grid>
        <Grid item xs={12}sx={{ my: 1 }}>
          <OrderItems
            order={order}
            user_key={user_key}
            mode={'postOrder'}
          />
        </Grid>
        { availableForDownload ?
          <>
            <Grid item xs={12} sx={{ my: 1 }}>
              <Typography><strong>Order Results</strong></Typography>
            </Grid>
            <Grid item xs={12}>
              <Tooltip title={`Download the Files (results of the Order as a single .zip archive)`} arrow>
                <Button
                  id="orders-get-one-download-results"          
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleGetOrderFiles}
                  disabled={isSubmitting}
                  endIcon={<FileDownloadIcon />}
                  sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}
                >
                  {"Download"}
                </Button>
              </Tooltip>
            </Grid>
          </>
          : null
        }
      </Grid>
      { load_progress?.progress !== 1 && isSubmitting ?
        <Grid container id="orders-get-one-download-results-progress"  >
          <Grid item xs={12} sx={{ my: 1 }}>
            <Typography><strong>Order Results: File Download Progress</strong></Typography>
          </Grid>
          <Grid item xs={12}>
            <Alert
              sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}
              severity="warning"
              variant="outlined"
            >
              {"Downloading the Files... Feel free to navigate from this page, but please do not close or refresh it"}
            </Alert>
          </Grid>
          <Grid item xs={12} sx={{ m: 1 }}>
            <ProgressBar {...load_progress} />
          </Grid>
        </Grid>
        : null
      }
    </>
  );
}
