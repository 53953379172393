import React from 'react';
import { enUS } from '.';
import { useContext, useMemo } from 'react';
export const LocaleContext = React.createContext(enUS);
export function useLocale(component) {
    const locale = useContext(LocaleContext);
    return useMemo(() => {
        return locale.components[component];
    }, [component, locale.components]);
}
