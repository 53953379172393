import React from 'react';
import { Paper } from '@mui/material';
import NewUploadAdditionalData from './NewUploadAdditionalData';
import NewUploadFormat from './NewUploadFormat';
import NewUploadPreview from './NewUploadPreview';
import NewUploadSubmit from './NewUploadSubmit';
import UploadType from './UploadType';

export function UploadsCreate() {
  return (
    <Paper id="uploads-create" sx={{ boxShadow: 10, borderRadius: 1, p: 2 }}>
      <UploadType />
      <NewUploadFormat />
      <NewUploadPreview />
      <NewUploadAdditionalData />
      <NewUploadSubmit />
    </Paper>
  )
};
