import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardMedia, Grid, Link } from '@mui/material';
import { constructURLQueryString } from '../../lib/Utils';

export function AnalyticsUpgrade() {

  const { user } = useSelector(state => state.auth);

  const [images, setImages] = useState([]);

  const importAll = (x) => {
    return x.keys().map(x);
  };

  useEffect(() => {
    setImages(importAll(require.context('./img/', false, /\.(png|jpg)$/)));
  }, []);

  const queryString = constructURLQueryString(user, {
    how_did_you_hear_about_us_: 'I am interested in upgrading my Omni IQ Account to see advanced analytics',
  });

  const url = `https://www.bdex.com/pricing?${queryString}`;

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
    >
      {images.map((image, index) => (
        <Grid item xs={12} sm={6} md={3} key={`analytics-img-${index}`}>
          <Link href={url} underline="none" target="_blank">
            <Card variant='text'>
              <CardMedia
                alt="Upgrade your Omni IQ Account"
                id="logo"
                component="img"
                image={image}
              />
            </Card>
          </Link>
        </Grid>
      ))}
    </Grid>
  )
}
