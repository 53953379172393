import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { Alert, AlertTitle, Grid, Paper, Typography } from '@mui/material';
import { RunAnalyticsPaid } from './RunAnalyticsPaid';
import { isEmpty, uuidv4 } from '../../../lib/Utils';
import { getRunAnalytics } from '../../../actions/run';
import { AutoRefresh } from '../../Common';

export function RunAnalytics(props) {

  const { embedded } = props;

  const { run_list, run, run_analytics } = useSelector(state => state.data);
  const { user } = useSelector(state => state.auth);
  
  const dispatch = useDispatch();

  const params = useParams();
  const { run_key } = params;

  // RunAnalytics could be requested as a standalone call with no RunDetails
  // so find the requested run in run_list, as we need run.name
  const _run = (run.key === run_key) ? run : run_list.find(x => x.key === run_key);

  const run_id = _run?.id;
  const run_name = _run?.name;
  const run_raw_table = _run?.athena_raw_table || `raw_file_${run_key}_parquet`;
  const run_s3_key = _run?.s3_location;

  const [searchParams] = useSearchParams();
  const user_key = searchParams.get('user_key')
  const run_user_key = user_key || _run?.user_key;
  
  const [randomKey, setRandomKey] = useState(uuidv4());

  const handleGetRunAnalytics = () => {
    dispatch(getRunAnalytics({
      run_key,
      user_key,
    }));
  };

  // 'initial load'
  useEffect(() => {

    // update 'run' state
    handleGetRunAnalytics();
  }, [randomKey]);

  const handleComponentRefresh = () => {
    setRandomKey(uuidv4());
  }

  const isAnalyticsEmpty = isEmpty(Object.values(run_analytics || {}).flat());

  const component = (
    <Grid container id="analytics-run">
      <Grid item xs={6} sx={{ my: 1 }}>
        <Typography><strong>Available XAudience data:</strong></Typography>
      </Grid>
      <Grid item xs={6} sx={{ my: 1 }}>
        <AutoRefresh handler={handleComponentRefresh} disabled={true} />
      </Grid>
      { !isAnalyticsEmpty ?
        <>
          <Grid item xs={12}>
            <RunAnalyticsPaid 
              randomKey={randomKey}
              run_props={{
                id: run_id,
                key: run_key,
                name: run_name,
                user_key: run_user_key,
                raw_table: run_raw_table,
                s3_key: run_s3_key,
              }}
              user_key={user.key}
            />
          </Grid>
        </>
        :
        <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}>
          <Alert severity="error" variant="outlined">
            <AlertTitle>
              Not enought data to generate List Analytics! Try to refresh this List or run another one
            </AlertTitle>
          </Alert>
        </Grid>
      }
    </Grid>
  );

  return (
    <>
      { !embedded ?
        <Paper id="run-history" sx={{ boxShadow: 10, borderRadius: 1, p: 2 }}>
          {component}
        </Paper>
        : component
      }
    </>
  );
}
