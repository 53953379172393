import { sendHttpRequest } from '../lib/Utils';
import { store } from '../redux/store';

const {
  REACT_APP_API_EXTERNAL_URL_AUDIENCES: API_EXTERNAL_URL_AUDIENCES,
} = process.env;

export const createAudience = async(kwargs) => {

  const { auth } = store.getState();
  const { user } = auth;
  const { key: user_key, id: user_id } = user;

  const response = await sendHttpRequest.request({
    url: `${API_EXTERNAL_URL_AUDIENCES}`,
    method: 'post',
    data: kwargs,
    params: {
      user_id,
      user_key,
    },
  });
  return response;
};

export const getAudiences = async () => {

  const { auth } = store.getState();
  const { user } = auth;
  const { key: user_key, id: user_id } = user;

  const response = await sendHttpRequest.request({
    url: `${API_EXTERNAL_URL_AUDIENCES}`,
    method: 'get',
    params: {
      user_id,
      user_key,
    },
  });
  return response;
};

export const getAudience = async (kwargs) => {

  const { auth } = store.getState();
  const { user } = auth;
  const { key: user_key, id: user_id } = user;

  const { audience_key } = kwargs;
  const response = await sendHttpRequest.request({
    url: `${API_EXTERNAL_URL_AUDIENCES}/${audience_key}`,
    method: 'get',
    params: {
      user_id,
      user_key,
    },
  });
  return response;
};

// not used
export const getAudienceAnalytics = async (kwargs) => {

  const { auth } = store.getState();
  const { user } = auth;
  const { key: user_key, id: user_id } = user;

  const { audience_key } = kwargs;
  const response = await sendHttpRequest.request({
    url: `${API_EXTERNAL_URL_AUDIENCES}/${audience_key}/analytics`,
    method: 'get',
    params: {
      user_id,
      user_key,
    },
  });
  return response;
};

export const deleteAudience = async (kwargs) => {

  const { auth } = store.getState();
  const { user } = auth;
  const { key: user_key, id: user_id } = user;

  const { audience_key } = kwargs;
  const response = await sendHttpRequest.request({
    url: `${API_EXTERNAL_URL_AUDIENCES}/${audience_key}`,
    method: 'delete',
    params: {
      user_id,
      user_key,
    },
  });
  return response;
};
