import React from 'react';
import { Paper } from '@mui/material';
import { RunHistory } from './RunHistory';
import { RunAnalytics } from '../../Analytics';
import { RunDetails } from './RunDetails';

export function RunsGetOne() {

  return (
    <Paper id="runs-get-one" sx={{ boxShadow: 10, borderRadius: 1, p: 2 }}>
      <RunDetails />
      <RunAnalytics embedded={true} />
      <RunHistory embedded={true} />
    </Paper>
  )
}
