import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { Grid, Paper, Typography } from '@mui/material';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { getPixelVisits } from '../../../actions/pixel';
import { isEmpty, sortBy, uuidv4 } from '../../../lib/Utils';
import { pixelVisitsSchema } from '../config';
import { AutoRefresh, BdexDataGrid } from '../../Common';
import { DateTime } from '../../../lib';
import BdexDatePicker from '../../Common/BdexDatePicker';

export function PixelVisits(props) {
  
  const { embedded } = props;
  
  // descending by id desc, i.e. latest first
  const defaultSort = sortBy('id', 'desc');
  
  const { pixel_visits } = useSelector(state => state.data);

  const dispatch = useDispatch();
  
  const params = useParams();
  const { pixel_key } = params;

  const [searchParams] = useSearchParams();

  const [availableRows, setAvailableRows] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const [dateRange, setDateRange] = useState([DateTime.initialStartDate, DateTime.initialEndDate]);
  const [startDate, endDate] = dateRange;

  const handleGetPixelVisits = () => {
    setAvailableRows([]);
    // setIsLoading(true);
    dispatch(getPixelVisits({
      pixel_key,
      date_from: startDate,
      date_to: endDate,
    }));
  };

  // initial load
  useEffect(() => {
    handleGetPixelVisits();
    // setIsLoading(false);
  }, []);

  // date updated
  useEffect(() => {
    handleGetPixelVisits();
    // setIsLoading(false); 
  }, [dateRange])

  useEffect(() => {
    setAvailableRows([]);
    if (!isEmpty(pixel_visits)) {
      setAvailableRows(pixel_visits);
    }
  }, [pixel_visits])

  let columns = [];
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    () => pixelVisitsSchema.filter(x => x.hide === true).reduce((a, v) => ({ ...a, [v['field']]: false }), {})
  );

  if (!isEmpty(pixel_visits)) {

    columns = Object.keys(pixel_visits[0]).map(x => {
      return {
        field: x,
        headerName: x,
      };
    });

    // set up from the config
    columns = pixelVisitsSchema.filter(x => x.id === pixel_visits[0].id);
    
  }

  const DownloadToolbar = () => (
    <GridToolbarContainer>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <BdexDatePicker
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        </Grid>
        <Grid item>
        { !isEmpty(pixel_visits) ?

          <GridToolbarExport
            csvOptions={{
              fileName: `bdex-omni-pixel-${pixel_key}-${new Date().toISOString().replace(/[-:T\.Z]/g, '')}`,
              delimiter: ',',
              utf8WithBom: true,
            }}
          />
          : null
        }
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );

  const component = (
    <Grid container id="pixel-get-one-pixel-visits">
      <Grid item xs={6} sx={{ my: 1 }}>
        <Typography><strong>Pixel Visits</strong></Typography>
      </Grid>      
      <Grid item xs={6} sx={{ my: 1 }}>
        <AutoRefresh handler={handleGetPixelVisits} off={true} />
      </Grid>
      {/* <Grid item>
        <Paper>start: {startDate?.toISOString()}</Paper>
        <Paper>end: {endDate?.toISOString()}</Paper>
      </Grid> */}
      <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, mt: 1 }}>
        <BdexDataGrid
          rows={availableRows}
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(x) => setColumnVisibilityModel(x)}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10,50,100]}
          checkboxSelection={false}
          disableRowSelectionOnClick={true}
          autoHeight={true}
          density={'compact'}
          getRowId={row => uuidv4()}
          slots={{ 
            toolbar: DownloadToolbar,
          }}
          // loading={isLoading}
          sx={{
            m: 1,
            '--DataGrid-overlayHeight': '200px'
          }}
        />
      </Grid> 
    </Grid>
  );

  return (
    <>
      { !embedded ?
        <Paper id="pixel-visits" sx={{ boxShadow: 10, borderRadius: 1, p: 2 }}>
          {component}
        </Paper>
        : component
      }
    </>
  );
}
