import { Utils, LocalStorage } from '../lib';

const {
  REACT_APP_VERSION: VERSION,
  REACT_APP_GOOGLE_RECAPTCHA_BACKEND_VERIFICATION: GOOGLE_RECAPTCHA_BACKEND_VERIFICATION,
 } = process.env;

export const register = async (data) => {
  const response = await Utils.sendHttpRequest.post('/auth/register', data);
  return response;
};

export const login = async (data) => {

  const response = await Utils.sendHttpRequest.post('/auth/login', data);
  const { user } = response.data?.data ?? {};

  if (user) {
    LocalStorage.setItem('user', user);
    LocalStorage.setItem('app_version', 
      {
        client: VERSION || '1.*.*',
        server: response.data.version,
      }
    );
  }

  return response;
};

export const activateAccount = async (data) => {
  const response = await Utils.sendHttpRequest.post('/auth/activate-account', data);
  return response;
};

export const resendActivation = async (data) => {
  const response = await Utils.sendHttpRequest.post('/auth/resend-activation', data);
  return response;
};

export const forgotPassword = async (data) => {
  const response = await Utils.sendHttpRequest.post('/auth/forgot-password', data);
  return response;
};

export const resetPassword = async (data) => {
  const response = await Utils.sendHttpRequest.post('/auth/reset-password', data);
  return response;
};

export const refreshToken = async () => {

  const data = {
    refresh_token: LocalStorage.getItem('user')?.['refresh_token'],
  };

  const response = await Utils.sendHttpRequest.post('/auth/refresh-token', data);
  const { access_token, refresh_token } = response.data?.data?.user ?? {};

  if (access_token && refresh_token) {
    LocalStorage.updateItem(
      'user',
      {
        access_token,
        refresh_token,
      }
    );
  }

  return response;
};

export const logout = () => {
  LocalStorage.removeItem('user');
  LocalStorage.removeItem('app_version');
  LocalStorage.removeItem('context_user');
  LocalStorage.removeItem('shopping_cart');
};

export const loginContextUser = (data) => {
  LocalStorage.setItem('context_user', data);
};

export const logoutContextUser = () => {
  LocalStorage.removeItem('context_user');
};

// google recaptcha v2 verification
export const verifyCaptcha = async (captcha_token) => {
  if (GOOGLE_RECAPTCHA_BACKEND_VERIFICATION) {
    const response = await Utils.sendHttpRequest.post('/auth/verify-captcha', {
      captcha_token,
    });
    return response;
  }
};
