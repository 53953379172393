import { loadStripe } from "@stripe/stripe-js";

const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

let stripeClient;
if (!stripeClient) {
  stripeClient = loadStripe(STRIPE_PUBLIC_KEY);
};

export default stripeClient;
