//constants

//register
export const REGISTER_SUCCESS = "REGISTER_SUCCESSS";
export const REGISTER_ERROR = "REGISTER_ERROR";

//login
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

//logout
export const LOGOUT = "LOGOUT";

//messages (info and errors)
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

//reset-password
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

//activate-account
export const ACTIVATE_ACCOUNT_SUCCESS = "ACTIVATE_ACCOUNT_SUCCESS";
export const ACTIVATE_ACCOUNT_ERROR = "ACTIVATE_ACCOUNT_ERROR";

//refresh-token
export const REFRESH_TOKEN = "REFRESH_TOKEN";

//data returned from API (user, uploads, etc.)
export const DATA = "DATA";
export const DATA_MERGED = "DATA_MERGED";
export const CLEAR_DATA = "CLEAR_DATA";

//context_user
export const LOGIN_CONTEXT_USER = "LOGIN_CONTEXT_USER";
export const LOGOUT_CONTEXT_USER = "LOGOUT_CONTEXT_USER";

//shopping_cart
export const SHOPPING_CART = "SHOPPING_CART";
