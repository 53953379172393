import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Button, CssBaseline, Link, Grid, Typography, Container } from '@mui/material';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import { Formik, Form } from 'formik';
import { BdexInputField } from '../Common';
import { validationSchema, useStyles } from './config';
import { login } from '../../actions/auth';
import { Link as RouterLink } from 'react-router-dom';
import { ReCaptcha } from './ReCaptcha';

export default function Login(props) {

  const classes = useStyles();
  const dispatch = useDispatch();

  const { error, warning, status } = useSelector(state => state.message);
  const [ accountInactive, setAccountInactive ] = useState(false);

  const captchaRef = useRef();
  const executeCaptchaAsync = async () => {
    return await captchaRef.current?.executeAsync();
  };

  useEffect(() => {

    if (status !== 200) {

      //display the link to resend the account activation link
      if (error?.includes('has not been activated') || warning?.includes('has not been activated')) {
        setAccountInactive(true);
      }
    }
  }, [error, warning, status]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar} >
          <VpnKeyOutlinedIcon />
        </Avatar>
        <Typography sx={{ textAlign: 'center', typography: 'h5'}}>
          {"Login"}
        </Typography>
      </div>
      <div className={classes.form} noValidate>
        <Formik
          initialValues={{
            email_address: '',
            password: '',
            // remember: false,
          }}
          validationSchema={validationSchema([
            'email_address',
            'password',
          ])}
          validateOnChange={true}
          validateOnBlur={false}
          onSubmit={ async (values, actions) => {

            //start submitting
            actions.setSubmitting(true);

            //execute (solve) captcha (front-end)
            const captcha_token = await executeCaptchaAsync();

            if (captcha_token) {

              // verify solved captcha (server-side) and login
              dispatch(login({...values, captcha_token }));
            }

            //stop submitting
            actions.setSubmitting(false);

            //reset captcha (front-end)
            captchaRef.current?.reset();

            //reset form
            actions.resetForm();
          }}>
            {
              ({ values, errors, isSubmitting }) => (
                <Form>
                  <Grid container>
                    <Grid item xs={12}>
                      <BdexInputField 
                        type="email"
                        name="email_address"
                        label="Email Address"
                        autoFocus
                        autoComplete="email"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <BdexInputField 
                        type="password"
                        name="password"
                        label="Password"
                        autoComplete="current-password"
                      />
                    </Grid>
                    {/* [TODO] - to review the 'remember_me' functionality */}
                    {/* <Grid item xs={12} container justify="flex-start">
                      <BdexCheckbox
                        name="remember"
                        label="Remeber this account for 2 weeks"
                      />
                    </Grid> */}
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={isSubmitting}
                      >
                        Login
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={5} container justifyContent="space-evenly">
                      <Link component={RouterLink} to="/auth/forgot-password" variant="body2" underline="hover">
                        {"Forgot password?"}
                      </Link>
                    </Grid>
                    <Grid item xs={12} sm={7} container justifyContent="space-evenly">
                      <Link component={RouterLink} to="/auth/register" variant="body2" underline="hover">
                        {"Don't have an account? Register"}
                      </Link>
                    </Grid>
                    { accountInactive
                      ? <Grid item container justifyContent="center">
                          <Link 
                            component={RouterLink}
                            to="/auth/resend-activation"
                            underline="hover"
                            sx={{
                              color: 'error.main',
                              border: 1,
                              fontWeight: 'medium',
                              borderRadius: 1,
                              typography: 'body2'
                            }}
                          >
                            {"Resend the account activation link"}
                          </Link>
                        </Grid>
                      : <div></div>
                    }
                  </Grid>
                </Form>
              )
            }
        </Formik>
      </div>
      <ReCaptcha captcharef={captchaRef}/>
    </Container>
  );
};
