import React from 'react';
import { 
  Alert,
  AlertTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Grid,
} from '@mui/material';
import {
  ThumbUpOffAlt as ThumbUpOffAltIcon,
  ThumbDownOffAlt as ThumbDownOffAltIcon,
} from '@mui/icons-material';
import { uploadTypes } from '../config';

const {
  REACT_APP_MIN_MATCHED_RECORDS_REQUIRED_FOR_NEW_RUN: MIN_MATCHED_RECORDS_REQUIRED_FOR_NEW_RUN = 1000,
} = process.env;

export default function UploadType() {

  return (
    <Grid container id="uploads-create-u">
      <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, mb: 1 }}>
        <Alert severity="info" variant="outlined">
          <AlertTitle><strong>Note!</strong></AlertTitle>
          <Box>
            When uploading a new List, please keep in mind that <strong>at least 1 (One)</strong> Positive List is required to create an Expanded Audience.
            Having both Positive and Negative Lists increases the accuracy of the ML (Machine Learning) model, which might yield better results in the created Expanded Audience.
          </Box>
          <Box>
            The size of each List should be at least <strong>500</strong> records.
          </Box>
          <Box>
            In order to qualify for the new Expanded Audience the Matched Records size of all Positive List(s) must be at least <strong>{MIN_MATCHED_RECORDS_REQUIRED_FOR_NEW_RUN}</strong>.
          </Box>
          <Box>
            <List dense>
              {uploadTypes.map(e => {
                return (
                  <ListItem key={e.id}>
                    <ListItemIcon>
                      { e.id === 'positive' ? <ThumbUpOffAltIcon /> : <ThumbDownOffAltIcon /> }
                      </ListItemIcon>
                    <ListItemText
                      primary={e.label}
                      secondary={e.description}
                      sx={{whiteSpace: 'pre-line'}}
                    />
                  </ListItem>
                )
              })}
            </List>
          </Box>
        </Alert>
      </Grid>
    </Grid>
  )
}
