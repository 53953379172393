import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, DialogActions, Divider, Grid, Stack, Switch, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  Edit as EditIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import { formatNumber, isEmpty, mergeDeep, priceDisplay, phoneNumberDisplay } from '../../../lib/Utils';
import { updateUser } from '../../../actions/user';

export function UserSettings(props) {

  const { admin, user } = props;
  const { user_groups_default_config } = admin;

  // default settings for the user from the backend 
  const defaultUserSettings = user_groups_default_config.find(x => x.user_group === user?.config?.enterprise_user ? 'admin' : 'user');

  const dispatch = useDispatch();

  const [configSettings, setConfigSettings] = useState({});
  const [availableRows, setAvailableRows] = useState([]);

  const handleSaveSettings = () => {

    dispatch(updateUser({
      user_key: user.id,
      config: configSettings,
    }));

    // reset configSettings
    setConfigSettings({});
  };

  const handleDiscardSettings = () => {};

  useEffect(() => {

    if (user) {

      const rows = [];
      const keys = ['id', 'key', 'first_name', 'last_name', 'email_address', 'phone_number', 'company_name'];

      rows.push({
        id: 1,
        key: 'user_active',
        description: 'User Active flag, uncheck to make the user INACTIVE',
        description2: 'Please be aware of the outcome of changing this setting, as it will delete the user from the platform',
        value: !Boolean(user.dt_deleted),
        type: 'boolean',
        editable_icon: true,
      });

      keys.forEach((x, i) => {
        rows.push({
          id: i + 2,
          key: x,
          value: user[x],
        });
      });
      
      const {
        limits = [],
        third_party_service_limits = [],
        enterprise_user = false,
      } = user.config;

      // editable columns - enterprise_user
      rows.push({
        id: rows.length + 1,
        key: 'enterprise_user',
        description: 'Enterprise User flag, check to make the user an ENTERPRISE USER',
        description2: 'Please be aware of the outcome of changing this setting, as it will increase the limits of resources/API calls, and user will pay with their User Credit for any Orders',
        value: Boolean(enterprise_user),
        type: 'boolean',
        editable_icon: true,
      });

      // editable columns - resource limits
      // first - check if all settings are present
      const missingConfigLimits = [];
      for (const [resource, limit_max] of Object.entries(defaultUserSettings.resource_limit.max)) {

        const _resource = limits.find(x => x.resource === resource && x.limit_window === defaultUserSettings.resource_limit.window);
        
        // if not present - create it
        if (!_resource) {

          // in datagrid (ui)
          rows.push({
            id: rows.length + 1,
            key: `limit_${resource} (user missing setting)`,
            description: `Set the limit of ${resource} that can be created in ${defaultUserSettings.resource_limit.window}`,
            description2: `As this property is missing in the user config, the default configuration setting for the user tier was used. You can change this setting. Don't forget to click the 'Save changes' button`,
            value: limit_max,
            limit_window: defaultUserSettings.resource_limit.window,
            type: 'number',
            editable: true,
            linked_to: {
              resource,
            }
          });

          // in db
          missingConfigLimits.push({
            resource: resource,
            limit_max: limit_max,
            limit_window: defaultUserSettings.resource_limit.window,
          });
        }
      };

      limits.forEach(x => {

        const { 
          resource,
          limit_max,
          limit_window,
        } = x;
        
        rows.push({
          id: rows.length + 1,
          key: `limit_${resource}`,
          description: `Set the limit of ${resource} that can be created in ${limit_window}`, 
          value: limit_max,
          limit_window: limit_window,
          type: 'number',
          editable: true,
          linked_to: {
            resource,
          }
        });

      });
      
      // editable columns - third party service limits
      // first - check if all settings are present
      const missingConfigThirdPartyServiceLimits = [];
      defaultUserSettings.third_party_service_api_call_limits.forEach(third_party_service => {
        
        // in db
        const thirdPartyService = {
          service: third_party_service.service,
          limits: [],
        };

        let settingsUpdated = false;

        const _service = third_party_service_limits.find(x => x.service === third_party_service.service);

        //if service exists - check all domains, and add any missing
        //if service does not exist - add all from the default config settings 
        // if (_service) {
          
          for (const [domain, limit_max] of Object.entries(third_party_service.max)) {
            
            const _domain = _service?.limits?.find(x => x.domain === domain && x.limit_window === third_party_service.window);

            // if not present - create it
            if (!_domain) {

              // in datagrid (ui)
              rows.push({
                id: rows.length + 1,
                key: `${third_party_service.service}_limit_${domain} (user missing setting)`,
                description: `Set the limit of ${third_party_service.service}/${domain} API calls in ${third_party_service.window}`,
                description2: `As this property is missing in the user config, the default configuration setting for the user tier was used. You can change this setting. Don't forget to click the 'Save changes' button`,
                value: limit_max,
                limit_window: third_party_service.window,
                type: 'number',
                editable: true,
                linked_to: {
                  service: third_party_service.service,
                  domain,
                }
              });

              // in db
              thirdPartyService.limits.push({
                domain,
                limit_max,
                limit_window: third_party_service.window,
              });

              settingsUpdated = true;              
            }
          };
        // }
        
        if (settingsUpdated) missingConfigThirdPartyServiceLimits.push(thirdPartyService);
      });

      // db
      // updated config settings
      if (!isEmpty(missingConfigLimits) || !isEmpty(missingConfigThirdPartyServiceLimits)) {
        setConfigSettings((prev) => mergeDeep(
          prev,
          {
            limits: missingConfigLimits,
            third_party_service_limits: missingConfigThirdPartyServiceLimits,
          },
        ));
      }

      third_party_service_limits.forEach(y => {

        const { limits, service } = y;

        limits.forEach(x => {

          const { domain, limit_max, limit_window } = x;

          rows.push({
            id: rows.length + 1,
            key: `${service}_limit_${domain}`,
            description: `Set the limit of ${service}/${domain} API calls in ${limit_window}`,
            value: limit_max,
            limit_window: limit_window,
            type: 'number',
            editable: true,
            linked_to: {
              service,
              domain,
            }
          });
        });
      });

      // editable columns - user credit
      const {
        amount = 0,
        decimals = 2,
        currency = 'USD',
      } = user.user_credit || {};
      
      rows.push({
        id: rows.length + 1,
        key: `user_credit_amount`,
        description: `Set the limit of user_credit_amount`,
        value: amount,
        editable: true,
        type: 'number',
      });

      setAvailableRows(rows);
    }
  }, [user])

  let columns = [];
  if (user) {
    columns = [
      {
        field: 'key',
        minWidth: '120px',
        flex: 1,
        display: 'flex',
      },
      {
        field: 'value',
        minWidth: '240 px',
        flex: 1,
        editable: true,
        valueFormatter: (value, row, column, apiRef) => {
          if (parseInt(value)) return formatNumber(value);
        }
      },
    ];
  }

  if (!isEmpty(columns)) {
    
    columns[columns.findIndex(x => x.field === 'key')].renderCell = (params) => {
      const { key, description, description2, editable, editable_icon } = params.row;
      if (editable || editable_icon) {
        return (
          <Tooltip
            title={
              <Stack spacing={1} justifyContent="space-between" alignItems="center">
                <Box>{description}</Box>
                { description2 ?
                  <>
                    <Divider><Box sx={{ bgcolor: "error.main" }}>WARNING</Box></Divider>
                    <Box sx={{ bgcolor: "error.main" }}>{description2}</Box>
                  </>
                  : null
                }
              </Stack>
            }
            arrow
          >
            <Box>
              {key}
              <EditIcon color="primary" sx={{ fontSize: "small", ml: 1 }} />
            </Box>
          </Tooltip>
        )
      }
    }

    columns[columns.findIndex(x => x.field === 'value')].renderCell = (params) => {

      const { key, value, type } = params.row;

      // overwrite phone_number format
      if (key === 'phone_number') {
        return phoneNumberDisplay(value);
      }

      // overwrite user_credit_amount format
      if (key === 'user_credit_amount') {
        return priceDisplay(value);
      }

      if (type === 'boolean') {
        return (
          <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
            <Switch
              checked={value}
              onChange={(event) => {

                const checked = event.target.checked;
                const updatedRows = availableRows.map(x => {
                  if (x.key === key) return {
                    ...params.row,
                    value: checked,
                  };
                  else return x;
                })
                
                // overwrite limits to defaults if enterprise_user setting is toggled
                if (key === 'enterprise_user') {

                  const userSettings = user_groups_default_config.find(x => x.user_group === (checked ? 'admin' : 'user'));           
                  const {
                    resource_limit,
                    third_party_service_api_call_limits,
                    default_user_credit_amount,
                  } = userSettings;

                  // update limits
                  const newConfigLimits = [];
                  const limit_window = resource_limit.window;
                  for (const [resource, limit_max] of Object.entries(resource_limit.max)) {

                    // in db
                    newConfigLimits.push({
                      resource,
                      limit_max,
                      limit_window,
                    });

                    // in datagrid (ui)
                    const rowIndex = updatedRows.findIndex(x => x.key === `limit_${resource}`);
                    updatedRows[rowIndex] = {
                      ...updatedRows[rowIndex],
                      description: `The limit of ${resource} that can be created in ${limit_window} was set to the default minumum value for ${checked ? 'an enterprise/admin' : 'a regular'} user. You may increase that limit`,
                      value: limit_max,
                    }
                  };

                  // update third party api call limits
                  const newConfigThirdPartyServiceLimits = [];
                  third_party_service_api_call_limits.forEach(third_party_service => {

                    const service = third_party_service.service;
                    const limit_window = third_party_service.window;

                    // in db
                    const thirdPartyService = {
                      service,
                      limits: [],
                    };

                    for (const [domain, limit_max] of Object.entries(third_party_service.max)) {

                      // in db
                      thirdPartyService.limits.push({
                        domain,
                        limit_max,
                        limit_window,
                      });

                      // in datagrid (ui)
                      const rowIndex = updatedRows.findIndex(x => x.key === `${service}_limit_${domain}`);
                      updatedRows[rowIndex] = {
                        ...updatedRows[rowIndex],
                        description: `The limit of ${service}/${domain} API calls in ${limit_window} was set to the default minimum value for ${checked ? 'an enterprise/admin' : 'a regular'} user. You may increase that limit`,
                        value: limit_max,
                      }
                    };

                    // db
                    // add new service config
                    newConfigThirdPartyServiceLimits.push(thirdPartyService);
                  });

                  // update user_credit
                  const newUserCredit = {};

                  // in db
                  newUserCredit.amount = default_user_credit_amount;

                  // in datagrid (ui)
                  const rowIndex = updatedRows.findIndex(x => x.key === `user_credit_amount`);
                  updatedRows[rowIndex] = {
                    ...updatedRows[rowIndex],
                    value: newUserCredit.amount,
                  }

                  // db
                  // updated config settings
                  setConfigSettings((prev) => mergeDeep(
                    prev,
                    {
                      enterprise_user: checked,
                      limits: newConfigLimits,
                      third_party_service_limits: newConfigThirdPartyServiceLimits,
                      user_credit: newUserCredit,
                    },
                  ));

                }
            
                setAvailableRows(updatedRows);
              }}
            />
            <Typography sx={{ color: "error.main" }}>a very important setting, please be careful</Typography>
          </Stack>
        );
      }
    }
  }


  return (
    <Grid container id="users-get-one-userdetails-usersettings">
      <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}>
        <DataGrid
          rows={availableRows}
          columns={columns}
          hideFooter={true}
          autoHeight={true}
          density={'compact'}
          customHeadRender={() => null }
          processRowUpdate={(updatedRow, originalRow) => {
            
            // number validation
            if (originalRow.type === 'number') {
              if (!updatedRow.value || isNaN(updatedRow.value) || updatedRow.value < 0 || updatedRow.value > Number.MAX_SAFE_INTEGER) {
                updatedRow.value = originalRow.value;
              }
              updatedRow.value = parseInt(updatedRow.value);
            }

            // update resource limits in db
            if (updatedRow.linked_to?.resource) {
              setConfigSettings((prev) => mergeDeep(
                prev,
                {
                  limits: [
                    {
                      resource: updatedRow.linked_to.resource,
                      limit_max: updatedRow.value,
                      limit_window: updatedRow.limit_window,
                    },
                  ],
                }
              ));
            }
            // update third-party service(s) limits in db
            else if (updatedRow.linked_to?.service && updatedRow.linked_to?.domain) {
              setConfigSettings((prev) => mergeDeep(
                prev,
                {
                  third_party_service_limits: [
                    {
                      service: updatedRow.linked_to.service,
                      limits: [
                        {
                          domain: updatedRow.linked_to.domain,
                          limit_max: updatedRow.value,
                          limit_window: updatedRow.limit_window,
                        },
                      ],
                    },
                  ],
                },
              ));
            }

            // update user credit
            else if (updatedRow.key === 'user_credit_amount') {
              setConfigSettings((prev) => mergeDeep(
                prev,
                {
                  user_credit: {
                    amount: updatedRow.value
                  },
                },
              ));
            }

            // update the limit in datagrid (ui)
            const updatedRows = availableRows.map(x => {
              if (x.id === originalRow.id) return updatedRow;
              else return x;
            })
            setAvailableRows(updatedRows);

            return updatedRow;
          }}
          onProcessRowUpdateError={(err) => {
            console.log(err);
          }}
          isCellEditable={(params) => {
            if (params.row.editable) return true;
          }}
          sx={
            {
              m: 1,
              // hide header
              "& .MuiDataGrid-columnHeaders": { display: "none" },
              "& .MuiDataGrid-virtualScroller": { marginTop: "0!important" },
              // highlight admin-visible rows
              "& .MuiDataGrid-row--admin-visible": {
                bgcolor: '#e0e0e0',
                '&:hover': {
                  bgcolor: '#d0d0d0',
                }
              }
            }
          }
          getRowClassName={(params) => {
            if (params.row.key.endsWith('*')) {
              return `MuiDataGrid-row--admin-visible`;
            }
          }}
        />
      </Grid>
      <DialogActions
        sx={{ 
          mt: 2,
          alignItems: "end",
          justifyContent: "flex-end"
        }}
      >
        {/* <Button
          type="submit"
          variant="outlined"
          color="error"
          onClick={handleDiscardSettings}
          startIcon={<CancelIcon />}
          sx={{ boxShadow: 10 }}
        >
          {"Discard changes"}
        </Button> */}
        <Button
          type="submit"
          variant="outlined"
          color="info"
          onClick={handleSaveSettings}
          startIcon={<SaveIcon />}
          sx={{ boxShadow: 10 }}
          disabled={isEmpty(configSettings)}
        >
          {"Save changes"}
        </Button>
      </DialogActions>
    </Grid>
  );
}
