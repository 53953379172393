import React, { useState } from 'react';
import {  Button, ListItemIcon, ListItemText, Menu, MenuItem, Fade } from '@mui/material';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Login as LoginIcon,
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { loginContextUser } from '../../../actions/auth';

export default function UserActions(props) {

  const dispatch = useDispatch();
  const { context_user } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="user-action-button"
        aria-controls={open ? 'user-action-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="outlined"
        disableElevation
        endIcon={<KeyboardArrowDownIcon />}
      >
        Actions
      </Button>
      <Menu
        id="user-action-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'user-action-button',
        }}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={() => dispatch(loginContextUser(context_user))}
        >
          <ListItemIcon>
            <LoginIcon color={"primary"}/>
          </ListItemIcon>
          <ListItemText>
            {`Login as ${context_user.email_address}`} 
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
