import { Paper } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import { setMessage } from '../actions/message';
import { clearShoppingCart } from '../actions/shopping_cart';
import { HomePageLinks } from './HomePageLinks';
import { UserSummary, UsersGetAll } from './Users';

export default function Home() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const redirect_status = searchParams?.get('redirect_status')

  // redirect from successful paid order payment
  if (redirect_status === 'succeeded') {

    // [TODO] - review 
    setSearchParams(createSearchParams(''));
    navigate('/');

    // set success message
    dispatch(setMessage({
      info: "New Paid Order has been successfully created. Our data experts will be in touch with you when it is ready" 
    }));

    // clear shopping cart
    dispatch(clearShoppingCart());
  }

  return (
    <Paper id="home" sx={{ boxShadow: 10, borderRadius: 1, p: 2 }}>
      <UserSummary />
      <UsersGetAll />
      <HomePageLinks />
    </Paper>
  )
};
