import React from 'react'
import { Alert, AlertTitle, List, ListItem, Typography } from '@mui/material';
import { isEmpty, priceDisplay } from '../../lib/Utils';

export default function OrderPriceChangeNotification(props) {
  
  const { data } = props;
  if (isEmpty(data)) return null;

  const {
    reasons,
    currency,
    decimals,
    total_price,
    order_amount,
  } = data;

  const priceToDisplay = (price) => priceDisplay(price, decimals, currency);

  return (
    <Alert
      sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}
      severity="info"
      variant="outlined"
    >
      <AlertTitle><strong>Total Price change notification!</strong></AlertTitle>
      <Typography variant="body2">
        The total price of the Order was adjusted from <strong>{priceToDisplay(total_price)}</strong> to <strong>{priceToDisplay(order_amount)}</strong>, because:
      </Typography>
      <List dense>
        <>
          { 
            reasons.map(reason => (
              <ListItem key={reason.items_type}>
                <Typography variant='body2'>
                  <> - the total price for all <strong>{reason.name}</strong> in the Order</>
                  { !reason.min_amount_met ?
                    <> was changed from <strong>{priceToDisplay(reason.total_price)}</strong> to <strong>{priceToDisplay(reason.order_amount)}</strong> (the minimum amount of <strong>{priceToDisplay(reason.min_amount)}</strong> has <em>not</em> been met);</>
                    :
                    <> is <strong>{priceToDisplay(reason.order_amount)}</strong> (the minimum amount of <strong>{priceToDisplay(reason.min_amount)}</strong> has been met);</>
                  }
                </Typography>
              </ListItem>
            ))
          }
        </>
      </List>
    </Alert>
  )
};
