import {
  Settings as SettingsIcon,
  Feedback as FeedbackIcon,
  MenuBook as MenuBookIcon,
  AccountCircle as AccountCircleIcon,
  FileCopy as FileCopyIcon,
  DoneAll as DoneAllIcon,
  Info as InfoIcon,
  BarChart as BarChartIcon,
  History as HistoryIcon,
  AttachFile as AttachFileIcon,
  UploadFileRounded as UploadListIcon,
  OpenWith as ExpandeAudienceIcon,
  CrisisAlert as ActivateAudienceIcon,
  AccountBox as AudienceIcon,
  RecentActors as AudiencesIcon,  
  ShoppingCart as ShoppingCartIcon,
  Payment as PaymentIcon,
  FmdGood as PixelIcon,
  AddLocationAlt as PixelAddIcon, 
} from '@mui/icons-material';

export const categories = [
  {
    id: 'Analyze',
    description: 'Step 1',
    children: [
      {
        id: 'Upload List',
        // icon: <InsertDriveFileIcon />,
        icon: <UploadListIcon />,
        link_to: '/lists/new',
      },
      {
        id: 'Lists',
        icon: <FileCopyIcon />,
        link_to: '/lists',
        //disabled: true,
      },
      {
        id: 'Analytics',
        icon: <BarChartIcon />,
        link_to: '/lists/analytics',
        //disabled: true,
        hidden: true,
      },
    ],
  },
  {
    id: 'Target',
    description: 'Step 2',
    children: [
      {
        id: 'Expand Audience',
        //icon: <DoneIcon />,
        icon: <ExpandeAudienceIcon />,
        link_to: '/xaudiences/new',
      },
      { 
        id: 'XAudiences',
        icon: <DoneAllIcon />,
        link_to: '/xaudiences',
      },
      {
        id: 'Analytics',
        icon: <BarChartIcon />,
        link_to: '/xaudiences/analytics',
        //disabled: true,
        hidden: true,
      },      
      { 
        id: 'XAudience Details',
        sub_id: ':key',
        link_to: '/xaudiences/:key',
        icon: <InfoIcon />,
        hidden: true,
      },
      { 
        id: 'XAudience Analytics',
        sub_id: ':key',
        link_to: '/xaudiences/:key/analytics',
        icon: <BarChartIcon />,
        hidden: true,
      },
      { 
        id: 'XAudience History',
        sub_id: ':key',
        link_to: '/xaudiences/:key/history',
        icon: <HistoryIcon />,
        hidden: true,
      },
    ],
  },
  {
    id: 'Activate',
    description: 'Step 3',
    children: [
      {
        id: 'Delivery Partners',
        // icon: <CloudUploadIcon />,
        icon: <ActivateAudienceIcon />,
        link_to: '/activate/partners',
        // disabled: true,
      },
    ],
  },
  // {
  //   id: 'Explore',
  //   description: '... and create',
  //   children: [
  //     {
  //       id: 'Audience',
  //       //icon: <DoneIcon />,
  //       icon: <AudienceIcon />,
  //       link_to: '/audiences/new',
  //     },
  //     { 
  //       id: 'Audiences',
  //       icon: <AudiencesIcon />,
  //       link_to: '/audiences',
  //     },
  //     { 
  //       id: 'Audience Details',
  //       sub_id: ':key',
  //       link_to: '/audiences/:key',
  //       icon: <InfoIcon />,
  //       hidden: true,
  //     },
  //     {
  //       id: 'Audience History',
  //       sub_id: ':key',
  //       link_to: '/audiences/:key/history',
  //       icon: <HistoryIcon />,
  //       hidden: true,
  //     },
  //   ],
  // },
  {
    id: 'Track',
    description: 'your website visitors',
    children: [
      {
        id: 'Pixel',
        icon: <PixelAddIcon />,
        link_to: '/pixels/new',
        hidden: true,
      },
      { 
        id: 'Omni Pixel',
        icon: <PixelIcon />,
        link_to: '/pixels',
      },
      { 
        id: 'Pixels Details',
        sub_id: ':key',
        link_to: '/pixels/:key',
        icon: <PixelIcon />,
        hidden: true,
      },
    ],
  },
  {
    id: 'Data Clean Room',
    description: 'file storage',
    children: [
      {
        id: 'Upload File',
        icon: <AttachFileIcon />,
        link_to: '/files/new',
      },
      {
        id: 'Files',
        icon: <FileCopyIcon />,
        link_to: '/files',
        //disabled: true,
      },
    ],
  },
  {
    id: 'General',
    children: [
      {
        id: 'Account',
        icon: <AccountCircleIcon />,
        link_to: '/account',
      },
      {
        id: 'Shopping Cart',
        icon: <ShoppingCartIcon />,
        link_to: '/cart',
      },
      {
        id: 'Orders',
        icon: <PaymentIcon />,
        link_to: '/orders',
      },
      {
        id: 'Settings',
        icon: <SettingsIcon />,
        link_to: '/settings',
        disabled: true,
        hidden: true,
      },
      {
        id: 'Documentation',
        icon: <MenuBookIcon />,
        link_to: '/documentation',
        disabled: true,
        hidden: true,
      },
      {
        id: 'Feedback', 
        icon: <FeedbackIcon />,
        link_to: '/feedback',
      },
    ],
  },
];