import moment from 'moment-timezone';

// default to UTC
moment.tz.setDefault("UTC");

export const intervals = {
	today: {
		title: "Today",
		startDate: moment().startOf("day").toDate(), // today 00:00:00Z (included)
		endDate: moment().add(1, "day").startOf("day").toDate(), // tomorrow 00:00:00Z (not included)
	},
	yesterday: {
		title: "Yesterday",
		startDate: moment().subtract(1, "day").startOf("day").toDate(), // 1 day from today (yesterday) 00:00:00Z (inluded)
		endDate: moment().startOf("day").toDate(), // today 00:00:00Z (not included)
	},
	last_7_days: {
		title: "Last 7 days",
		startDate: moment().endOf("day").subtract("7", "day").startOf("day").toDate(), // 7 days ago from today 00:00:00Z (inluded)
		endDate: moment().add(1, "day").startOf("day").toDate(), // tomorrow 00:00:00Z (not included)
	},
	last_30_days: {
		title: "Last 30 days",
		startDate: moment().endOf("day").subtract("30", "day").startOf("day").toDate(), // 30 days ago from today 00:00:00Z (inluded)
		endDate: moment().add(1, "day").startOf("day").toDate(), // tomorrow 00:00:00Z (not included)
	},
	this_month: {
		title: "This month",
		startDate: moment().startOf("month").toDate(), // 1st day of the current month 00:00:00Z (inluded)
		endDate: moment().add(1, "day").startOf("day").toDate(), // tomorrow 00:00:00Z (not included)
	},
	previous_month: {
		title: "Previous month",
		startDate: moment().endOf("day").subtract(1, "month").startOf("month").toDate(), // 1st day of the previous month 00:00:00Z (inluded)
		endDate: moment().startOf("month").toDate(), // 1st day of the current month 00:00:00Z (not inluded)
	},
	last_3_months: {
		title: "Last 3 months",
		startDate: moment().endOf("day").subtract("3", "month").startOf("day").toDate(), // 3 months ago from today 00:00:00Z (inluded)
		endDate: moment().add(1, "day").startOf("day").toDate(), // tomorrow 00:00:00Z (not included)
	},
};

export const getCorrectDisplayDate = (date, type = 'from') => {
	if (date) {
		const extraOffset = type === 'to' ? 1 : 0;
		return new Date(date.getTime() + (date.getTimezoneOffset() * 60000) - extraOffset);
	}
	return date;
};

export const getDayStart = (date) => {
	if (date) return moment(date.toISOString()).startOf("day").toDate();
	return date;
};

export const getDayEnd = (date) => {
	if (date) return moment(date.toISOString()).endOf("day").toDate();
	return date;
};

export const getNextDayStart = (date) => {
	if (date) return moment(date.toISOString()).add(1, "day").startOf("day").toDate();
	return date;
};

export const minDate = moment().endOf("day").subtract("12", "month").startOf("day").toDate(); // 12 months ago from today 00:00:00Z (inluded)
export const maxDate = moment().add(1, "day").startOf("day").toDate(); // tomorrow 00:00:00Z (not included)
export const initialStartDate = moment().endOf("day").subtract("1", "month").startOf("day").toDate(); // 1 month ago from today 00:00:00Z (inluded)
export const initialEndDate = moment().add(1, "day").startOf("day").toDate(); // tomorrow 00:00:00Z (not included)
