import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Grid, Box, Tooltip, Typography, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  History as HistoryIcon,
} from '@mui/icons-material';
import { fileListSchema } from '../config';
import { getFiles } from '../../../actions/file';
import { bytesDisplay, isEmpty, sortBy } from '../../../lib/Utils';
import FileActions from './FileActions';
import { AutoRefresh, DataGridStatusColumn } from '../../Common';

export function FilesList(props = {}) {

  const { checkboxes } = props;
  const [availableRows, setAvailableRows] = useState([]);
  const [checkedRowIDs, setCheckedRowIDs] = useState([]);
  const [checkedFileKey, setCheckedFileKey] = useState();
  const [checkedFileUserKey, setCheckedFileUserKey] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  
  // sort by dt_last_updated desc, i.e., the latest updated first
  const defaultSort = sortBy('dt_last_updated', 'desc');

  const { file_list } = useSelector(state => state.data);
  const { user, context_user } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const handleGetFiles = () => {
    dispatch(getFiles());
  };

  // initial load
  useEffect(() => {

    // set 'file_list' state
    handleGetFiles();

    if (user.permissions?.resources?.includes('others.files')) {
      setIsAdmin(true);
    }
  }, [])

  // load when file_list is updated
  useEffect(() => {
    if (file_list) {
      const _availableRows = [...file_list].sort(defaultSort);
      setAvailableRows(_availableRows);
    }
  }, [file_list])

  // file.key
  useEffect(() => {
    if (checkboxes) {
      const checkedFile = availableRows.filter(x => x.id === checkedRowIDs[0])?.[0];
      setCheckedFileKey(checkedFile?.key);
      setCheckedFileUserKey(checkedFile?.user_key);
    } 
  }, [availableRows, checkedRowIDs])

  let columns = [];
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    () => fileListSchema.filter(x => x.hide === true).reduce((a, v) => ({ ...a, [v['field']]: false }), {})
  );

  const isUserKeyRequired = isAdmin && isEmpty(context_user);

  if (!isEmpty(file_list)) {
    columns = Object.keys(file_list[0]).map(x => {
      return {
        field: x,
        headerName: x,
      };
    });

    // set up from the config
    columns = fileListSchema.filter(x => x.id === file_list?.id);

    // remove all admin-visible columns if not an admin
    if (!isAdmin) {
      columns = columns.filter(x => !x.onlyVisibleToAdmin);
    }

    // enhance 'status' column
    columns[columns.findIndex(x => x.field === 'status')].renderCell = (params) => {
      return (
        <DataGridStatusColumn params={params} type={"files"} />
      );
    }

    // enhance 'name' column
    columns[columns.findIndex(x => x.field === 'name')].renderCell = (params) => {
      const { name, key: file_key, user_key } = params.row;
      
      return (
        <Grid container direction="row" sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
          <Grid item xs={10} zeroMinWidth>
            <Tooltip title={`See '${name}' File Details`} arrow>
              <Button 
                component={Link}
                to={{
                  pathname: `/files/${file_key}`,
                  search: isUserKeyRequired && `user_key=${user_key}`,
                }}
                sx={{ width: '100%', justifyContent: 'flex-start' }}
              >
                <Typography variant="inherit" noWrap>{name}</Typography>
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={2} zeroMinWidth sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
            <Stack direction="row" spacing={0} sx={{ justifyContent: 'center' }}>
              <Tooltip title={`See File History`} arrow>
                <Box
                  component={Link}
                  to={{
                    pathname: `/files/${file_key}/history`,
                    search: isUserKeyRequired && `user_key=${user_key}`,
                  }}
                >
                  <HistoryIcon
                    fontSize="small"
                    color="primary"
                    sx={{ display: 'flex' }}
                  />
                </Box>
              </Tooltip>
            </Stack>
          </Grid>
        </Grid>
      );
    }

    // enhance 'file_size' column
    columns[columns.findIndex(x => x.field === 'file_size')].renderCell = (params) => {
      return bytesDisplay(params.row.file_size);
    };

  }

  const handleMarkChecked = (selection) => {
    if (checkboxes) {
      if (selection.length > 1) {
        const selectionSet = new Set(checkedRowIDs);
        const result = selection.filter(x => !selectionSet.has(x));
        setCheckedRowIDs(result);
      }
      else {
        setCheckedRowIDs(selection);
      }
    }
  };

  const handleRowSelectable = (params) => true;

  return (
    <Grid container id="files-get-all-files-list">
      <Grid item xs={6} sx={{ my: 1 }}>
        <Typography><strong>Uploaded Files</strong></Typography>
      </Grid>
      <Grid item xs={6} sx={{ my: 1 }}>
        <AutoRefresh handler={handleGetFiles} />
      </Grid>
      <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, mt: 1 }}>
        <DataGrid
          rows={availableRows}
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(x) => setColumnVisibilityModel(x)}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10,50,100]}
          checkboxSelection={checkboxes ? true : false}
          disableRowSelectionOnClick={false}
          autoHeight={true}
          density={'compact'}
          rowSelectionModel={checkedRowIDs}
          onRowSelectionModelChange={handleMarkChecked}
          localeText={
            checkboxes ? { 
              footerRowSelected: (count) => <FileActions file_key={checkedFileKey} user_key={isUserKeyRequired && checkedFileUserKey} /> }
            : null
          }
          isRowSelectable={handleRowSelectable}
          sx={{
            m: 1,
            // hideSelectAllCheckbox
            '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
              display: 'none',
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
