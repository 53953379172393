import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { Alert, AlertTitle, Grid, Paper, Typography } from '@mui/material';
import { UploadAnalyticsBase } from './UploadAnalyticsBase';
import { UploadAnalyticsPaid } from './UploadAnalyticsPaid';
import { AnalyticsUpgrade }  from '../AnalyticsUpgrade';
import { isEmpty, uuidv4 } from '../../../lib/Utils';
import { getUploadAnalytics } from '../../../actions/upload';
import { AutoRefresh } from '../../Common';

export function UploadAnalytics(props) {

  const { embedded } = props;

  const { upload_list, upload, upload_analytics } = useSelector(state => state.data);
  const { user } = useSelector(state => state.auth);
  
  const dispatch = useDispatch();

  const params = useParams();
  const { upload_key } = params;

  // UploadAnalytics could be requested as a standalone call with no UploadDetails
  // so find the requested upload in upload_list, as we need upload.name
  const _upload = (upload.key === upload_key) ? upload : upload_list.find(x => x.key === upload_key);

  const upload_id = _upload?.id;
  const upload_name = _upload?.name;
  const upload_raw_table = _upload?.athena_raw_table || `raw_file_${upload_key}_parquet`;
  const upload_s3_key = _upload?.s3_location;

  const [searchParams] = useSearchParams();
  const user_key = searchParams.get('user_key')
  const upload_user_key = user_key || _upload?.user_key;
  
  const [randomKey, setRandomKey] = useState(uuidv4());

  const handleGetUploadAnalytics = () => {
    dispatch(getUploadAnalytics({
      upload_key,
      user_key,
    }));
  };

  // 'initial load'
  useEffect(() => {

    // update 'upload' state
    handleGetUploadAnalytics();
  }, [randomKey]);

  const handleComponentRefresh = () => {
    setRandomKey(uuidv4());
  }

  const isAnalyticsEmpty = isEmpty(Object.values(upload_analytics || {}).flat());

  const component = (
    <Grid container id="analytics-upload">
      <Grid item xs={6} sx={{ my: 1 }}>
        <Typography><strong>List Analytics</strong></Typography>
      </Grid>
      <Grid item xs={6} sx={{ my: 1 }}>
        <AutoRefresh handler={handleComponentRefresh} disabled={true} />
      </Grid>
      { !isAnalyticsEmpty ?
        <>
          <Grid item xs={12}>
            <UploadAnalyticsBase randomKey={randomKey} />
          </Grid>
          <Grid item xs={12}>
            <UploadAnalyticsPaid 
              randomKey={randomKey}
              upload_props={{
                id: upload_id,
                key: upload_key,
                name: upload_name,
                user_key: upload_user_key,
                raw_table: upload_raw_table,
                s3_key: upload_s3_key,
              }}
              user_key={user.key}
            />
          </Grid>
          <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}>
            <AnalyticsUpgrade />
          </Grid>
        </>
        :
        <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}>
          <Alert severity="error" variant="outlined">
            <AlertTitle>
              Not enought data to generate List Analytics! Try to refresh this List or upload another one
            </AlertTitle>
          </Alert>
        </Grid>
      }
    </Grid>
  );

  return (
    <>
      { !embedded ?
        <Paper id="upload-history" sx={{ boxShadow: 10, borderRadius: 1, p: 2 }}>
          {component}
        </Paper>
        : component
      }
    </>
  );
}
