import { dataGridColumns } from "../../../lib/Utils";

export const userListSchema = [
  {
    field: "id",
    headerName: "id",
    editable: false,
    sortable: true,
    hide: false,
    flex: 40,
    onlyVisibleToAdmin: true,
  },
  {
    field: "key",
    headerName: "key",
    editable: false,
    sortable: false,
    hide: false,
    flex: 90,
    onlyVisibleToAdmin: true,
  },
  {
    field: "email_address",
    headerName: "email",
    editable: false,
    sortable: true,
    hide: false,
    flex: 80,
    onlyVisibleToAdmin: true,
  },
  {
    field: "company_name",
    headerName: "company",
    editable: false,
    sortable: true,
    hide: false,
    flex: 80,
    onlyVisibleToAdmin: true,
  },
  {
    field: "phone_number",
    headerName: "phone",
    editable: false,
    sortable: true,
    hide: false,
    flex: 80,
    onlyVisibleToAdmin: true,
    ...dataGridColumns.display_phone,
  },
  {
    field: "dt_last_login",
    headerName: "last_login",
    editable: false,
    sortable: true,
    hide: false,
    flex: 120,
    onlyVisibleToAdmin: true,
  },
  {
    field: "dt_created",
    headerName: "created",
    editable: false,
    sortable: true,
    hide: false,
    flex: 120,
    onlyVisibleToAdmin: true,
  },
  {
    field: "upload_count",
    headerName: "lists",
    editable: false,
    sortable: true,
    hide: false,
    flex: 60,
    onlyVisibleToAdmin: true,
  },
  {
    field: "run_count",
    headerName: "xaudiences",
    editable: false,
    sortable: true,
    hide: false,
    flex: 60,
    onlyVisibleToAdmin: true,
  },
  {
    field: "file_count",
    headerName: "files",
    editable: false,
    sortable: true,
    hide: false,
    flex: 60,
    onlyVisibleToAdmin: true,
  },
  {
    field: "order_count",
    headerName: "orders",
    editable: false,
    sortable: true,
    hide: false,
    flex: 60,
    onlyVisibleToAdmin: true,
  },
  {
    field: "audience_count",
    headerName: "audiences",
    editable: false,
    sortable: true,
    hide: false,
    flex: 60,
    onlyVisibleToAdmin: true,
  },
];
