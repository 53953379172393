import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid, Tooltip, Typography, Stack, Button, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  BarChart as BarChartIcon,
  History as HistoryIcon,
} from '@mui/icons-material';
import { getRuns } from '../../../actions/run';
import { runListSchema } from '../config';
import { isEmpty, sortBy } from '../../../lib/Utils';
import RunActions from './RunActions';
import { AutoRefresh, DataGridStatusColumn } from '../../Common';

export function RunsList(props = {}) {

  const { checkboxes } = props;
  const [availableRows, setAvailableRows] = useState([]);
  const [checkedRowIDs, setCheckedRowIDs] = useState([]);
  const [checkedRunKey, setCheckedRunKey] = useState();
  const [checkedRunUserKey, setCheckedRunUserKey] = useState();
  const [isAdmin, setIsAdmin] = useState(false);

  // sort by dt_last_updated desc, i.e., the latest updated first
  const defaultSort = sortBy('dt_last_updated', 'desc');

  const { run_list } = useSelector(state => state.data);
  const { user, context_user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  
  // set the schema-required counts
  run_list?.forEach((item,index) => {
    item.xaudience_row_count = item.counts?.xaudience_row_count;

    run_list[index] = item;
  })

  const handleGetRuns = () => {
    dispatch(getRuns());
  };

  // initial load
  useEffect(() => {

    // set 'run_list' state
    handleGetRuns();

    if (user.permissions?.resources?.includes('others.runs')) {
      setIsAdmin(true);
    }
  }, []);
  
  // load when run_list is updated
  useEffect(() => {
    const _availableRows = run_list.filter(x => x).sort(defaultSort);
    setAvailableRows(_availableRows);
  }, [run_list])

  // run.key
  useEffect(() => {
    const checkedRun = availableRows.filter(x => x.id === checkedRowIDs[0])?.[0];
    setCheckedRunKey(checkedRun?.key);
    setCheckedRunUserKey(checkedRun?.user_key);
  }, [availableRows, checkedRowIDs])
  
  let columns = [];
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    () => runListSchema.filter(x => x.hide === true).reduce((a, v) => ({ ...a, [v['field']]: false }), {})
  );

  const isUserKeyRequired = isAdmin && isEmpty(context_user);

  if (!isEmpty(run_list)) {
    columns = Object.keys(run_list[0]).map(x => {
      return {
        field: x,
        headerName: x,
      };
    });

    // set up from the config
    columns = runListSchema.filter(x => x.id === run_list?.id);

    // remove all admin-visible columns if not an admin
    if (!isAdmin) {
      columns = columns.filter(x => !x.onlyVisibleToAdmin);
    }

    // enhance 'status' column
    columns[columns.findIndex(x => x.field === 'status')].renderCell = (params) => {      
      return (
        <DataGridStatusColumn params={params} type={"runs"} />
      );
    }

    // enhance 'name' column
    columns[columns.findIndex(x => x.field === 'name')].renderCell = (params) => {
      const { name, key: run_key, user_key } = params.row;

      return (
        <Grid container direction="row" sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
          <Grid item xs={10} zeroMinWidth>
            <Tooltip title={`See '${name}' XAudience Details`} arrow>
              <Button
                component={Link}
                to={{
                  pathname: `/xaudiences/${run_key}`,
                  search: isUserKeyRequired && `user_key=${user_key}`,
                }}
                sx={{ width: '100%', justifyContent: 'flex-start' }}
              >
                <Typography variant="inherit" noWrap>{name}</Typography>
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={2} zeroMinWidth sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
            <Stack direction="row" spacing={0} sx={{ justifyContent: 'center' }}>
              <Tooltip title={`See XAudience Analytics`} arrow>
                <Box
                  component={Link}
                  to={{
                    pathname: `/xaudiences/${run_key}/analytics`,
                    search: isUserKeyRequired && `user_key=${user_key}`,
                  }}
                >
                  <BarChartIcon
                    fontSize="small"
                    color="primary"
                    sx={{ display: 'flex' }}
                  />
                </Box>
              </Tooltip>
              <Tooltip title={`See XAudience History`} arrow>
                <Box 
                  component={Link}
                  to={{
                    pathname: `/xaudiences/${run_key}/history`,
                    search: isUserKeyRequired && `user_key=${user_key}`,
                  }}
                >
                  <HistoryIcon
                    fontSize="small"
                    color="primary"
                    sx={{ display: 'flex' }}
                  />
                </Box>
              </Tooltip>
            </Stack>
          </Grid>
        </Grid>
      );
    }
  }

  return (
    <Grid container id="runs-get-all-runs-list">
      <Grid item xs={6} sx={{ my: 1 }}>
        <Typography><strong>Created XAudiences (Expanded Audiences)</strong></Typography>
      </Grid>
      <Grid item xs={6} sx={{ my: 1 }}>
        <AutoRefresh handler={handleGetRuns} />
      </Grid>
      <Grid item xs={12} sx={{ boxShadow: 10, borderRadius: 1, my: 1 }}>
        <DataGrid
          rows={availableRows}
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(x) => setColumnVisibilityModel(x)}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10,50,100]}
          checkboxSelection={checkboxes ? true : false}
          disableRowSelectionOnClick={false}
          autoHeight={true}
          density={'compact'}
          rowSelectionModel={checkedRowIDs}
          onRowSelectionModelChange={(selection) => {
            if (selection.length > 1) {
              const selectionSet = new Set(checkedRowIDs);
              const result = selection.filter(x => !selectionSet.has(x));
              setCheckedRowIDs(result);
            }
            else {
              setCheckedRowIDs(selection);
            }
          }}
          localeText={{
            footerRowSelected: (count) => <RunActions run_key={checkedRunKey} user_key={isUserKeyRequired && checkedRunUserKey}/>
          }}
          sx={{
            m: 1,
            // hideSelectAllCheckbox
            '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
              display: 'none',
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
